import React from "react";
import Modal from "react-modal";
import "./orderModal.css";

Modal.setAppElement("#root");
const OrderModal = props => {
  return (
    <Modal
      isOpen={props.visible}
      onRequestClose={() => props.closeModal()}
      shouldCloseOnOverlayClick={true}
      closeTimeoutMS={1000}
      className="modal"
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "rgba(229, 239, 255, 0.6)",
        },
        content: {},
      }}>
      {props.children}
    </Modal>
  );
};

export default OrderModal;
