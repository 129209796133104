import React from "react";
import styled from "styled-components";
// import {useSelector} from "react-redux";
import {FaCheckCircle} from "react-icons/fa";

const AccountDetailContainer = ({onClick, wallet}) => {
  // const {wallet} = useSelector(state => state.storeWallet);

  if (wallet) {
    return (
      <MainContainer className="w-full bg-primary-light rounded-md md:px-4 px-2 md:py-4 py-2 relative">
        <div className="w-full flex flex-row items-center justify-between">
          {/* Acc_no */}
          <div>
            <p className="font-Bold text-1 text-white-lightGray text-xs">
              Account Number
            </p>
            <p className="font-Bold text-white-dim text-base text-details">
              {wallet?.account?.acc_no}
            </p>
          </div>
          {/* Acc_no */}
          <div>
            <p className="font-Bold text-1 text-white-lightGray text-xs text-right">
              Status
            </p>
            {wallet?.account_verify === true ? (
              <p className="font-Bold verified-text text-base text-details">
                Verified
              </p>
            ) : (
              <p className="font-Bold text-secondary-error text-xs text-details">
                Not-Verified
              </p>
            )}
          </div>
        </div>
        <div className="w-full flex flex-row items-center justify-between mt-5">
          {/* Acc_no */}
          <div>
            <p className="font-Bold text-1 text-white-lightGray text-xs">
              Account Name
            </p>
            <p className="font-Bold text-white-dim text-base text-details">
              {wallet?.account?.acc_name}
            </p>
          </div>
          {/* Acc_no */}
          <div>
            <p className="font-Bold text-1 text-white-lightGray text-xs text-right">
              Bank Name
            </p>
            <p className="font-Bold text-white-dim text-base text-details">
              {wallet?.account?.bank}
            </p>
          </div>
        </div>
        <div className="absolute bottom-0 abs-container bg-white-main px-3 py-2 right-0">
          {wallet.account_verify === true ? (
            <div className="flex flex-row items-center w-full h-full">
              <p className="text-primary-light text-xs font-SemiBold">
                Verified Account
              </p>
              <FaCheckCircle className="text-sm ml-1 verified-text " />
            </div>
          ) : (
            <div
              onClick={onClick}
              className="flex flex-row items-center w-full h-full cursor-pointer animate-bounce">
              <p className="text-secondary-error text-xs font-SemiBold underline">
                Verify Account
              </p>
            </div>
          )}
        </div>
      </MainContainer>
    );
  }
};

export default AccountDetailContainer;

const MainContainer = styled.div`
  /* background: linear-gradient(144.89deg, #20639b 21.04%, #58cced 97.54%); */

  height: 170px;
  .verified-text {
    color: #39ff14;
  }
  .abs-container {
    filter: drop-shadow(0px 60px 40px rgba(0, 0, 0, 0.07));
    border-top-left-radius: 10px;
    border-bottom-right-radius: 6px;
  }
  @media (max-width: 350px) {
    .text-1 {
      font-size: 10px;
    }
    .text-details {
      font-size: 12px;
    }
  }
`;
