import React, {useEffect} from "react";
import styled, {css} from "styled-components";
import {useNavigate} from "react-router-dom";
import about1 from "../../assets/images/about1.png";
import about2 from "../../assets/images/about2.png";
import about3 from "../../assets/images/about3.png";
import about5 from "../../assets/images/about5.png";
import {Helmet} from "react-helmet";
import MainContainerSlim from "../../components/common/mainContainer/mainContainerSlim";
import {GeneralPadding} from "../../styles/globalStyles";
import CV from "../../assets/images/CV.png";
import market2 from "../../assets/images/market2.png";
import {
  HeaderMixed,
  List,
  ListItems,
  HeaderLarge,
  ContainerWithBg,
} from "../../styles/globalStyles";

const Box = styled.div`
  &.header-bg {
    background: ${props => (props.blue ? "#d5dfe8" : "#f7ead5")};
    margin: 1em 0;
    width: max-content;
    padding: 0.5em 1em;
    border-radius: 10px;
    margin: 0 auto;

    @media (min-width: 768px) {
      margin: 0;
    }
  }

  &.about-images {
    display: flex;
    justify-content: space-between;
  }

  &.header-bg-container {
    display: flex;
  }

  &.vision {
    margin: 2em 0;

    @media (min-width: 768px) {
      &.vision {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        margin: 5em 0;
      }
    }
  }

  @media (min-width: 768px) {
    &.mission {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      margin: 3em 0;

      & > div {
        flex: 0 1 48%;
      }
    }

    &.values {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 2em;
      padding-bottom: 7em;

      & > div {
        flex: 0 1 48%;
      }
    }

    &.vision-container > &.header-bg-container {
      justify-content: flex-end;
    }
  }
`;

const Text = styled.p`
  margin: 0.5em 0;
  color: #718096;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: ${props => (props.cover ? "cover" : "contain")};
  border-radius: 5px;
`;

const Figure = styled.figure`
  flex: 0 1 calc(50% - 4px);
  height: 230px;

  @media (min-width: 768px) {
    height: 360px;
  }

  ${props =>
    props.second &&
    css`
      @media (min-width: 768px) {
        position: relative;
        top: 3em;
      }
    `}
`;

function AboutUs() {
  let navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const missions = [
    "To drive the evolution of e-commerce in Africa and provide limitless opportunities for African traders.",
    "To provide seamless access to markets across the continent for our users.",
    "To create new income opportunities in commerce and make them accessible to all.",
    "Digitizing African markets and connecting it to the rest of the world to hoist the African economy.",
  ];

  return (
    <MainContainerSlim>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Thrindle | About Us | Redefining commerce one market at a time.
        </title>
        <link rel="canonical" href="http://thrindle.com/about" />
        <meta
          name="description"
          content="We are a technology company providing solutions to barriers around traditional shopping by creating a platform for traders in local markets to sell their products online.
          
          "
        />
      </Helmet>
      <ContainerWithBg
        data-aos="fade-right"
        data-aos-easing="ease-in-out"
        data-aos-delay="0">
        <GeneralPadding>
          <HeaderMixed className="heading font-Bold">About us</HeaderMixed>

          <HeaderLarge>
            <List>
              <ListItems className="font-Bold">About Us</ListItems>

              <ListItems inactive onClick={() => navigate("/terms-of-service")}>
                Terms of Service
              </ListItems>
              <ListItems inactive onClick={() => navigate("/privacy-policy")}>
                Privacy Policy
              </ListItems>
            </List>
          </HeaderLarge>

          <div className="mt-6 md:flex md:justify-between md:items-center">
            <div className=" md:w-[46%]">
              <h1 className="font-Bold text-[32px] text-white-text">
                Redefining Commerce{" "}
                <span className="block text-primary-main">
                  One market at a time
                </span>
              </h1>
              <p className="text-white-text2 mt-4 text-justify">
                We are a technology company providing solutions to barriers
                around traditional shopping by creating a platform for traders
                in local markets to sell their products online.{" "}
              </p>
            </div>
            <img
              src={CV}
              alt="about"
              loading="eager"
              className="my-6 max-h-[320px] md:w-[48%] rounded-md"
            />
          </div>

          <Box className="mission">
            <Box>
              <Box className="header-bg-container md:justify-end">
                <Box className="header-bg ">
                  <HeaderMixed orange className="font-Bold">
                    Our Mission
                  </HeaderMixed>
                </Box>
              </Box>

              <ul className="list-disc text-white-text2 p-4 text-justify md:py-2 md:pl-12 md:pr-0 ">
                {missions.map((mission, index) => (
                  <li key={index} className="my-2">
                    {mission}
                  </li>
                ))}
              </ul>
            </Box>

            <Box className="about-images">
              <Figure>
                <Image src={about1} loading="eager" />
              </Figure>

              <Figure second>
                <Image src={about2} loading="eager" />
              </Figure>
            </Box>
          </Box>

          <Box className="vision">
            <Box className="vision-container md:w-[46%]">
              <Box className="header-bg-container">
                <Box className="header-bg" blue>
                  <HeaderMixed blue className="font-Bold">
                    Our Vision
                  </HeaderMixed>
                </Box>
              </Box>

              <Text className="pt-4 text-justify ">
                Thrindle’s vision is a synergized internet infrastructure
                powering African commerce without borders.
              </Text>
            </Box>

            <img
              src={market2}
              alt="about"
              loading="eager"
              className="my-6 max-h-[320px] md:w-[48%] rounded-md"
            />
          </Box>

          <Box className="values">
            <Box>
              <Box className="header-bg-container">
                <Box className="header-bg">
                  <HeaderMixed orange className="font-Bold">
                    Our Values
                  </HeaderMixed>
                </Box>
              </Box>

              <div className="my-6 text-justify text-white-text2">
                Our guiding principles are and will always be;
                <Text>
                  <span className="mr-2">&#8226;</span>
                  <span className="font-Bold">Transparency</span> with merchants
                  and consumers.
                </Text>
                <Text>
                  <span className="mr-2">&#8226;</span>
                  <span className="font-Bold">Passionate</span> about
                  championing Africa in what we do.
                </Text>
                <Text>
                  <span className="mr-2">&#8226;</span>
                  <span className="font-Bold">Consistency </span>
                  <span className="mr-2">-</span>We will not decrease our
                  standards at any point.
                </Text>
                <Text>
                  <span className="mr-2">&#8226;</span> We will remain&nbsp;
                  <span className="font-Bold">Innovative </span> to keep giving
                  you the best services and to achieve our mission.
                </Text>
              </div>
            </Box>

            <Box className="about-images">
              <Figure>
                <Image src={about3} loading="eager" />
              </Figure>

              <Figure second>
                <Image src={about5} loading="eager" />
              </Figure>
            </Box>
          </Box>
        </GeneralPadding>
      </ContainerWithBg>
    </MainContainerSlim>
  );
}

export default AboutUs;
