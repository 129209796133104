export const Notification = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 16.5C9.825 16.5 10.5 15.825 10.5 15H7.5C7.5 15.825 8.175 16.5 9 16.5ZM13.5 12V8.25C13.5 5.9475 12.2775 4.02 10.125 3.51V3C10.125 2.3775 9.6225 1.875 9 1.875C8.3775 1.875 7.875 2.3775 7.875 3V3.51C5.73 4.02 4.5 5.94 4.5 8.25V12L3 13.5V14.25H15V13.5L13.5 12ZM12 12.75H6V8.25C6 6.39 7.1325 4.875 9 4.875C10.8675 4.875 12 6.39 12 8.25V12.75Z"
      fill="#464F54"
    />
  </svg>
);
