import React from "react";
import styled, {css} from "styled-components";
import Footer from "../footer/footer";
import NavBar from "../navBar/navBar";
import StoreNavDetails from "../navBar/StoreNavContainer";

const Container = styled.div`
  padding-left: 1.5em;
  padding-right: 1.5em;

  @media (min-width: 768px) {
    padding-left: 3em;
    padding-right: 3em;
  }

  @media (min-width: 1024px) {
    padding-left: 5em;
    padding-right: 5em;
  }
  @media (min-width: 2560px) {
    padding-left: 10em;
    padding-right: 10em;
  }
`;

const Main = styled.main`
  @media (min-width: 1024px) {
    ${props =>
      props.removeFooter &&
      css`
        max-height: 100vh;
        overflow-y: hidden;
      `}
  }
`;

const MainContainer = ({children, removeFooter, storeNav}) => {
  return (
    <Main removeFooter={removeFooter}>
      <NavBar />
      {storeNav && <StoreNavDetails />}
      <Container>{children}</Container>
      <Footer />
    </Main>
  );
};

export default MainContainer;
