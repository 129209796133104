import {combineReducers} from "redux";
import {
  userLoginReducer,
  verifyNumberReducer,
  userRegisterReducer,
  resendOtpReducer,
  storeLoginNumberReducer,
  resetPasswordReducer,
  forgotPasswordReducer,
  storeRegisterDetailsReducer,
  storeForgotPasswordNumberReducer,
} from "./reducers/authReducers/index";
import {notificationReducer} from "./reducers/statusNotification/index";
import {
  getMarketsReducer,
  storeMarketTypeReducer,
  storeSellerDetailsReducer,
  createStoreReducer,
} from "./reducers/storeReducer/index";

import {storeActiveProductReducer} from "./reducers/productReducer/productReducer";
import {storeWalletReducer} from "./reducers/walletReducer/walletReducer";
const rootReducer = combineReducers({
  userLogin: userLoginReducer,
  verifyNumber: verifyNumberReducer,
  userRegister: userRegisterReducer,
  resendOtp: resendOtpReducer,
  storeLoginNumber: storeLoginNumberReducer,
  resetPassword: resetPasswordReducer,
  forgotPassword: forgotPasswordReducer,
  notification: notificationReducer,
  storeRegisterDetails: storeRegisterDetailsReducer,
  getMarkets: getMarketsReducer,
  storeMarketType: storeMarketTypeReducer,
  storeSellerDetails: storeSellerDetailsReducer,
  createStore: createStoreReducer,
  storeForgotPasswordNumber: storeForgotPasswordNumberReducer,
  storeActiveProduct: storeActiveProductReducer,
  storeWallet: storeWalletReducer,
});

export default rootReducer;
