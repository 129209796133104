import React from "react";
import styled from "styled-components";

const SingleStoreCategories = ({image, name}) => {
  return (
    <MainCont className="rounded-md mr-5">
      <div className=" rounded-md imageContainer w-full h-28">
        <img
          src={`${process.env.REACT_APP_API_URL_IMAGES}${image}`}
          alt="Category"
          className="block w-full h-full cat-image"
        />
      </div>
      <div className="h-1/4 bg-primary-light flex flex-row items-center cat-cont pl-3 py-1">
        <p className="text-white-main sm:text-sm text-xs font-Bold">{name}</p>
      </div>
    </MainCont>
  );
};

export default SingleStoreCategories;

const MainCont = styled.div`
  min-width: 150px;
  min-height: 150px;
  .cat-image {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }
  .cat-cont {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .imageContainer {
    max-height: 75% !important;
  }
`;
