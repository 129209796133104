import React, {useEffect, useState} from "react";
import styled from "styled-components";
import MainContainer from "../common/mainContainer/mainContainer";
import {NetworkErrorIcon} from "../../assets/svg/networkError";

const LoaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 90vh;
`;

const LoaderBox = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 3em;
  height: 3em;
  transform: translate(-50%, -50%);
`;

const Spin = styled.div`
  width: 100%;
  height: 100%;
  border: 2px solid #20639b;
  border-color: #20639b transparent #20639b transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Button = styled.button`
  display: block;
  border: 1px solid #f39e28;
  color: #f39e28;
  padding: 0.5em 1em;
  border-radius: 8px;
  width: max-content;
  margin: 2em auto;
  transition: all 0.5s ease-in-out;

  &:hover {
    color: white;
    background: #f39e28;
  }
`;

const MainLoader = ({onClick}) => {
  const [networkFailure, setNetworkFailure] = useState(false);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      setTimeout(() => setNetworkFailure(true), 30000);
    }

    return () => {
      mounted = false;
      clearTimeout(3000);
    };
  });

  return (
    <MainContainer>
      <LoaderContainer>
        {!networkFailure ? (
          <LoaderBox>
            <Spin></Spin>
          </LoaderBox>
        ) : (
          <Container>
            <div>
              <div className="w-200px max-w-[300px]">{NetworkErrorIcon}</div>
              <Button onClick={onClick}>Refresh</Button>
            </div>
          </Container>
        )}
      </LoaderContainer>
    </MainContainer>
  );
};

export default MainLoader;
