import React from "react";

const StoreButton = ({text, blue, onClick, loading}) => {
  return (
    <button
      onClick={onClick}
      className={
        blue
          ? " px-5 py-3 rounded-[3px] w-32 h-12  flex justify-center items-center bg-primary-light text-white-main"
          : "px-5 py-3 rounded-[3px] w-36 h-12 flex justify-center items-center bg-white-main border border-primary-light text-primary-light"
      }>
      {loading ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30px"
          height="30px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid">
          <circle
            cx="50"
            cy="50"
            fill="none"
            stroke="#fff"
            strokeWidth="10"
            r="35"
            strokeDasharray="164.93361431346415 56.97787143782138">
            <animateTransform
              attributeName="transform"
              type="rotate"
              repeatCount="indefinite"
              dur="1s"
              values="0 50 50;360 50 50"
              keyTimes="0;1"></animateTransform>
          </circle>
        </svg>
      ) : (
        <p>{text}</p>
      )}
    </button>
  );
};

export default StoreButton;
