import React, {useState, useEffect} from "react";
import MainContainer from "../../components/common/mainContainer/mainContainerWithBg";
import {mainApi} from "../../utils/axiosInstance";
import icon from "../../assets/svg/notificationIcon.svg";
import Loader from "../../components/Loader/Loader";
import EmptyNotification from "../../components/emptyStates/EmptyNotifications";
import {useDispatch} from "react-redux";
import {showNotification} from "../../redux/actions/statusNotifications";
import {formatDate} from "../../utils/formatPrices";

const Notifications = () => {
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const getNotification = async () => {
      try {
        const {data} = await mainApi.get(
          `${process.env.REACT_APP_API_URL}notifications`,
        );
        setLoading(false);
        const res = data.data;
        //  console.log(res)
        setResult(res);
        dispatch(
          showNotification({
            notify: {
              status: 1,
              message: `Notifications Loaded successfully`,
            },
          }),
        );
      } catch (error) {
        setLoading(false);
        if (!error.response) {
          dispatch(
            showNotification({
              notify: {
                status: 2,
                message: "Please check that you're connected",
              },
            }),
          );
        } else if (error.response) {
          dispatch(
            showNotification({
              notify: {
                status: 2,
                message: `${error.response.data.message}`,
              },
            }),
          );
        }
      }
    };

    getNotification();
  }, [dispatch]);

  if (loading) {
    return <Loader />;
  }
  return (
    <MainContainer>
      <div className="py-20 ">
        <p className="text-center text-xl font-bold mb-10">Notifications</p>
        {!loading && result.length === 0 && (
          <div className="flex justify-center items-center">
            <div>
              <EmptyNotification />
            </div>
          </div>
        )}

        {!loading && result.length !== 0 && (
          <div className="flex bg-white-main shadow-sm rounded-lg h-[500px] sm:w-[500px] mx-auto">
            <div className="flex-col justify-center overflow-y-scroll items-center  mx-auto  px-2">
              {result.map(({message, _id, createdAt}) => (
                <div
                  key={_id}
                  className="flex gap-5 mt-10 border-b border-gray-400 pb-3">
                  <img src={icon} alt="image" className="h-[50px] w-[50px]" />
                  <div>
                    <p className="text-base font-bold">{message}</p>
                    <p className="text-primary-light text-sm">
                      {formatDate(createdAt)}
                    </p>
                  </div>
                  <hr />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </MainContainer>
  );
};

export default Notifications;
