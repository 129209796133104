import React, {useState, useEffect, useCallback} from "react";
import MainContainer from "../../components/common/mainContainer/mainContainer";
import MainTab from "../../components/common/tab/MainTab";
// import profile from "../../assets/images/profile.png";
import FieldSetInput from "../../components/common/inputs/FieldSetInput";
import InputWithFieldSet from "../../components/common/inputs/inputWithFieldSet";
// import Dropdown from "../../components/common/inputs/dropdown";
import Button from "../../components/common/buttons/button";
import styled from "styled-components";
import ModifyWithdrawalModal from "./modals/modifyWithdrawalPin";
import UpdatePasswordModal from "./modals/updatePasswordModal";
import {mainApi} from "../../utils/axiosInstance";
import Loader from "../../components/Loader/Loader";
import dummy from "../../assets/images/dummyprofile.png";
// import { useLocation } from "react-router-dom";
import loaderblue from "../../assets/svg/loaderblue.svg";
import {useDispatch} from "react-redux";
import {showNotification} from "../../redux/actions/statusNotifications";
const Container = styled.div`
  &.main {
    @media (min-width: 768px) {
      width: 70%;
      margin: 2em auto 4em;
    }

    @media (min-width: 1024px) {
      width: 50%;
    }
  }
`;

function Profile() {
  const [activeTab, setActiveTab] = useState("Personal Info");
  const tabData = ["Personal Info", "Verified Info"];
  const dispatch = useDispatch();

  const [personalInfo, setPersonalInfo] = useState(true);
  const [verifiedInfo, setVerifiedInfo] = useState(false);
  // update password modal
  const [updatePassword, setUpdatePassword] = useState(false);
  // update withdrawal pin modal
  const [modifyPin, setModifyPin] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  // profile dteails
  const [details, setDetails] = useState([]);
  // loading state
  const [loading, setLoading] = useState(true);
  // profile details starts here
  const [profileName, setProfileName] = useState("");
  const [profileLocation, setProfileLocation] = useState("");
  const [profileGender, setProfileGender] = useState("");
  // profile details ends here

  // update profile
  const [updateLoading, setUpdateLoading] = useState(false);
  // uopload profile image
  const [uploadFile, setUploadFile] = useState("");
  // set the image from request to show on jsx
  const [mainImage, setMainImage] = useState("");

  //uolpad options
  const [imageOptions, setImageOptions] = useState(true);
  const [uploadLoading, setUploadLoading] = useState(false);
  // const [resetOptions, setResetOptions] =useState(false)
  // preview image to be uploaded
  const [preview, setPreview] = useState();
  // to show either thr main image or preview
  const [loaded, setLoaded] = useState(false);

  const handleClick = val => {
    setActiveTab(val);

    if (val === "Verified Info") {
      setPersonalInfo(false);
      setVerifiedInfo(true);
    }

    if (val === "Personal Info") {
      setPersonalInfo(true);
      setVerifiedInfo(false);
    }
  };

  // shows upDate password modal
  const updatePasswordModal = () => {
    setUpdatePassword(true);
    setDisplayModal(true);
  };

  // shows update withdrawal pin modal
  const modifyWithdrawalModal = () => {
    setModifyPin(true);
    setDisplayModal(true);
  };

  const getProfileDetails = useCallback(async () => {
    try {
      const {data} = await mainApi.get(
        `${process.env.REACT_APP_API_URL}users/me`,
      );
      const res = data.data;
      if (res) {
        setDetails(res);
        setProfileName(res.name);
        setProfileLocation(res.location);
        setProfileGender(res.gender);
        setMainImage(`${process.env.REACT_APP_API_URL_IMAGES}${res.photo}`);
        setLoading(false);
        dispatch(
          showNotification({
            notify: {
              status: 1,
              message: `Profile successfully Loaded`,
            },
          }),
        );
      }
    } catch (error) {
      if (!error.response) {
        dispatch(
          showNotification({
            notify: {
              status: 2,
              message: `${error}`,
            },
          }),
        );
      } else if (error.response) {
        dispatch(
          showNotification({
            notify: {
              status: 2,
              message: `${error.response.data.message}`,
            },
          }),
        );
      }
    }
  }, [dispatch]);

  useEffect(() => {
    setLoaded(true);

    let mounted = true;

    if (mounted) {
      if (displayModal) {
        document.documentElement.style.overflow = "hidden";
        document.body.style.overflow = "hidden";
      } else {
        document.documentElement.style.overflow = "revert";
        document.body.style.overflow = "revert";
      }

      // get profile dtails

      getProfileDetails();
    }

    return () => (mounted = false);
  }, [dispatch, getProfileDetails]);

  const {name, gender, phone, location} = details;

  // update profile

  const updateProfile = async e => {
    setUpdateLoading(true);
    e.preventDefault();
    const config = {
      name: profileName,
      gender: profileGender,
      location: profileLocation,
    };
    if (profileGender !== "" && profileLocation !== "" && profileName !== "")
      setUpdateLoading(true);
    try {
      const {data} = await mainApi.put(
        `${process.env.REACT_APP_API_URL}users/updateprofile`,
        config,
      );
      setUpdateLoading(false);
      dispatch(
        showNotification({
          notify: {
            status: 1,
            message: `${data.message}`,
          },
        }),
      );
    } catch (error) {
      if (!error.response) {
        dispatch(
          showNotification({
            notify: {
              status: 2,
              message: "Please check that you're connected",
            },
          }),
        );
      } else if (error.response) {
        dispatch(
          showNotification({
            notify: {
              status: 2,
              message: `${error.response.data.message}`,
            },
          }),
        );
      }
      setUpdateLoading(false);
    }
  };

  // event handler for change profile image
  const changeHandler = event => {
    // check to see if the image  object start with iage
    let newImage = event.target.files[0];
    if (newImage.type.startsWith("image")) {
      setUploadFile(URL.createObjectURL(event.target.files[0]));
      setPreview(event.target.files[0]);
      setMainImage(event.target.files[0].name);
      setImageOptions(false);
      setLoaded(false);
    } else {
      dispatch(
        showNotification({
          notify: {
            status: 2,
            message: "Please upload an Image file",
          },
        }),
      );
    }
  };

  const handleSubmission = async () => {
    let formData = new FormData();

    formData.append("photo", preview);
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}users/profilepic`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    try {
      setUploadLoading(true);
      const {data} = await mainApi(config);
      const res = data.data;
      if (res) {
        setMainImage(`${process.env.REACT_APP_API_URL_IMAGES}${res.photo}`);
        setLoaded(true);

        setUploadLoading(false);
        setImageOptions(true);
        dispatch(
          showNotification({
            notify: {
              status: 1,
              message: `${data.message}`,
            },
          }),
        );
      }
    } catch (error) {
      setUploadLoading(false);
      if (!error.response) {
        dispatch(
          showNotification({
            notify: {
              status: 2,
              message: `${error}`,
            },
          }),
        );
      } else if (error.response) {
        dispatch(
          showNotification({
            notify: {
              status: 2,
              message: `Unable to change profile picture`,
            },
          }),
        );
      }

      // console.log(error);
    }
  };

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (displayModal) {
        document.documentElement.style.overflow = "hidden";
        document.body.style.overflow = "hidden";
      } else {
        document.documentElement.style.overflow = "revert";
        document.body.style.overflow = "revert";
      }
    }

    return () => (mounted = false);
  }, [displayModal]);

  //  console.log(uploadFile.name);

  if (loading) {
    return <Loader func={getProfileDetails} />;
  }

  return (
    <MainContainer storeNav>
      <div className="mt-8">
        {" "}
        <MainTab
          activeTab={activeTab}
          tabData={tabData}
          onClick={val => handleClick(val)}
        />
      </div>
      <Container className="main">
        <div className="flex-col">
          {mainImage ? (
            <img
              src={loaded ? mainImage : uploadFile}
              alt="profile-picture"
              className="rounded-full mr-auto ml-auto  block"
              style={{height: "200px", width: "200px"}}
            />
          ) : (
            <img
              src={dummy}
              alt="profile-picture"
              className="rounded-full mr-auto ml-auto block"
              style={{height: "200px", width: "200px"}}
            />
          )}
          {imageOptions ? (
            <div className="upload-btn-wrapper w-full mx-auto mt-4 ">
              <p className="btn w-full text-center mx-auto">
                Tap to change profile picture
              </p>
              <input type="file" name="file" onChange={changeHandler} />
            </div>
          ) : uploadLoading ? (
            <img src={loaderblue} className="w-32 py-6 mx-auto  " />
          ) : (
            <p
              className="text-center text-xl mt-2 font-bold"
              onClick={handleSubmission}>
              Tap to save
            </p>
          )}
        </div>

        {personalInfo && (
          <>
            <form className="my-6" onSubmit={updateProfile}>
              <div className="my-2">
                <FieldSetInput
                  fieldset="Full Name"
                  placeholder={name}
                  // className="profilePlaceholder"
                  value={profileName}
                  // onChange={(e) => e.target.value}
                  onChange={e => setProfileName(e)}
                />
              </div>
              <div className="my-2">
                <FieldSetInput
                  fieldset="Location"
                  placeholder={location}
                  value={profileLocation}
                  onChange={e => setProfileLocation(e)}
                  className="profilePlaceholder"
                />
              </div>
              <div className="my-2">
                <FieldSetInput
                  fieldset="Gender"
                  value={profileGender}
                  placeholder={gender}
                  className="profilePlaceholder"
                  onChange={e => setProfileGender(e)}
                />
              </div>

              <div className="my-8">
                <Button
                  text={"Update Profile"}
                  processing={updateLoading}
                  blueBg={true}
                  longButton={true}
                />
              </div>
            </form>
          </>
        )}
        {verifiedInfo && (
          <>
            <form className="my-6">
              <div className="my-2">
                <InputWithFieldSet
                  fieldset="Phone No"
                  value={phone}
                  onChange={() => e.target.value}
                />
              </div>
              {/* <div className="my-2">
                <InputWithFieldSet
                  fieldset="Email"
                  value={email}
                  onChange={() => e.target.value}
                />
              </div> */}
              <div className="flex align-center justify-between my-4 text-white-main2 cursor-pointer">
                <p onClick={updatePasswordModal}>Update Password</p>
                <svg
                  width="7"
                  height="13"
                  viewBox="0 0 7 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.282921 11.3579C0.118645 11.5436 0.02738 11.788 0.0365062 12.0421C0.0365062 12.2962 0.127771 12.5308 0.301174 12.7165C0.46545 12.8925 0.693612 13 0.9309 13C1.16819 13 1.39635 12.912 1.56975 12.7361L6.73533 7.20376C6.81747 7.11579 6.88136 7.00827 6.92699 6.89098C6.97262 6.77368 7 6.64662 7 6.51955C7 6.39248 6.97262 6.26541 6.92699 6.13835C6.88136 6.02105 6.81747 5.91353 6.73533 5.82556L1.56975 0.293233C1.48761 0.205263 1.38722 0.127068 1.27771 0.0781955C1.15906 0.0293233 1.04042 0 0.921774 0C0.80313 0 0.675359 0.0195489 0.565841 0.0684211C0.456324 0.117293 0.355933 0.195489 0.264668 0.283459C0.18253 0.371429 0.118645 0.488722 0.0730124 0.606015C0.0182533 0.723308 4.76837e-07 0.850376 4.76837e-07 0.987218C4.76837e-07 1.11429 0.02738 1.24135 0.0730124 1.35865C0.118645 1.47594 0.191656 1.58346 0.282921 1.6812L4.80052 6.51955L3.94263 7.42857L0.282921 11.3579Z"
                    fill="#9FA5C0"
                  />
                </svg>
              </div>
            </form>
            {updatePassword && (
              <UpdatePasswordModal
                displayModal={displayModal}
                setUpdatePassword={setUpdatePassword}
                setDisplayModal={setDisplayModal}
              />
            )}
            <div className="flex align-center justify-between my-4 text-white-main2 cursor-pointer">
              <p onClick={modifyWithdrawalModal}>Modify withdrawal pin</p>
              <svg
                width="7"
                height="13"
                viewBox="0 0 7 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.282921 11.3579C0.118645 11.5436 0.02738 11.788 0.0365062 12.0421C0.0365062 12.2962 0.127771 12.5308 0.301174 12.7165C0.46545 12.8925 0.693612 13 0.9309 13C1.16819 13 1.39635 12.912 1.56975 12.7361L6.73533 7.20376C6.81747 7.11579 6.88136 7.00827 6.92699 6.89098C6.97262 6.77368 7 6.64662 7 6.51955C7 6.39248 6.97262 6.26541 6.92699 6.13835C6.88136 6.02105 6.81747 5.91353 6.73533 5.82556L1.56975 0.293233C1.48761 0.205263 1.38722 0.127068 1.27771 0.0781955C1.15906 0.0293233 1.04042 0 0.921774 0C0.80313 0 0.675359 0.0195489 0.565841 0.0684211C0.456324 0.117293 0.355933 0.195489 0.264668 0.283459C0.18253 0.371429 0.118645 0.488722 0.0730124 0.606015C0.0182533 0.723308 4.76837e-07 0.850376 4.76837e-07 0.987218C4.76837e-07 1.11429 0.02738 1.24135 0.0730124 1.35865C0.118645 1.47594 0.191656 1.58346 0.282921 1.6812L4.80052 6.51955L3.94263 7.42857L0.282921 11.3579Z"
                  fill="#9FA5C0"
                />
              </svg>
            </div>
            {modifyPin && (
              <ModifyWithdrawalModal
                displayModal={displayModal}
                setDisplayModal={setDisplayModal}
                setModifyPin={setModifyPin}
              />
            )}
          </>
        )}
      </Container>
    </MainContainer>
  );
}

export default Profile;
