import React from "react";

function FlexedButton({
  firstBtnText,
  secondBtnText,
  firstBtnType,
  secondBtnType,
  onClickCancel,
}) {
  return (
    <div className="flex justify-center mt-8 w-4/5 mr-auto ml-auto">
      <button
        type={firstBtnType}
        className="border border-primary-main px-6 py-2 rounded-md"
        onClick={onClickCancel}>
        {firstBtnText}
      </button>
      <button
        type={secondBtnType}
        className="bg-primary-main text-white-main px-6 py-2 ml-4 rounded-md">
        {secondBtnText}
      </button>
    </div>
  );
}

export default FlexedButton;
