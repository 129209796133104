import React, {useState,useEffect} from "react";
import "./App.css";
import {routes} from "./routes/appRoutes";
import {Routes, Route} from "react-router-dom";
import Login from "./pages/auth/login/Login";
import SignUp from "./pages/auth/signUp/signUp";
import Home from "./pages/home/Home";
import ProtectedRoute from "./routes/PrivateRoute";
import OTP from "./pages/auth/otp/otp";
import AccountCreated from "./pages/auth/success/accountCreated";
import ForgotPassword from "./pages/auth/forgotPassword/forgotPassword";
import SetPassword from "./pages/auth/setPassword/setPassword";
import PasswordReset from "./pages/auth/success/passwordReset";
import ChooseMarket from "./pages/auth/chooseMarket/chooseMarket";
import StoreDetails from "./pages/auth/storeDetails/storeDetails";
import StoreWallet from "./pages/auth/storeWallet/storeWallet";
import StoreCreated from "./pages/auth/success/storeCreated";
import {useDispatch, useSelector} from "react-redux";
import {hideNotification} from "./redux/actions/statusNotifications";
import Notifications from "./components/common/statusNotification/Notifications";
import Support from "./pages/support/support";
import AboutUs from "./pages/AboutUs/aboutUs";
import PrivacyPolicy from "./pages/PrivacyPolicy/privacyPolicy";
import TermsOfService from "./pages/TermsOfService/termsOfService";
// import WellKnown from "./apple-app-site-association";
// import AndroidWellKnown from "./assetslinks.json";
import { ToastContainer } from 'react-toastify'
import ProductHomePromptModal from './components/common/modal/ProductHomePropmtModal'
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const dispatch = useDispatch();
  let { notify } = useSelector(state => state.notification);
  const {   store_id } = useSelector(state => state.userLogin.user);
  const [promptModal, setPromptModal] = useState(false)

    const storeDetails = JSON.parse(localStorage.getItem("storeDetails"))

  useEffect(() => {
    if (store_id) {
       if (store_id?.startsWith("TM") && !storeDetails[0]?.thrindleDeliveries && storeDetails[1]?.deliveryPrices?.length ===0 ) {
      setPromptModal(true)
      
    }
      
    }
   

   
  }, [store_id, storeDetails])
  
  
   useEffect(() => {
     if (window.location.pathName === "store/edit-store") {
      setPromptModal(false)
      
    }
    
  },[ setPromptModal])



  //  REQUEST NOTIFICATIONS
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (notify) {
        setTimeout(() => {
          dispatch(hideNotification());
        }, 3000);
      }
    }

    return () => (mounted = false);
  }, [notify, dispatch]);

  console.log(window.location);

  return (
    <>
      <Notifications />
      <ToastContainer />
          { promptModal  && <ProductHomePromptModal setPromptModal={setPromptModal} promptModal={promptModal} />}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signUp" element={<SignUp />} />
        <Route path="/otp" element={<OTP />} />
        <Route path="/account-created" element={<AccountCreated />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/set-password" element={<SetPassword />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/choose-market" element={<ChooseMarket />} />
        <Route path="/store-details" element={<StoreDetails />} />
        <Route path="/store-wallet" element={<StoreWallet />} />
        <Route path="/store-created" element={<StoreCreated />} />
        <Route path="/support" element={<Support />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        {/* For apple deep linking  domain verification */}
        {/* <Route
          path="/.well-known/apple-app-site-association"
          element={<WellKnown />}
        /> */}
        {/* For android deep linking */}
        {/* <Route
          path="/.well-known/assetslinks.json"
          element={<AndroidWellKnown />}
        /> */}

        {routes.map(({component: Component, path}, index) => (
          <Route
            path={path}
            key={index}
            element={
              <ProtectedRoute>
                <Component />
              </ProtectedRoute>
            }
          />
        ))}
      </Routes>
    </>
  );
}

export default App;
