import { apiCalls as api } from "../utils/axiosInstance";
import { mainApiShop as apiShop } from "../utils/axiosInstance";
import { store } from "../redux/store";

const dispatch = store.dispatch;

export const PostWithoutHeader = async (
  path,
  data,
  phone = null,
  navigate = null,
) => {
  let res = await api(phone, navigate).post(path, data);
  return res;
};

export const GetWithoutHeader = async (
  path,
  data,
  phone = null,
  navigate = null,
) => {
  let res = await api(phone, navigate).get(path, data);
  return res;
};

export const GetWithHeader = async (path, phone = null, navigate = null) => {
  let res = await api(phone, navigate).get(path);
  return res;
};

export const GetWithHeaderShop = async (path, phone = null, navigate = null) => {
  let res = await apiShop(phone, navigate).get(path);
  return res;
};

export const PostWithHeader = async (
  path,
  data,
  phone = null,
  navigate = null,
) => {
  let res = await api(phone, navigate).post(path, data);
  return res;
};

export const UpdateWithHeader = async (
  path,
  data,
  phone = null,
  navigate = null,
) => {
  let res = await api(phone, navigate).put(path, data);
  return res;
};



export const UpdateWithHeaderShop = async (
  path,
  data,
  phone = null,
  navigate = null,
) => {
  let res = await apiShop(phone, navigate).put(path, data);
  return res;
};

export const UpdateWithoutHeader = async (
  path,
  data,
  phone = null,
  navigate = null,
) => {
  let res = await api(phone, navigate).put(path, data);
  return res;
};

export const DeleteWithHeader = async (path, phone = null, navigate = null) => {
  let res = await api(phone, navigate).delete(path);
  return res;
};

export const errorDispatch = (type, error) => {
  dispatch({
    type: type,
    payload:
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
  });
};

export const loadingDispatch = type => {
  dispatch({
    type: type,
  });
};

export const successDispatch = (type, payload) => {
  dispatch({
    type: type,
    payload: payload,
  });
};
