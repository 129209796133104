import {mainBanks} from "../data/productionDatas";

export default function filterFunc(data, inputValue) {
  let formatInputValue = inputValue.toLowerCase();

  let result = data.filter(
    datum =>
      datum.title.toLowerCase().includes(formatInputValue) &&
      datum.title.toLowerCase().indexOf(formatInputValue[0]) === 0,
  );

  return result;
}

// FILTER FUNCTION FOR GETTING BANKCODE FROM BANK NAME DURING WALLET VERIFICATION .DO NO DELETE
export const chooseBankCode = (val = "") => {
  let code = null;
  mainBanks.map(item => {
    if (item.name.toLowerCase() === val?.toLowerCase()) {
      code = item.bankCode;
    }
  });
  return code;
};
