import React from "react";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import {NetworkErrorIcon} from "../../assets/svg/networkError";
// import BackButton from "../backButton/backButton";

const Button = styled.button`
  display: block;
  border: 1px solid #f39e28;
  color: #f39e28;
  padding: 0.5em 1em;
  border-radius: 8px;
  width: max-content;
  margin: 2em auto;
  transition: all 0.5s ease-in-out;

  &:hover {
    color: white;
    background: #f39e28;
  }
`;

function NetworkError({func}) {
  let navigate = useNavigate();

  return (
    <div>
      <div className="w-200px max-w-[300px]">{NetworkErrorIcon}</div>
      {func ? (
        <Button onClick={() => func()}>Refresh</Button>
      ) : (
        <Button onClick={() => navigate(0)}>Refresh</Button>
      )}
    </div>
  );
}

export default NetworkError;
