import React, {useState, useEffect, useCallback} from "react";
import ShippinFeeModal from "./ShippinFeeModal";
import {motion, AnimatePresence} from "framer-motion";
import StoreButton from "../buttons/StoreButton";
import {mainApiShop} from "../../../utils/axiosInstance";
import {toast} from "react-toastify";

const DeliverPlanModal = ({onClick}) => {
  const [active, setActive] = useState();
  const [priceModal, setPriceModal] = useState(false);
  const [lagos, setLagos] = useState(false);
  const [lagosLoading, setLagosLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [region, setRegion] = useState("");
  const [price, setPrice] = useState("");

  const [isDesktop, setDesktop] = useState(window.innerWidth >= 1440);
  const [miniDesktop, setMiniDesktop] = useState(window.innerWidth >= 1024);
  const [tablet, setTablet] = useState(window.innerWidth >= 768);
  const [mobileLarge, setMobileLarge] = useState(window.innerWidth >= 425);
  const [mobile, setMobile] = useState(window.innerWidth >= 320);

  const storeDetails = JSON.parse(localStorage.getItem("storeDetails"));
  console.log(storeDetails, "hhhhhhhhhhhhh");

  const deilveryOptions = [
    "Let Thrindle handle it",
    "I would handle the delivery myself",
  ];
  const trueLagos = active === 0;

  const thrindleDelivery = true;

  useEffect(() => {
    if (thrindleDelivery) {
      setActive(0);
    } else {
      setActive(1);
    }
  }, [thrindleDelivery]);

  const getStoreDetails = useCallback(async () => {
    const config = {
      headers: {},

      timeout: 30000,
      timeoutErrorMessage:
        "Couldn't connect to the server. Poor network connection",
    };
    try {
      let res = await mainApiShop.get(`/stores`, config);
      if (res) {
        localStorage.setItem("storeDetails", JSON.stringify(res.data.data));
        console.log(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const chooseLagos = async () => {
    if (storeDetails[0].store_state === "lagos") {
      try {
        setLagosLoading(true);
        const response = await mainApiShop.put(`stores/updatedelivery`, {
          thrindleDeliveries: 1,
        });
        getStoreDetails();
        console.log(response);
        setLagosLoading(false);

        setLagos(false);
        toast.success("Saved", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.log(error);
        setLagosLoading(false);
        toast.error("An Error occurred, please try again", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.warn("Sorry, We only handle deliveries for merchant in Lagos", {
        position: "top-left",
        // autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const notLagos = async () => {
    try {
      setLagosLoading(true);
      const response = await mainApiShop.put(`stores/updatedelivery`, {
        thrindleDeliveries: 0,
      });
      console.log(response);
      setLagosLoading(false);
      getStoreDetails();
      setLagos(false);
    } catch (error) {
      console.log(error);
      setLagosLoading(false);
      toast.error("An Error occurred, please try again", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const addDeliveryPrice = async () => {
    setLoading(true);

    try {
      const response = await mainApiShop.post(`deliveryprices/add`, {
        region,
        price,
      });
      getStoreDetails();
      setLoading(false);
      setPriceModal(false);
      toast.success("Saved", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      console.log(response);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setPriceModal(false);
      toast.error("An Error occurred, please try again", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const updateMedia = () => {
    setDesktop(window.innerWidth >= 1440);
    setMiniDesktop(window.innerWidth >= 1024);
    setTablet(window.innerWidth >= 768);
    setMobileLarge(window.innerWidth >= 425);
    setMobile(window.innerWidth >= 320);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  useEffect(() => {
    setLagos(true);
  }, [trueLagos]);

  const LagosModal = ({closeModal, updateLagos}) => {
    return (
      <motion.div
        animate={{
          y: "-35vh",
          x: isDesktop
            ? "37vw"
            : miniDesktop
            ? "32vw"
            : tablet
            ? "25vw"
            : mobileLarge
            ? "15vw"
            : mobile
            ? "10vw"
            : "",
        }}
        initial={{y: "-40vh", x: "100vw"}}
        transition={{
          duration: 0.8,
          ease: [0.6, 0.05, -0.01, 0.9],
        }}
        // transition={{duration:2000}}
        exit={{x: "100vw"}}
        className=" flex justify-center items-center text-sm mt-10">
        <div className="bg-white-main  w-[70%] md:w-[24rem] rounded-lg shadow-lg px-8 py-4" style={{zIndex:"100000000000000000000"}}>
          <div className="flex justify-between mb-4 items-center">
            <div className="flex gap-2 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                viewBox="0 0 20 20"
                fill="#166CB4">
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clipRule="evenodd"
                />
              </svg>
              <p className="font-bold text-xs sm:text-lg">
                For Lagos State Merchants
              </p>
            </div>
            <svg
              onClick={closeModal}
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#464F54"
              strokeWidth={1}>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>

        <p>
            This option is currently only available to Lagos Merchants. Please
            be informed that we are unable to deliver delicate items including;
          </p>
          <div className="flex justify-between items-center mt-4">
              <div>
            
          <ul className="list-disc text-xs">
            <li>Cakes</li>
            <li>Frozen foods</li>
            <li>Cooked meals</li>
          </ul>
          </div>
            <StoreButton
              blue
              text="Got it"
              loading={lagosLoading}
              onClick={updateLagos}
            />
          </div>
        </div>
      </motion.div>
    );
  };
  return (
    <div
      className="top-0 left-0 right-0 bottom-0 fixed flex justify-center items-center text-sm"
      style={{backgroundColor: "rgba(0,0,0, 0.5)", zIndex: "1000"}}>
      <AnimatePresence exitBeforeEnter>
        {active === 0 && lagos && (
          <LagosModal closeModal={() => setLagos()} updateLagos={chooseLagos} />
        )}
      </AnimatePresence>

      <div className="bg-white-main fixed  w-[80%] md:w-[24rem] rounded-lg shadow-lg p-6">
        <div className="flex justify-between mb-8 items-center">
          <p className="header font-black text-[18px]  ">Delivery Preference</p>

          <svg
            onClick={onClick}
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 cursor-pointer"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#464F54"
            strokeWidth={1}>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <div className="text-sm">
          <div>
            {deilveryOptions.map((item, index) => (
              <div
                className="flex gap-4 items-center mt-4 cursor-pointer"
                key={index}
                onClick={() => {
                  setActive(index);
                  setPriceModal(true);
                  {
                    index === 1 && notLagos();
                  }
                }}>
                <div className="h-4 w-4 border-2 border-primary-light rounded-full flex justify-center items-center">
                  {active === index && (
                    <div className="h-2 w-2 bg-primary-light rounded-full" />
                  )}
                </div>

                <p>{item}</p>
              </div>
            ))}
          </div>
        </div>
        <AnimatePresence exitBeforeEnter>
          {active === 1 && priceModal && (
            <ShippinFeeModal
              onClick={() => setPriceModal(false)}
              event={addDeliveryPrice}
              priceChange={e => setPrice(e)}
              regionChange={e => setRegion(e)}
              price={price}
              region={region}
              loading={loading}
              header="Add Shipping Fee"
              text="Add fee"
            />
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default DeliverPlanModal;
