export const Account = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2562_6420)">
      <path
        d="M4.52065 17.1952L4.46576 17.5112L4.73014 17.6928C6.23029 18.7235 8.04627 19.3281 10 19.3281C11.9537 19.3281 13.7697 18.7235 15.2699 17.6928L15.5342 17.5112L15.4794 17.1952C15.0196 14.5482 12.7257 12.5963 10 12.5963C7.27447 12.5963 4.98038 14.5482 4.52065 17.1952ZM15.5204 16.6033L15.7421 17.3913L16.342 16.8343C18.1764 15.1307 19.3281 12.6976 19.3281 10C19.3281 4.856 15.144 0.671875 10 0.671875C4.856 0.671875 0.671875 4.856 0.671875 10C0.671875 12.6975 1.82363 15.1306 3.65801 16.8341L4.25787 17.3912L4.47957 16.6032C4.76953 15.5725 5.34558 14.637 6.15355 13.9057C6.64909 13.4572 7.2131 13.1036 7.81918 12.8542L8.69836 12.4925L7.90213 11.973C6.84583 11.284 6.1485 10.0936 6.1485 8.74481C6.1485 6.62151 7.8767 4.89331 10 4.89331C12.1233 4.89331 13.8517 6.62153 13.8517 8.74481C13.8517 10.0936 13.1543 11.2839 12.0979 11.973L11.3015 12.4925L12.1809 12.8542C12.787 13.1035 13.3508 13.4573 13.8465 13.9059L13.8465 13.9059C14.6545 14.6371 15.2305 15.5727 15.5204 16.6033ZM10 0.5C12.5384 0.5 14.9226 1.48751 16.7175 3.28248C18.5125 5.07744 19.5 7.46141 19.5 10C19.5 12.5384 18.5125 14.9226 16.7175 16.7175C14.9226 18.5125 12.5384 19.5 10 19.5C7.46141 19.5 5.07744 18.5125 3.28248 16.7175C1.48751 14.9226 0.5 12.5384 0.5 10C0.5 7.46141 1.48751 5.07744 3.28248 3.28248C5.07744 1.48751 7.46141 0.5 10 0.5ZM10 5.06519C7.97064 5.06519 6.32037 6.71527 6.32037 8.74481C6.32037 10.7742 7.97062 12.4244 10 12.4244C12.0294 12.4244 13.6796 10.7742 13.6796 8.74481C13.6796 6.71543 12.0294 5.06519 10 5.06519Z"
        fill="#464F54"
        stroke="#464F54"
      />
    </g>
    <defs>
      <clipPath id="clip0_2562_6420">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
