import React, {useEffect} from "react";
// import styled from "styled-components";
import Button from "../../../components/common/buttons/button";
import passwordReset from "../../../assets/images/passwordReset.png";
import MainContainer from "../../../components/common/mainContainer/mainContainer";
import {Container, Text} from "./accountCreated";
import {Link} from "react-router-dom";

function PasswordReset() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <MainContainer>
      <Container className="main">
        <img
          src={passwordReset}
          alt="suceess"
          className="py-8 ml-auto mr-auto"
        />

        <Container className="text">
          <Text className="success-title">Account Created</Text>
          <Text className="success-message">
            Your password has been reset successfully. Click the button below to
            login.
          </Text>

          <Container className="button-container">
            <Link to="/login">
              <Button type="button" text="Login" longButton={true} />
            </Link>
          </Container>
        </Container>
      </Container>
    </MainContainer>
  );
}

export default PasswordReset;
