import React from "react";
import styled from "styled-components";
import {numberFormat} from "../../utils/formatPrices";
import moment from "moment";

const SingleOrderProduct = ({product}) => {
  const {images, name, original_price, createdAt} = product;
  return (
    <MainCont className="w-full flex flex-row items-center justify-between py-3">
      <div className="flex flex-row ">
        <img
          src={`${process.env.REACT_APP_API_URL_IMAGES}${images[0]}`}
          alt="Product"
          className="block order-image rounded-md"
        />
        <div className="sm:ml-5 ml-2 pt-1 xl:max-w-2/3 truncate">
          {/* Truncated text on big screen */}
          <p className="text-white-text md:text-lg sm:text-base text-sm font-Bold truncate hidden xl:block">
            {`${name.substring(0, 30)}`}
          </p>
          {/* Truncated text on small screen */}
          <p className="text-white-text md:text-lg sm:text-base text-sm font-Bold truncate block xl:hidden">
            {`${name.substring(0, 15)}`}
          </p>
          <p className="text-white-text  sm:text-sm text-xs font-Light mt-1">
            {moment(createdAt).format("ll")}
          </p>
        </div>
      </div>
      <div className="">
        <p className="text-primary-light font-Bold text-xs sm:text-base">
          N{numberFormat(original_price)}
        </p>
      </div>
    </MainCont>
  );
};

export default SingleOrderProduct;

const MainCont = styled.div`
  border-bottom: 1px solid #e7edf2;
  .order-image {
    width: 80px;
    height: 80px;
  }
  @media (max-width: 500px) {
    .order-image {
      width: 50px;
      height: 50px;
    }
  }
`;
