import React, {useEffect, useState, useCallback} from "react";
import EmptyStoreProducts from "./subScreens/EmptyStoreProducts";
import styled from "styled-components";
import AllProducts from "./subScreens/AllProducts";
import MainContainerWithBg from "../../components/common/mainContainer/mainContainerWithBg";
import {mainApi, mainApiShop} from "../../utils/axiosInstance";
import {useSelector} from "react-redux";
import MainLoader from "../../components/emptyStates/MainNetworkError";
import ErrorView from "../../components/emptyStates/ErrorView";
// import ProductHomePropmtModal from "../../components/common/modal/ProductHomePropmtModal";
// import {STORE_USER_WALLET} from "../../redux/constants";



function Products() {
  const [products, setProducts] = useState([]);
  // const [walletError, setWalletError] = useState("");
  // const [walletLoading, setWalletLoading] = useState(false);
  const [productError, setProductError] = useState("");
  const [loadingProducts, setloadingProducts] = useState(false);
  const { store_id,  } = useSelector(state => state.userLogin.user);
  // const [priceData, setPriceData] = useState([])

  
  // const dispatch = useDispatch();

  // const getWallets = useCallback(async () => {
  //   const config = {
  //     headers: {},

  //     timeout: 30000,
  //     timeoutErrorMessage:
  //       "Couldn't connect to the server. Poor network connection",
  //   };
  //   try {
  //     setWalletLoading(true);
  //     let res = await mainApi.get(`wallets/getone`, config);
  //     if (res) {
  //       setWalletLoading(false);
  //       // localStorage.setItem("storeDetails", JSON.stringify(res.data.data));
  //       dispatch({type: STORE_USER_WALLET, payload: res.data.data});
  //       console.log(res.data.data, "WALLET DETAILS");
  //     }
  //   } catch (error) {
  //     setWalletLoading(false);
  //     if (!error.response) {
  //       setWalletError("Please check that you're connected");
  //     } else {
  //       setWalletError(error.response.data.message);
  //     }
  //   }
  // }, [dispatch]);


    

  const fetchProducts = useCallback(async () => {
    try {
      setloadingProducts(true);
      if (store_id.startsWith('TM')) {
        const res = await mainApiShop.get(`products/store/${store_id}`);
           setProducts(res.data.data);
      setloadingProducts(false);
        
      } else {
        const res = await mainApi.get(`products/store/${store_id}`);
           setProducts(res.data.data);
      setloadingProducts(false);
        
      }

      
    } catch (error) {
      console.log(error);
      setloadingProducts(false);
      if (!error.response) {
        setProductError("Please check that you're connected");
      } else {
        setProductError(error.response.data.message);
      }
    }
  }, [store_id]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      fetchProducts();
      // getWallets();
    }

    return () => {
      mounted = false;
    };
  }, [store_id,  fetchProducts]);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  if (loadingProducts) {
    return <MainLoader />;
  }
  if (productError) {
    return <ErrorView onClick={() => fetchProducts()} error={productError} />;
  }

  if (products)
    return (
      <MainContainerWithBg storeNav={true}>
        <MainCont className="w-full pt-10 md:pt-20">
          
          {products.length > 0 ? (
            <AllProducts data={products} />
          ) : (
            <EmptyStoreProducts />
          )}
        </MainCont>
      </MainContainerWithBg>
    );
}

export default Products;

const MainCont = styled.div``;
