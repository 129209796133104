import React, {useState, useEffect, useCallback} from "react";
// import SingleProduct from "../../components/productScreen/SingleProduct";
import MainTab from "../../components/common/tab/MainTab";
// import {orderData} from "../../devData";
import styled from "styled-components";

import MainContainerWithBg from "../../components/common/mainContainer/mainContainerWithBg";
import {mainApi} from "../../utils/axiosInstance";
import EmptyOrder from "../../components/emptyStates/EmptyOrder";
import SingleOrder from "../../components/orderScreen/SingleOrder";
import Loader from "../../components/Loader/Loader";
import {useDispatch} from "react-redux";
import {showNotification} from "../../redux/actions/statusNotifications";

const tabData = ["All", "Pending", "Completed", "Cancelled"];

function Orders() {
  const [activeTab, setActiveTab] = useState("All");
  const [orderModal, setOrderModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const getAllOrder = useCallback(async () => {
    setLoading(true);
    try {
      const {data} = await mainApi.get(
        `${process.env.REACT_APP_API_URL}orders/getorders`,
      );
      const res = data.data;
      setResult(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (!error.response) {
        dispatch(
          showNotification({
            notify: {
              status: 2,
              message: "Please check that you're connected",
            },
          }),
        );
      } else if (error.response) {
        dispatch(
          showNotification({
            notify: {
              status: 2,
              message: `${error.response.data.message}`,
            },
          }),
        );
      }
    }
  }, [dispatch]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getAllOrder();
    }

    return () => (mounted = false);
  }, [getAllOrder]);

  const showStatusOrder = val => {
    if (val === "All") {
      return result;
    } else if (val !== "All") {
      const filteredOrder = result.filter(
        item => item.status === val.toLowerCase(),
      );
      return filteredOrder;
    }
  };

  if (loading) {
    return <Loader func={getAllOrder} />;
  }

  return (
    <div>
      <MainContainerWithBg storeNav>
        <MainOrderContainer className="w-full pt-20">
          <MainTab
            activeTab={activeTab}
            tabData={tabData}
            onClick={val => setActiveTab(val)}
          />

          {!loading && result.length === 0 && (
            <div className="flex justify-center items-center">
              <div className="py-20">
                <EmptyOrder getAllOrder={getAllOrder} />
              </div>
            </div>
          )}
          <div className="w-full grid-view grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 sm:gap-5 gap-3 lg:gap-5  pb-20">
            {result.length !== 0 &&
              showStatusOrder(activeTab).map((item, index) => (
                <SingleOrder
                  key={index}
                  singleOrder={item}
                  {...item}
                  type="order"
                  onClick={() => setOrderModal(true)}
                  orderModal={orderModal}
                  onClose={() => setOrderModal(false)}
                  setOrderModal={setOrderModal}
                />
              ))}
          </div>
        </MainOrderContainer>
      </MainContainerWithBg>
    </div>
  );
}

export default Orders;

const MainOrderContainer = styled.div`
  @media (max-width: 500px) {
    .grid-view {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  @media (max-width: 380px) {
    .grid-view {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
`;
