export const UploadNav = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.03125 5.58383H8.33027V11.5305C8.33027 11.6078 8.39355 11.6711 8.4709 11.6711H9.52559C9.60293 11.6711 9.66621 11.6078 9.66621 11.5305V5.58383H10.9688C11.0865 5.58383 11.1516 5.44848 11.0795 5.35708L9.11074 2.8645C9.09759 2.84769 9.08078 2.8341 9.06159 2.82475C9.04241 2.81541 9.02134 2.81055 9 2.81055C8.97866 2.81055 8.95759 2.81541 8.93841 2.82475C8.91922 2.8341 8.90241 2.84769 8.88926 2.8645L6.92051 5.35532C6.84844 5.44848 6.91348 5.58383 7.03125 5.58383V5.58383ZM15.4336 11.0032H14.3789C14.3016 11.0032 14.2383 11.0664 14.2383 11.1438V13.8508H3.76172V11.1438C3.76172 11.0664 3.69844 11.0032 3.62109 11.0032H2.56641C2.48906 11.0032 2.42578 11.0664 2.42578 11.1438V14.6242C2.42578 14.9354 2.67715 15.1867 2.98828 15.1867H15.0117C15.3229 15.1867 15.5742 14.9354 15.5742 14.6242V11.1438C15.5742 11.0664 15.5109 11.0032 15.4336 11.0032Z"
      fill="#464F54"
    />
  </svg>
);
