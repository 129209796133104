import React from "react";
import {NavLink} from "react-router-dom";
import Button from "../../../components/common/buttons/button";
import SingleProduct from "../../../components/productScreen/SingleProduct";
import styled from "styled-components";
import {useDispatch} from "react-redux";
import {STORE_ACTIVE_PRODUCT} from "../../../redux/constants";

const AllProducts = ({data}) => {
  const dispatch = useDispatch();

  return (
    <div className="w-full">
      <GridCont className="w-full grid-view grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 sm:gap-5 gap-3 lg:gap-5">
        {/* <NavAndSearch /> */}
        {data.map((item, index) => (
          <SingleProduct
            key={index}
            onClick={() =>
              dispatch({type: STORE_ACTIVE_PRODUCT, payload: item})
            }
            {...item}
            type="product"
            to={`/products/${item.name}/${item._id}`}
          />
        ))}
      </GridCont>
      <div className=" w-full mx-auto  flex flex-col items-center justify-center">
        <NavLink to="/products/add-product">
          <div className="my-8 md:my-12">
            <Button text="Add product" blueBg longButton />
          </div>
        </NavLink>
      </div>
    </div>
  );
};

export default AllProducts;

const GridCont = styled.div`
  @media (max-width: 330px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;
