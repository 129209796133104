import React, {useState} from "react";
import styled from "styled-components";
import {FaAngleDown} from "react-icons/fa";
// import SingleDropdownSelect from "../../common/dropdownSelects/SingleDropdownSelect";

const InputDropdown = props => {
  const [focus, setFocus] = useState(false);
  // const [show, setShow] = useState(false);
  const toggleDropdown = () => {
    setFocus(!focus);
    // props.onClick();
    if (props.clearErr) {
      props.clearErr();
    }
    // props.setShow(!show);
    props.toggleModal();
  };

  // const chooseValue = val => {
  //   setFocus(false);
  //   props.chooseFilter(val);
  //   props.closeModal();
  // };
  return (
    <>
      <MainCont
        error={props.error}
        focus={focus}
        className="w-full mb-5"
        width={props.width}>
        <p className="text-sm common-input-placeholder sm:text-base lg:text-base xl:text-base font-Bold mb-2">
          {props.label}
        </p>
        <div className="w-full relative main-common-input-cont rounded-md  flex flex-row px-5">
          {props.value && props.value !== "" ? (
            <p className="value text-primary-light font-Regular xl:text-base text-sm">
              {props.value}
            </p>
          ) : (
            <p className="value placeholder text-primary-light font-Regular xl:text-base text-sm">
              {props.placeholder}
            </p>
          )}

          <div className="icon-cont">
            <FaAngleDown
              className="input-icon text-xl cursor-pointer"
              onClick={toggleDropdown}
            />
          </div>
          <div
            className={
              props.visible
                ? "w-full filter-dropdown active z-50 bg-white-dim  rounded-md absolute top-14 left-0 right-0 "
                : "w-full filter-dropdown z-50 bg-white-dim  rounded-md absolute top-14 left-0 right-0 "
            }>
            {props.children}
            {/* {props.filterData?.map((item, index) => (
              <SingleDropdownSelect
                name={item.name}
                key={index}
                value={props.value}
                onClick={() => chooseValue(item.name)}
              />
            ))} */}
          </div>
        </div>
      </MainCont>
      {props.error && (
        <p className="text-xs text-secondary-error font-Medium pt-1">
          {props.error}
        </p>
      )}
    </>
  );
};

export default InputDropdown;

const MainCont = styled.div`
  width: ${({width}) => (width ? `${width}%` : `100%`)};
  .main-common-input-cont {
    align-items: center;
    background: transparent;
    border: ${({focus, error}) =>
      focus
        ? "1px solid #F69F13 "
        : error
        ? "1px solid #D83434"
        : "1px solid #166CB4"};
    height: 50px;
    justify-content: space-between;
  }
  .common-input-placeholder {
    width: 100%;
    color: #464f54;
  }
  .value {
    width: 90%;
    color: #464f54;
  }
  .placeholder {
    color: #9fa5c0;
  }
  .filter-dropdown {
    // opacity: 0;
    // transform: translateY(-20%);
    display: none;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 50px 24px 1px rgba(0, 0, 0, 0.1);
    background: #fff !important;
  }
  .active {
    // opacity: 1;
    // transform: translateY(0%);
    display: block;
  }
`;
