import React, {useState} from "react";
import van from ".././../../assets/images/van.svg";
import { useNavigate } from "react-router-dom";
import DeliverPlanModal from "./DeliverPlanModal";

const ProductHomePropmtModal = ({setPromptModal, }) => {
  const navigate = useNavigate()
  const [delivery, setDelivery] = useState(false)

  // useEffect(() => {
  //   // if (promptModal) {
  //     setDelivery(true)
  //   // }
  // }, [])

  
  return (
    <div
      className="fixed top-0 left-0 right-0 bottom-0 flex justify-center px-6 items-center"
      style={{backgroundColor: "rgba(0,0,0, 0.5)", zIndex: "1000"}}>
      <div className="bg-white-main md:w-[450px] md:p-10 px-4 py-8 rounded-lg ">
        <div className="flex  justify-between">
          <div className="flex gap-4">
            <img src={van} alt="van" />
            <p className="md:text-lg text-sm font-semibold">Set up your delivery preference</p>
          </div>
          <svg
            onClick={() =>setPromptModal(false)}
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={1}>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
         {delivery && <DeliverPlanModal onClick={() =>{setDelivery(false)}} />}
        <div className="px-1">
          <p className="md:px-11 px-10 mt-1">
              
            You can either choose to do delivery yourself or we do it for you.
                  </p>
                  <div className="flex justify-start md:px-11 px-10 gap-4 mt-4">
                      <button   onClick={() =>setPromptModal(false)} className="bg-white-main text-primary-light border text-xs md:text-sm border-primary-light rounded-md  px-5 md:px-8 py-2">Later</button>

            <button className="bg-primary-light text-white-main rounded-md  text-xs md:text-sm px-5 md:px-8 py-2" onClick={() => {
              navigate('/store')
              // setPromptModal(false)
      setDelivery(true)

            }}>Set up</button>

                  </div>
        </div>
      </div>
    </div>
  );
};

export default ProductHomePropmtModal;
