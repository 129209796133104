import React from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";
import successImage from "../../../assets/images/account.png";
import Button from "../../../components/common/buttons/button";
import MainContainer from "../../../components/common/mainContainer/mainContainer";

export const Text = styled.p`
  &.success-title {
    font-family: var(--font-bold);
    color: #20639b;
    margin: 1em 0 0.5em 0;
  }

  &.success-message {
    font-family: var(--font-regular);
    font-weight: 400;
    color: #2f3133;
  }
`;

export const Container = styled.div`
  &.main {
    margin: 2em 0;

    @media (min-width: 768px) {
      margin: 4em 0;
    }
  }

  &.text {
    text-align: center;

    @media (min-width: 768px) {
      width: 50%;
      margin: 0 auto;
    }

    @media (min-width: 1024px) {
      width: 35%;
    }
  }

  &.button-container {
    margin: 1.5em 0;
  }
`;

function AccountCreated() {
  return (
    <MainContainer>
      <Container className="main">
        <img
          src={successImage}
          alt="suceess"
          className="py-4 ml-auto mr-auto"
        />

        <Container className="text">
          <Text className="success-title">Account Created</Text>
          <Text className="success-message">
            You have successfully created an account. Click the button below to
            continue setup
          </Text>
          <Container className="button-container">
            <Link to="/choose-market">
              <Button type="button" text="Continue" longButton={true} />
            </Link>
          </Container>
        </Container>
      </Container>
    </MainContainer>
  );
}

export default AccountCreated;
