import React from "react";
import styled from "styled-components";
import MainContainer from "../mainContainer/mainContainer";

const Container = styled.div`
  &.main-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: auto;
    background: #f5f5f5;

    margin-left: -1.5em;
    margin-right: -1.5em;

    @media (min-width: 768px) {
      margin-left: -3em;
      margin-right: -3em;
    }

    @media (min-width: 1024px) {
      margin-left: -5em;
      margin-right: -5em;
    }
  }

  &.forms {
    width: 100%;
    padding: 1.5em;

    @media (min-width: 768px) {
      &.forms {
        padding: 3em;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    @media (min-width: 1024px) {
      &.forms {
        padding: 3em;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    @media (min-width: 1440px) {
      &.forms {
        width: 50%;
        padding: 4em 7em;
      }
    }
  }

  &.image-container {
    display: none;

    @media (min-width: 1024px) {
      display: block;
      width: 50%;
      padding: 3em;
    }

    @media (min-width: 1440px) {
      padding: 5em 0em 5em 7em;
    }
  }
`;

function AuthContainer({image, children}) {
  return (
    <MainContainer>
      <Container className="main-container">
        <Container className="image-container">{image}</Container>

        <Container className="forms">{children}</Container>
      </Container>
    </MainContainer>
  );
}

export default AuthContainer;
