import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {IoIosCloseCircle} from "react-icons/io";
import InputWithFieldSet from "../../components/common/inputs/FieldSetInput";
import DropdownInputs from "../../components/common/inputs/DropdownInputs";
import WalletDropdownContent from "../auth/storeWallet/WalletDropdownContent";
import {mainBanks} from "../../data/productionDatas";
import Button from "../../components/common/buttons/button";
import {useSelector, useDispatch} from "react-redux";
import {showNotification} from "../../redux/actions/statusNotifications";
import {mainApi} from "../../utils/axiosInstance";
import {chooseBankCode} from "../../utils/filter";
import {STORE_USER_WALLET} from "../../redux/constants";

const VerifyAccountModal = ({closeModal}) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [bank, setBank] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [accountName, setAccountName] = useState("");
  const {wallet} = useSelector(state => state.storeWallet);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setAccountName(wallet?.account?.acc_name);
    setAccountNumber(wallet?.account?.acc_no);
    setBank(wallet?.account?.bank);
  }, [
    wallet?.account?.acc_name,
    wallet?.account?.acc_no,
    wallet?.account?.bank,
  ]);

  //   SET BANK CODE ON MODAL OPEN
  useEffect(() => {
    setBankCode(chooseBankCode(bank));
  }, [bank]);

  const verifyWallet = async e => {
    e.preventDefault();
    if (bank === "" || accountNumber === "" || accountName === "") {
      dispatch(
        showNotification({
          notify: {
            status: 2,
            message: `Please fill all fields to verify your account`,
          },
        }),
      );
    } else {
      try {
        setLoading(true);
        const {data} = await mainApi.post("wallets/verify", {
          bank,
          accountNumber,
          bankCode,
        });
        if (data) {
          setLoading(false);
          dispatch(
            showNotification({
              notify: {
                status: 1,
                message: `${data.message}`,
              },
            }),
          );
          closeModal();
          //   GETTING WALLET AGAIN ON SUCCESSFUL
          let res = await mainApi.get(`/wallets/getone`);
          if (res) {
            dispatch({type: STORE_USER_WALLET, payload: res.data.data});
          }
        }
      } catch (error) {
        setLoading(false);
        if (!error.response) {
          dispatch(
            showNotification({
              notify: {
                status: 2,
                message: "Please check that you're connected",
              },
            }),
          );
        } else if (error.response) {
          dispatch(
            showNotification({
              notify: {
                status: 2,
                message: `Incorrect bank details.`,
              },
            }),
          );
        }
      }
    }
  };
  return (
    <MainCont className="w-full h-full relative pt-10 md:px-7 px-3">
      <div
        className="absolute top-5 right-10 cursor-pointer"
        onClick={closeModal}>
        <IoIosCloseCircle className="text-3xl text-white-text" />
      </div>
      <p className="text-white-text font-Bold text-center text-base">
        Verify Wallet
      </p>
      <p className="text-primary-light font-SemiBold text-sm pt-7 pb-4">
        Please confirm that your account details are correct
      </p>
      <p className="text-white-text font-Bold pt-3 text-sm">Account Details</p>
      <form onSubmit={verifyWallet}>
        <div className="py-2 w-full">
          <InputWithFieldSet
            type="number"
            id="accountNo"
            name="accountNo"
            fieldset="Account No"
            placeholder="0123456789"
            inputMode="numeric"
            value={accountNumber}
            onChange={val => setAccountNumber(val)}
            //   onBlur={formik.handleBlur}
          />
        </div>
        <div className="py-2 w-full">
          <DropdownInputs
            visible={show}
            toggleModal={() => setShow(!show)}
            closeModal={() => setShow(false)}
            label="Bank Name"
            value={bank}
            placeholder="Select your bank">
            <WalletDropdownContent
              data={mainBanks}
              value={bank}
              chooseValue={val => {
                setBank(val);
              }}
              closeModal={() => setShow(false)}
            />
          </DropdownInputs>
        </div>
        <div className="py-2 w-full">
          <InputWithFieldSet
            type="text"
            id="accountName"
            name="accountName"
            fieldset="Account Name"
            placeholder="John Doe"
            value={accountName}
            onChange={val => setAccountName(val)}
          />
        </div>
        <div className="mt-5 w-full">
          <Button text="Verify-Wallet" processing={loading} type="submit" />
        </div>
      </form>
    </MainCont>
  );
};

export default VerifyAccountModal;

const MainCont = styled.div``;
