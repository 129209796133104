import React from "react";
import styled from "styled-components";
import MainContainer from "../common/mainContainer/mainContainer";
import {NetworkErrorIcon} from "../../assets/svg/networkError";

const LoaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 90vh;
`;

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Button = styled.button`
  display: block;
  border: 1px solid #f39e28;
  color: #f39e28;
  padding: 0.5em 1em;
  border-radius: 8px;
  width: max-content;
  margin: 2em auto;
  transition: all 0.5s ease-in-out;

  &:hover {
    color: white;
    background: #f39e28;
  }
`;

const ErrorView = ({onClick, error}) => {
  return (
    <MainContainer>
      <LoaderContainer>
        <Container>
          <div>
            <div className="w-200px max-w-[300px] mx-auto">
              {NetworkErrorIcon}
            </div>
            {error && (
              <p className="text-white-text py-3 text-center md:text-lg text-sm">
                {error}
              </p>
            )}

            <Button onClick={onClick}>Refresh</Button>
          </div>
        </Container>
      </LoaderContainer>
    </MainContainer>
  );
};

export default ErrorView;
