import React, {useEffect} from "react";
import styled from "styled-components";
import {useFormik} from "formik";
import * as Yup from "yup";
import AuthContainer from "../../../components/common/authContainer/authContainer";
import Button from "../../../components/common/buttons/button";
import Input from "../../../components/common/inputs/input";
import {Header, Text, Error} from "../../../styles/globalStyles";
import UseAlternative from "../../../components/useAlternative/useAlternative";
import {useDispatch, useSelector} from "react-redux";
import {CLEAR_ERROR, STORE_RESET_PHONE_NUMBER} from "../../../redux/constants";
import {forgot_Password} from "../../../redux/actions/authActions";
import {showNotification} from "../../../redux/actions/statusNotifications";
import {useNavigate} from "react-router-dom";
import MobileBackBtn from "../../../components/common/buttons/MobileBackBtn";
import { LoginIllustration } from "../../../assets/svg/login";

const Container = styled.div`
  &.button-container {
    margin: 1em 0;
  }
`;

function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema: Yup.object({
      phone: Yup.string()
        .matches(/^\d{11}$|^[+234]\d{13}$/, "Enter a valid number")
        .required("Required"),
    }),

    onSubmit: values => {
      dispatch(forgot_Password(values.phone));
    },
  });

  const forgotPassword = useSelector(state => state.forgotPassword);
  const {loading, error, message} = forgotPassword;

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    if (error && error !== "") {
      dispatch({type: CLEAR_ERROR});
      dispatch(
        showNotification({
          notify: {
            status: 2,
            message: error,
          },
        }),
      );
    }
    if (message && message !== "") {
      dispatch({type: CLEAR_ERROR});
      dispatch({type: STORE_RESET_PHONE_NUMBER, payload: formik.values.phone});
      formik.values.phone = "";
      dispatch(
        showNotification({
          notify: {
            status: 1,
            message: `${message}`,
          },
        }),
      );
      navigate("/set-password");
    }
  }, [error, dispatch, message, formik.values, navigate]);

  return (
    <AuthContainer image={LoginIllustration}>
      <Container>
        <MobileBackBtn to="/login" />
        <Header>Forgot Password?</Header>
        <Text>
          Not to worry, things like this happen. An OTP code will be sent to
          your phone.
        </Text>

        <form onSubmit={formik.handleSubmit} className="mt-8">
          <Input
            labelText="Phone Number"
            id="phone"
            name="phone"
            placeholder="09020002003"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Error>
            {formik.errors.phone && formik.touched.phone ? (
              <>{formik.errors.phone}</>
            ) : null}
          </Error>

          <Container className="button-container">
            <Button type="submit" text="Send" processing={loading} />
          </Container>
        </form>

        <UseAlternative text="Remember Password?" link="Login" signUp={true} />
      </Container>
    </AuthContainer>
  );
}

export default ForgotPassword;
