import React from "react";
import styled from "styled-components";

const SingleProductSize = ({name}) => {
  return (
    <MainCont className="flex flex-row items-center justify-center border border-solid border-primary-light mr-2 px-2 py-1 rounded-md">
      <p className="text-sm text-primary-light font-Medium">{name}</p>
    </MainCont>
  );
};

export default SingleProductSize;

const MainCont = styled.div``;
