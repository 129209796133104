import React, {useState, useEffect, useCallback} from "react";
import styled from "styled-components";
import PayoutModal from "../../../components/common/modal/payoutModal/PayoutModal";
import SinglePayout from "../../../components/productScreen/SinglePayout";
import PayoutModalView from "../PayoutModalView";
import {mainApi} from "../../../utils/axiosInstance";
import Loader from "../../../assets/images/Loader.gif";
import EmptyOrder from "../../../assets/images/no-withdrawal.png";

const WithdrawalsTabScreen = () => {
  const [showModal, setShowModal] = useState(false);
  const [withdrawal, setWithdrawal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [activePayout, setActivePayout] = useState({});

  const getWithdrawal = useCallback(async () => {
    try {
      setLoading(true);
      const {data} = await mainApi.get(
        `${process.env.REACT_APP_API_URL}withdrawal`,
      );
      const res = data.data;
      if (res) {
        setLoading(false);
        setWithdrawal(res);
      }
    } catch (error) {
      setLoading(false);
      if (!error.response) {
        setError("Please check that you're connected");
      } else if (error.response) {
        setError(error.response.data.message);
      }
    }
  }, []);

  useEffect(() => {
    getWithdrawal();
  }, [getWithdrawal]);

  if (loading) {
    return (
      <div className="w-full mt-10 mx-auto">
        <img src={Loader} alt="loader" className="w-20 h-20 block mx-auto" />
      </div>
    );
  } else if (error) {
    return (
      <div className="w-full mt-10 mx-auto">
        <p className="md:text-lg text-secondary-error text-center text-base pb-5">
          {error}
        </p>
        <p
          onClick={() => getWithdrawal()}
          className="underline text-center text-primary-main font-Bold cursor-pointer text-sm">
          Try Again.
        </p>
      </div>
    );
  } else if (!loading && !error && withdrawal.length === 0) {
    return (
      <div className="w-full mt-10 mx-auto">
        <EmptyOrders src={EmptyOrder} alt="empty" className=" block mx-auto" />
      </div>
    );
  }

  // SET ACTIVE PAYOUT TO SHOW ON THE MODAL
  const showPayoutModal = val => {
    setActivePayout(val);
    setTimeout(() => {
      setShowModal(true);
    }, 300);
  };

  return (
    <MainCont className="w-full">
      <PayoutModal visible={showModal} closeModal={() => setShowModal(false)}>
        <PayoutModalView
          activePayout={activePayout}
          closeModal={() => setShowModal(false)}
        />
      </PayoutModal>
      {withdrawal.map((item, index) => (
        <SinglePayout
          onClick={() => showPayoutModal(item)}
          key={index}
          {...item}
        />
      ))}
    </MainCont>
  );
};

export default WithdrawalsTabScreen;
const MainCont = styled.div``;
const EmptyOrders = styled.img``;
