export const PlusImage = (
  <svg
    width="45"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.5 0.1875C16.6045 0.258628 10.9704 2.63224 6.80133 6.80133C2.63224 10.9704 0.258628 16.6045 0.1875 22.5C0.258628 28.3955 2.63224 34.0296 6.80133 38.1987C10.9704 42.3678 16.6045 44.7414 22.5 44.8125C28.3955 44.7414 34.0296 42.3678 38.1987 38.1987C42.3678 34.0296 44.7414 28.3955 44.8125 22.5C44.7414 16.6045 42.3678 10.9704 38.1987 6.80133C34.0296 2.63224 28.3955 0.258628 22.5 0.1875ZM35.25 24.0938H24.0938V35.25H20.9062V24.0938H9.75V20.9062H20.9062V9.75H24.0938V20.9062H35.25V24.0938Z"
      fill="#FFA91F"
    />
  </svg>
);
