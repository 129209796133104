import React from "react";
import styled from "styled-components";
import Footer from "../footer/footer";
import NavBar from "../navBar/navBar";
import StoreNavDetails from "../navBar/StoreNavContainer";

const Container = styled.div`
  padding-left: 1.5em;
  padding-right: 1.5em;
  background: linear-gradient(
    180deg,
    #e5efff 0%,
    rgba(229, 239, 255, 0.262661) 83.7%,
    rgba(229, 239, 255, 0) 100%
  );

  @media (min-width: 768px) {
    padding-left: 3em;
    padding-right: 3em;
  }

  @media (min-width: 1024px) {
    padding-left: 5em;
    padding-right: 5em;
  }
  @media (min-width: 2560px) {
    padding-left: 10em;
    padding-right: 10em;
  }
`;

const Main = styled.main``;

const MainContainerWithBg = ({children, storeNav}) => {
  return (
    <Main>
      <NavBar />
      {storeNav && <StoreNavDetails />}
      <Container>{children}</Container>
      <Footer />
    </Main>
  );
};

export default MainContainerWithBg;
