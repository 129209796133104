import {
  USER_LOGIN_FAIL,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_LOADING,
  USER_REGISTER_FAIL,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_LOADING,
  VERIFY_NUMBER_FAIL,
  VERIFY_NUMBER_SUCCESS,
  VERIFY_NUMBER_LOADING,
  RESEND_OTP_FAIL,
  RESEND_OTP_SUCCESS,
  RESEND_OTP_LOADING,
  FORGET_PASSWORD_FAIL,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_LOADING,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_LOADING,
  STORE_LOGIN_NUMBER,
  STORE_REGISTER_DETAILS,
} from "../../constants/index";
import {
  errorDispatch,
  loadingDispatch,
  PostWithoutHeader,
  successDispatch,
} from "../../../utils/requestFunc";
import axios from "axios";
// import {mainApiShop, mainApi} from "../../../utils/axiosInstance";

export const login =
  (phone, password, navigate = null) =>
  async dispatch => {
    try {
      dispatch({type: STORE_LOGIN_NUMBER, payload: phone});
      loadingDispatch(USER_LOGIN_LOADING);
      const {data} = await PostWithoutHeader(
        "users/login",
        {phone, password},
        phone,
        navigate,
      );
      if (data) {
              const config = {
              headers: {
        "Authorization" : `Bearer ${data.data.access_token}`
      },

      timeout: 30000,
      timeoutErrorMessage:
        "Couldn't connect to the server. Poor network connection",
    }
        // console.log(data.data.user.store_id, "rrrrrrrrrrrrrr");
        if (data.data.user.store_id.startsWith("TM")) {
      
          try {
            let res = await axios.get(`${process.env.REACT_APP_API_SHOP_URL}stores`, config);
            localStorage.setItem("storeDetails", JSON.stringify(res.data.data));
            console.log(res.data.data, "fromm TM");
          } catch (error) {
            throw new Error(error);
          }
        } else {
          try {
            let res = await axios.get(`${process.env.REACT_APP_API_URL}stores`, config);
            localStorage.setItem("storeDetails", JSON.stringify(res.data.data));
            console.log(res.data.data, "from cv and ek");
          } catch (error) {
            throw new Error(error);
          }
        }
        dispatch({
          type: USER_LOGIN_SUCCESS,
          payload: {
            user: data.data.user,
            accessToken: data.data.access_token,
            refreshToken: data.data.refresh_token,
            message: data.message,
          },
        });
      }
      // console.log(data, "LOGIN DATA");
    } catch (error) {
      errorDispatch(USER_LOGIN_FAIL, error);
    }
  };

export const register =
  (name, phone, password, passwordConfirm) => async dispatch => {
    try {
      loadingDispatch(USER_REGISTER_LOADING);
      const {data} = await axios.post(
        `${process.env.REACT_APP_API_URL}users/signup`,
        {
          name,
          phone,
          password,
          passwordConfirm,
        },
      );
      if (data) {
        dispatch({type: STORE_REGISTER_DETAILS, payload: {phone, password}});
        dispatch({type: USER_REGISTER_SUCCESS, payload: data.message});
      }
    } catch (error) {
      errorDispatch(USER_REGISTER_FAIL, error);
    }
  };

export const verify_Number = (phone, otp) => async dispatch => {
  try {
    loadingDispatch(VERIFY_NUMBER_LOADING);
    const {data} = await axios.post(
      `${process.env.REACT_APP_API_URL}users/verify`,
      {phone, otp},
      {
        timeout: 30000,
        timeoutErrorMessage:
          "Cannot connect to server. Please check your network",
      },
    );
    if (data) {
      dispatch({
        type: VERIFY_NUMBER_SUCCESS,
        payload: {token: data.data.access_token, message: data.message},
      });
    }
  } catch (error) {
    errorDispatch(VERIFY_NUMBER_FAIL, error);
  }
};
export const resend_Otp = phone => async dispatch => {
  try {
    loadingDispatch(RESEND_OTP_LOADING);
    const {data} = await PostWithoutHeader(
      "users/resendotp",
      {phone},
      {
        timeout: 30000,
        timeoutErrorMessage:
          "Cannot connect to server. Please check your network",
      },
    );
    if (data) {
      dispatch({type: RESEND_OTP_SUCCESS, payload: data.message});
    }
  } catch (error) {
    errorDispatch(RESEND_OTP_FAIL, error);
  }
};

export const forgot_Password = phone => async () => {
  try {
    loadingDispatch(FORGET_PASSWORD_LOADING);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      timeout: 60000,
      timeoutErrorMessage:
        "Couldn't connect to the server. Poor network connection",
    };

    const {data} = await PostWithoutHeader(
      `users/forgotPassword`,
      {phone},
      config,
    );
    successDispatch(FORGET_PASSWORD_SUCCESS, data.message);

    //  if (data) {
    //    dispatch(store_Reset_PhoneNumber(phone));
    //  }
  } catch (error) {
    errorDispatch(FORGET_PASSWORD_FAIL, error);
  }
};
export const reset_Password =
  (phone, otp, password, passwordConfirm) => async getState => {
    const token = getState().userLogin.accessToken;

    try {
      loadingDispatch(RESET_PASSWORD_LOADING);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        timeout: 60000,
        timeoutErrorMessage:
          "Couldn't connect to the server. Poor network connection",
      };
      const {data} = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/resetpassword`,
        {
          phone,
          otp,
          password,
          passwordConfirm,
        },
        config,
      );

      successDispatch(RESET_PASSWORD_SUCCESS, data.message);
    } catch (error) {
      errorDispatch(RESET_PASSWORD_FAIL, error);
    }
  };
