import * as constants from "../../constants/index";

let initialState = {
  notify: null,
};

export const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SHOW_NOTIFICATION:
      return {
        ...state,
        ...action.payload,
      };

    case constants.HIDE_NOTIFICATION:
      return initialState;

    default:
      return state;
  }
};
