import React from "react";
import "./WalletModal.css";
import Modal from "react-modal";

Modal.setAppElement("#root");

const WalletModal = ({children, closeModal, visible}) => {
  return (
    <Modal
      isOpen={visible}
      onRequestClose={() => closeModal()}
      shouldCloseOnOverlayClick={true}
      closeTimeoutMS={1000}
      className="verify-modal"
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "rgba(229, 239, 255, 0.6)",
        },
        content: {},
      }}>
      {children}
    </Modal>
  );
};

export default WalletModal;
