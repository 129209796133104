import React, {useEffect} from "react";
// import styled from "styled-components";
import Button from "../../../components/common/buttons/button";
import storeCreated from "../../../assets/images/storeCreated.png";
import MainContainer from "../../../components/common/mainContainer/mainContainer";
import {Container, Text} from "./accountCreated";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {CLEAR_ERROR} from "../../../redux/constants";

function StoreCreated() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const dispatch = useDispatch();

  const createStore = useSelector(state => state.createStore);
  const {message} = createStore;
  const navigate = useNavigate();

  const finish = () => {
    navigate("/login");
    dispatch({type: CLEAR_ERROR});
    // dispatch(login(phone, password));
  };

  return (
    <MainContainer>
      <Container className="main">
        <img
          src={storeCreated}
          alt="suceess"
          className="py-8 ml-auto mr-auto"
        />

        <Container className="text">
          <Text className="success-title">Congratulations!</Text>
          <Text className="success-message">{message}</Text>

          <Container className="button-container">
            <Button
              type="button"
              text="Login"
              longButton={true}
              onClick={finish}
            />
          </Container>
        </Container>
      </Container>
    </MainContainer>
  );
}

export default StoreCreated;
