import styled, {css} from "styled-components";
import tw from "twin.macro";
import Bg from "../assets/images/bg.png";

export const Header = styled.h1`
  font-size: 36px;
  font-weight: bolder;
  font-family: var(--font-bold);

  @media (min-width: 768px) {
    text-align: center;
  }
  @media (min-width: 1440px) {
    text-align: start;
  }
`;

export const Text = styled.p`
  font-size: 16px;

  @media (min-width: 768px) {
    text-align: center;
  }

  @media (min-width: 1440px) {
    text-align: revert;
  }

  ${props =>
    props.light &&
    css`
      font-family: var(--font-light);
      font-size: 14px;
    `}

  &.header {
    font-size: 24px;
    color: #20639b;
    font-family: var(--font-bold);
    margin: 1em 0 0.5em;
    text-align: start;
  }
`;

export const Error = styled.p`
  font-size: 12px;
  color: red;
`;

export const Span = styled.span`
  color: rgba(22, 108, 180, 0.9);
  cursor: pointer;
  font-family: var(--font-bold);

  &:hover {
    color: rgba(22, 108, 180, 1);
  }
`;

export const InputFieldPassword = styled.input`
  width: 100%;
  border: none;
  background-color: transparent;
  &:focus {
    outline: none;
  }

  &::placeholder {
    font-family: inherit;
  }

  &::-ms-clear,
  &::-ms-reveal {
    display: none;
  }
`;

export const PasswordContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  border: 1px solid #e1e1e1;
  padding: 0.5em;
  border-radius: 12px;
  margin: 0.5em 0;

  &:focus-within {
    border: 1px solid #f39e28;
    box-shadow: 3px 4px 10px rgba(237, 56, 51, 0.25);
  }
`;

export const Modal = styled.div`
  background: gray;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 5;
  opacity: 0.6;
`;

export const GeneralPadding = styled.div`
  padding-left: 1.5em;
  padding-right: 1.5em;

  @media (min-width: 768px) {
    padding-left: 3em;
    padding-right: 3em;
  }

  @media (min-width: 1024px) {
    padding-left: 5em;
    padding-right: 5em;
  }
  @media (min-width: 2560px) {
    padding-left: 10em;
    padding-right: 10em;
  }
`;

export const ModalContainer = styled.div`
  background: white;
  color: #20639b;
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 1;
  z-index: 5;
  transform: translate(-50%, -50%);
  border: 1px solid #20639b;
  border-radius: 12px;

  @media (min-width: 768px) {
    width: 70%;
  }

  @media (min-width: 1024px) {
    width: 50%;
  }

  @media (min-width: 1400px) {
    width: 40%;
  }
`;

export const RemoveGeneralPadding = styled.div`
  margin-left: -1.5em;
  margin-right: -1.5em;

  @media (min-width: 768px) {
    margin-left: -3em;
    margin-right: -3em;
  }

  @media (min-width: 1024px) {
    margin-left: -5em;
    margin-right: -5em;
  }
  @media (min-width: 2560px) {
    margin-left: -10em;
    margin-right: -10em;
  }
`;

export const ThrindleNotification = styled.div`
  ${tw`flex items-center justify-between h-20 bg-white-main rounded-md md:w-72 fixed -top-96 left-2 right-2 md:left-[unset] shadow-lg z-[100]`};
  transition: all 0.5s ease-in-out;

  ${props => (props.notify ? tw`top-10 opacity-100` : tw`-top-96 opacity-0`)};
`;

export const ArrowBack = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  cursor: pointer;
`;

export const HeaderMixed = styled.h1`
  font-size: 18px;
  font-weight: 800;
  color: #20639b;
  margin: 1em 0 1.5em;

  ${props =>
    props.orange &&
    css`
      color: #f69f13;
      margin: 0;
    `}

  ${props =>
    props.blue &&
    css`
      color: #166cb4;
      margin: 0;
    `}

    &.heading {
    margin-top: 0;
    padding-top: 1em;
    font-family: var(--font-bold);

    @media (min-width: 768px) {
      display: none;
    }
  }
`;

export const List = styled.ul`
  padding: 0;
  list-style-type: disc;
`;

export const ListItems = styled.li`
  display: inline-block;
  margin: 1em 1em 1em 0;
  color: ${props => (props.inactive ? "#C2C2C2" : "#20639b")};
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  border-bottom: ${props => (props.inactive ? "" : "1px solid #f39e28")};
`;

export const HeaderLarge = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: block;
    margin: 0em 0 1.5em;
  }
`;

export const ContainerWithBg = styled.div`
  background: url(${Bg});
`;
