import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import InputWithFieldSet from "../../../components/common/inputs/inputWithFieldSet";
import MainContainer from "../../../components/common/mainContainer/mainContainer";
import {Text} from "../../../styles/globalStyles";
import Button from "../../../components/common/buttons/button";
import TextArea from "../../../components/common/inputs/textarea";
import DropdownContent from "./DropdownContent";
// import UploadFile from "../../../components/uploadFile/uploadFile";
import store from "../../../assets/images/store.png";
import MainDropDown from "../../../components/common/inputs/DropdownInputs";
import {useDispatch, useSelector} from "react-redux";
import {STORE_SELLER_STORE_DETAILS} from "../../../redux/constants";
import {useNavigate} from "react-router-dom";
import {stateData} from "../../../data/productionDatas";
// import {showNotification} from "../../../redux/actions/statusNotifications";
// import MobileBackBtn from "../../../components/common/buttons/MobileBackBtn";

function StoreDetails() {
  // const [images, setImages] = useState([]);
  // const [shopLogo, setShopLogo] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {marketType} = useSelector(state => state.storeMarketType);
  const [show, setShow] = useState(false);
  const [storeState, setStoreState] = useState("");
  const [storeStateErr, setStoreStateErr] = useState("");


  // const chooseImage = event => {
  //   const newFile = event.target.files[0];
  //   console.log(newFile);
  //   if (newFile && newFile.type.startsWith("image")) {
  //     const updatedList = [newFile];
  //     console.log(updatedList);
  //     setImages(updatedList);
  //   } else {
  //     dispatch(
  //       showNotification({
  //         notify: {
  //           status: 2,
  //           message: "Please select a file of image type",
  //         },
  //       }),
  //     );
  //   }
  // };
  // console.log(images, "STORE_ LOGOG");
  const formik = useFormik({
    initialValues: {
      storeTitle: "",
      storeAddress: "",
      description: "",
      NIN: "",
      storeLink: "",
    },

    validationSchema: Yup.object({
      storeTitle: Yup.string().required("Required"),
      storeAddress: Yup.string().required("Required"),
      description: Yup.string().required("Required"),
      storeLink: Yup.string().required(
        "Please choose a unique name for your store link",
      ),
    }),

    onSubmit: values => {
      if (storeState === "") {
        setStoreStateErr("Please select your state");
      } else {
        dispatch({
          type: STORE_SELLER_STORE_DETAILS,
          payload: {
            shopTitle: values.storeTitle,
            shopAddress: values.storeAddress,
            shopDescription: values.description,
            shopLogo: [],
            shopNIN: values.NIN,
            storeLink: values.storeLink,
            storeState: storeState,
          },
        });

        navigate("/store-wallet");
      }
    },
  });
  return (
    <MainContainer>
      <Container className="main">
        <Container className="main-form">
          {/* <MobileBackBtn to='/store-details'/> */}
          <Text className="header">Store Details</Text>

          <form onSubmit={formik.handleSubmit}>
            <Container className="input-container">
              <InputWithFieldSet
                type="text"
                id="storeTitle"
                name="storeTitle"
                fieldset="Store Title"
                placeholder="Yinkzi Store"
                value={formik.values.storeTitle}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.storeTitle && formik.touched.storeTitle ? (
                <p className="text-sm mt-1 text-secondary-error">Required</p>
              ) : null}
            </Container>
            <Container className="input-container">
              <TextArea
                id="description"
                name="description"
                rows="3"
                fieldset="Description"
                placeholder="Dealers in all kinds of fabric wears"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.description && formik.touched.description ? (
                <p className="text-sm mt-1 text-secondary-error">Required</p>
              ) : null}
            </Container>

            <Container className="input-container">
              <InputWithFieldSet
                type="text"
                id="storeAddress"
                name="storeAddress"
                fieldset="Store Address"
                placeholder="27, Adamu Close, Balogun Market, Lagos"
                value={formik.values.storeAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.storeAddress && formik.touched.storeAddress ? (
                <p className="text-sm mt-1 text-secondary-error">Required</p>
              ) : null}
            </Container>
            <Container className="input-container">
              <InputWithFieldSet
                type="text"
                id="storeLink"
                name="storeLink"
                fieldset="Unique store Link"
                placeholder="Unique store link for customers"
                value={formik.values.storeLink}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.storeLink && formik.touched.storeLink ? (
                <p className="text-sm mt-1 text-secondary-error">
                  Please choose a unique name for your store link
                </p>
              ) : null}
              {marketType === "Thrindle Mall" ? (
                <p className="text-xs text-primary-light pt-2">
                  {formik.values.storeLink ? (
                    <span>{formik.values.storeLink}</span>
                  ) : (
                    <span>Storename</span>
                  )}
                  .thrindle.shop (No spaces allowed)
                </p>
              ) : (
                <p className="text-xs text-primary-light pt-2">
                  {formik.values.storeLink ? (
                    <span>{formik.values.storeLink}</span>
                  ) : (
                    <span>Storename</span>
                  )}
                  .thrindle.com (No spaces allowed)
                </p>
              )}
            </Container>
            {/* Dropdown */}
            <Container className="mb-0 ">
              <MainDropDown
                visible={show}
                toggleModal={() => {
                  setShow(!show);
                  setStoreStateErr("");
                }}
                closeModal={() => setShow(false)}
                // label="States"
                value={storeState}
                placeholder="Select your state">
                <DropdownContent
                  chooseValue={val => {
                    setStoreStateErr("");
                    setStoreState(val);
                  }}
                  closeModal={() => setShow(false)}
                  data={stateData}
                  value={storeState}
                />
              </MainDropDown>
              {storeStateErr && (
                <p className="text-sm  text-secondary-error">
                  {
                    <span className="text-sm  text-secondary-error">
                      {storeStateErr}
                    </span>
                  }
                </p>
              )}
            </Container>

          

            

            {/* {images && images.length !== 0 ? (
              <>
                <p className="md:text-base text-white-lightGray text-sm font-Bold mb-2">
                  Store logo:
                </p>
                <p className="md:text-sm text-xs text-white-text">
                  {images[0].name}
                </p>
              </>
            ) : (
              <UploadFile text="Shop logo" onChange={val => chooseImage(val)} />
            )} */}

            <Text className="header pt-6">Store Documents</Text>

            <Container className="input-container">
              <InputWithFieldSet
                type="text"
                id="NIN"
                name="NIN"
                fieldset="NIN (Optional)"
                placeholder="NIN(Optional)"
                value={formik.values.NIN}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Container>

            <Container className="button-container">
              <Button type="submit" text="Continue" arrow={true} />
            </Container>
          </form>
        </Container>

        <Image src={store} alt="storeDetails" />
      </Container>
    </MainContainer>
  );
}

export default StoreDetails;

const Container = styled.div`
  &.input-container {
    margin: 1em 0;
  }

  &.button-container {
    margin: 2em 0;
  }

  &.main {
    @media (min-width: 1024px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 1em 0;
    }
  }

  &.main-form {
    @media (min-width: 1024px) {
      width: 35%;
    }
  }
  &.store-logo {
    width: 30px;
    height: 30px;
    border-radius: 30px;
  }
`;

const Image = styled.img`
  margin: 2em 0;

  @media (min-width: 1024px) {
    width: 60%;
  }
`;
