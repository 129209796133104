import axios from "axios";
import {store} from "../redux/store";
// import {LoginUser, LogOutUser} from "../store/userReducer/actions";
// import {showNotification} from "../store/Notification/actions";
// import {PostWithoutHeader} from "./requestFunc";
import {
  CLEAR_ERROR,
  // USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  VERIFY_NUMBER_SUCCESS,
} from "../redux/constants";
import {showNotification} from "../redux/actions/statusNotifications";
import {resend_Otp} from "../redux/actions/authActions";
// import createAuthRefreshInterceptor from "axios-auth-refresh";
let connect = process.env;

let url = {
  prod: connect.REACT_APP_API_URL,
  shopProd:connect.REACT_APP_API_SHOP_URL
};



const apiCalls = (phone = null, navigate = null) => {
  let apiCalls = axios.create({
    baseURL: url.prod,
    headers: {
      "Content-Type": "application/json",
    },
  });

  // Defaults timeout when unable to connect to server
  apiCalls.defaults.timeout = 30000;
  apiCalls.defaults.timeoutErrorMessage =
    "Cannot connect to server. Please check your network";

  // Request interceptor for API calls
  // https://www.bezkoder.com/react-refresh-token/
  apiCalls.interceptors.request.use(
    async config => {
      const token = store.getState().userLogin.accessToken;
      config.headers.authorization = `Bearer ${token}`;
      return config;
    },
    error => {
      Promise.reject(error);
    },
  );

  apiCalls.interceptors.response.use(
    res => {
      // console.log("LOGIN DETAILS", res?.data?.data?.user);
      // console.log(res, "LOGIN DETAILS TOW");
      if (res?.data?.data?.user?.store_id === null) {
        navigate("/choose-market");
        store.dispatch({
          type: VERIFY_NUMBER_SUCCESS,
          payload: {token: res?.data?.data?.access_token},
        });
        store.dispatch(
          showNotification({
            notify: {
              status: 0,
              message: "Please kindly setup your online store",
            },
          }),
        );
        throw "Please create your online store";
      }
      return res;
    },
    async err => {
      if (
        err?.response?.status === 401 &&
        err.response?.data?.message === "user needs to be verified"
      ) {
        store.dispatch(resend_Otp(phone));
        store.dispatch({type: CLEAR_ERROR});
        navigate("/otp");
        store.dispatch(
          showNotification({
            notify: {
              status: 0,
              message: "Please kindly verify your account",
            },
          }),
        );
      }

      return Promise.reject(err);
    },
  );
  return apiCalls;
};



const apiCallShop = (phone = null, navigate = null) => {
  let apiCalls = axios.create({
    baseURL: process.env.REACT_APP_API_SHOP_URL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  // Defaults timeout when unable to connect to server
  apiCalls.defaults.timeout = 30000;
  apiCalls.defaults.timeoutErrorMessage =
    "Cannot connect to server. Please check your network";

  // Request interceptor for API calls
  // https://www.bezkoder.com/react-refresh-token/
  apiCalls.interceptors.request.use(
    async config => {
      const token = store.getState().userLogin.accessToken;
      config.headers.authorization = `Bearer ${token}`;
      return config;
    },
    error => {
      Promise.reject(error);
    },
  );

  apiCalls.interceptors.response.use(
    res => {
      // console.log("LOGIN DETAILS", res?.data?.data?.user);
      // console.log(res, "LOGIN DETAILS TOW");
      if (res?.data?.data?.user?.store_id === null) {
        navigate("/choose-market");
        store.dispatch({
          type: VERIFY_NUMBER_SUCCESS,
          payload: {token: res?.data?.data?.access_token},
        });
        store.dispatch(
          showNotification({
            notify: {
              status: 0,
              message: "Please kindly setup your online store",
            },
          }),
        );
        throw "Please create your online store";
      }
      return res;
    },
    async err => {
      if (
        err?.response?.status === 401 &&
        err.response?.data?.message === "user needs to be verified"
      ) {
        store.dispatch(resend_Otp(phone));
        store.dispatch({type: CLEAR_ERROR});
        navigate("/otp");
        store.dispatch(
          showNotification({
            notify: {
              status: 0,
              message: "Please kindly verify your account",
            },
          }),
        );
      }

      return Promise.reject(err);
    },
  );
  return apiCalls;
};






const mainApi = axios.create({ 
  baseURL: url.prod,
  headers: {
    "Content-Type": "application/json",
  },
});

mainApi.defaults.timeout = 30000;
mainApi.defaults.timeoutErrorMessage =
  "Cannot connect to server. Please check your network";

mainApi.interceptors.request.use(
  async config => {
    const token = store.getState().userLogin.accessToken;
    config.headers.authorization = `Bearer ${token}`;
    return config;
  },
  error => {
    Promise.reject(error);
  },
);

mainApi.interceptors.response.use(
  res => res,
  async err => {
    const originalConfig = err.config;
    if (
      (originalConfig.url !== "/login" ||
        originalConfig.url !== "/signup" ||
        originalConfig.url !== "/forgot-password" ||
        originalConfig.url !== "/otp" ||
        originalConfig.url !== "/account-created" ||
        originalConfig.url !== "/forgot-password" ||
        originalConfig.url !== "/set-password" ||
        originalConfig.url !== "/password-reset" ||
        originalConfig.url !== "/choose-market" ||
        originalConfig.url !== "/store-details" ||
        originalConfig.url !== "/store-wallet" ||
        originalConfig.url !== "/store-created") &&
      err.response
    ) {
      // Access Token was expired
      if (
        err.response.status === 401 &&
        !originalConfig._retry &&
        err.response?.data?.message !== "user needs to be verified"
      ) {
        originalConfig._retry = true;
        store.dispatch({type: USER_LOGOUT});
        store.dispatch(
          showNotification({
            notify: {
              status: 0,
              message: "Session timeout. Please login",
            },
          }),
        );
        return Promise.reject(err);
        // const refresh_token = store.getState().userLogin.refreshToken;
        // try {
        //   const {data} = await PostWithoutHeader("users/refresh-token", {
        //     refreshToken: refresh_token,
        //   });
        //   if (data) {
        //     let token = data.data.access_token;
        //     let refresh_token = data.data.refresh_token;
        //     store.dispatch({
        //       type: USER_LOGIN_SUCCESS,
        //       payload: {
        //         accessToken: token,
        //         refreshToken: refresh_token,
        //       },
        //     });
        //   }
        //   return mainApi(originalConfig);
        // } catch (error) {
        //   store.dispatch({type: USER_LOGOUT});
        //   store.dispatch(
        //     showNotification({
        //       notify: {
        //         status: 0,
        //         message: "Session timeout. Please login",
        //       },
        //     }),
        //   );
        //   return Promise.reject(err);
        // }
      }
    }

    return Promise.reject(err);
  },
);



// shop main api


const mainApiShop = axios.create({ 
  baseURL: url.shopProd,
  headers: {
    "Content-Type": "application/json",
  },
});

mainApiShop.defaults.timeout = 30000;
mainApiShop.defaults.timeoutErrorMessage =
  "Cannot connect to server. Please check your network";

mainApiShop.interceptors.request.use(
  async config => {
    const token = store.getState().userLogin.accessToken;
    config.headers.authorization = `Bearer ${token}`;
    return config;
  },
  error => {
    Promise.reject(error);
  },
);

mainApiShop.interceptors.response.use(
  res => res,
  async err => {
    const originalConfig = err.config;
    if (
      (originalConfig.url !== "/login" ||
        originalConfig.url !== "/signup" ||
        originalConfig.url !== "/forgot-password" ||
        originalConfig.url !== "/otp" ||
        originalConfig.url !== "/account-created" ||
        originalConfig.url !== "/forgot-password" ||
        originalConfig.url !== "/set-password" ||
        originalConfig.url !== "/password-reset" ||
        originalConfig.url !== "/choose-market" ||
        originalConfig.url !== "/store-details" ||
        originalConfig.url !== "/store-wallet" ||
        originalConfig.url !== "/store-created") &&
      err.response
    ) {
      // Access Token was expired
      if (
        err.response.status === 401 &&
        !originalConfig._retry &&
        err.response?.data?.message !== "user needs to be verified"
      ) {
        originalConfig._retry = true;
        store.dispatch({type: USER_LOGOUT});
        store.dispatch(
          showNotification({
            notify: {
              status: 0,
              message: "Session timeout. Please login",
            },
          }),
        );
        return Promise.reject(err);
        // const refresh_token = store.getState().userLogin.refreshToken;
        // try {
        //   const {data} = await PostWithoutHeader("users/refresh-token", {
        //     refreshToken: refresh_token,
        //   });
        //   if (data) {
        //     let token = data.data.access_token;
        //     let refresh_token = data.data.refresh_token;
        //     store.dispatch({
        //       type: USER_LOGIN_SUCCESS,
        //       payload: {
        //         accessToken: token,
        //         refreshToken: refresh_token,
        //       },
        //     });
        //   }
        //   return mainApi(originalConfig);
        // } catch (error) {
        //   store.dispatch({type: USER_LOGOUT});
        //   store.dispatch(
        //     showNotification({
        //       notify: {
        //         status: 0,
        //         message: "Session timeout. Please login",
        //       },
        //     }),
        //   );
        //   return Promise.reject(err);
        // }
      }
    }

    return Promise.reject(err);
  },
);

export {apiCalls, apiCallShop, mainApiShop, mainApi};
