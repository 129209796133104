export const productSizes = [
  {title: "Small", id: "1"},
  {title: "Medium", id: "2"},
  {title: "Large", id: "3"},
  {title: "Extra Large", id: "4"},
  {title: "Extra Extra Large", id: "5"},
];

export const productTypeArr = ["New", "Used"];
export const stateData = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "FCT - Abuja",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
];

export const productSizes2 = [
  {title: "1", id: "1"},
  {title: "2", id: "2"},
  {title: "3", id: "3"},
  {title: "4", id: "4"},
  {title: "5", id: "5"},
  {title: "6", id: "6"},
  {title: "7", id: "7"},
  {title: "8", id: "8"},
  {title: "9", id: "9"},
  {title: "10", id: "10"},
  {title: "11", id: "11"},
  {title: "12", id: "12"},
  {title: "13", id: "13"},
  {title: "14", id: "14"},
  {title: "15", id: "15"},
  {title: "16", id: "16"},
  {title: "17", id: "17"},
  {title: "18", id: "18"},
  {title: "19", id: "19"},
  {title: "20", id: "20"},
  {title: "21", id: "21"},
  {title: "22", id: "22"},
  {title: "23", id: "23"},
  {title: "24", id: "24"},
  {title: "25", id: "25"},
  {title: "26", id: "26"},
  {title: "27", id: "27"},
  {title: "28", id: "28"},
  {title: "29", id: "29"},
  {title: "30", id: "30"},
  {title: "31", id: "31"},
  {title: "32", id: "32"},
  {title: "33", id: "33"},
  {title: "34", id: "34"},
  {title: "35", id: "35"},
  {title: "36", id: "36"},
  {title: "37", id: "37"},
  {title: "38", id: "38"},
  {title: "39", id: "39"},
  {title: "40", id: "40"},
  {title: "41", id: "41"},
  {title: "42", id: "42"},
  {title: "43", id: "43"},
  {title: "44", id: "44"},
  {title: "45", id: "45"},
  {title: "46", id: "46"},
  {title: "47", id: "47"},
  {title: "48", id: "48"},
  {title: "49", id: "49"},
  {title: "50", id: "50"},
  {title: "51", id: "51"},
  {title: "52", id: "52"},
  {title: "53", id: "53"},
  {title: "54", id: "54"},
  {title: "55", id: "55"},
  {title: "56", id: "56"},
  {title: "57", id: "57"},
  {title: "58", id: "58"},
  {title: "59", id: "59"},
  {title: "60", id: "60"},
];

export const sizesTab = [{title: "Type1"}, {title: "Type2"}];

export const units = [
  {
    title: "litres",
    id: "1",
  },
  {
    title: "bag",
    id: "2",
  },
  {
    title: "pcs",
    id: "3",
  },
  {
    title: "pair",
    id: "4",
  },
  {
    title: "pack",
    id: "5",
  },
  {
    title: "yard",
    id: "6",
  },
  {
    title: "portion",
    id: "7",
  },
  {
    title: "bowl",
    id: "8",
  },
  {
    title: "plate",
    id: "9",
  },
  {
    title: "bottles",
    id: "10",
  },
  {
    title: "lb",
    id: "11",
  },
  {
    title: "kg",
    id: "12",
  },
  {
    title: "cm",
    id: "13",
  },
  {
    title: "in",
    id: "14",
  },
  {
    title: "ft",
    id: "15",
  },
  {
    title: "km",
    id: "16",
  },
];

export const unitsArr = [
  "litres",
  "bag",
  "pcs",
  "pair",
  "pack",
  "yard",
  "portion",
  "bowl",
  "plate",
  "bottles",
  "lb",
  "kg",
  "cm",
  "in",
  "ft",
  "km",
];

export const mainBanks = [
  {
    category: "MICRO_FINANCE_BANK",
    code: "090140",
    bankCode: "140",
    name: "Sagamu Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090148",
    bankCode: "989",
    name: "Bowen Microfinance Bank",
  },
  {
    category: "OTHER_FINANCIAL_INSTITUTIONS",
    code: "110002",
    bankCode: "987",
    name: "Flutterwave Technology Solutions Limited",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090143",
    bankCode: "986",
    name: "Apeks Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090141",
    bankCode: "985",
    name: "Chikum Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090144",
    bankCode: "984",
    name: "CIT Microfinance Bank",
  },
  {
    category: "PRIMARY_MORTGAGE_BANK",
    code: "070014",
    bankCode: "983",
    name: "First Generation Mortgage Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090147",
    bankCode: "982",
    name: "Hackman Microfinance Bank",
  },
  {
    category: "PRIMARY_MORTGAGE_BANK",
    code: "070016",
    bankCode: "981",
    name: "Infinity Trust Mortgage Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090149",
    bankCode: "980",
    name: "IRL Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090151",
    bankCode: "979",
    name: "Mutual Trust Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090170",
    bankCode: "588",
    name: "Rahama Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090193",
    bankCode: "597",
    name: "Unical Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090152",
    bankCode: "598",
    name: "Nagarta Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090268",
    bankCode: "618",
    name: "ADEYEMI COLLEGE STAFF MICROFINANCE BANK",
  },
  {
    category: "12",
    code: "110001",
    bankCode: "329",
    name: "PayAttitude Online",
  },
  {
    category: "MOBILE_MONEY_OPERATOR",
    code: "100023",
    bankCode: "328",
    name: "TagPay",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "070001",
    bankCode: "552",
    name: "NPF MicroFinance Bank",
  },
  {
    category: "PRIMARY_MORTGAGE_BANK",
    code: "070009",
    bankCode: "570",
    name: "Gateway Mortgage Bank",
  },
  {
    category: "PRIMARY_MORTGAGE_BANK",
    code: "070011",
    bankCode: "575",
    name: "Refuge Mortgage Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090179",
    bankCode: "590",
    name: "Fast Microfinance Bank ",
  },
  {
    category: "PRIMARY_MORTGAGE_BANK",
    code: "070013",
    bankCode: "603",
    name: "Platinum Mortgage Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090156",
    bankCode: "998",
    name: "e-BARCS MICROFINANCE BANK ",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090176",
    bankCode: "600",
    name: "Bosak Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090119",
    bankCode: "116",
    name: "Ohafia Microfinance Bank",
  },
  {
    category: "PRIMARY_MORTGAGE_BANK",
    code: "090120",
    bankCode: "117",
    name: "Wetland Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090174",
    bankCode: "586",
    name: "Malachy Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090259",
    bankCode: "608",
    name: "Alekun Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090198",
    bankCode: "599",
    name: "Renmoney Microfinance Bank",
  },
  {
    category: "COMMERCIAL_BANK",
    code: "000022",
    bankCode: "100",
    name: "SunTrust Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090118",
    bankCode: "244",
    name: "Ibile Microfinance Bank",
  },
  {
    category: "PRIMARY_MORTGAGE_BANK",
    code: "090117",
    bankCode: "581",
    name: "Boctrust Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090130",
    bankCode: "573",
    name: "Consumer Microfinance Bank",
  },
  {
    category: "MOBILE_MONEY_OPERATOR",
    code: "100032",
    bankCode: "997",
    name: "NOW NOW DIGITAL SYSTEMS LIMITED",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090164",
    bankCode: "974",
    name: "First Royal Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090165",
    bankCode: "973",
    name: "Petra Microfinance Bank",
  },
  {
    category: "MOBILE_MONEY_OPERATOR",
    code: "100031",
    bankCode: "312",
    name: "FCMB Easy Account",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090192",
    bankCode: "596",
    name: "Midland Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090262",
    bankCode: "609",
    name: "Stellas Microfinance Bank ",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090133",
    bankCode: "572",
    name: "Al-Barakah Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090135",
    bankCode: "576",
    name: "Personal Trust Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090139",
    bankCode: "578",
    name: "Visa Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090138",
    bankCode: "579",
    name: "Royal Exchange Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090134",
    bankCode: "602",
    name: "Accion Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090145",
    bankCode: "907",
    name: "Fullrange Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090267",
    bankCode: "611",
    name: "Kuda Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090006",
    bankCode: "403",
    name: "SafeTrust Mortgage Bank",
  },
  {
    category: "MOBILE_MONEY_OPERATOR",
    code: "100022",
    bankCode: "326",
    name: "GoMoney",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090114",
    bankCode: "114",
    name: "EMPIRE MFB",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090126",
    bankCode: "568",
    name: "Fidifund Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090123",
    bankCode: "569",
    name: "Verite Microfinance Bank",
  },
  {
    category: "MERCHANT_BANK",
    code: "060002",
    bankCode: "911",
    name: "FBNQUEST MERCHANT BANK",
  },
  {
    category: "PRIMARY_MORTGAGE_BANK",
    code: "090108",
    bankCode: "561",
    name: "New Prudential Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090121",
    bankCode: "574",
    name: "Hasal Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090112",
    bankCode: "580",
    name: "Seed Capital Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090177",
    bankCode: "591",
    name: "Lapo Microfinance Bank ",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090191",
    bankCode: "595",
    name: "KCMB Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090189",
    bankCode: "592",
    name: "Esan Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090188",
    bankCode: "593",
    name: "Baines Credit Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090265",
    bankCode: "616",
    name: "Lovonus Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090269",
    bankCode: "617",
    name: "Greenville Microfinance Bank",
  },
  {
    category: "COMMERCIAL_BANK",
    code: "000020",
    bankCode: "030",
    name: "Heritage",
  },
  {
    category: "COMMERCIAL_BANK",
    code: "000021",
    bankCode: "068",
    name: "Standard Chartered Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "070002",
    bankCode: "501",
    name: "Fortis Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "070006",
    bankCode: "551",
    name: "Covenant Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090001",
    bankCode: "401",
    name: "ASO Savings & Loans",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090003",
    bankCode: "402",
    name: "Jubilee Life Mortgage Bank",
  },
  {
    category: "MOBILE_MONEY_OPERATOR",
    code: "100003",
    bankCode: "311",
    name: "ReadyCash (Parkway)",
  },
  {
    category: "MOBILE_MONEY_OPERATOR",
    code: "100004",
    bankCode: "305",
    name: "OPAY",
  },
  {
    category: "MOBILE_MONEY_OPERATOR",
    code: "100005",
    bankCode: "317",
    name: "Cellulant",
  },
  {
    category: "MOBILE_MONEY_OPERATOR",
    code: "100006",
    bankCode: "306",
    name: "eTranzact",
  },
  {
    category: "MOBILE_MONEY_OPERATOR",
    code: "100007",
    bankCode: "304",
    name: "Stanbic IBTC @ease wallet",
  },
  {
    category: "MOBILE_MONEY_OPERATOR",
    code: "100008",
    bankCode: "307",
    name: "Ecobank Xpress Account",
  },
  {
    category: "MOBILE_MONEY_OPERATOR",
    code: "100009",
    bankCode: "315",
    name: "GTMobile",
  },
  {
    category: "MOBILE_MONEY_OPERATOR",
    code: "100010",
    bankCode: "319",
    name: "TeasyMobile",
  },
  {
    category: "MOBILE_MONEY_OPERATOR",
    code: "100011",
    bankCode: "313",
    name: "Mkudi",
  },
  {
    category: "MOBILE_MONEY_OPERATOR",
    code: "100013",
    bankCode: "323",
    name: "Access Money",
  },
  {
    category: "MOBILE_MONEY_OPERATOR",
    code: "100017",
    bankCode: "324",
    name: "Hedonmark",
  },
  {
    category: "MOBILE_MONEY_OPERATOR",
    code: "100020",
    bankCode: "325",
    name: "MoneyBox",
  },
  {
    category: "MOBILE_MONEY_OPERATOR",
    code: "100021",
    bankCode: "302",
    name: "Eartholeum",
  },
  {
    category: "COMMERCIAL_BANK",
    code: "000007",
    bankCode: "070",
    name: "Fidelity Bank",
  },
  {
    category: "COMMERCIAL_BANK",
    code: "000008",
    bankCode: "076",
    name: "Polaris Bank",
  },
  {
    category: "COMMERCIAL_BANK",
    code: "000009",
    bankCode: "023",
    name: "CitiBank",
  },
  {
    category: "COMMERCIAL_BANK",
    code: "000010",
    bankCode: "050",
    name: "Ecobank Plc",
  },
  {
    category: "COMMERCIAL_BANK",
    code: "000011",
    bankCode: "215",
    name: "Unity Bank",
  },
  {
    category: "COMMERCIAL_BANK",
    code: "000015",
    bankCode: "057",
    name: "Zenith Bank",
  },
  {
    category: "COMMERCIAL_BANK",
    code: "000016",
    bankCode: "011",
    name: "First Bank of Nigeria",
  },
  {
    category: "COMMERCIAL_BANK",
    code: "000017",
    bankCode: "035",
    name: "Wema Bank",
  },
  {
    category: "COMMERCIAL_BANK",
    code: "000018",
    bankCode: "032",
    name: "Union Bank",
  },
  {
    category: "COMMERCIAL_BANK",
    code: "000019",
    bankCode: "084",
    name: "Enterprise Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090004",
    bankCode: "526",
    name: "Parralex",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090005",
    bankCode: "523",
    name: "Trustbond",
  },
  {
    category: "MOBILE_MONEY_OPERATOR",
    code: "100001",
    bankCode: "314",
    name: "FET",
  },
  {
    category: "MOBILE_MONEY_OPERATOR",
    code: "100002",
    bankCode: "327",
    name: "Pagatech",
  },
  {
    category: "MOBILE_MONEY_OPERATOR",
    code: "100012",
    bankCode: "320",
    name: "VTNetworks",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "100014",
    bankCode: "309",
    name: "Firstmonie Wallet",
  },
  {
    category: "DISCOUNT_HOUSES",
    code: "400001",
    bankCode: "908",
    name: "FSDH",
  },
  {
    category: "OTHER_FINANCIAL_INSTITUTIONS",
    code: "999999",
    bankCode: "999",
    name: "NIP Virtual Bank",
  },
  {
    category: "MERCHANT_BANK",
    code: "060001",
    bankCode: "559",
    name: "Coronation Merchant Bank",
  },
  {
    category: "BUREAUX_DE_CHANGE",
    code: "000014",
    bankCode: "044",
    name: "Access Bank",
  },
  {
    category: "COMMERCIAL_BANK",
    code: "000001",
    bankCode: "232",
    name: "Sterling Bank",
  },
  {
    category: "COMMERCIAL_BANK",
    code: "000002",
    bankCode: "082",
    name: "Keystone Bank",
  },
  {
    category: "COMMERCIAL_BANK",
    code: "000003",
    bankCode: "214",
    name: "FCMB",
  },
  {
    category: "COMMERCIAL_BANK",
    code: "000004",
    bankCode: "033",
    name: "United Bank for Africa",
  },
  {
    category: "COMMERCIAL_BANK",
    code: "000006",
    bankCode: "301",
    name: "JAIZ Bank",
  },
  {
    category: "COMMERCIAL_BANK",
    code: "000012",
    bankCode: "221",
    name: "Stanbic IBTC Bank",
  },
  {
    category: "COMMERCIAL_BANK",
    code: "000013",
    bankCode: "058",
    name: "GTBank Plc",
  },
  {
    category: "MOBILE_MONEY_OPERATOR",
    code: "100015",
    bankCode: "303",
    name: "ChamsMobile",
  },
  {
    category: "MOBILE_MONEY_OPERATOR",
    code: "100016",
    bankCode: "308",
    name: "FortisMobile",
  },
  {
    category: "MOBILE_MONEY_OPERATOR",
    code: "100018",
    bankCode: "322",
    name: "ZenithMobile",
  },
  {
    category: "MOBILE_MONEY_OPERATOR",
    code: "100019",
    bankCode: "318",
    name: "Fidelity Mobile",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "070008",
    bankCode: "560",
    name: "Page MFBank",
  },
  {
    category: "MERCHANT_BANK",
    code: "000024",
    bankCode: "502",
    name: "Rand Merchant Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090160",
    bankCode: "975",
    name: "ADDOSSER MICROFINANCE BANK",
  },
  {
    category: "COMMERCIAL_BANK",
    code: "000023",
    bankCode: "101",
    name: "Providus Bank",
  },
  {
    category: "PRIMARY_MORTGAGE_BANK",
    code: "999033",
    bankCode: "182",
    name: "NIP NEWBANK TSQ",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090180",
    bankCode: "589",
    name: "Amju Unique Microfinance Bank ",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090159",
    bankCode: "996",
    name: "CREDIT AFRIQUE MICROFINANCE BANK",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090153",
    bankCode: "976",
    name: "FFS Microfinance",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090172",
    bankCode: "601",
    name: "Astrapolaris Microfinance Bank ",
  },
  {
    category: "MOBILE_MONEY_OPERATOR",
    code: "100028",
    bankCode: "028",
    name: "AG MORTGAGE BANK PLC",
  },
  {
    category: "PRIMARY_MORTGAGE_BANK",
    code: "070017",
    bankCode: "024",
    name: "Haggai Mortgage Bank Limited",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090261",
    bankCode: "610",
    name: "Quickfund Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "100024",
    bankCode: "415",
    name: "Imperial Homes Mortgage Bank",
  },
  {
    category: "MOBILE_MONEY_OPERATOR",
    code: "100027",
    bankCode: "330",
    name: "miMONEY",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090175",
    bankCode: "587",
    name: "Rubies MFB",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090195",
    bankCode: "619",
    name: "GROOMING MICROFINANCE BANK",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090273",
    bankCode: "620",
    name: "EMERALDS MICROFINANCE BANK ",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090276",
    bankCode: "621",
    name: "TRUSTFUND MICROFINANCE BANK ",
  },
  {
    category: "MOBILE_MONEY_OPERATOR",
    code: "100029",
    bankCode: "029",
    name: "Innovectives Kesh",
  },
  {
    category: "MOBILE_MONEY_OPERATOR",
    code: "100025",
    bankCode: "025",
    name: "Zinternet – KongaPay",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090115",
    bankCode: "115",
    name: "TCF MICROFINANCE BANK",
  },
  {
    category: "PRIMARY_MORTGAGE_BANK",
    code: "070012",
    bankCode: "026",
    name: "Lagos Building Investment Company",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090097",
    bankCode: "992",
    name: "Ekondo MFB",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090111",
    bankCode: "031",
    name: "FinaTrust Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090116",
    bankCode: "993",
    name: "AMML MFB",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090122",
    bankCode: "991",
    name: "Gowans Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090124",
    bankCode: "034",
    name: "Xslnce Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090125",
    bankCode: "995",
    name: "Regent Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090127",
    bankCode: "037",
    name: "BC Kash Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090128",
    bankCode: "038",
    name: "Ndiorah Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090129",
    bankCode: "039",
    name: "Money Trust Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090131",
    bankCode: "040",
    name: "Allworkers Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090132",
    bankCode: "041",
    name: "Richway Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090137",
    bankCode: "042",
    name: "PecanTrust Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090142",
    bankCode: "043",
    name: "Yes Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090154",
    bankCode: "404",
    name: "CEMCS Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090155",
    bankCode: "594",
    name: "La Fayette Microfinance Bank",
  },
  {
    category: "PRIMARY_MORTGAGE_BANK",
    code: "070015",
    bankCode: "615",
    name: "Brent Mortgage Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090205",
    bankCode: "622",
    name: "New Dawn Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090258",
    bankCode: "623",
    name: "Imo State Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090190",
    bankCode: "624",
    name: "Mutual Benefits Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090162",
    bankCode: "582",
    name: "Stanford Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090167",
    bankCode: "583",
    name: "Daylight Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090178",
    bankCode: "604",
    name: "GREENBANK MICROFINANCE BANK",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "070007",
    bankCode: "990",
    name: "LIVINGTRUST MORTGAGE BANK",
  },
  {
    category: "MERCHANT_BANK",
    code: "060003",
    bankCode: "637",
    name: "Nova Merchant Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090173",
    bankCode: "585",
    name: "Reliance Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090264",
    bankCode: "612",
    name: "Auchi Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090270",
    bankCode: "613",
    name: "AB MICROFINANCE BANK ",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090263",
    bankCode: "614",
    name: "NIGERIAN NAVY MICROFINANCE BANK ",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090107",
    bankCode: "413",
    name: "FBN MORTGAGES",
  },
  {
    category: "PRIMARY_MORTGAGE_BANK",
    code: "070010",
    bankCode: "567",
    name: "Abbey Mortgage Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090110",
    bankCode: "566",
    name: "VFD MFB",
  },
  {
    category: "PRIMARY_MORTGAGE_BANK",
    code: "100026",
    bankCode: "565",
    name: "One Finance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090169",
    bankCode: "584",
    name: "Alpha Kapital Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090251",
    bankCode: "607",
    name: "UNIVERSITY OF NIGERIA",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090136",
    bankCode: "577",
    name: "Baobab Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090146",
    bankCode: "978",
    name: "Trident Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090194",
    bankCode: "605",
    name: "NIRSAL MICROFINANCE BANK",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090197",
    bankCode: "606",
    name: "ABU MICROFINANCE BANK",
  },
  {
    category: "MOBILE_MONEY_OPERATOR",
    code: "100052",
    bankCode: "052",
    name: "BETA/ACCESS YELLO",
  },
  {
    category: "COMMERCIAL_BANK",
    code: "000027",
    bankCode: "103",
    name: "Globus Bank",
  },
  {
    category: "COMMERCIAL_BANK",
    code: "000025",
    bankCode: "102",
    name: "Titan Trust Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090286",
    bankCode: "639",
    name: "SAFE HAVEN MICROFINANCE BANK",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090272",
    bankCode: "629",
    name: "Olabisi Onabanjo University Microfinance Bank",
  },
  {
    category: "COMMERCIAL_BANK",
    code: "000026",
    bankCode: "626",
    name: "Taj Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090295",
    bankCode: "630",
    name: "Omiye Microfinance Bank",
  },
  // {
  //   category: 'MICRO_FINANCE_BANK',
  //   code: '090172',
  //   bankCode: '631',
  //   name: 'Astrapolaris Microfinance Bank ',
  // },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090297",
    bankCode: "642",
    name: "Alert Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090289",
    bankCode: "638",
    name: "Pillar Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090271",
    bankCode: "628",
    name: "Lavender Microfinance Bank",
  },
  {
    category: "OTHER_FINANCIAL_INSTITUTIONS",
    code: "110006",
    bankCode: "641",
    name: "Paystack Payment Limited",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090310",
    bankCode: "645",
    name: "EdFin Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090304",
    bankCode: "648",
    name: "EVANGEL MICROFINANCE BANK",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090327",
    bankCode: "649",
    name: "TRUST MICROFINANCE BANK",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090328",
    bankCode: "651",
    name: "EYOWO MICROFINANCE BANK",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090332",
    bankCode: "652",
    name: "EVERGREEN MICROFINANCE BANK",
  },
  {
    category: "PRIMARY_MORTGAGE_BANK",
    code: "070019",
    bankCode: "416",
    name: "Mayfresh Mortgage Bank Ltd",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090279",
    bankCode: "662",
    name: "IKIRE MICROFINANCE BANK",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090329",
    bankCode: "653",
    name: "NEPTUNE MICROFINANCE BANK",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090374",
    bankCode: "667",
    name: "COASTLINE MICROFINANCE BANK",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090303",
    bankCode: "668",
    name: "PURPLEMONEY MFB",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090275",
    bankCode: "669",
    name: "MERIDIAN MFB",
  },
  {
    category: "12",
    code: "120002",
    bankCode: "336",
    name: "Hope Payment Service Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090393",
    bankCode: "335",
    name: "Bridgeway Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090401",
    bankCode: "670",
    name: "Shepherd Trust Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090400",
    bankCode: "672",
    name: "FINCA MICROFINANCE BANK",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090376",
    bankCode: "675",
    name: "Apple Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090366",
    bankCode: "676",
    name: "FIRMUS Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090373",
    bankCode: "677",
    name: "Think Finance MFB",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090308",
    bankCode: "640",
    name: "Brightway Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090333",
    bankCode: "650",
    name: "OCHE Microfinance Bank",
  },
  {
    category: "12",
    code: "120001",
    bankCode: "332",
    name: "9 PAYMENT SERVICE BANK",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090281",
    bankCode: "643",
    name: "MINT-FINEX MFB",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090380",
    bankCode: "333",
    name: "CONPRO MICROFINANCE BANK",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090405",
    bankCode: "671",
    name: "ROLEZ MICROFINANCE BANK",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090113",
    bankCode: "674",
    name: "Microvis MFB",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090391",
    bankCode: "678",
    name: "DAVODANI MICROFINANCE BANK",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090389",
    bankCode: "681",
    name: "EK-Reliable Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090166",
    bankCode: "679",
    name: "Eso-E Microfinance Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090399",
    bankCode: "680",
    name: "NWANNEGADI MICROFINANCE BANK",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090371",
    bankCode: "682",
    name: "AGOSASA MICROFINANCE BANK",
  },
  {
    category: "MOBILE_MONEY_OPERATOR",
    code: "100035",
    bankCode: "683",
    name: "M36",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090412",
    bankCode: "692",
    name: "PREEMINENT MICROFINANCE BANK",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090424",
    bankCode: "696",
    name: "ABUCOOP MICROFINANCE BANK",
  },
  {
    category: "PRIMARY_MORTGAGE_BANK",
    code: "070009",
    bankCode: "570",
    name: "Gateway Mortgage Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090325",
    bankCode: "644",
    name: "SPARKLE",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090326",
    bankCode: "647",
    name: "BALOGUN GAMBARI MFB",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090299",
    bankCode: "658",
    name: "KONTAGORA MICROFINANCE BANK",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090318",
    bankCode: "665",
    name: "FEDERAL UNIVERSITY DUTSE MICROFINANCE BANK",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090321",
    bankCode: "656",
    name: "MAYFAIR MICROFINANCE BANK",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090322",
    bankCode: "657",
    name: "REPHIDIM MICROFINANCE BANK",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090324",
    bankCode: "655",
    name: "IKENNE MICROFINANCE BANK",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090331",
    bankCode: "654",
    name: "UNAAB MICROFINANCE BANK",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090336",
    bankCode: "661",
    name: "BIPC MICROFINANCE BANK",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090360",
    bankCode: "659",
    name: "CASHCONNECT MFB",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090362",
    bankCode: "663",
    name: "MOLUSI MICROFINANCE BANK",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090364",
    bankCode: "660",
    name: "NUTURE MICROFINANCE BANK",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090372",
    bankCode: "664",
    name: "LEGEND MICROFINANCE BANK",
  },
  {
    category: "OTHER_FINANCIAL_INSTITUTIONS",
    code: "110005",
    bankCode: "666",
    name: "3LINE CARD MANAGEMENT LIMITED",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090365",
    bankCode: "673",
    name: "CORESTEP MICROFINANCE BANK",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090370",
    bankCode: "334",
    name: "ILISAN MICROFINANCE BANK",
  },
  {
    category: "PRIMARY_MORTGAGE_BANK",
    code: "070021",
    bankCode: "693",
    name: "Coop Mortgage Bank",
  },
  {
    category: "MICRO_FINANCE_BANK",
    code: "090416",
    bankCode: "694",
    name: "Chibueze MFB",
  },
];

export const mainColor = [
  {
    hex: "#EFDECD",
    name: "Almond",
    rgb: "(239, 222, 205)",
  },
  {
    hex: "#CD9575",
    name: "Antique Brass",
    rgb: "(205, 149, 117)",
  },
  {
    hex: "#FDD9B5",
    name: "Apricot",
    rgb: "(253, 217, 181)",
  },
  {
    hex: "#78DBE2",
    name: "Aquamarine",
    rgb: "(120, 219, 226)",
  },
  {
    hex: "#87A96B",
    name: "Asparagus",
    rgb: "(135, 169, 107)",
  },
  {
    hex: "#FFA474",
    name: "Atomic Tangerine",
    rgb: "(255, 164, 116)",
  },
  {
    hex: "#FAE7B5",
    name: "Banana Mania",
    rgb: "(250, 231, 181)",
  },
  {
    hex: "#9F8170",
    name: "Beaver",
    rgb: "(159, 129, 112)",
  },
  {
    hex: "#FD7C6E",
    name: "Bittersweet",
    rgb: "(253, 124, 110)",
  },
  {
    hex: "#000000",
    name: "Black",
    rgb: "(0,0,0)",
  },
  {
    hex: "#ACE5EE",
    name: "Blizzard Blue",
    rgb: "(172, 229, 238)",
  },
  {
    hex: "#1F75FE",
    name: "Blue",
    rgb: "(31, 117, 254)",
  },
  {
    hex: "#A2A2D0",
    name: "Blue Bell",
    rgb: "(162, 162, 208)",
  },
  {
    hex: "#6699CC",
    name: "Blue Gray",
    rgb: "(102, 153, 204)",
  },
  {
    hex: "#0D98BA",
    name: "Blue Green",
    rgb: "(13, 152, 186)",
  },
  {
    hex: "#7366BD",
    name: "Blue Violet",
    rgb: "(115, 102, 189)",
  },
  {
    hex: "#DE5D83",
    name: "Blush",
    rgb: "(222, 93, 131)",
  },
  {
    hex: "#CB4154",
    name: "Brick Red",
    rgb: "(203, 65, 84)",
  },
  {
    hex: "#B4674D",
    name: "Brown",
    rgb: "(180, 103, 77)",
  },
  {
    hex: "#FF7F49",
    name: "Burnt Orange",
    rgb: "(255, 127, 73)",
  },
  {
    hex: "#EA7E5D",
    name: "Burnt Sienna",
    rgb: "(234, 126, 93)",
  },
  {
    hex: "#B0B7C6",
    name: "Cadet Blue",
    rgb: "(176, 183, 198)",
  },
  {
    hex: "#FFFF99",
    name: "Canary",
    rgb: "(255, 255, 153)",
  },
  {
    hex: "#1CD3A2",
    name: "Caribbean Green",
    rgb: "(28, 211, 162)",
  },
  {
    hex: "#FFAACC",
    name: "Carnation Pink",
    rgb: "(255, 170, 204)",
  },
  {
    hex: "#DD4492",
    name: "Cerise",
    rgb: "(221, 68, 146)",
  },
  {
    hex: "#1DACD6",
    name: "Cerulean",
    rgb: "(29, 172, 214)",
  },
  {
    hex: "#BC5D58",
    name: "Chestnut",
    rgb: "(188, 93, 88)",
  },
  {
    hex: "#DD9475",
    name: "Copper",
    rgb: "(221, 148, 117)",
  },
  {
    hex: "#9ACEEB",
    name: "Cornflower",
    rgb: "(154, 206, 235)",
  },
  {
    hex: "#FFBCD9",
    name: "Cotton Candy",
    rgb: "(255, 188, 217)",
  },
  {
    hex: "#FDDB6D",
    name: "Dandelion",
    rgb: "(253, 219, 109)",
  },
  {
    hex: "#2B6CC4",
    name: "Denim",
    rgb: "(43, 108, 196)",
  },
  {
    hex: "#EFCDB8",
    name: "Desert Sand",
    rgb: "(239, 205, 184)",
  },
  {
    hex: "#6E5160",
    name: "Eggplant",
    rgb: "(110, 81, 96)",
  },
  {
    hex: "#CEFF1D",
    name: "Electric Lime",
    rgb: "(206, 255, 29)",
  },
  {
    hex: "#71BC78",
    name: "Fern",
    rgb: "(113, 188, 120)",
  },
  {
    hex: "#6DAE81",
    name: "Forest Green",
    rgb: "(109, 174, 129)",
  },
  {
    hex: "#C364C5",
    name: "Fuchsia",
    rgb: "(195, 100, 197)",
  },
  {
    hex: "#CC6666",
    name: "Fuzzy Wuzzy",
    rgb: "(204, 102, 102)",
  },
  {
    hex: "#E7C697",
    name: "Gold",
    rgb: "(231, 198, 151)",
  },
  {
    hex: "#FCD975",
    name: "Goldenrod",
    rgb: "(252, 217, 117)",
  },
  {
    hex: "#A8E4A0",
    name: "Granny Smith Apple",
    rgb: "(168, 228, 160)",
  },
  {
    hex: "#95918C",
    name: "Gray",
    rgb: "(149, 145, 140)",
  },
  {
    hex: "#1CAC78",
    name: "Green",
    rgb: "(28, 172, 120)",
  },
  {
    hex: "#1164B4",
    name: "Green Blue",
    rgb: "(17, 100, 180)",
  },
  {
    hex: "#F0E891",
    name: "Green Yellow",
    rgb: "(240, 232, 145)",
  },
  {
    hex: "#FF1DCE",
    name: "Hot Magenta",
    rgb: "(255, 29, 206)",
  },
  {
    hex: "#B2EC5D",
    name: "Inchworm",
    rgb: "(178, 236, 93)",
  },
  {
    hex: "#5D76CB",
    name: "Indigo",
    rgb: "(93, 118, 203)",
  },
  {
    hex: "#CA3767",
    name: "Jazzberry Jam",
    rgb: "(202, 55, 103)",
  },
  {
    hex: "#3BB08F",
    name: "Jungle Green",
    rgb: "(59, 176, 143)",
  },
  {
    hex: "#FEFE22",
    name: "Laser Lemon",
    rgb: "(254, 254, 34)",
  },
  {
    hex: "#FCB4D5",
    name: "Lavender",
    rgb: "(252, 180, 213)",
  },
  {
    hex: "#FFF44F",
    name: "Lemon Yellow",
    rgb: "(255, 244, 79)",
  },
  {
    hex: "#FFBD88",
    name: "Macaroni and Cheese",
    rgb: "(255, 189, 136)",
  },
  {
    hex: "#F664AF",
    name: "Magenta",
    rgb: "(246, 100, 175)",
  },
  {
    hex: "#AAF0D1",
    name: "Magic Mint",
    rgb: "(170, 240, 209)",
  },
  {
    hex: "#CD4A4C",
    name: "Mahogany",
    rgb: "(205, 74, 76)",
  },
  {
    hex: "#EDD19C",
    name: "Maize",
    rgb: "(237, 209, 156)",
  },
  {
    hex: "#979AAA",
    name: "Manatee",
    rgb: "(151, 154, 170)",
  },
  {
    hex: "#FF8243",
    name: "Mango Tango",
    rgb: "(255, 130, 67)",
  },
  {
    hex: "#C8385A",
    name: "Maroon",
    rgb: "(200, 56, 90)",
  },
  {
    hex: "#EF98AA",
    name: "Mauvelous",
    rgb: "(239, 152, 170)",
  },
  {
    hex: "#FDBCB4",
    name: "Melon",
    rgb: "(253, 188, 180)",
  },
  {
    hex: "#1A4876",
    name: "Midnight Blue",
    rgb: "(26, 72, 118)",
  },
  {
    hex: "#30BA8F",
    name: "Mountain Meadow",
    rgb: "(48, 186, 143)",
  },
  {
    hex: "#C54B8C",
    name: "Mulberry",
    rgb: "(197, 75, 140)",
  },
  {
    hex: "#1974D2",
    name: "Navy Blue",
    rgb: "(25, 116, 210)",
  },
  {
    hex: "#FFA343",
    name: "Neon Carrot",
    rgb: "(255, 163, 67)",
  },
  {
    hex: "#BAB86C",
    name: "Olive Green",
    rgb: "(186, 184, 108)",
  },
  {
    hex: "#FF7538",
    name: "Orange",
    rgb: "(255, 117, 56)",
  },
  {
    hex: "#FF2B2B",
    name: "Orange Red",
    rgb: "(255, 43, 43)",
  },
  {
    hex: "#F8D568",
    name: "Orange Yellow",
    rgb: "(248, 213, 104)",
  },
  {
    hex: "#E6A8D7",
    name: "Orchid",
    rgb: "(230, 168, 215)",
  },
  {
    hex: "#414A4C",
    name: "Outer Space",
    rgb: "(65, 74, 76)",
  },
  {
    hex: "#FF6E4A",
    name: "Outrageous Orange",
    rgb: "(255, 110, 74)",
  },
  {
    hex: "#1CA9C9",
    name: "Pacific Blue",
    rgb: "(28, 169, 201)",
  },
  {
    hex: "#FFCFAB",
    name: "Peach",
    rgb: "(255, 207, 171)",
  },
  {
    hex: "#C5D0E6",
    name: "Periwinkle",
    rgb: "(197, 208, 230)",
  },
  {
    hex: "#FDDDE6",
    name: "Piggy Pink",
    rgb: "(253, 221, 230)",
  },
  {
    hex: "#158078",
    name: "Pine Green",
    rgb: "(21, 128, 120)",
  },
  {
    hex: "#FC74FD",
    name: "Pink Flamingo",
    rgb: "(252, 116, 253)",
  },
  {
    hex: "#F78FA7",
    name: "Pink Sherbet",
    rgb: "(247, 143, 167)",
  },
  {
    hex: "#8E4585",
    name: "Plum",
    rgb: "(142, 69, 133)",
  },
  {
    hex: "#7442C8",
    name: "Purple Heart",
    rgb: "(116, 66, 200)",
  },
  {
    hex: "#9D81BA",
    name: "Purple Mountain's Majesty",
    rgb: "(157, 129, 186)",
  },
  {
    hex: "#FE4EDA",
    name: "Purple Pizzazz",
    rgb: "(254, 78, 218)",
  },
  {
    hex: "#FF496C",
    name: "Radical Red",
    rgb: "(255, 73, 108)",
  },
  {
    hex: "#D68A59",
    name: "Raw Sienna",
    rgb: "(214, 138, 89)",
  },
  {
    hex: "#714B23",
    name: "Raw Umber",
    rgb: "(113, 75, 35)",
  },
  {
    hex: "#FF48D0",
    name: "Razzle Dazzle Rose",
    rgb: "(255, 72, 208)",
  },
  {
    hex: "#E3256B",
    name: "Razzmatazz",
    rgb: "(227, 37, 107)",
  },
  {
    hex: "#EE204D",
    name: "Red",
    rgb: "(238,32 ,77 )",
  },
  {
    hex: "#FF5349",
    name: "Red Orange",
    rgb: "(255, 83, 73)",
  },
  {
    hex: "#C0448F",
    name: "Red Violet",
    rgb: "(192, 68, 143)",
  },
  {
    hex: "#1FCECB",
    name: "Robin's Egg Blue",
    rgb: "(31, 206, 203)",
  },
  {
    hex: "#7851A9",
    name: "Royal Purple",
    rgb: "(120, 81, 169)",
  },
  {
    hex: "#FF9BAA",
    name: "Salmon",
    rgb: "(255, 155, 170)",
  },
  {
    hex: "#FC2847",
    name: "Scarlet",
    rgb: "(252, 40, 71)",
  },
  {
    hex: "#76FF7A",
    name: "Screamin' Green",
    rgb: "(118, 255, 122)",
  },
  {
    hex: "#9FE2BF",
    name: "Sea Green",
    rgb: "(159, 226, 191)",
  },
  {
    hex: "#A5694F",
    name: "Sepia",
    rgb: "(165, 105, 79)",
  },
  {
    hex: "#8A795D",
    name: "Shadow",
    rgb: "(138, 121, 93)",
  },
  {
    hex: "#45CEA2",
    name: "Shamrock",
    rgb: "(69, 206, 162)",
  },
  {
    hex: "#FB7EFD",
    name: "Shocking Pink",
    rgb: "(251, 126, 253)",
  },
  {
    hex: "#CDC5C2",
    name: "Silver",
    rgb: "(205, 197, 194)",
  },
  {
    hex: "#80DAEB",
    name: "Sky Blue",
    rgb: "(128, 218, 235)",
  },
  {
    hex: "#ECEABE",
    name: "Spring Green",
    rgb: "(236, 234, 190)",
  },
  {
    hex: "#FFCF48",
    name: "Sunglow",
    rgb: "(255, 207, 72)",
  },
  {
    hex: "#FD5E53",
    name: "Sunset Orange",
    rgb: "(253, 94, 83)",
  },
  {
    hex: "#FAA76C",
    name: "Tan",
    rgb: "(250, 167, 108)",
  },
  {
    hex: "#18A7B5",
    name: "Teal Blue",
    rgb: "(24, 167, 181)",
  },
  {
    hex: "#EBC7DF",
    name: "Thistle",
    rgb: "(235, 199, 223)",
  },
  {
    hex: "#FC89AC",
    name: "Tickle Me Pink",
    rgb: "(252, 137, 172)",
  },
  {
    hex: "#DBD7D2",
    name: "Timberwolf",
    rgb: "(219, 215, 210)",
  },
  {
    hex: "#17806D",
    name: "Tropical Rain Forest",
    rgb: "(23, 128, 109)",
  },
  {
    hex: "#DEAA88",
    name: "Tumbleweed",
    rgb: "(222, 170, 136)",
  },
  {
    hex: "#77DDE7",
    name: "Turquoise Blue",
    rgb: "(119, 221, 231)",
  },
  {
    hex: "#FFFF66",
    name: "Unmellow Yellow",
    rgb: "(255, 255, 102)",
  },
  {
    hex: "#926EAE",
    name: "Violet (Purple)",
    rgb: "(146, 110, 174)",
  },
  {
    hex: "#324AB2",
    name: "Violet Blue",
    rgb: "(50, 74, 178)",
  },
  {
    hex: "#F75394",
    name: "Violet Red",
    rgb: "(247, 83, 148)",
  },
  {
    hex: "#FFA089",
    name: "Vivid Tangerine",
    rgb: "(255, 160, 137)",
  },
  {
    hex: "#8F509D",
    name: "Vivid Violet",
    rgb: "(143, 80, 157)",
  },
  {
    hex: "#FFFFFF",
    name: "White",
    rgb: "(255, 255, 255)",
  },
  {
    hex: "#A2ADD0",
    name: "Wild Blue Yonder",
    rgb: "(162, 173, 208)",
  },
  {
    hex: "#FF43A4",
    name: "Wild Strawberry",
    rgb: "(255, 67, 164)",
  },
  {
    hex: "#FC6C85",
    name: "Wild Watermelon",
    rgb: "(252, 108, 133)",
  },
  {
    hex: "#CDA4DE",
    name: "Wisteria",
    rgb: "(205, 164, 222)",
  },
  {
    hex: "#FCE883",
    name: "Yellow",
    rgb: "(252, 232, 131)",
  },
  {
    hex: "#C5E384",
    name: "Yellow Green",
    rgb: "(197, 227, 132)",
  },
  {
    hex: "#FFAE42",
    name: "Yellow Orange",
    rgb: "(255, 174, 66)",
  },
];

export const mainColors = [
  {
    colorId: 0,
    hexString: "#000000",
    rgb: {
      r: 0,
      g: 0,
      b: 0,
    },
    hsl: {
      h: 0,
      s: 0,
      l: 0,
    },
    name: "Black",
  },
  {
    colorId: 1,
    hexString: "#800000",
    rgb: {
      r: 128,
      g: 0,
      b: 0,
    },
    hsl: {
      h: 0,
      s: 100,
      l: 25,
    },
    name: "Maroon",
  },
  {
    colorId: 2,
    hexString: "#008000",
    rgb: {
      r: 0,
      g: 128,
      b: 0,
    },
    hsl: {
      h: 120,
      s: 100,
      l: 25,
    },
    name: "Green",
  },
  {
    colorId: 3,
    hexString: "#808000",
    rgb: {
      r: 128,
      g: 128,
      b: 0,
    },
    hsl: {
      h: 60,
      s: 100,
      l: 25,
    },
    name: "Olive",
  },
  {
    colorId: 4,
    hexString: "#000080",
    rgb: {
      r: 0,
      g: 0,
      b: 128,
    },
    hsl: {
      h: 240,
      s: 100,
      l: 25,
    },
    name: "Navy",
  },
  {
    colorId: 5,
    hexString: "#800080",
    rgb: {
      r: 128,
      g: 0,
      b: 128,
    },
    hsl: {
      h: 300,
      s: 100,
      l: 25,
    },
    name: "Purple",
  },
  {
    colorId: 6,
    hexString: "#008080",
    rgb: {
      r: 0,
      g: 128,
      b: 128,
    },
    hsl: {
      h: 180,
      s: 100,
      l: 25,
    },
    name: "Teal",
  },
  {
    colorId: 7,
    hexString: "#c0c0c0",
    rgb: {
      r: 192,
      g: 192,
      b: 192,
    },
    hsl: {
      h: 0,
      s: 0,
      l: 75,
    },
    name: "Silver",
  },
  {
    colorId: 8,
    hexString: "#808080",
    rgb: {
      r: 128,
      g: 128,
      b: 128,
    },
    hsl: {
      h: 0,
      s: 0,
      l: 50,
    },
    name: "Grey",
  },
  {
    colorId: 9,
    hexString: "#ff0000",
    rgb: {
      r: 255,
      g: 0,
      b: 0,
    },
    hsl: {
      h: 0,
      s: 100,
      l: 50,
    },
    name: "Red",
  },
  {
    colorId: 10,
    hexString: "#00ff00",
    rgb: {
      r: 0,
      g: 255,
      b: 0,
    },
    hsl: {
      h: 120,
      s: 100,
      l: 50,
    },
    name: "Lime",
  },
  {
    colorId: 11,
    hexString: "#ffff00",
    rgb: {
      r: 255,
      g: 255,
      b: 0,
    },
    hsl: {
      h: 60,
      s: 100,
      l: 50,
    },
    name: "Yellow",
  },
  {
    colorId: 12,
    hexString: "#0000ff",
    rgb: {
      r: 0,
      g: 0,
      b: 255,
    },
    hsl: {
      h: 240,
      s: 100,
      l: 50,
    },
    name: "Blue",
  },
  {
    colorId: 13,
    hexString: "#ff00ff",
    rgb: {
      r: 255,
      g: 0,
      b: 255,
    },
    hsl: {
      h: 300,
      s: 100,
      l: 50,
    },
    name: "Fuchsia",
  },
  {
    colorId: 14,
    hexString: "#00ffff",
    rgb: {
      r: 0,
      g: 255,
      b: 255,
    },
    hsl: {
      h: 180,
      s: 100,
      l: 50,
    },
    name: "Aqua",
  },
  {
    colorId: 15,
    hexString: "#ffffff",
    rgb: {
      r: 255,
      g: 255,
      b: 255,
    },
    hsl: {
      h: 0,
      s: 0,
      l: 100,
    },
    name: "White",
  },
  {
    colorId: 16,
    hexString: "#000000",
    rgb: {
      r: 0,
      g: 0,
      b: 0,
    },
    hsl: {
      h: 0,
      s: 0,
      l: 0,
    },
    name: "Grey0",
  },
  {
    colorId: 17,
    hexString: "#00005f",
    rgb: {
      r: 0,
      g: 0,
      b: 95,
    },
    hsl: {
      h: 240,
      s: 100,
      l: 18,
    },
    name: "NavyBlue",
  },
  {
    colorId: 18,
    hexString: "#000087",
    rgb: {
      r: 0,
      g: 0,
      b: 135,
    },
    hsl: {
      h: 240,
      s: 100,
      l: 26,
    },
    name: "DarkBlue",
  },
  {
    colorId: 19,
    hexString: "#0000af",
    rgb: {
      r: 0,
      g: 0,
      b: 175,
    },
    hsl: {
      h: 240,
      s: 100,
      l: 34,
    },
    name: "Blue3",
  },
  {
    colorId: 20,
    hexString: "#0000d7",
    rgb: {
      r: 0,
      g: 0,
      b: 215,
    },
    hsl: {
      h: 240,
      s: 100,
      l: 42,
    },
    name: "Blue3",
  },
  {
    colorId: 21,
    hexString: "#0000ff",
    rgb: {
      r: 0,
      g: 0,
      b: 255,
    },
    hsl: {
      h: 240,
      s: 100,
      l: 50,
    },
    name: "Blue1",
  },
  {
    colorId: 22,
    hexString: "#005f00",
    rgb: {
      r: 0,
      g: 95,
      b: 0,
    },
    hsl: {
      h: 120,
      s: 100,
      l: 18,
    },
    name: "DarkGreen",
  },
  {
    colorId: 23,
    hexString: "#005f5f",
    rgb: {
      r: 0,
      g: 95,
      b: 95,
    },
    hsl: {
      h: 180,
      s: 100,
      l: 18,
    },
    name: "DeepSkyBlue4",
  },
  {
    colorId: 24,
    hexString: "#005f87",
    rgb: {
      r: 0,
      g: 95,
      b: 135,
    },
    hsl: {
      h: 197.777777777778,
      s: 100,
      l: 26,
    },
    name: "DeepSkyBlue4",
  },
  {
    colorId: 25,
    hexString: "#005faf",
    rgb: {
      r: 0,
      g: 95,
      b: 175,
    },
    hsl: {
      h: 207.428571428571,
      s: 100,
      l: 34,
    },
    name: "DeepSkyBlue4",
  },
  {
    colorId: 26,
    hexString: "#005fd7",
    rgb: {
      r: 0,
      g: 95,
      b: 215,
    },
    hsl: {
      h: 213.488372093023,
      s: 100,
      l: 42,
    },
    name: "DodgerBlue3",
  },
  {
    colorId: 27,
    hexString: "#005fff",
    rgb: {
      r: 0,
      g: 95,
      b: 255,
    },
    hsl: {
      h: 217.647058823529,
      s: 100,
      l: 50,
    },
    name: "DodgerBlue2",
  },
  {
    colorId: 28,
    hexString: "#008700",
    rgb: {
      r: 0,
      g: 135,
      b: 0,
    },
    hsl: {
      h: 120,
      s: 100,
      l: 26,
    },
    name: "Green4",
  },
  {
    colorId: 29,
    hexString: "#00875f",
    rgb: {
      r: 0,
      g: 135,
      b: 95,
    },
    hsl: {
      h: 162.222222222222,
      s: 100,
      l: 26,
    },
    name: "SpringGreen4",
  },
  {
    colorId: 30,
    hexString: "#008787",
    rgb: {
      r: 0,
      g: 135,
      b: 135,
    },
    hsl: {
      h: 180,
      s: 100,
      l: 26,
    },
    name: "Turquoise4",
  },
  {
    colorId: 31,
    hexString: "#0087af",
    rgb: {
      r: 0,
      g: 135,
      b: 175,
    },
    hsl: {
      h: 193.714285714286,
      s: 100,
      l: 34,
    },
    name: "DeepSkyBlue3",
  },
  {
    colorId: 32,
    hexString: "#0087d7",
    rgb: {
      r: 0,
      g: 135,
      b: 215,
    },
    hsl: {
      h: 202.325581395349,
      s: 100,
      l: 42,
    },
    name: "DeepSkyBlue3",
  },
  {
    colorId: 33,
    hexString: "#0087ff",
    rgb: {
      r: 0,
      g: 135,
      b: 255,
    },
    hsl: {
      h: 208.235294117647,
      s: 100,
      l: 50,
    },
    name: "DodgerBlue1",
  },
  {
    colorId: 34,
    hexString: "#00af00",
    rgb: {
      r: 0,
      g: 175,
      b: 0,
    },
    hsl: {
      h: 120,
      s: 100,
      l: 34,
    },
    name: "Green3",
  },
  {
    colorId: 35,
    hexString: "#00af5f",
    rgb: {
      r: 0,
      g: 175,
      b: 95,
    },
    hsl: {
      h: 152.571428571429,
      s: 100,
      l: 34,
    },
    name: "SpringGreen3",
  },
  {
    colorId: 36,
    hexString: "#00af87",
    rgb: {
      r: 0,
      g: 175,
      b: 135,
    },
    hsl: {
      h: 166.285714285714,
      s: 100,
      l: 34,
    },
    name: "DarkCyan",
  },
  {
    colorId: 37,
    hexString: "#00afaf",
    rgb: {
      r: 0,
      g: 175,
      b: 175,
    },
    hsl: {
      h: 180,
      s: 100,
      l: 34,
    },
    name: "LightSeaGreen",
  },
  {
    colorId: 38,
    hexString: "#00afd7",
    rgb: {
      r: 0,
      g: 175,
      b: 215,
    },
    hsl: {
      h: 191.162790697674,
      s: 100,
      l: 42,
    },
    name: "DeepSkyBlue2",
  },
  {
    colorId: 39,
    hexString: "#00afff",
    rgb: {
      r: 0,
      g: 175,
      b: 255,
    },
    hsl: {
      h: 198.823529411765,
      s: 100,
      l: 50,
    },
    name: "DeepSkyBlue1",
  },
  {
    colorId: 40,
    hexString: "#00d700",
    rgb: {
      r: 0,
      g: 215,
      b: 0,
    },
    hsl: {
      h: 120,
      s: 100,
      l: 42,
    },
    name: "Green3",
  },
  {
    colorId: 41,
    hexString: "#00d75f",
    rgb: {
      r: 0,
      g: 215,
      b: 95,
    },
    hsl: {
      h: 146.511627906977,
      s: 100,
      l: 42,
    },
    name: "SpringGreen3",
  },
  {
    colorId: 42,
    hexString: "#00d787",
    rgb: {
      r: 0,
      g: 215,
      b: 135,
    },
    hsl: {
      h: 157.674418604651,
      s: 100,
      l: 42,
    },
    name: "SpringGreen2",
  },
  {
    colorId: 43,
    hexString: "#00d7af",
    rgb: {
      r: 0,
      g: 215,
      b: 175,
    },
    hsl: {
      h: 168.837209302326,
      s: 100,
      l: 42,
    },
    name: "Cyan3",
  },
  {
    colorId: 44,
    hexString: "#00d7d7",
    rgb: {
      r: 0,
      g: 215,
      b: 215,
    },
    hsl: {
      h: 180,
      s: 100,
      l: 42,
    },
    name: "DarkTurquoise",
  },
  {
    colorId: 45,
    hexString: "#00d7ff",
    rgb: {
      r: 0,
      g: 215,
      b: 255,
    },
    hsl: {
      h: 189.411764705882,
      s: 100,
      l: 50,
    },
    name: "Turquoise2",
  },
  {
    colorId: 46,
    hexString: "#00ff00",
    rgb: {
      r: 0,
      g: 255,
      b: 0,
    },
    hsl: {
      h: 120,
      s: 100,
      l: 50,
    },
    name: "Green1",
  },
  {
    colorId: 47,
    hexString: "#00ff5f",
    rgb: {
      r: 0,
      g: 255,
      b: 95,
    },
    hsl: {
      h: 142.352941176471,
      s: 100,
      l: 50,
    },
    name: "SpringGreen2",
  },
  {
    colorId: 48,
    hexString: "#00ff87",
    rgb: {
      r: 0,
      g: 255,
      b: 135,
    },
    hsl: {
      h: 151.764705882353,
      s: 100,
      l: 50,
    },
    name: "SpringGreen1",
  },
  {
    colorId: 49,
    hexString: "#00ffaf",
    rgb: {
      r: 0,
      g: 255,
      b: 175,
    },
    hsl: {
      h: 161.176470588235,
      s: 100,
      l: 50,
    },
    name: "MediumSpringGreen",
  },
  {
    colorId: 50,
    hexString: "#00ffd7",
    rgb: {
      r: 0,
      g: 255,
      b: 215,
    },
    hsl: {
      h: 170.588235294118,
      s: 100,
      l: 50,
    },
    name: "Cyan2",
  },
  {
    colorId: 51,
    hexString: "#00ffff",
    rgb: {
      r: 0,
      g: 255,
      b: 255,
    },
    hsl: {
      h: 180,
      s: 100,
      l: 50,
    },
    name: "Cyan1",
  },
  {
    colorId: 52,
    hexString: "#5f0000",
    rgb: {
      r: 95,
      g: 0,
      b: 0,
    },
    hsl: {
      h: 0,
      s: 100,
      l: 18,
    },
    name: "DarkRed",
  },
  {
    colorId: 53,
    hexString: "#5f005f",
    rgb: {
      r: 95,
      g: 0,
      b: 95,
    },
    hsl: {
      h: 300,
      s: 100,
      l: 18,
    },
    name: "DeepPink4",
  },
  {
    colorId: 54,
    hexString: "#5f0087",
    rgb: {
      r: 95,
      g: 0,
      b: 135,
    },
    hsl: {
      h: 282.222222222222,
      s: 100,
      l: 26,
    },
    name: "Purple4",
  },
  {
    colorId: 55,
    hexString: "#5f00af",
    rgb: {
      r: 95,
      g: 0,
      b: 175,
    },
    hsl: {
      h: 272.571428571429,
      s: 100,
      l: 34,
    },
    name: "Purple4",
  },
  {
    colorId: 56,
    hexString: "#5f00d7",
    rgb: {
      r: 95,
      g: 0,
      b: 215,
    },
    hsl: {
      h: 266.511627906977,
      s: 100,
      l: 42,
    },
    name: "Purple3",
  },
  {
    colorId: 57,
    hexString: "#5f00ff",
    rgb: {
      r: 95,
      g: 0,
      b: 255,
    },
    hsl: {
      h: 262.352941176471,
      s: 100,
      l: 50,
    },
    name: "BlueViolet",
  },
  {
    colorId: 58,
    hexString: "#5f5f00",
    rgb: {
      r: 95,
      g: 95,
      b: 0,
    },
    hsl: {
      h: 60,
      s: 100,
      l: 18,
    },
    name: "Orange4",
  },
  {
    colorId: 59,
    hexString: "#5f5f5f",
    rgb: {
      r: 95,
      g: 95,
      b: 95,
    },
    hsl: {
      h: 0,
      s: 0,
      l: 37,
    },
    name: "Grey37",
  },
  {
    colorId: 60,
    hexString: "#5f5f87",
    rgb: {
      r: 95,
      g: 95,
      b: 135,
    },
    hsl: {
      h: 240,
      s: 17,
      l: 45,
    },
    name: "MediumPurple4",
  },
  {
    colorId: 61,
    hexString: "#5f5faf",
    rgb: {
      r: 95,
      g: 95,
      b: 175,
    },
    hsl: {
      h: 240,
      s: 33,
      l: 52,
    },
    name: "SlateBlue3",
  },
  {
    colorId: 62,
    hexString: "#5f5fd7",
    rgb: {
      r: 95,
      g: 95,
      b: 215,
    },
    hsl: {
      h: 240,
      s: 60,
      l: 60,
    },
    name: "SlateBlue3",
  },
  {
    colorId: 63,
    hexString: "#5f5fff",
    rgb: {
      r: 95,
      g: 95,
      b: 255,
    },
    hsl: {
      h: 240,
      s: 100,
      l: 68,
    },
    name: "RoyalBlue1",
  },
  {
    colorId: 64,
    hexString: "#5f8700",
    rgb: {
      r: 95,
      g: 135,
      b: 0,
    },
    hsl: {
      h: 77.7777777777778,
      s: 100,
      l: 26,
    },
    name: "Chartreuse4",
  },
  {
    colorId: 65,
    hexString: "#5f875f",
    rgb: {
      r: 95,
      g: 135,
      b: 95,
    },
    hsl: {
      h: 120,
      s: 17,
      l: 45,
    },
    name: "DarkSeaGreen4",
  },
  {
    colorId: 66,
    hexString: "#5f8787",
    rgb: {
      r: 95,
      g: 135,
      b: 135,
    },
    hsl: {
      h: 180,
      s: 17,
      l: 45,
    },
    name: "PaleTurquoise4",
  },
  {
    colorId: 67,
    hexString: "#5f87af",
    rgb: {
      r: 95,
      g: 135,
      b: 175,
    },
    hsl: {
      h: 210,
      s: 33,
      l: 52,
    },
    name: "SteelBlue",
  },
  {
    colorId: 68,
    hexString: "#5f87d7",
    rgb: {
      r: 95,
      g: 135,
      b: 215,
    },
    hsl: {
      h: 220,
      s: 60,
      l: 60,
    },
    name: "SteelBlue3",
  },
  {
    colorId: 69,
    hexString: "#5f87ff",
    rgb: {
      r: 95,
      g: 135,
      b: 255,
    },
    hsl: {
      h: 225,
      s: 100,
      l: 68,
    },
    name: "CornflowerBlue",
  },
  {
    colorId: 70,
    hexString: "#5faf00",
    rgb: {
      r: 95,
      g: 175,
      b: 0,
    },
    hsl: {
      h: 87.4285714285714,
      s: 100,
      l: 34,
    },
    name: "Chartreuse3",
  },
  {
    colorId: 71,
    hexString: "#5faf5f",
    rgb: {
      r: 95,
      g: 175,
      b: 95,
    },
    hsl: {
      h: 120,
      s: 33,
      l: 52,
    },
    name: "DarkSeaGreen4",
  },
  {
    colorId: 72,
    hexString: "#5faf87",
    rgb: {
      r: 95,
      g: 175,
      b: 135,
    },
    hsl: {
      h: 150,
      s: 33,
      l: 52,
    },
    name: "CadetBlue",
  },
  {
    colorId: 73,
    hexString: "#5fafaf",
    rgb: {
      r: 95,
      g: 175,
      b: 175,
    },
    hsl: {
      h: 180,
      s: 33,
      l: 52,
    },
    name: "CadetBlue",
  },
  {
    colorId: 74,
    hexString: "#5fafd7",
    rgb: {
      r: 95,
      g: 175,
      b: 215,
    },
    hsl: {
      h: 200,
      s: 60,
      l: 60,
    },
    name: "SkyBlue3",
  },
  {
    colorId: 75,
    hexString: "#5fafff",
    rgb: {
      r: 95,
      g: 175,
      b: 255,
    },
    hsl: {
      h: 210,
      s: 100,
      l: 68,
    },
    name: "SteelBlue1",
  },
  {
    colorId: 76,
    hexString: "#5fd700",
    rgb: {
      r: 95,
      g: 215,
      b: 0,
    },
    hsl: {
      h: 93.4883720930233,
      s: 100,
      l: 42,
    },
    name: "Chartreuse3",
  },
  {
    colorId: 77,
    hexString: "#5fd75f",
    rgb: {
      r: 95,
      g: 215,
      b: 95,
    },
    hsl: {
      h: 120,
      s: 60,
      l: 60,
    },
    name: "PaleGreen3",
  },
  {
    colorId: 78,
    hexString: "#5fd787",
    rgb: {
      r: 95,
      g: 215,
      b: 135,
    },
    hsl: {
      h: 140,
      s: 60,
      l: 60,
    },
    name: "SeaGreen3",
  },
  {
    colorId: 79,
    hexString: "#5fd7af",
    rgb: {
      r: 95,
      g: 215,
      b: 175,
    },
    hsl: {
      h: 160,
      s: 60,
      l: 60,
    },
    name: "Aquamarine3",
  },
  {
    colorId: 80,
    hexString: "#5fd7d7",
    rgb: {
      r: 95,
      g: 215,
      b: 215,
    },
    hsl: {
      h: 180,
      s: 60,
      l: 60,
    },
    name: "MediumTurquoise",
  },
  {
    colorId: 81,
    hexString: "#5fd7ff",
    rgb: {
      r: 95,
      g: 215,
      b: 255,
    },
    hsl: {
      h: 195,
      s: 100,
      l: 68,
    },
    name: "SteelBlue1",
  },
  {
    colorId: 82,
    hexString: "#5fff00",
    rgb: {
      r: 95,
      g: 255,
      b: 0,
    },
    hsl: {
      h: 97.6470588235294,
      s: 100,
      l: 50,
    },
    name: "Chartreuse2",
  },
  {
    colorId: 83,
    hexString: "#5fff5f",
    rgb: {
      r: 95,
      g: 255,
      b: 95,
    },
    hsl: {
      h: 120,
      s: 100,
      l: 68,
    },
    name: "SeaGreen2",
  },
  {
    colorId: 84,
    hexString: "#5fff87",
    rgb: {
      r: 95,
      g: 255,
      b: 135,
    },
    hsl: {
      h: 135,
      s: 100,
      l: 68,
    },
    name: "SeaGreen1",
  },
  {
    colorId: 85,
    hexString: "#5fffaf",
    rgb: {
      r: 95,
      g: 255,
      b: 175,
    },
    hsl: {
      h: 150,
      s: 100,
      l: 68,
    },
    name: "SeaGreen1",
  },
  {
    colorId: 86,
    hexString: "#5fffd7",
    rgb: {
      r: 95,
      g: 255,
      b: 215,
    },
    hsl: {
      h: 165,
      s: 100,
      l: 68,
    },
    name: "Aquamarine1",
  },
  {
    colorId: 87,
    hexString: "#5fffff",
    rgb: {
      r: 95,
      g: 255,
      b: 255,
    },
    hsl: {
      h: 180,
      s: 100,
      l: 68,
    },
    name: "DarkSlateGray2",
  },
  {
    colorId: 88,
    hexString: "#870000",
    rgb: {
      r: 135,
      g: 0,
      b: 0,
    },
    hsl: {
      h: 0,
      s: 100,
      l: 26,
    },
    name: "DarkRed",
  },
  {
    colorId: 89,
    hexString: "#87005f",
    rgb: {
      r: 135,
      g: 0,
      b: 95,
    },
    hsl: {
      h: 317.777777777778,
      s: 100,
      l: 26,
    },
    name: "DeepPink4",
  },
  {
    colorId: 90,
    hexString: "#870087",
    rgb: {
      r: 135,
      g: 0,
      b: 135,
    },
    hsl: {
      h: 300,
      s: 100,
      l: 26,
    },
    name: "DarkMagenta",
  },
  {
    colorId: 91,
    hexString: "#8700af",
    rgb: {
      r: 135,
      g: 0,
      b: 175,
    },
    hsl: {
      h: 286.285714285714,
      s: 100,
      l: 34,
    },
    name: "DarkMagenta",
  },
  {
    colorId: 92,
    hexString: "#8700d7",
    rgb: {
      r: 135,
      g: 0,
      b: 215,
    },
    hsl: {
      h: 277.674418604651,
      s: 100,
      l: 42,
    },
    name: "DarkViolet",
  },
  {
    colorId: 93,
    hexString: "#8700ff",
    rgb: {
      r: 135,
      g: 0,
      b: 255,
    },
    hsl: {
      h: 271.764705882353,
      s: 100,
      l: 50,
    },
    name: "Purple",
  },
  {
    colorId: 94,
    hexString: "#875f00",
    rgb: {
      r: 135,
      g: 95,
      b: 0,
    },
    hsl: {
      h: 42.2222222222222,
      s: 100,
      l: 26,
    },
    name: "Orange4",
  },
  {
    colorId: 95,
    hexString: "#875f5f",
    rgb: {
      r: 135,
      g: 95,
      b: 95,
    },
    hsl: {
      h: 0,
      s: 17,
      l: 45,
    },
    name: "LightPink4",
  },
  {
    colorId: 96,
    hexString: "#875f87",
    rgb: {
      r: 135,
      g: 95,
      b: 135,
    },
    hsl: {
      h: 300,
      s: 17,
      l: 45,
    },
    name: "Plum4",
  },
  {
    colorId: 97,
    hexString: "#875faf",
    rgb: {
      r: 135,
      g: 95,
      b: 175,
    },
    hsl: {
      h: 270,
      s: 33,
      l: 52,
    },
    name: "MediumPurple3",
  },
  {
    colorId: 98,
    hexString: "#875fd7",
    rgb: {
      r: 135,
      g: 95,
      b: 215,
    },
    hsl: {
      h: 260,
      s: 60,
      l: 60,
    },
    name: "MediumPurple3",
  },
  {
    colorId: 99,
    hexString: "#875fff",
    rgb: {
      r: 135,
      g: 95,
      b: 255,
    },
    hsl: {
      h: 255,
      s: 100,
      l: 68,
    },
    name: "SlateBlue1",
  },
  {
    colorId: 100,
    hexString: "#878700",
    rgb: {
      r: 135,
      g: 135,
      b: 0,
    },
    hsl: {
      h: 60,
      s: 100,
      l: 26,
    },
    name: "Yellow4",
  },
  {
    colorId: 101,
    hexString: "#87875f",
    rgb: {
      r: 135,
      g: 135,
      b: 95,
    },
    hsl: {
      h: 60,
      s: 17,
      l: 45,
    },
    name: "Wheat4",
  },
  {
    colorId: 102,
    hexString: "#878787",
    rgb: {
      r: 135,
      g: 135,
      b: 135,
    },
    hsl: {
      h: 0,
      s: 0,
      l: 52,
    },
    name: "Grey53",
  },
  {
    colorId: 103,
    hexString: "#8787af",
    rgb: {
      r: 135,
      g: 135,
      b: 175,
    },
    hsl: {
      h: 240,
      s: 20,
      l: 60,
    },
    name: "LightSlateGrey",
  },
  {
    colorId: 104,
    hexString: "#8787d7",
    rgb: {
      r: 135,
      g: 135,
      b: 215,
    },
    hsl: {
      h: 240,
      s: 50,
      l: 68,
    },
    name: "MediumPurple",
  },
  {
    colorId: 105,
    hexString: "#8787ff",
    rgb: {
      r: 135,
      g: 135,
      b: 255,
    },
    hsl: {
      h: 240,
      s: 100,
      l: 76,
    },
    name: "LightSlateBlue",
  },
  {
    colorId: 106,
    hexString: "#87af00",
    rgb: {
      r: 135,
      g: 175,
      b: 0,
    },
    hsl: {
      h: 73.7142857142857,
      s: 100,
      l: 34,
    },
    name: "Yellow4",
  },
  {
    colorId: 107,
    hexString: "#87af5f",
    rgb: {
      r: 135,
      g: 175,
      b: 95,
    },
    hsl: {
      h: 90,
      s: 33,
      l: 52,
    },
    name: "DarkOliveGreen3",
  },
  {
    colorId: 108,
    hexString: "#87af87",
    rgb: {
      r: 135,
      g: 175,
      b: 135,
    },
    hsl: {
      h: 120,
      s: 20,
      l: 60,
    },
    name: "DarkSeaGreen",
  },
  {
    colorId: 109,
    hexString: "#87afaf",
    rgb: {
      r: 135,
      g: 175,
      b: 175,
    },
    hsl: {
      h: 180,
      s: 20,
      l: 60,
    },
    name: "LightSkyBlue3",
  },
  {
    colorId: 110,
    hexString: "#87afd7",
    rgb: {
      r: 135,
      g: 175,
      b: 215,
    },
    hsl: {
      h: 210,
      s: 50,
      l: 68,
    },
    name: "LightSkyBlue3",
  },
  {
    colorId: 111,
    hexString: "#87afff",
    rgb: {
      r: 135,
      g: 175,
      b: 255,
    },
    hsl: {
      h: 220,
      s: 100,
      l: 76,
    },
    name: "SkyBlue2",
  },
  {
    colorId: 112,
    hexString: "#87d700",
    rgb: {
      r: 135,
      g: 215,
      b: 0,
    },
    hsl: {
      h: 82.3255813953488,
      s: 100,
      l: 42,
    },
    name: "Chartreuse2",
  },
  {
    colorId: 113,
    hexString: "#87d75f",
    rgb: {
      r: 135,
      g: 215,
      b: 95,
    },
    hsl: {
      h: 100,
      s: 60,
      l: 60,
    },
    name: "DarkOliveGreen3",
  },
  {
    colorId: 114,
    hexString: "#87d787",
    rgb: {
      r: 135,
      g: 215,
      b: 135,
    },
    hsl: {
      h: 120,
      s: 50,
      l: 68,
    },
    name: "PaleGreen3",
  },
  {
    colorId: 115,
    hexString: "#87d7af",
    rgb: {
      r: 135,
      g: 215,
      b: 175,
    },
    hsl: {
      h: 150,
      s: 50,
      l: 68,
    },
    name: "DarkSeaGreen3",
  },
  {
    colorId: 116,
    hexString: "#87d7d7",
    rgb: {
      r: 135,
      g: 215,
      b: 215,
    },
    hsl: {
      h: 180,
      s: 50,
      l: 68,
    },
    name: "DarkSlateGray3",
  },
  {
    colorId: 117,
    hexString: "#87d7ff",
    rgb: {
      r: 135,
      g: 215,
      b: 255,
    },
    hsl: {
      h: 200,
      s: 100,
      l: 76,
    },
    name: "SkyBlue1",
  },
  {
    colorId: 118,
    hexString: "#87ff00",
    rgb: {
      r: 135,
      g: 255,
      b: 0,
    },
    hsl: {
      h: 88.2352941176471,
      s: 100,
      l: 50,
    },
    name: "Chartreuse1",
  },
  {
    colorId: 119,
    hexString: "#87ff5f",
    rgb: {
      r: 135,
      g: 255,
      b: 95,
    },
    hsl: {
      h: 105,
      s: 100,
      l: 68,
    },
    name: "LightGreen",
  },
  {
    colorId: 120,
    hexString: "#87ff87",
    rgb: {
      r: 135,
      g: 255,
      b: 135,
    },
    hsl: {
      h: 120,
      s: 100,
      l: 76,
    },
    name: "LightGreen",
  },
  {
    colorId: 121,
    hexString: "#87ffaf",
    rgb: {
      r: 135,
      g: 255,
      b: 175,
    },
    hsl: {
      h: 140,
      s: 100,
      l: 76,
    },
    name: "PaleGreen1",
  },
  {
    colorId: 122,
    hexString: "#87ffd7",
    rgb: {
      r: 135,
      g: 255,
      b: 215,
    },
    hsl: {
      h: 160,
      s: 100,
      l: 76,
    },
    name: "Aquamarine1",
  },
  {
    colorId: 123,
    hexString: "#87ffff",
    rgb: {
      r: 135,
      g: 255,
      b: 255,
    },
    hsl: {
      h: 180,
      s: 100,
      l: 76,
    },
    name: "DarkSlateGray1",
  },
  {
    colorId: 124,
    hexString: "#af0000",
    rgb: {
      r: 175,
      g: 0,
      b: 0,
    },
    hsl: {
      h: 0,
      s: 100,
      l: 34,
    },
    name: "Red3",
  },
  {
    colorId: 125,
    hexString: "#af005f",
    rgb: {
      r: 175,
      g: 0,
      b: 95,
    },
    hsl: {
      h: 327.428571428571,
      s: 100,
      l: 34,
    },
    name: "DeepPink4",
  },
  {
    colorId: 126,
    hexString: "#af0087",
    rgb: {
      r: 175,
      g: 0,
      b: 135,
    },
    hsl: {
      h: 313.714285714286,
      s: 100,
      l: 34,
    },
    name: "MediumVioletRed",
  },
  {
    colorId: 127,
    hexString: "#af00af",
    rgb: {
      r: 175,
      g: 0,
      b: 175,
    },
    hsl: {
      h: 300,
      s: 100,
      l: 34,
    },
    name: "Magenta3",
  },
  {
    colorId: 128,
    hexString: "#af00d7",
    rgb: {
      r: 175,
      g: 0,
      b: 215,
    },
    hsl: {
      h: 288.837209302326,
      s: 100,
      l: 42,
    },
    name: "DarkViolet",
  },
  {
    colorId: 129,
    hexString: "#af00ff",
    rgb: {
      r: 175,
      g: 0,
      b: 255,
    },
    hsl: {
      h: 281.176470588235,
      s: 100,
      l: 50,
    },
    name: "Purple",
  },
  {
    colorId: 130,
    hexString: "#af5f00",
    rgb: {
      r: 175,
      g: 95,
      b: 0,
    },
    hsl: {
      h: 32.5714285714286,
      s: 100,
      l: 34,
    },
    name: "DarkOrange3",
  },
  {
    colorId: 131,
    hexString: "#af5f5f",
    rgb: {
      r: 175,
      g: 95,
      b: 95,
    },
    hsl: {
      h: 0,
      s: 33,
      l: 52,
    },
    name: "IndianRed",
  },
  {
    colorId: 132,
    hexString: "#af5f87",
    rgb: {
      r: 175,
      g: 95,
      b: 135,
    },
    hsl: {
      h: 330,
      s: 33,
      l: 52,
    },
    name: "HotPink3",
  },
  {
    colorId: 133,
    hexString: "#af5faf",
    rgb: {
      r: 175,
      g: 95,
      b: 175,
    },
    hsl: {
      h: 300,
      s: 33,
      l: 52,
    },
    name: "MediumOrchid3",
  },
  {
    colorId: 134,
    hexString: "#af5fd7",
    rgb: {
      r: 175,
      g: 95,
      b: 215,
    },
    hsl: {
      h: 280,
      s: 60,
      l: 60,
    },
    name: "MediumOrchid",
  },
  {
    colorId: 135,
    hexString: "#af5fff",
    rgb: {
      r: 175,
      g: 95,
      b: 255,
    },
    hsl: {
      h: 270,
      s: 100,
      l: 68,
    },
    name: "MediumPurple2",
  },
  {
    colorId: 136,
    hexString: "#af8700",
    rgb: {
      r: 175,
      g: 135,
      b: 0,
    },
    hsl: {
      h: 46.2857142857143,
      s: 100,
      l: 34,
    },
    name: "DarkGoldenrod",
  },
  {
    colorId: 137,
    hexString: "#af875f",
    rgb: {
      r: 175,
      g: 135,
      b: 95,
    },
    hsl: {
      h: 30,
      s: 33,
      l: 52,
    },
    name: "LightSalmon3",
  },
  {
    colorId: 138,
    hexString: "#af8787",
    rgb: {
      r: 175,
      g: 135,
      b: 135,
    },
    hsl: {
      h: 0,
      s: 20,
      l: 60,
    },
    name: "RosyBrown",
  },
  {
    colorId: 139,
    hexString: "#af87af",
    rgb: {
      r: 175,
      g: 135,
      b: 175,
    },
    hsl: {
      h: 300,
      s: 20,
      l: 60,
    },
    name: "Grey63",
  },
  {
    colorId: 140,
    hexString: "#af87d7",
    rgb: {
      r: 175,
      g: 135,
      b: 215,
    },
    hsl: {
      h: 270,
      s: 50,
      l: 68,
    },
    name: "MediumPurple2",
  },
  {
    colorId: 141,
    hexString: "#af87ff",
    rgb: {
      r: 175,
      g: 135,
      b: 255,
    },
    hsl: {
      h: 260,
      s: 100,
      l: 76,
    },
    name: "MediumPurple1",
  },
  {
    colorId: 142,
    hexString: "#afaf00",
    rgb: {
      r: 175,
      g: 175,
      b: 0,
    },
    hsl: {
      h: 60,
      s: 100,
      l: 34,
    },
    name: "Gold3",
  },
  {
    colorId: 143,
    hexString: "#afaf5f",
    rgb: {
      r: 175,
      g: 175,
      b: 95,
    },
    hsl: {
      h: 60,
      s: 33,
      l: 52,
    },
    name: "DarkKhaki",
  },
  {
    colorId: 144,
    hexString: "#afaf87",
    rgb: {
      r: 175,
      g: 175,
      b: 135,
    },
    hsl: {
      h: 60,
      s: 20,
      l: 60,
    },
    name: "NavajoWhite3",
  },
  {
    colorId: 145,
    hexString: "#afafaf",
    rgb: {
      r: 175,
      g: 175,
      b: 175,
    },
    hsl: {
      h: 0,
      s: 0,
      l: 68,
    },
    name: "Grey69",
  },
  {
    colorId: 146,
    hexString: "#afafd7",
    rgb: {
      r: 175,
      g: 175,
      b: 215,
    },
    hsl: {
      h: 240,
      s: 33,
      l: 76,
    },
    name: "LightSteelBlue3",
  },
  {
    colorId: 147,
    hexString: "#afafff",
    rgb: {
      r: 175,
      g: 175,
      b: 255,
    },
    hsl: {
      h: 240,
      s: 100,
      l: 84,
    },
    name: "LightSteelBlue",
  },
  {
    colorId: 148,
    hexString: "#afd700",
    rgb: {
      r: 175,
      g: 215,
      b: 0,
    },
    hsl: {
      h: 71.1627906976744,
      s: 100,
      l: 42,
    },
    name: "Yellow3",
  },
  {
    colorId: 149,
    hexString: "#afd75f",
    rgb: {
      r: 175,
      g: 215,
      b: 95,
    },
    hsl: {
      h: 80,
      s: 60,
      l: 60,
    },
    name: "DarkOliveGreen3",
  },
  {
    colorId: 150,
    hexString: "#afd787",
    rgb: {
      r: 175,
      g: 215,
      b: 135,
    },
    hsl: {
      h: 90,
      s: 50,
      l: 68,
    },
    name: "DarkSeaGreen3",
  },
  {
    colorId: 151,
    hexString: "#afd7af",
    rgb: {
      r: 175,
      g: 215,
      b: 175,
    },
    hsl: {
      h: 120,
      s: 33,
      l: 76,
    },
    name: "DarkSeaGreen2",
  },
  {
    colorId: 152,
    hexString: "#afd7d7",
    rgb: {
      r: 175,
      g: 215,
      b: 215,
    },
    hsl: {
      h: 180,
      s: 33,
      l: 76,
    },
    name: "LightCyan3",
  },
  {
    colorId: 153,
    hexString: "#afd7ff",
    rgb: {
      r: 175,
      g: 215,
      b: 255,
    },
    hsl: {
      h: 210,
      s: 100,
      l: 84,
    },
    name: "LightSkyBlue1",
  },
  {
    colorId: 154,
    hexString: "#afff00",
    rgb: {
      r: 175,
      g: 255,
      b: 0,
    },
    hsl: {
      h: 78.8235294117647,
      s: 100,
      l: 50,
    },
    name: "GreenYellow",
  },
  {
    colorId: 155,
    hexString: "#afff5f",
    rgb: {
      r: 175,
      g: 255,
      b: 95,
    },
    hsl: {
      h: 90,
      s: 100,
      l: 68,
    },
    name: "DarkOliveGreen2",
  },
  {
    colorId: 156,
    hexString: "#afff87",
    rgb: {
      r: 175,
      g: 255,
      b: 135,
    },
    hsl: {
      h: 100,
      s: 100,
      l: 76,
    },
    name: "PaleGreen1",
  },
  {
    colorId: 157,
    hexString: "#afffaf",
    rgb: {
      r: 175,
      g: 255,
      b: 175,
    },
    hsl: {
      h: 120,
      s: 100,
      l: 84,
    },
    name: "DarkSeaGreen2",
  },
  {
    colorId: 158,
    hexString: "#afffd7",
    rgb: {
      r: 175,
      g: 255,
      b: 215,
    },
    hsl: {
      h: 150,
      s: 100,
      l: 84,
    },
    name: "DarkSeaGreen1",
  },
  {
    colorId: 159,
    hexString: "#afffff",
    rgb: {
      r: 175,
      g: 255,
      b: 255,
    },
    hsl: {
      h: 180,
      s: 100,
      l: 84,
    },
    name: "PaleTurquoise1",
  },
  {
    colorId: 160,
    hexString: "#d70000",
    rgb: {
      r: 215,
      g: 0,
      b: 0,
    },
    hsl: {
      h: 0,
      s: 100,
      l: 42,
    },
    name: "Red3",
  },
  {
    colorId: 161,
    hexString: "#d7005f",
    rgb: {
      r: 215,
      g: 0,
      b: 95,
    },
    hsl: {
      h: 333.488372093023,
      s: 100,
      l: 42,
    },
    name: "DeepPink3",
  },
  {
    colorId: 162,
    hexString: "#d70087",
    rgb: {
      r: 215,
      g: 0,
      b: 135,
    },
    hsl: {
      h: 322.325581395349,
      s: 100,
      l: 42,
    },
    name: "DeepPink3",
  },
  {
    colorId: 163,
    hexString: "#d700af",
    rgb: {
      r: 215,
      g: 0,
      b: 175,
    },
    hsl: {
      h: 311.162790697674,
      s: 100,
      l: 42,
    },
    name: "Magenta3",
  },
  {
    colorId: 164,
    hexString: "#d700d7",
    rgb: {
      r: 215,
      g: 0,
      b: 215,
    },
    hsl: {
      h: 300,
      s: 100,
      l: 42,
    },
    name: "Magenta3",
  },
  {
    colorId: 165,
    hexString: "#d700ff",
    rgb: {
      r: 215,
      g: 0,
      b: 255,
    },
    hsl: {
      h: 290.588235294118,
      s: 100,
      l: 50,
    },
    name: "Magenta2",
  },
  {
    colorId: 166,
    hexString: "#d75f00",
    rgb: {
      r: 215,
      g: 95,
      b: 0,
    },
    hsl: {
      h: 26.5116279069767,
      s: 100,
      l: 42,
    },
    name: "DarkOrange3",
  },
  {
    colorId: 167,
    hexString: "#d75f5f",
    rgb: {
      r: 215,
      g: 95,
      b: 95,
    },
    hsl: {
      h: 0,
      s: 60,
      l: 60,
    },
    name: "IndianRed",
  },
  {
    colorId: 168,
    hexString: "#d75f87",
    rgb: {
      r: 215,
      g: 95,
      b: 135,
    },
    hsl: {
      h: 340,
      s: 60,
      l: 60,
    },
    name: "HotPink3",
  },
  {
    colorId: 169,
    hexString: "#d75faf",
    rgb: {
      r: 215,
      g: 95,
      b: 175,
    },
    hsl: {
      h: 320,
      s: 60,
      l: 60,
    },
    name: "HotPink2",
  },
  {
    colorId: 170,
    hexString: "#d75fd7",
    rgb: {
      r: 215,
      g: 95,
      b: 215,
    },
    hsl: {
      h: 300,
      s: 60,
      l: 60,
    },
    name: "Orchid",
  },
  {
    colorId: 171,
    hexString: "#d75fff",
    rgb: {
      r: 215,
      g: 95,
      b: 255,
    },
    hsl: {
      h: 285,
      s: 100,
      l: 68,
    },
    name: "MediumOrchid1",
  },
  {
    colorId: 172,
    hexString: "#d78700",
    rgb: {
      r: 215,
      g: 135,
      b: 0,
    },
    hsl: {
      h: 37.6744186046512,
      s: 100,
      l: 42,
    },
    name: "Orange3",
  },
  {
    colorId: 173,
    hexString: "#d7875f",
    rgb: {
      r: 215,
      g: 135,
      b: 95,
    },
    hsl: {
      h: 20,
      s: 60,
      l: 60,
    },
    name: "LightSalmon3",
  },
  {
    colorId: 174,
    hexString: "#d78787",
    rgb: {
      r: 215,
      g: 135,
      b: 135,
    },
    hsl: {
      h: 0,
      s: 50,
      l: 68,
    },
    name: "LightPink3",
  },
  {
    colorId: 175,
    hexString: "#d787af",
    rgb: {
      r: 215,
      g: 135,
      b: 175,
    },
    hsl: {
      h: 330,
      s: 50,
      l: 68,
    },
    name: "Pink3",
  },
  {
    colorId: 176,
    hexString: "#d787d7",
    rgb: {
      r: 215,
      g: 135,
      b: 215,
    },
    hsl: {
      h: 300,
      s: 50,
      l: 68,
    },
    name: "Plum3",
  },
  {
    colorId: 177,
    hexString: "#d787ff",
    rgb: {
      r: 215,
      g: 135,
      b: 255,
    },
    hsl: {
      h: 280,
      s: 100,
      l: 76,
    },
    name: "Violet",
  },
  {
    colorId: 178,
    hexString: "#d7af00",
    rgb: {
      r: 215,
      g: 175,
      b: 0,
    },
    hsl: {
      h: 48.8372093023256,
      s: 100,
      l: 42,
    },
    name: "Gold3",
  },
  {
    colorId: 179,
    hexString: "#d7af5f",
    rgb: {
      r: 215,
      g: 175,
      b: 95,
    },
    hsl: {
      h: 40,
      s: 60,
      l: 60,
    },
    name: "LightGoldenrod3",
  },
  {
    colorId: 180,
    hexString: "#d7af87",
    rgb: {
      r: 215,
      g: 175,
      b: 135,
    },
    hsl: {
      h: 30,
      s: 50,
      l: 68,
    },
    name: "Tan",
  },
  {
    colorId: 181,
    hexString: "#d7afaf",
    rgb: {
      r: 215,
      g: 175,
      b: 175,
    },
    hsl: {
      h: 0,
      s: 33,
      l: 76,
    },
    name: "MistyRose3",
  },
  {
    colorId: 182,
    hexString: "#d7afd7",
    rgb: {
      r: 215,
      g: 175,
      b: 215,
    },
    hsl: {
      h: 300,
      s: 33,
      l: 76,
    },
    name: "Thistle3",
  },
  {
    colorId: 183,
    hexString: "#d7afff",
    rgb: {
      r: 215,
      g: 175,
      b: 255,
    },
    hsl: {
      h: 270,
      s: 100,
      l: 84,
    },
    name: "Plum2",
  },
  {
    colorId: 184,
    hexString: "#d7d700",
    rgb: {
      r: 215,
      g: 215,
      b: 0,
    },
    hsl: {
      h: 60,
      s: 100,
      l: 42,
    },
    name: "Yellow3",
  },
  {
    colorId: 185,
    hexString: "#d7d75f",
    rgb: {
      r: 215,
      g: 215,
      b: 95,
    },
    hsl: {
      h: 60,
      s: 60,
      l: 60,
    },
    name: "Khaki3",
  },
  {
    colorId: 186,
    hexString: "#d7d787",
    rgb: {
      r: 215,
      g: 215,
      b: 135,
    },
    hsl: {
      h: 60,
      s: 50,
      l: 68,
    },
    name: "LightGoldenrod2",
  },
  {
    colorId: 187,
    hexString: "#d7d7af",
    rgb: {
      r: 215,
      g: 215,
      b: 175,
    },
    hsl: {
      h: 60,
      s: 33,
      l: 76,
    },
    name: "LightYellow3",
  },
  {
    colorId: 188,
    hexString: "#d7d7d7",
    rgb: {
      r: 215,
      g: 215,
      b: 215,
    },
    hsl: {
      h: 0,
      s: 0,
      l: 84,
    },
    name: "Grey84",
  },
  {
    colorId: 189,
    hexString: "#d7d7ff",
    rgb: {
      r: 215,
      g: 215,
      b: 255,
    },
    hsl: {
      h: 240,
      s: 100,
      l: 92,
    },
    name: "LightSteelBlue1",
  },
  {
    colorId: 190,
    hexString: "#d7ff00",
    rgb: {
      r: 215,
      g: 255,
      b: 0,
    },
    hsl: {
      h: 69.4117647058823,
      s: 100,
      l: 50,
    },
    name: "Yellow2",
  },
  {
    colorId: 191,
    hexString: "#d7ff5f",
    rgb: {
      r: 215,
      g: 255,
      b: 95,
    },
    hsl: {
      h: 75,
      s: 100,
      l: 68,
    },
    name: "DarkOliveGreen1",
  },
  {
    colorId: 192,
    hexString: "#d7ff87",
    rgb: {
      r: 215,
      g: 255,
      b: 135,
    },
    hsl: {
      h: 80,
      s: 100,
      l: 76,
    },
    name: "DarkOliveGreen1",
  },
  {
    colorId: 193,
    hexString: "#d7ffaf",
    rgb: {
      r: 215,
      g: 255,
      b: 175,
    },
    hsl: {
      h: 90,
      s: 100,
      l: 84,
    },
    name: "DarkSeaGreen1",
  },
  {
    colorId: 194,
    hexString: "#d7ffd7",
    rgb: {
      r: 215,
      g: 255,
      b: 215,
    },
    hsl: {
      h: 120,
      s: 100,
      l: 92,
    },
    name: "Honeydew2",
  },
  {
    colorId: 195,
    hexString: "#d7ffff",
    rgb: {
      r: 215,
      g: 255,
      b: 255,
    },
    hsl: {
      h: 180,
      s: 100,
      l: 92,
    },
    name: "LightCyan1",
  },
  {
    colorId: 196,
    hexString: "#ff0000",
    rgb: {
      r: 255,
      g: 0,
      b: 0,
    },
    hsl: {
      h: 0,
      s: 100,
      l: 50,
    },
    name: "Red1",
  },
  {
    colorId: 197,
    hexString: "#ff005f",
    rgb: {
      r: 255,
      g: 0,
      b: 95,
    },
    hsl: {
      h: 337.647058823529,
      s: 100,
      l: 50,
    },
    name: "DeepPink2",
  },
  {
    colorId: 198,
    hexString: "#ff0087",
    rgb: {
      r: 255,
      g: 0,
      b: 135,
    },
    hsl: {
      h: 328.235294117647,
      s: 100,
      l: 50,
    },
    name: "DeepPink1",
  },
  {
    colorId: 199,
    hexString: "#ff00af",
    rgb: {
      r: 255,
      g: 0,
      b: 175,
    },
    hsl: {
      h: 318.823529411765,
      s: 100,
      l: 50,
    },
    name: "DeepPink1",
  },
  {
    colorId: 200,
    hexString: "#ff00d7",
    rgb: {
      r: 255,
      g: 0,
      b: 215,
    },
    hsl: {
      h: 309.411764705882,
      s: 100,
      l: 50,
    },
    name: "Magenta2",
  },
  {
    colorId: 201,
    hexString: "#ff00ff",
    rgb: {
      r: 255,
      g: 0,
      b: 255,
    },
    hsl: {
      h: 300,
      s: 100,
      l: 50,
    },
    name: "Magenta1",
  },
  {
    colorId: 202,
    hexString: "#ff5f00",
    rgb: {
      r: 255,
      g: 95,
      b: 0,
    },
    hsl: {
      h: 22.3529411764706,
      s: 100,
      l: 50,
    },
    name: "OrangeRed1",
  },
  {
    colorId: 203,
    hexString: "#ff5f5f",
    rgb: {
      r: 255,
      g: 95,
      b: 95,
    },
    hsl: {
      h: 0,
      s: 100,
      l: 68,
    },
    name: "IndianRed1",
  },
  {
    colorId: 204,
    hexString: "#ff5f87",
    rgb: {
      r: 255,
      g: 95,
      b: 135,
    },
    hsl: {
      h: 345,
      s: 100,
      l: 68,
    },
    name: "IndianRed1",
  },
  {
    colorId: 205,
    hexString: "#ff5faf",
    rgb: {
      r: 255,
      g: 95,
      b: 175,
    },
    hsl: {
      h: 330,
      s: 100,
      l: 68,
    },
    name: "HotPink",
  },
  {
    colorId: 206,
    hexString: "#ff5fd7",
    rgb: {
      r: 255,
      g: 95,
      b: 215,
    },
    hsl: {
      h: 315,
      s: 100,
      l: 68,
    },
    name: "HotPink",
  },
  {
    colorId: 207,
    hexString: "#ff5fff",
    rgb: {
      r: 255,
      g: 95,
      b: 255,
    },
    hsl: {
      h: 300,
      s: 100,
      l: 68,
    },
    name: "MediumOrchid1",
  },
  {
    colorId: 208,
    hexString: "#ff8700",
    rgb: {
      r: 255,
      g: 135,
      b: 0,
    },
    hsl: {
      h: 31.7647058823529,
      s: 100,
      l: 50,
    },
    name: "DarkOrange",
  },
  {
    colorId: 209,
    hexString: "#ff875f",
    rgb: {
      r: 255,
      g: 135,
      b: 95,
    },
    hsl: {
      h: 15,
      s: 100,
      l: 68,
    },
    name: "Salmon1",
  },
  {
    colorId: 210,
    hexString: "#ff8787",
    rgb: {
      r: 255,
      g: 135,
      b: 135,
    },
    hsl: {
      h: 0,
      s: 100,
      l: 76,
    },
    name: "LightCoral",
  },
  {
    colorId: 211,
    hexString: "#ff87af",
    rgb: {
      r: 255,
      g: 135,
      b: 175,
    },
    hsl: {
      h: 340,
      s: 100,
      l: 76,
    },
    name: "PaleVioletRed1",
  },
  {
    colorId: 212,
    hexString: "#ff87d7",
    rgb: {
      r: 255,
      g: 135,
      b: 215,
    },
    hsl: {
      h: 320,
      s: 100,
      l: 76,
    },
    name: "Orchid2",
  },
  {
    colorId: 213,
    hexString: "#ff87ff",
    rgb: {
      r: 255,
      g: 135,
      b: 255,
    },
    hsl: {
      h: 300,
      s: 100,
      l: 76,
    },
    name: "Orchid1",
  },
  {
    colorId: 214,
    hexString: "#ffaf00",
    rgb: {
      r: 255,
      g: 175,
      b: 0,
    },
    hsl: {
      h: 41.1764705882353,
      s: 100,
      l: 50,
    },
    name: "Orange1",
  },
  {
    colorId: 215,
    hexString: "#ffaf5f",
    rgb: {
      r: 255,
      g: 175,
      b: 95,
    },
    hsl: {
      h: 30,
      s: 100,
      l: 68,
    },
    name: "SandyBrown",
  },
  {
    colorId: 216,
    hexString: "#ffaf87",
    rgb: {
      r: 255,
      g: 175,
      b: 135,
    },
    hsl: {
      h: 20,
      s: 100,
      l: 76,
    },
    name: "LightSalmon1",
  },
  {
    colorId: 217,
    hexString: "#ffafaf",
    rgb: {
      r: 255,
      g: 175,
      b: 175,
    },
    hsl: {
      h: 0,
      s: 100,
      l: 84,
    },
    name: "LightPink1",
  },
  {
    colorId: 218,
    hexString: "#ffafd7",
    rgb: {
      r: 255,
      g: 175,
      b: 215,
    },
    hsl: {
      h: 330,
      s: 100,
      l: 84,
    },
    name: "Pink1",
  },
  {
    colorId: 219,
    hexString: "#ffafff",
    rgb: {
      r: 255,
      g: 175,
      b: 255,
    },
    hsl: {
      h: 300,
      s: 100,
      l: 84,
    },
    name: "Plum1",
  },
  {
    colorId: 220,
    hexString: "#ffd700",
    rgb: {
      r: 255,
      g: 215,
      b: 0,
    },
    hsl: {
      h: 50.5882352941176,
      s: 100,
      l: 50,
    },
    name: "Gold1",
  },
  {
    colorId: 221,
    hexString: "#ffd75f",
    rgb: {
      r: 255,
      g: 215,
      b: 95,
    },
    hsl: {
      h: 45,
      s: 100,
      l: 68,
    },
    name: "LightGoldenrod2",
  },
  {
    colorId: 222,
    hexString: "#ffd787",
    rgb: {
      r: 255,
      g: 215,
      b: 135,
    },
    hsl: {
      h: 40,
      s: 100,
      l: 76,
    },
    name: "LightGoldenrod2",
  },
  {
    colorId: 223,
    hexString: "#ffd7af",
    rgb: {
      r: 255,
      g: 215,
      b: 175,
    },
    hsl: {
      h: 30,
      s: 100,
      l: 84,
    },
    name: "NavajoWhite1",
  },
  {
    colorId: 224,
    hexString: "#ffd7d7",
    rgb: {
      r: 255,
      g: 215,
      b: 215,
    },
    hsl: {
      h: 0,
      s: 100,
      l: 92,
    },
    name: "MistyRose1",
  },
  {
    colorId: 225,
    hexString: "#ffd7ff",
    rgb: {
      r: 255,
      g: 215,
      b: 255,
    },
    hsl: {
      h: 300,
      s: 100,
      l: 92,
    },
    name: "Thistle1",
  },
  {
    colorId: 226,
    hexString: "#ffff00",
    rgb: {
      r: 255,
      g: 255,
      b: 0,
    },
    hsl: {
      h: 60,
      s: 100,
      l: 50,
    },
    name: "Yellow1",
  },
  {
    colorId: 227,
    hexString: "#ffff5f",
    rgb: {
      r: 255,
      g: 255,
      b: 95,
    },
    hsl: {
      h: 60,
      s: 100,
      l: 68,
    },
    name: "LightGoldenrod1",
  },
  {
    colorId: 228,
    hexString: "#ffff87",
    rgb: {
      r: 255,
      g: 255,
      b: 135,
    },
    hsl: {
      h: 60,
      s: 100,
      l: 76,
    },
    name: "Khaki1",
  },
  {
    colorId: 229,
    hexString: "#ffffaf",
    rgb: {
      r: 255,
      g: 255,
      b: 175,
    },
    hsl: {
      h: 60,
      s: 100,
      l: 84,
    },
    name: "Wheat1",
  },
  {
    colorId: 230,
    hexString: "#ffffd7",
    rgb: {
      r: 255,
      g: 255,
      b: 215,
    },
    hsl: {
      h: 60,
      s: 100,
      l: 92,
    },
    name: "Cornsilk1",
  },
  {
    colorId: 231,
    hexString: "#ffffff",
    rgb: {
      r: 255,
      g: 255,
      b: 255,
    },
    hsl: {
      h: 0,
      s: 0,
      l: 100,
    },
    name: "Grey100",
  },
  {
    colorId: 232,
    hexString: "#080808",
    rgb: {
      r: 8,
      g: 8,
      b: 8,
    },
    hsl: {
      h: 0,
      s: 0,
      l: 3,
    },
    name: "Grey3",
  },
  {
    colorId: 233,
    hexString: "#121212",
    rgb: {
      r: 18,
      g: 18,
      b: 18,
    },
    hsl: {
      h: 0,
      s: 0,
      l: 7,
    },
    name: "Grey7",
  },
  {
    colorId: 234,
    hexString: "#1c1c1c",
    rgb: {
      r: 28,
      g: 28,
      b: 28,
    },
    hsl: {
      h: 0,
      s: 0,
      l: 10,
    },
    name: "Grey11",
  },
  {
    colorId: 235,
    hexString: "#262626",
    rgb: {
      r: 38,
      g: 38,
      b: 38,
    },
    hsl: {
      h: 0,
      s: 0,
      l: 14,
    },
    name: "Grey15",
  },
  {
    colorId: 236,
    hexString: "#303030",
    rgb: {
      r: 48,
      g: 48,
      b: 48,
    },
    hsl: {
      h: 0,
      s: 0,
      l: 18,
    },
    name: "Grey19",
  },
  {
    colorId: 237,
    hexString: "#3a3a3a",
    rgb: {
      r: 58,
      g: 58,
      b: 58,
    },
    hsl: {
      h: 0,
      s: 0,
      l: 22,
    },
    name: "Grey23",
  },
  {
    colorId: 238,
    hexString: "#444444",
    rgb: {
      r: 68,
      g: 68,
      b: 68,
    },
    hsl: {
      h: 0,
      s: 0,
      l: 26,
    },
    name: "Grey27",
  },
  {
    colorId: 239,
    hexString: "#4e4e4e",
    rgb: {
      r: 78,
      g: 78,
      b: 78,
    },
    hsl: {
      h: 0,
      s: 0,
      l: 30,
    },
    name: "Grey30",
  },
  {
    colorId: 240,
    hexString: "#585858",
    rgb: {
      r: 88,
      g: 88,
      b: 88,
    },
    hsl: {
      h: 0,
      s: 0,
      l: 34,
    },
    name: "Grey35",
  },
  {
    colorId: 241,
    hexString: "#626262",
    rgb: {
      r: 98,
      g: 98,
      b: 98,
    },
    hsl: {
      h: 0,
      s: 0,
      l: 37,
    },
    name: "Grey39",
  },
  {
    colorId: 242,
    hexString: "#6c6c6c",
    rgb: {
      r: 108,
      g: 108,
      b: 108,
    },
    hsl: {
      h: 0,
      s: 0,
      l: 40,
    },
    name: "Grey42",
  },
  {
    colorId: 243,
    hexString: "#767676",
    rgb: {
      r: 118,
      g: 118,
      b: 118,
    },
    hsl: {
      h: 0,
      s: 0,
      l: 46,
    },
    name: "Grey46",
  },
  {
    colorId: 244,
    hexString: "#808080",
    rgb: {
      r: 128,
      g: 128,
      b: 128,
    },
    hsl: {
      h: 0,
      s: 0,
      l: 50,
    },
    name: "Grey50",
  },
  {
    colorId: 245,
    hexString: "#8a8a8a",
    rgb: {
      r: 138,
      g: 138,
      b: 138,
    },
    hsl: {
      h: 0,
      s: 0,
      l: 54,
    },
    name: "Grey54",
  },
  {
    colorId: 246,
    hexString: "#949494",
    rgb: {
      r: 148,
      g: 148,
      b: 148,
    },
    hsl: {
      h: 0,
      s: 0,
      l: 58,
    },
    name: "Grey58",
  },
  {
    colorId: 247,
    hexString: "#9e9e9e",
    rgb: {
      r: 158,
      g: 158,
      b: 158,
    },
    hsl: {
      h: 0,
      s: 0,
      l: 61,
    },
    name: "Grey62",
  },
  {
    colorId: 248,
    hexString: "#a8a8a8",
    rgb: {
      r: 168,
      g: 168,
      b: 168,
    },
    hsl: {
      h: 0,
      s: 0,
      l: 65,
    },
    name: "Grey66",
  },
  {
    colorId: 249,
    hexString: "#b2b2b2",
    rgb: {
      r: 178,
      g: 178,
      b: 178,
    },
    hsl: {
      h: 0,
      s: 0,
      l: 69,
    },
    name: "Grey70",
  },
  {
    colorId: 250,
    hexString: "#bcbcbc",
    rgb: {
      r: 188,
      g: 188,
      b: 188,
    },
    hsl: {
      h: 0,
      s: 0,
      l: 73,
    },
    name: "Grey74",
  },
  {
    colorId: 251,
    hexString: "#c6c6c6",
    rgb: {
      r: 198,
      g: 198,
      b: 198,
    },
    hsl: {
      h: 0,
      s: 0,
      l: 77,
    },
    name: "Grey78",
  },
  {
    colorId: 252,
    hexString: "#d0d0d0",
    rgb: {
      r: 208,
      g: 208,
      b: 208,
    },
    hsl: {
      h: 0,
      s: 0,
      l: 81,
    },
    name: "Grey82",
  },
  {
    colorId: 253,
    hexString: "#dadada",
    rgb: {
      r: 218,
      g: 218,
      b: 218,
    },
    hsl: {
      h: 0,
      s: 0,
      l: 85,
    },
    name: "Grey85",
  },
  {
    colorId: 254,
    hexString: "#e4e4e4",
    rgb: {
      r: 228,
      g: 228,
      b: 228,
    },
    hsl: {
      h: 0,
      s: 0,
      l: 89,
    },
    name: "Grey89",
  },
  {
    colorId: 255,
    hexString: "#eeeeee",
    rgb: {
      r: 238,
      g: 238,
      b: 238,
    },
    hsl: {
      h: 0,
      s: 0,
      l: 93,
    },
    name: "Grey93",
  },
];
