import React from "react";
import styled from "styled-components";
import {Link, useNavigate} from "react-router-dom";

const FooterContainer = styled.div`
  background: #f8f8f8;
  padding: 0 1.5em 0em;
  box-shadow: 0 -2px 3px -1px rgb(0 0 0 / 0.1), 0 -1px 2px -2px rgb(0 0 0 / 0.1);

  @media (min-width: 768px) {
    padding: 0 3em 1em;
  }

  @media (min-width: 1440px) {
    padding: 0 5em 1em;
  }
`;

const Socials = styled.div`
  margin-top: 1.5em;

  @media (min-width: 768px) {
    margin-top: 0;
    margin-right: 3em;
  }

  @media (min-width: 1024px) {
    margin-right: 6em;
  }
`;

const Thrindle = styled.div`
  &.thrindle-contact-item {
    @media (min-width: 768px) {
      margin-right: 3em;
    }

    @media (min-width: 1024px) {
      margin-right: 6em;
    }
  }
`;

const Contact = styled.div``;

const Container = styled.div`
  @media (min-width: 768px) {
    &.footer-content {
      display: flex;
      justify-content: space-between;
    }
  }

  @media (min-width: 768px) {
    &.thrindle-contact {
      display: flex;
    }
  }
`;

const Footer = () => {
  const navigate = useNavigate();
  return (
    <FooterContainer>
      <div className="bg-bg-color-footer w-full">
        <Container className="footer-content">
          <div className="pt-12 md:w-52 lg:w-72">
            <img
              src="https://res.cloudinary.com/codeinstd/image/upload/v1627459268/Logo_Identity_iskwsm.png"
              alt="thrindle logo"
              style={{
                width: "114.31px",
                height: "28.99px",
              }}
              onClick={() => navigate("/")}
            />

            <p className="text-white-gray mt-5 leading-7">
              Thrindle provides the platform to shop from major markets online
              and at your convenience.
            </p>
          </div>

          <div className="flex justify-between mt-12">
            <Container className="thrindle-contact">
              <Thrindle className="thrindle-contact-item">
                <div>
                  <h6 className="text-white-grayDark text-lg">Company</h6>
                  <Link
                    to="/about"
                    replace
                    className="text-sm md:text-base capitalize mt-4">
                    <p className="text-sm md:text-base text-white-gray mt-4 hover:text-primary-dark">
                      About Us
                    </p>
                  </Link>

                  <Link
                    to="/privacy-policy"
                    replace
                    className="text-sm md:text-base capitalize mt-4">
                    <p className="text-sm md:text-base text-white-gray mt-4 hover:text-primary-dark">
                      Privacy Policy
                    </p>
                  </Link>
                  <Link
                    to="/terms-of-service"
                    replace
                    className="text-sm md:text-base mt-4">
                    <p className="text-sm md:text-base text-white-gray mt-4 hover:text-primary-dark">
                      Terms of Service
                    </p>
                  </Link>
                  <Link
                    to="/support"
                    replace
                    className="text-sm md:text-base mt-4">
                    <p className="text-sm md:text-base text-white-gray mt-4 hover:text-primary-dark">
                      Support
                    </p>
                  </Link>
                </div>
              </Thrindle>
              <Socials>
                <h6 className="text-white-grayDark text-lg">Social</h6>

                <a
                  href="https://web.facebook.com/thrindle/"
                  target="_blank"
                  rel="noreferrer">
                  <p className="text-sm md:text-base text-white-gray mt-4 hover:text-primary-dark">
                    Facebook
                  </p>
                </a>

                <a
                  href="https://www.instagram.com/thrindle/"
                  target="_blank"
                  rel="noreferrer">
                  <p className="text-sm md:text-base text-white-gray mt-4 hover:text-primary-dark">
                    Instagram
                  </p>
                </a>

                <a
                  href="https://twitter.com/thrindle_ng"
                  target="_blank"
                  rel="noreferrer">
                  {" "}
                  <p className="text-sm md:text-base text-white-gray mt-4 hover:text-primary-dark">
                    Twitter
                  </p>
                </a>

                <a
                  href="https://www.linkedin.com/company/thrindle/"
                  target="_blank"
                  rel="noreferrer">
                  {" "}
                  <p className="text-sm md:text-base text-white-gray mt-4 hover:text-primary-dark">
                    LinkedIn
                  </p>
                </a>
              </Socials>{" "}
            </Container>

            <Contact className="flex flex-col">
              <h6 className="text-white-garyDark text-lg">Contacts</h6>

              <a
                href="mailto:info@thrindle.com"
                target="_blank"
                className="cursor-pointer"
                rel="noreferrer">
                <p className="text-sm md:text-base text-white-gray mt-4 hover:text-primary-dark">
                  info@thrindle.com
                </p>
              </a>
              <p className="text-sm md:text-base text-white-gray mt-4 hover:text-primary-dark">
                +234-810-056-9319
              </p>
            </Contact>
          </div>
        </Container>

        <div className="flex w-full justify-center items-center text-white-gray h-14 text-sm mt-10">
          <p>All rights reserved ©{new Date().getFullYear()} Thrindle</p>
        </div>
      </div>
    </FooterContainer>
  );
};

export default Footer;
