import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import MainContainerSlim from "../../components/common/mainContainer/mainContainerSlim";
import headers from "../../data/privaryPolicy";
import {
  HeaderMixed,
  List,
  ListItems,
  HeaderLarge,
  ContainerWithBg,
  GeneralPadding,
} from "../../styles/globalStyles";

const Text = styled.p`
  color: #718096;
  text-align: justify;
  line-height: 1.8;

  &.policies-item-text {
    margin: 0.5em 0;
    cursor: pointer;
  }

  &.policies-section {
    margin: 1em 0;
    cursor: pointer;
    color: rgba(113, 128, 150, 0.8);
    line-height: normal;

    &:hover {
      color: rgba(113, 128, 150, 1);
    }
  }
`;

const Heading = styled.h3`
  color: #166cb4;
  margin-bottom: 0.5em;
  font-weight: 800;
  font-family: var(--font-bold);
  text-transform: capitalize;
`;

const Box = styled.div`
  &.policies-item {
    margin-bottom: 1em;
  }

  &.index {
    display: none;

    @media (min-width: 1024px) {
      display: block;
      background: #f7ead5;
      width: 35%;
      padding: 0.7em 1em;
      height: 90vh;
      position: -webkit-sticky;
      position: sticky;
      top: 1em;
    }
  }

  &.policies {
    @media (min-width: 1024px) {
      width: 60%;
    }
  }

  &.index-policies {
    display: flex;
    justify-content: space-between;
  }
`;

const SubHeading = styled.p`
  margin: 0.5em 0;
  font-weight: 800;
  font-family: var(--font-bold);
  text-transform: capitalize;
`;

function PrivacyPolicy() {
  let navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MainContainerSlim fixed={true}>
      <ContainerWithBg data-aos="fade-right" data-aos-easing="ease-in-out">
        <GeneralPadding className="pb-24">
          <HeaderMixed className="heading">Privacy Policy</HeaderMixed>
          <HeaderLarge>
            <List>
              <ListItems inactive onClick={() => navigate("/about")}>
                About Us
              </ListItems>

              <ListItems inactive onClick={() => navigate("/terms-of-service")}>
                Terms of Service
              </ListItems>
              <ListItems className="font-Bold">Privacy Policy</ListItems>
            </List>
          </HeaderLarge>

          <Box className="index-policies">
            <Box className="index">
              <Heading className="font-Bold">Privacy Policy</Heading>
              {headers.map((header, index) => (
                <a key={index} href={`#${header.link}`}>
                  <Text className="policies-section font-Medium">
                    {header.heading}
                  </Text>
                </a>
              ))}
            </Box>

            <Box className="policies">
              <Box className="policies-item" id="thrindlePrivacy">
                <Heading>Thrindle Privacy Policy</Heading>
                <Text className="policies-item-text">
                  Welcome to THRINDLE App which is owned and operated by
                  THRINDLE SERVICES LIMITED (&apos;we`&apos;, &apos;us&apos; or
                  &apos;our&apos;). We are committed to providing a platform
                  that digitalizes physical markets where store owners can
                  manage sales and buyers can conveniently place orders for
                  products, and where parties can feel confident that their
                  privacy is protected. This privacy policy outlines how
                  THRINDLE uses personal data relating to its web users and
                  other individuals who interact with THRINDLE. THRINDLE
                  considers your privacy and personal information to be
                  important, as such we are committed to protecting the
                  information you provide to us. This policy details the types
                  of personal data we collect, how we use that data, with whom
                  we share with and the choices you can make about our use of
                  such data. We will also explain your privacy rights and how
                  the law protects you by describing the measures we take to
                  protect the security of your personal data and how you can
                  contact us about our privacy practices. Details about how to
                  contact us can be found in the ‘How to Reach Us’ section of
                  this privacy policy.
                </Text>
              </Box>

              <Box className="policies-item" id="information">
                <Heading>Information We Collect</Heading>
                <Text>
                  As a provider of a networking and communication service, we
                  may have a direct relationship with you, or you may choose to
                  provide us with information in connection with that
                  relationship or we may collect it via other means. The data we
                  collect about you may include:
                  <ul className="my-2 list-disc pl-8 py-2">
                    <li>Contact Information </li>
                    <li>Content you Provide to Us</li>
                    <li>
                      Technical Information About Your Device and Website Usage{" "}
                    </li>
                    <li>Photos</li>
                  </ul>
                  <SubHeading>Contact Information</SubHeading>If you use our
                  website or interact with us, we will collect certain basic
                  contact information about you. This may include your name and
                  email address.
                  <SubHeading>Content you Provide to Us </SubHeading>You may
                  provide us with certain information while getting in touch
                  with us via our website or by telephone and we may email you
                  or vice versa or you may send us feedback via a survey form.
                  In this case, information such as, your date of birth, gender,
                  education, photograph, profession and/or interests might be
                  required.
                  <SubHeading>
                    Technical Information about your device and website usage
                  </SubHeading>{" "}
                  If you use our website, then we will install certain cookies
                  on your device. The details of how we use these technologies
                  is outlined in detail within our COOKIE POLICY. Through
                  cookies and similar technologies, we may collect details about
                  your device, such as IP address, internet browser type and
                  operating system. We may also collect information about your
                  browsing activity, such as how you use our website, the pages
                  you have visited, the date and time of each visit and the URL
                  of any websites that re-directed you to our website. This
                  information is used for the operation of the services to
                  maintain quality of the service, and to provide general
                  statistics regarding use of the services.
                </Text>
              </Box>

              <Box className="policies-item" id="howWeCollect">
                <Heading>How We collect Your Information</Heading>
                <Text>
                  We use different methods to collect data from and about you,
                  depending upon the nature of our relationship. This may
                  include through direct interactions we have with you and
                  through the use of automated technologies (e.g. cookies).
                </Text>
              </Box>

              <Box className="policies-item" id="directInteractions">
                <Heading>Direct Interactions</Heading>
                <Text>
                  You may provide us with your data by completing forms (e.g.
                  through our website) or by corresponding with us by post,
                  phone, email or otherwise. This will include data you provide
                  when you:
                  <ul className="list-disc pl-8 py-2">
                    <li>Create an Account</li>
                    <li>
                      Sign up to receive information from THRINDLE (e.g.
                      subscribe to our email marketing list or newsletter)
                    </li>
                    <li>Participate in promotions or surveys</li>
                    <li>Provide us with feedback; or</li>
                    <li>Otherwise communicate with us</li>
                  </ul>
                </Text>
              </Box>

              <Box className="policies-item" id="Automated">
                <Heading>Automated Technologies</Heading>
                <Text>
                  As you interact with our website, we may automatically collect
                  technical information about your equipment, browsing actions
                  and patterns. We collect this personal data by using cookies,
                  and other similar technologies.
                </Text>
              </Box>

              <Box className="policies-item" id="howWeUse">
                <Heading>How We Use Your Data</Heading>
                <Text>
                  Depending on the nature of our relationship with you, we may
                  use your data to:
                  <ul className="list-disc pl-8 py-2">
                    <li>
                      Provide you with information about our products and
                      services
                    </li>
                    <Text className="policies-item-text">
                      If you have requested to receive information from us, then
                      we may contact you from time to time by email, post,
                      telephone, SMS about our products and services.
                      <SubHeading>Categories of data processed</SubHeading>
                      <ul className="list-disc pl-8">
                        <li>Contact Information</li>
                      </ul>
                      <SubHeading> Legal Basis </SubHeading>
                      <Text>
                        We will only send you information about our products and
                        services;
                        <ul className="list-disc pl-8">
                          <li>If you have consented to receiving them</li>
                          <li>
                            {" "}
                            Where it is absolutely necessary for your continued
                            use and enjoyment of our services
                          </li>
                          <li>
                            In furtherance of our legitimate interests, Where
                            permitted to do so under applicable law.
                          </li>
                        </ul>
                      </Text>{" "}
                    </Text>

                    <li>
                      Operate, evaluate and improve our business, products and
                      services
                    </li>
                    <Text className="policies-item-text">
                      <ul className="list-disc pl-8">
                        <li>
                          Recording of telephone calls and online chats that we
                          have with you for training or quality assurances
                        </li>
                        <li>
                          Monitor the use of and improve our interactive assets
                        </li>
                        <li>
                          Perform data analysis (including anonymisation) of
                          your data
                        </li>
                      </ul>
                      <SubHeading>Categories of Data Processed</SubHeading>
                      <ul className="list-disc pl-8">
                        <li>Contact Information</li>
                        <li>Content you provide to us</li>
                        <li>
                          Technical Information about your device and website
                          usage
                        </li>
                      </ul>
                      <SubHeading>Legal Basis</SubHeading>
                      It is in our legitimate business interest to review and
                      improve our business, products and services to ensure that
                      customer satisfaction is as high as possible. We will
                      conduct data analysis (including benchmarking and
                      reporting) to enable us to do so.
                    </Text>

                    <li>
                      Comply with legal obligations, applicable to our industry
                      standards and our policies
                    </li>
                    <Text className="policies-item-text">
                      Comply with any legal obligations as required.
                      <SubHeading>Categories of Data Processed</SubHeading>
                      <ul className="list-disc pl-8">
                        <li>Contact Information</li>
                        <li>Content you provide to us</li>
                        <li>
                          Technical Information about your device and website
                          usage
                        </li>
                        <li>
                          We may keep records of your data, such as your
                          messages for evidential purposes in order to defend
                          ourselves against or issue one or more future legal
                          claims.
                        </li>
                      </ul>
                      <SubHeading>Legal Basis</SubHeading>It is necessary for
                      compliance with legal obligations to which we are subject.
                    </Text>

                    <li> Allow you to participate in interactive features</li>

                    <li>
                      Perform analysis (including anonymisation) of your data
                    </li>
                    <li>
                      Keep you up to date with changes and updates that would
                      make your THRINDLE experience seamless
                    </li>
                    <li>
                      Send you updates and news by email or push notification,
                      depending on your settings
                    </li>
                    <li>Respond to your questions or comments</li>

                    <li>
                      Notify you when we are collecting information in future
                      for use
                    </li>
                  </ul>
                </Text>
              </Box>

              <Box className="policies-item" id="marketing">
                <Heading>Marketing Purpose</Heading>
                <Text>
                  In addition to the specific circumstances above, we will only
                  use your information with your consent, and we may have to;
                  Share your personal information with third parties in the
                  event that we sell or buy any business or assets, in which
                  case we may disclose your personal information to the
                  prospective seller or buyer of such business or assets, along
                  with its professional advisers. If we use your personal data
                  in ways that are not described above, we will provide you with
                  notice of this before doing so.
                </Text>
              </Box>

              <Box className="policies-item" id="dataWeShare">
                <Heading>Data We Share and Receive</Heading>
                <Text className="policies-item-text">
                  We do not sell or otherwise disclose your data, except as
                  described in this privacy policy. However, we may share your
                  data with the following parties:
                  <ul className="list-disc pl-8">
                    <li>IT suppliers</li>
                    <li>
                      {" "}
                      Regulators and other government agencies including, but
                      not limited to, public authorities, regulators, the police
                      and other law enforcement agencies.
                    </li>
                    <li>
                      Any company to whom we sell or buy any business or assets,
                      in which case we may disclose your personal data to the
                      prospective seller or buyer of such business or assets,
                      along with its professional advisers.
                    </li>
                  </ul>
                  <SubHeading>
                    We may also receive data about you from the following third
                    parties:{" "}
                  </SubHeading>
                  <ul className="list-disc pl-8">
                    <li>Social Media platforms</li>
                    <li>Public Databases</li>
                    <li>Regulators and other Government Agencies</li>
                    <li>
                      Our wholly owned subsidiaries and affiliates. If we were
                      to engage in a merger, acquisition, bankruptcy,
                      dissolution, reorganization, or similar transaction or
                      proceeding that involves the transfer of the information
                      described in this Policy, we would share your information
                      with a party involved in such a process (for example, a
                      potential purchaser)
                    </li>
                  </ul>
                </Text>
              </Box>

              <Box className="policies-item" id="whereYourData">
                <Heading>Where Your Data Maybe Sent</Heading>
                <Text>
                  Where your data may be sent. With any multinational
                  organisation, we may be required to transfer data
                  internationally. Therefore, your personal data may be
                  transferred globally. This includes transfers that have been
                  identified in the previous section: Who do we share your data
                  with and for what purposes. By using our products or services,
                  you authorize us to transfer and store your information
                  outside your home country, for the purposes described in this
                  policy. The privacy protections and the rights of authorities
                  to access your personal information in such countries may not
                  be equivalent to those of your home country.
                </Text>
              </Box>

              <Box className="policies-item" id="howWeKeep">
                <Heading>How We Keep Your Information Secure</Heading>
                <Text>
                  THRINDLE will take appropriate technical and organisational
                  security measures to secure your data and to protect it from
                  loss, misuse or alteration. Data that we hold about you is
                  stored on our secure servers or those of our appointed
                  suppliers. Our web pages that collect your data are secured
                  with Secured Socket Layers (SSLs).We use computer safeguard
                  such as firewalls and data encryption, enforce limited
                  physical access to our buildings and files, and only authorise
                  access to personal information to only employees who require
                  it to fulfill their job responsibilities or our affiliates as
                  may be required in the provision of our services to you.
                  THRINDLE maintains an information security program that is
                  designed to:
                  <ul className="list-disc pl-8">
                    <li>
                      {" "}
                      Secure and maintain confidentiality of your personal data.
                    </li>
                    <li>
                      Protect against anticipated threats or hazards to security
                      or integrity of your data.
                    </li>
                    <li>
                      Protect against unauthorised access to or use of your data
                      that could result in substantial harm or inconvenience to
                      you or your entity if compromised.
                    </li>
                    <li>Comply with applicable Laws.</li>
                  </ul>
                </Text>
              </Box>

              <Box className="policies-item" id="howLong">
                <Heading>How Long We May Keep Your Data</Heading>
                <Text>
                  We will only retain your personal data for as long as
                  necessary to fulfil the purposes we collected it for,
                  including for the purposes of satisfying any legal,
                  accounting, or reporting requirements. To determine the
                  appropriate retention period for personal data, we consider
                  the amount, nature, and sensitivity of the personal data, the
                  potential risk of harm from unauthorised use or disclosure of
                  your personal data, the purposes for which we process your
                  personal data and whether we can achieve those purposes
                  through other means, and the applicable legal requirements. In
                  some circumstances you can ask us to delete your data, please
                  see “Your Rights and Choices”. In some circumstances we may
                  anonymise your personal data (so that it can no longer be
                  associated with or be used to identity you) for research or
                  statistical purposes in which case we may use such data
                  indefinitely without further notice to you.
                </Text>
              </Box>

              <Box className="policies-item" id="YourRight">
                <Heading>Your Rights and Choices</Heading>
                <Text>
                  You may have some or all of the following rights in respect of
                  data about you that we hold:
                  <ul className="list-disc pl-8">
                    <li>request us to give you access to it</li>
                    <li>request us to rectify it, update it, or erase it</li>
                    <li>
                      request us to restrict our using it, in certain
                      circumstances
                    </li>
                    <li>object to our using it, in certain circumstances</li>
                    <li>data portability, in certain circumstances</li>
                    <li>opt out from our using it for direct marketing; and</li>
                    <li>
                      lodge a complaint with the supervisory authority in your
                      country (if there is one).
                    </li>
                  </ul>
                  You are able to exercise these rights by contacting us using
                  the details set out at in the How to reach us section below.
                  Please visit our COOKIE POLICY to learn about how you can also
                  exercise your rights in relation to our use of cookies, web
                  beacons and similar technologies. We will update this policy
                  from time to time to reflect changes in our business. However,
                  we will inform you of these changes as required under
                  applicable laws.
                </Text>
              </Box>

              <Box className="policies-item" id="thirdParty">
                <Heading>Third Party Links</Heading>
                <Text>
                  Occasionally, we may include or offer third party products or
                  services on our platform and our platform may contain links to
                  websites owned and operated by third parties. These
                  third-party sites have separate and independent privacy
                  policies and we urge you to review them – because this Privacy
                  Policy will not apply. We therefore have no responsibility or
                  liability for the content, activities and privacy practices of
                  such third-party websites.
                </Text>
              </Box>

              <Box className="policies-item" id="yourConsent">
                <Heading>Your Consent</Heading>
                <Text>
                  By submitting any personal information to us, you consent to
                  the use of your personal information as set out in this
                  Privacy Policy. We respect your rights and may take action to
                  protect your privacy. If you suspect potential misuse of your
                  information, please contact us with the details before taking
                  any action directly. You agree that any cause of action
                  related to the breach of this Privacy Policy must commence
                  within six (6) months after the cause of action accrues.
                  Otherwise, such cause of action is permanently barred.
                </Text>
              </Box>

              <Box className="policies-item" id="changes">
                <Heading>Changes to Our Privacy Policy</Heading>
                <Text>
                  We reserve the right to amend or modify this Privacy Policy
                  and if we do, so we will post the changes on this page. It is
                  your responsibility to check the Privacy Policy every time you
                  submit information to us or use our services. Your use will
                  signify that you agree to any such changes.
                </Text>
              </Box>

              <Box className="policies-item" id="howToReach">
                <Heading>How to Reach Us</Heading>
                <Text>
                  You can reach us by sending an email to info@thrindle.com or
                  by live chat on our website https://www.thrindle.com
                </Text>
              </Box>
            </Box>
          </Box>
        </GeneralPadding>
      </ContainerWithBg>
    </MainContainerSlim>
  );
}

export default PrivacyPolicy;
