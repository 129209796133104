import {STORE_ACTIVE_PRODUCT} from "../../constants/index";

export const storeActiveProductReducer = (
  state = {activeProduct: {}},
  {type, payload},
) => {
  switch (type) {
    case STORE_ACTIVE_PRODUCT:
      return {
        ...state,
        activeProduct: payload,
      };

    default:
      return state;
  }
};
