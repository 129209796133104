import {
  STORE_SELLER_MARKET,
  STORE_SELLER_STORE_DETAILS,
  GET_MARKETS_FAIL,
  GET_MARKETS_LOADING,
  GET_MARKETS_SUCCESS,
  CLEAR_DETAILS,
  CREATE_STORE_LOADING,
  CREATE_STORE_SUCCESS,
  CREATE_STORE_FAIL,
  CLEAR_ERROR,
  STORE_SELLERS_STORE_LOGO,
} from "../../constants/index";

export const storeMarketTypeReducer = (
  state = {marketType: "", marketId: ""},
  {type, payload},
) => {
  switch (type) {
    case STORE_SELLER_MARKET:
      return {
        ...state,
        marketType: payload.name,
        marketId: payload.id,
      };
    case CLEAR_DETAILS:
      return {
        ...state,
        marketType: "",
        marketId: "",
      };

    default:
      return state;
  }
};
export const storeSellerDetailsReducer = (
  state = {
    shopTitle: "",
    shopAddress: "",
    shopDescription: "",
    shopLogo: [],
    shopNIN: "",
    storeLink: "",
    storeState: "",
  },
  {type, payload},
) => {
  switch (type) {
    case STORE_SELLER_STORE_DETAILS:
      return {
        ...state,
        ...payload,
      };
    case CLEAR_DETAILS:
      return {
        ...state,
        shopTitle: "",
        shopAddress: "",
        shopDescription: "",
        shopLogo: [],
        shopNIN: "",
        storeLink: "",
        storeState: "",
      };

    default:
      return state;
  }
};
export const storeSellerLogoReducer = (state = {logo: []}, {type, payload}) => {
  switch (type) {
    case STORE_SELLERS_STORE_LOGO:
      return {
        ...state,
        logo: [payload],
      };
    case CLEAR_DETAILS:
      return {
        ...state,
        logo: [],
      };

    default:
      return state;
  }
};

export const getMarketsReducer = (
  state = {
    loading: false,
    error: "",
    markets: [],
  },
  {type, payload},
) => {
  switch (type) {
    case GET_MARKETS_LOADING:
      return {
        ...state,
        loading: true,
        error: "",
        markets: [],
      };
    case GET_MARKETS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        markets: payload.data,
      };
    case GET_MARKETS_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
        markets: [],
      };

    default:
      return state;
  }
};

export const createStoreReducer = (
  state = {
    loading: false,
    error: "",
    status: null,
    message: "",
    storeDetails: {},
    storeId: "",
    ownerId: "",
  },
  {type, payload},
) => {
  switch (type) {
    case CREATE_STORE_LOADING:
      return {
        ...state,
        loading: true,
        error: "",
        status: null,
        message: "",
        storeDetails: {},
        storeId: "",
        ownerId: "",
      };
    case CREATE_STORE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        status: payload?.status,
        message: payload?.message,
        storeDetails: payload?.data,
        storeId: payload?.store_id,
        ownerId: payload?.owner_id,
      };
    case CREATE_STORE_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
        status: null,
        message: "",
        storeDetails: {},
        storeId: "",
        ownerId: "",
      };

    case CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        error: "",
        status: null,
        message: "",
      };

    default:
      return state;
  }
};
