export const Wallet = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M12.375 9.5625H13.5V10.6875H12.375V9.5625Z" fill="#464F54" />
    <path
      d="M15.75 4.5H2.25V2.8125H14.625V1.6875H2.25C1.95163 1.6875 1.66548 1.80603 1.4545 2.017C1.24353 2.22798 1.125 2.51413 1.125 2.8125V14.625C1.125 14.9234 1.24353 15.2095 1.4545 15.4205C1.66548 15.6315 1.95163 15.75 2.25 15.75H15.75C16.0484 15.75 16.3345 15.6315 16.5455 15.4205C16.7565 15.2095 16.875 14.9234 16.875 14.625V5.625C16.875 5.32663 16.7565 5.04048 16.5455 4.8295C16.3345 4.61853 16.0484 4.5 15.75 4.5ZM2.25 14.625V5.625H15.75V7.3125H11.25C10.9516 7.3125 10.6655 7.43103 10.4545 7.642C10.2435 7.85298 10.125 8.13913 10.125 8.4375V11.8125C10.125 12.1109 10.2435 12.397 10.4545 12.608C10.6655 12.819 10.9516 12.9375 11.25 12.9375H15.75V14.625H2.25ZM15.75 8.4375V11.8125H11.25V8.4375H15.75Z"
      fill="#464F54"
    />
  </svg>
);
