import numbro from "numbro";
import moment from "moment";
import {mainColor} from "../data/productionDatas";

export const numberFormat = (val = 0) => {
  return numbro(Number(val)).format({thousandSeparated: true, mantissa: 2});
};

export const formatDate = val => {
  let newDate = moment(val).startOf("seconds").fromNow();
  return newDate;
};

export const getColorCode = val => {
  const result = mainColor.filter(item => item.name === val);
  return result.hex;
};
