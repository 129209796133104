import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import MainContainerSlim from "../../components/common/mainContainer/mainContainerSlim";
import {
  HeaderMixed,
  List,
  ListItems,
  HeaderLarge,
  ContainerWithBg,
  GeneralPadding,
} from "../../styles/globalStyles";
import {
  commissions,
  customerPayment,
  generals,
  indemnifications,
  introData,
  invoicing,
  licenses,
  merchantRightData,
  scopeData,
  sections,
  terminations,
  thrindleRightData,
} from "../../data/termsOfService";

const Text = styled.p`
  color: #718096;
  line-height: 1.8;

  &.terms-item-text {
    margin: 0.5em 0;
  }
`;

const Heading = styled.h3`
  color: #166cb4;
  margin-bottom: 0.5em;
  font-family: var(--font-bold);
`;

const Sections = styled.div`
  cursor: pointer;
  color: rgba(113, 128, 150, 0.8);

  &:hover {
    color: rgba(113, 128, 150, 1);
  }
`;

const Box = styled.div`
  &.terms-item {
    margin-bottom: 1em;
  }

  &.index {
    display: none;

    @media (min-width: 1024px) {
      display: block;
      background: #f7ead5;
      width: 35%;
      padding: 0.7em 1em;
      height: 75vh;
      position: -webkit-sticky;
      position: sticky;
      top: 1em;
    }
  }

  &.terms {
    @media (min-width: 1024px) {
      width: 60%;
    }
  }

  &.index-terms {
    display: flex;
    justify-content: space-between;
  }
`;

const Ul = styled.ul`
  color: #718096;
  list-style-type: lower-alpha;
  padding-left: 2em;
  text-align: justify;
  line-height: 1.8;
`;

function TermsOfService() {
  let navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MainContainerSlim fixed={true}>
      <ContainerWithBg data-aos="fade-right" data-aos-easing="ease-in-out">
        <GeneralPadding className="pb-24">
          <HeaderMixed className="heading">Terms of Service</HeaderMixed>
          <HeaderLarge>
            <List>
              <ListItems inactive onClick={() => navigate("/about")}>
                About Us
              </ListItems>
              <ListItems className="font-Bold">Terms of Service</ListItems>
              <ListItems inactive onClick={() => navigate("/privacy-policy")}>
                Privacy Policy
              </ListItems>
            </List>
          </HeaderLarge>

          <Box className="index-terms">
            <Box className="index">
              <Heading>Terms of Service</Heading>
              {sections.map((section, index) => (
                <a key={index} href={`#${section.link}`}>
                  <Sections className="font-Medium my-4 cursor-pointer">
                    {section.title}
                  </Sections>
                </a>
              ))}
            </Box>

            <Box className="terms">
              <Box className="terms-item" id="intro">
                <Heading>1. Introduction</Heading>
                <Text className="terms-item-text">
                  Thrindle Nigeria Limited (“THRINDLE”, “we” or “us”) operates
                  an ecommerce platform consisting of a website and mobile
                  application (“marketplace”), together with supporting payment
                  infrastructure, for the sale and purchase of consumer products
                  in Nigeria (“territory”).
                  <br></br>
                  <br></br>
                  Please read these terms and conditions (referred to as
                  &quot;Ts & Cs&quot; or T&C) carefully before using the
                  marketplace as they are the legal agreement between you and
                  THRINDLE. By using our marketplace, you accept these Ts & Cs
                  in full. If you do not accept the Ts & Cs or any part of the
                  Ts & Cs, please do not proceed as your use will be taken as
                  your acceptance. Also refer to the THRINDLE Merchant&apos;s Ts
                  & Cs if you intend to register with us as a Merchant.
                  <br></br>
                  <br></br>
                  These Ts & Cs apply specifically to your use of the THRINDLE
                  platform as a Merchant and it is applicable in addition to
                  THRINDLE’s General Ts & Cs.
                  <br></br>
                  <br></br>
                  If you use our marketplace, then by so doing you:
                  <Ul>
                    {introData.map((intro, index) => (
                      <li key={index} className="my-2">
                        {intro}
                      </li>
                    ))}
                  </Ul>
                  We may modify all or any part of these Ts & Cs from time to
                  time without notifying you. You should regularly check the Ts
                  & Cs so you are aware of your rights and obligations. Your
                  continued use of the services after changes to the Ts & Cs
                  constitutes your binding acceptance of the updated Ts & Cs.
                </Text>
              </Box>

              <Box className="terms-item" id="scope">
                <Heading>2. Scope</Heading>
                <Ul>
                  {scopeData.map((scope, index) => (
                    <li key={index} className="my-2">
                      {scope}
                    </li>
                  ))}
                </Ul>
              </Box>

              <Box className="terms-item" id="thrindleRight">
                <Heading>3. Thrindle&apos;s Right and Obligation</Heading>
                <Ul>
                  {thrindleRightData.map((right, index) => (
                    <li key={index} className="my-2">
                      {right}
                    </li>
                  ))}
                </Ul>
              </Box>

              <Box className="terms-item" id="merchantRight">
                <Heading>4. Merchant&apos;s Rights and Obligation</Heading>
                <Ul>
                  {merchantRightData.map((merchant, index) => (
                    <li key={index} className="my-2">
                      {merchant}
                    </li>
                  ))}
                </Ul>
              </Box>

              <Box className="terms-item" id="payment">
                <Heading>5. Customer Payment</Heading>
                <Ul>
                  {customerPayment.map((payment, index) => (
                    <li key={index} className="my-2">
                      {payment}
                    </li>
                  ))}
                </Ul>
              </Box>

              <Box className="terms-item" id="invoicing">
                <Heading>6. Invoicing and Merchant Payment</Heading>
                <Ul>
                  {invoicing.map((invoice, index) => (
                    <li key={index} className="my-2">
                      {invoice}
                    </li>
                  ))}
                </Ul>
              </Box>

              <Box className="terms-item" id="commission">
                <Heading>7. Commission</Heading>
                <Ul>
                  {commissions.map((commission, index) => (
                    <li key={index} className="my-2">
                      {commission}
                    </li>
                  ))}
                </Ul>
              </Box>

              <Box className="terms-item" id="indemnify">
                <Heading>8. Indemnification and Liability</Heading>
                <Ul>
                  {indemnifications.map((indemnification, index) => (
                    <li key={index} className="my-2">
                      {indemnification}
                    </li>
                  ))}
                </Ul>
              </Box>

              <Box className="terms-item" id="privacy">
                <Heading>9. Privacy</Heading>
                <Text>
                  Parties are obliged to treat confidentially the content of
                  this agreement, as well as all other information and data they
                  acquire in connection with their use of the marketplace or
                  partnership with THRINDLE, and not use it for purposes outside
                  the scope of this contract or pass it on to third parties.
                  This obligation is in force for 1 year after the termination
                  of the contract. Parties are obliged to follow privacy laws
                  and handle accordingly all data related to purchasers,
                  merchants and business partners.
                </Text>
              </Box>

              <Box className="terms-item" id="license">
                <Heading>10. License</Heading>
                <Ul>
                  {licenses.map((license, index) => (
                    <li key={index}>{license}</li>
                  ))}
                </Ul>
              </Box>

              <Box className="terms-item" id="termination">
                <Heading>11. Termination</Heading>
                <Ul>
                  {terminations.map((termination, index) => (
                    <li key={index}>{termination}</li>
                  ))}
                </Ul>
              </Box>

              <Box className="terms-item" id="general">
                <Heading>12. General</Heading>
                <Ul>
                  {generals.map((general, index) => (
                    <li key={index}>{general}</li>
                  ))}
                </Ul>
              </Box>
            </Box>
          </Box>
        </GeneralPadding>
      </ContainerWithBg>
    </MainContainerSlim>
  );
}

export default TermsOfService;
