import React, {useEffect, useState} from "react";
import styled from "styled-components";
import MainContainer from "../common/mainContainer/mainContainer";
import NetworkError from "../emptyStates/NetworkError";

const LoaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 90vh;
`;

const LoaderBox = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 3em;
  height: 3em;
  transform: translate(-50%, -50%);
`;

const Spin = styled.div`
  width: 100%;
  height: 100%;
  border: 2px solid #20639b;
  border-color: #20639b transparent #20639b transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Loader = ({func}) => {
  const [networkFailure, setNetworkFailure] = useState(false);

  useEffect(() => {
    let timeoutID = setTimeout(() => setNetworkFailure(true), 30000);

    return () => {
      clearTimeout(timeoutID);
    };
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MainContainer>
      <LoaderContainer>
        {!networkFailure ? (
          <LoaderBox>
            <Spin></Spin>
          </LoaderBox>
        ) : (
          <Container>
            <NetworkError func={func} />
          </Container>
        )}
      </LoaderContainer>
    </MainContainer>
  );
};

export default Loader;
