import React, {useState} from "react";
import styled from "styled-components";
import {IoMdCloseCircle} from "react-icons/io";
import {useDispatch} from "react-redux";
import {showNotification} from "../../../redux/actions/statusNotifications/index";
import {mainApi} from "../../../utils/axiosInstance";
import {useNavigate} from "react-router-dom";
import Loader from "../../../assets/images/Loader.gif";

const DeleteModalView = ({closeModal, activeProduct}) => {
  const {name, _id} = activeProduct;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deleteProduct = async () => {
    try {
      setLoading(true);
      const {data} = await mainApi.delete(`products/deleteProduct/${_id}`);
      if (data) {
        setLoading(false);
        dispatch(
          showNotification({
            notify: {
              status: 1,
              message: `${data.message}`,
            },
          }),
        );
        closeModal();
        setTimeout(() => {
          navigate("/products");
        }, 1000);
      }
    } catch (error) {
      setLoading(false);
      if (!error.response) {
        dispatch(
          showNotification({
            notify: {
              status: 2,
              message: `${error}`,
            },
          }),
        );
      } else {
        dispatch(
          showNotification({
            notify: {
              status: 2,
              message: `${error.response.data.message}`,
            },
          }),
        );
      }
    }
  };

  return (
    <MainCont className="w-full px-3 py-3 h-full relative">
      <div
        className="absolute top-5 right-5 cursor-pointer "
        onClick={closeModal}>
        <IoMdCloseCircle className="text-white-text text-2xl" />
      </div>
      <p className="text-center font-Bold text-lg pt-8">Delete Product?</p>
      <div className="w-full mx-auto mt-5">
        <p className="text-center">
          Are you sure you want to delete{" "}
          <span className="text-secondary-error font-Bold">{name}</span> from
          your store?
        </p>
        <p className="text-sm text-white-lightGray font-Bold text-center pt-5">
          This will delete the product permanently for you store records
        </p>
        <div className="w-11/12 flex flex-row items-center justify-between absolute bottom-5 mx-auto">
          <CancelButton
            onClick={closeModal}
            className="focus:outline-none outline-none flex flex-center border border-solid border-primary-light rounded-md text-base items-center justify-center py-2">
            Cancel
          </CancelButton>
          <DeleteButton
            onClick={deleteProduct}
            className="focus:outline-none outline-none flex flex-center border border-solid border-secondary-error bg-secondary-error rounded-md text-base items-center justify-center py-2 text-white-main">
            {loading ? (
              <img src={Loader} alt="Loader" className="w-5 h-5" />
            ) : (
              "Delete"
            )}
          </DeleteButton>
        </div>
      </div>
    </MainCont>
  );
};

export default DeleteModalView;

const MainCont = styled.div``;

const CancelButton = styled.button`
  width: 48%;
`;

const DeleteButton = styled.button`
  width: 48%;
`;
