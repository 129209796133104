import React from "react";
import {FaTimesCircle} from "react-icons/fa";
import styled from "styled-components";
import {numberFormat} from "../../utils/formatPrices";
import moment from "moment";

const PayoutModalView = ({closeModal, activePayout}) => {
  const {
    amount,
    account: {bank, acc_no},
    status,
    createdAt,
  } = activePayout;
  return (
    <MainCont className="w-full lg:px-10 xl:px-20 lg:py-5 xl:py-7 md:px-8 px-6 py-5">
      <div className="w-full flex flex-row justify-end">
        <FaTimesCircle
          onClick={closeModal}
          className="cursor-pointer text-white-text text-xl"
        />
      </div>
      <div className="w-full pt-3 ">
        <p className="text-center font-Bold text-lg text-white-text">
          Transaction Summary
        </p>
        {/* Single Details */}
        <div className="w-full flex flex-row items-center justify-between pt-5">
          <div>
            <p className="font-Light text-white-text text-sm md:text-base">
              Amount
            </p>
            <p className="font-Medium md:text-lg text-sm text-white-text">
              N{numberFormat(amount)}
            </p>
          </div>
          <div>
            <p className="font-Light text-white-text text-sm md:text-base">
              Date
            </p>
            <p className="font-Regular md:text-lg text-sm text-white-text">
              {moment(createdAt).format("ll")}
            </p>
          </div>
        </div>
        {/* Single Details */}
        <div className="mt-14">
          <p className="font-Light text-white-text text-sm md:text-base">
            Status
          </p>
          {status === "success" || status === "successful" ? (
            <p className="font-Medium md:text-lg text-sm text-secondary-success">
              Successful
            </p>
          ) : status === "pending" ? (
            <p className="font-Medium md:text-lg text-sm text-secondary-yellow">
              Pending
            </p>
          ) : (
            <p className="font-Medium md:text-lg text-sm text-secondary-error">
              Failed
            </p>
          )}
        </div>
        {/* Single Details */}
        <div className="mt-14">
          {status === "success" || status === "successful" ? (
            <p className="font-Light text-white-text text-sm md:text-base">
              Account Credited
            </p>
          ) : (
            <p className="font-Light text-white-text text-sm md:text-base">
              Account to be credited
            </p>
          )}

          <p className="font-Medium md:text-lg text-sm text-white-text">
            {bank} ({acc_no})
          </p>
        </div>
        <div className="w-full flex flex-row items-center mt-10 justify-center">
          <Button
            className="border border-primary-light px-10 rounded-md py-2"
            onClick={closeModal}>
            <p className="text-xs text-primary-light font-Medium">Close</p>
          </Button>
        </div>
      </div>
    </MainCont>
  );
};

export default PayoutModalView;

const MainCont = styled.div``;

const Button = styled.button``;
