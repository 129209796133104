export const StoreIcon = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 15H8V11H7V15H4V11H3V15C3 15.2652 3.10536 15.5196 3.29289 15.7071C3.48043 15.8946 3.73478 16 4 16H14C14.2652 16 14.5196 15.8946 14.7071 15.7071C14.8946 15.5196 15 15.2652 15 15V11H14V15Z"
      fill="black"
    />
    <path
      d="M16.895 6.635L14.855 2.555C14.7722 2.38839 14.6446 2.24819 14.4865 2.15014C14.3284 2.05209 14.146 2.0001 13.96 2H4.04001C3.85397 2.0001 3.67164 2.05209 3.51353 2.15014C3.35542 2.24819 3.2278 2.38839 3.14501 2.555L1.10501 6.635C1.03536 6.77475 0.999396 6.92886 1.00001 7.085V8.625C0.999551 8.85865 1.08093 9.08509 1.23001 9.265C1.45034 9.51757 1.72246 9.71974 2.02787 9.85778C2.33329 9.99582 2.66485 10.0665 3.00001 10.065C3.54689 10.0659 4.0771 9.87674 4.50001 9.53C4.92291 9.87694 5.453 10.0666 6.00001 10.0666C6.54701 10.0666 7.0771 9.87694 7.50001 9.53C7.92291 9.87694 8.453 10.0666 9.00001 10.0666C9.54701 10.0666 10.0771 9.87694 10.5 9.53C10.9229 9.87694 11.453 10.0666 12 10.0666C12.547 10.0666 13.0771 9.87694 13.5 9.53C13.9721 9.91761 14.5761 10.1071 15.185 10.0586C15.7938 10.0101 16.3602 9.72744 16.765 9.27C16.9159 9.09078 16.9991 8.86427 17 8.63V7.085C17.0006 6.92886 16.9647 6.77475 16.895 6.635ZM15 9.065C14.7864 9.0645 14.5761 9.01296 14.3865 8.91468C14.1969 8.8164 14.0335 8.67422 13.91 8.5L13.5 7.94L13.095 8.5C12.9691 8.67155 12.8047 8.81105 12.6149 8.9072C12.4251 9.00335 12.2153 9.05346 12.0025 9.05346C11.7897 9.05346 11.58 9.00335 11.3902 8.9072C11.2004 8.81105 11.0359 8.67155 10.91 8.5L10.5 7.94L10.095 8.5C9.96915 8.67155 9.80466 8.81105 9.61485 8.9072C9.42505 9.00335 9.21527 9.05346 9.00251 9.05346C8.78974 9.05346 8.57996 9.00335 8.39016 8.9072C8.20036 8.81105 8.03587 8.67155 7.91001 8.5L7.50001 7.94L7.09501 8.5C6.96915 8.67155 6.80466 8.81105 6.61485 8.9072C6.42505 9.00335 6.21528 9.05346 6.00251 9.05346C5.78974 9.05346 5.57996 9.00335 5.39016 8.9072C5.20036 8.81105 5.03587 8.67155 4.91001 8.5L4.50001 7.94L4.09001 8.5C3.96649 8.67422 3.80313 8.8164 3.61352 8.91468C3.42392 9.01296 3.21357 9.0645 3.00001 9.065C2.81158 9.06729 2.62484 9.02921 2.45235 8.95331C2.27986 8.87742 2.12562 8.76547 2.00001 8.625V7.085L4.04001 3H13.96L16 7.08V8.61C15.8751 8.75244 15.7214 8.86669 15.549 8.94514C15.3766 9.02359 15.1894 9.06445 15 9.065Z"
      fill="black"
    />
  </svg>
);
