import React from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {numberFormat} from "../../utils/formatPrices";
// import Image from "../../../assets/images/image.jpg";
// import { useLocation } from "react-router-dom";

const PRODUCT = "product";

const SingleProduct = ({
  name,
  original_price,
  images,
  onClick,
  type,
  quantity,
  to,
}) => {
  // const location = useLocation()
  // const url = location.pathname
  return (
    <div>
      <Product
        onClick={onClick}
        className="w-full bg-white-main rounded-md md:px-5 lg:px-3 px-4 py-4 overflow-hidden relative">
        {type === PRODUCT && quantity === 0 && (
          <div className="absolute w-full h-full top-0 left-0 right-0 bottom-0 sold-out flex flex-row items-center justify-center">
            <div className="sold-out-cont py-3 rounded-lg bg-white-dim">
              <p className="text-sm text-secondary-error font-ExtraBold text-center">
                Sold Out
              </p>
            </div>
          </div>
        )}

        {/*
        <div className="mb-2">
          <span className="text-white-ash text-xs productid">Product ID:</span>
          
          <span className="text-xs text-primary-light productid">
            &nbsp;{id}
          </span>
        </div>*/}

        {/* Other content */}
        <div>
          {to ? (
            <Link to={to}>
              <img
                src={`https://api.thrindle.com/api/thrindle/images/${images[0]}`}
                alt="Product"
                className="w-full productimage block rounded-md cursor-pointer"
              />
            </Link>
          ) : (
            <img
              src={`https://api.thrindle.com/api/thrindle/images/${images[0]}`}
              alt="Product"
              className="w-full productimage block rounded-md cursor-pointer"
            />
          )}
          <div className="w-full mt-3">
            {/* <p className="xl:block  hidden text-base font-SemiBold text-white-text">
              {name.substring(0, 30)}
              {name.length > 30 && "...."}
            </p>
            <p className="lg:block hidden  xl:hidden text-base font-SemiBold text-white-text">
              {name.substring(0, 20)}
              {name.length > 20 && "...."}
            </p>
            <p className="md:block hidden lg:hidden text-base font-SemiBold text-white-text">
              {name.substring(0, 35)}
              {name.length > 35 && "...."}
            </p>
            <p className="name-one sm:hidden text-base font-SemiBold text-white-text">
              {name.substring(0, 16)}
              {name.length > 16 && "...."}
            </p>
            <p className="name-two sm:hidden text-base font-SemiBold text-white-text">
              {name.substring(0, 12)}
              {name.length > 12 && "...."}
            </p>
            <p className="name-three sm:hidden text-base font-SemiBold text-white-text">
              {name.substring(0, 30)}
              {name.length > 30 && "...."}
            </p> */}
            {/* NEW */}
            <p className="xl:block  hidden text-base  capitalize font-SemiBold text-white-text">
              {name.substring(0, 30)}
              {name.length > 30 && "...."}
            </p>
            <p className="md:block hidden xl:hidden capitalize name-five text-base font-SemiBold text-white-text">
              {name.substring(0, 20)}
              {name.length > 20 && "...."}
            </p>
            {/* <p className="md:block hidden name-four capitalize lg:hidden text-base font-SemiBold text-white-text">
              {name.substring(0, 35)}
              {name.length > 35 && "...."}
            </p> */}
            <p className="name-one sm:hidden text-base capitalize font-SemiBold text-white-text">
              {name.substring(0, 16)}
              {name.length > 16 && "...."}
            </p>
            <p className="name-two sm:hidden text-base capitalize font-SemiBold text-white-text">
              {name.substring(0, 15)}
              {name.length > 15 && "...."}
            </p>
            <p className="name-three sm:hidden text-base capitalize font-SemiBold text-white-text">
              {name.substring(0, 12)}
              {name.length > 12 && "...."}
            </p>
            {/* END OF NEW TEXT */}
            <p className="text-lg font-Bold pt-1  productprice">
              N{numberFormat(original_price)}
            </p>
          </div>
          {to ? (
            <Link to={to}>
              <Button>
                {type === PRODUCT ? "View product" : "View order"}
              </Button>
            </Link>
          ) : (
            <Button onClick={onClick}>
              {type === PRODUCT ? "View product" : "View order"}
            </Button>
          )}
        </div>
      </Product>
    </div>
  );
};

export default SingleProduct;

const Product = styled.div`
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 5px 2px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 1em;

  .productid {
    font-size: 10px !important;
  }

  .productimage {
    height: 180px;
    transition: all 0.3s ease-in-out;

    @media (min-width: 768px) {
      height: 200px;
    }

    @media (min-width: 1024px) {
      height: 215px;
    }

    @media (min-width: 1440px) {
      height: 200px;
    }

    @media (max-width: 350px) {
      height: 250px;
    }
  }

  .productprice {
    color: #2f3133;
  }

  &:hover {
    .productimage {
      transform: scale(0.95);
    }
  }

  .sold-out {
    background-color: rgba(255, 255, 255, 0.7);
  }
  .sold-out-cont {
    width: 75%;
  }

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
  /* @media (min-width: 400px) {
    .name-two {
      display: none;
    }
    .name-three {
      display: none;
    }
  }
  @media (max-width: 375px) {
    .name-one {
      display: none;
    }
    .name-three {
      display: none;
    }
  }
  @media (max-width: 320px) {
    .name-one {
      display: none;
    }
    .name-two {
      display: none;
    }
    .name-three {
      display: block;
    }
  } */
  /* NEW ONE */
  @media (min-width: 1034px) {
    .name-five {
      display: none;
    }
    .name-four {
      display: none;
    }
  }
  @media (min-width: 768px) {
    .name-four {
      display: block;
    }
  }
  @media (min-width: 400px) {
    .name-two {
      display: none;
    }
    .name-three {
      display: none;
    }
  }
  @media (max-width: 400px) {
    .name-one {
      display: none;
    }
    .name-two {
      display: none;
    }
  }
  @media (max-width: 375px) {
    .name-one {
      display: none;
    }
    .name-three {
      display: block;
    }
  }
  @media (max-width: 320px) {
    .name-one {
      display: none;
    }
    .name-two {
      display: none;
    }
    .name-three {
      display: block;
    }
  }
  /* END OF NEW ONE */
`;

const Button = styled.button`
  width: 100%;
  background: rgba(22, 88, 143, 0.9);
  color: white;
  padding: 0.5em 0;
  margin-top: 1em;
  border-radius: 8px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);

  &:hover {
    background: rgba(22, 88, 143, 1);
  }
`;
