import React, {useState, useEffect} from "react";
import styled from "styled-components";
import FlexedButton from "../../../components/common/buttons/flexedButtons";
import CustomInput from "../../../components/customInput/customInput";
import {Modal} from "../../../styles/globalStyles";
import {mainApi} from "../../../utils/axiosInstance";
import InputWithFieldSet from "../../../components/common/inputs/inputWithFieldSet";
import loader from "../../../assets/svg/loader.svg";

import {useDispatch} from "react-redux";
import {showNotification} from "../../../redux/actions/statusNotifications";

const Container = styled.div`
  &.header {
    border-bottom: 1px solid #20639b;
  }
`;

const ModalContainer = styled.div`
  background: white;
  color: #20639b;
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 1;
  z-index: 5;
  transform: translate(-50%, -50%);
  border: 1px solid #20639b;
  border-radius: 12px;

  @media (min-width: 768px) {
    width: 60%;
  }

  @media (min-width: 1024px) {
    width: 50%;
  }

  @media (min-width: 1400px) {
    width: 35%;
  }
`;

function ModifyWithdrawalModal({
  setModifyPin,
  displayModal,
  setDisplayModal,
  // setPinSuccessModal,
}) {
  const [profilePassword, setProfilePassword] = useState("");
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (displayModal) {
        document.documentElement.style.overflow = "hidden";
        document.body.style.overflow = "hidden";
      } else {
        document.documentElement.style.overflow = "revert";
        document.body.style.overflow = "revert";
      }
    }

    return () => (mounted = false);
  }, [displayModal]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      window.scrollTo(0, 0);
    }

    return () => (mounted = false);
  });

  const handleCancel = () => {
    setModifyPin(false);
    setDisplayModal(false);
  };

  const changePin = async e => {
    e.preventDefault();

    const config = {
      tx_pin: pin,
      confirm_tx_pin: confirmPin,
      password: profilePassword,
    };
    try {
      setLoading(true);
      const {data} = await mainApi.post(
        `${process.env.REACT_APP_API_URL}wallets/changepin`,
        config,
      );
      setConfirmPin("");
      setPin("");
      setProfilePassword("");
      setLoading(false);
      dispatch(
        showNotification({
          notify: {
            status: 1,
            message: `${data.message}`,
          },
        }),
      );
      setTimeout(() => {
        setModifyPin(false);
      }, 2000);
    } catch (error) {
      setLoading(false);
      if (!error.response) {
        dispatch(
          showNotification({
            notify: {
              status: 2,
              message: `${error}`,
            },
          }),
        );
      } else if (error.response) {
        dispatch(
          showNotification({
            notify: {
              status: 2,
              message: `${error.response.data.message}`,
            },
          }),
        );
      }
    }
  };
  return (
    <>
      <Modal onClick={handleCancel}></Modal>
      <ModalContainer>
        <Container className="header text-primary-main flex justify-between p-4">
          Modify Pin{" "}
          <svg
            onClick={handleCancel}
            className="inline cursor-pointer"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20.5563 18.4379L20.5554 18.437L14.3531 12.3922L14.3529 12.3919L12.9363 11.0294L20.5553 3.62218C20.7135 3.46847 20.8416 3.29778 20.9222 3.10981C21.0017 2.92418 21.05 2.72201 21.05 2.51872C21.05 2.30399 21.0185 2.1004 20.9215 1.91098C20.8426 1.72722 20.7313 1.54117 20.5863 1.40022L20.5863 1.40017L20.5844 1.39844C20.4274 1.26107 20.2525 1.13696 20.0598 1.05892C19.8665 0.980635 19.6432 0.95 19.4378 0.95C19.2292 0.95 19.0221 0.996788 18.8171 1.07346L18.8171 1.07344L18.8158 1.07395C18.6231 1.152 18.4477 1.27631 18.3048 1.41525L9.55047 9.92609C9.40597 10.0666 9.29416 10.2377 9.21456 10.4234L9.21454 10.4234L9.21402 10.4247C9.13506 10.6243 9.08678 10.8261 9.08678 11.0296C9.08678 11.2328 9.135 11.435 9.21456 11.6206C9.29416 11.8064 9.40597 11.9775 9.55047 12.118L18.3048 20.6288L18.3048 20.6288L18.3058 20.6297C18.6099 20.9098 19.009 21.049 19.4223 21.049C19.838 21.049 20.237 20.8781 20.5244 20.5987C20.8273 20.3043 20.9878 19.931 20.9881 19.5263C21.0039 19.121 20.8433 18.7324 20.5563 18.4379Z"
              fill="#F02052"
              stroke="#F02052"
              strokeWidth="0.1"
            />
            <path
              d="M1.44364 3.56212L1.44455 3.56303L7.64682 9.60783L7.64705 9.60806L9.06369 10.9706L1.44459 18.3778C1.28648 18.5315 1.15831 18.7022 1.07775 18.8902C0.998189 19.0758 0.94997 19.278 0.94997 19.4813C0.94997 19.696 0.981421 19.8996 1.07844 20.089C1.15738 20.2728 1.26868 20.4588 1.41366 20.5998L1.41361 20.5998L1.41559 20.6016C1.57258 20.7389 1.74749 20.863 1.94015 20.9411C2.1334 21.0194 2.35673 21.05 2.56214 21.05C2.7707 21.05 2.97781 21.0032 3.18286 20.9265L3.18287 20.9266L3.18412 20.926C3.37679 20.848 3.55222 20.7237 3.69515 20.5847L12.4495 12.0739C12.594 11.9334 12.7058 11.7623 12.7854 11.5766L12.7854 11.5766L12.7859 11.5753C12.8649 11.3757 12.9132 11.1739 12.9132 10.9704C12.9132 10.7672 12.8649 10.565 12.7854 10.3794C12.7058 10.1936 12.594 10.0225 12.4495 9.88202L3.69515 1.37118L3.69516 1.37117L3.69417 1.37026C3.39008 1.09018 2.99089 0.951039 2.57761 0.951039C2.16198 0.951039 1.76293 1.12185 1.47553 1.40126C1.17261 1.69575 1.0121 2.06904 1.01184 2.47366C0.996089 2.87901 1.15664 3.2676 1.44364 3.56212Z"
              fill="#F02052"
              stroke="#F02052"
              strokeWidth="0.1"
            />
          </svg>
        </Container>
        <form onSubmit={changePin}>
          <div className="md:flex md:justify-center">
            <div className="px-4 pt-4 pb-8">
              <p className="relative top-2 text-white-main2">
                Enter your password
              </p>
              <div className="my-4">
                <InputWithFieldSet
                  fieldset="Current Password"
                  type="password"
                  id="currentPassword"
                  name="currentPassword"
                  autoComplete="current-password"
                  value={profilePassword}
                  onChange={e => setProfilePassword(e.target.value)}
                />
              </div>

              <p className="relative top-2 text-white-main2">
                Set new transaction pin
              </p>
              <CustomInput
                size={5}
                smallerPadding={true}
                isFive={true}
                value={pin}
                onChange={value => setPin(value)}
              />
              <p className="relative -top-2 font-Light mb-4 text-white-text text-sm">
                Pin must be only in digits
              </p>

              <p className="relative top-2 text-white-main2">
                Confirm new transaction pin
              </p>
              <CustomInput
                size={5}
                smallerPadding={true}
                isFive={true}
                value={confirmPin}
                onChange={value => setConfirmPin(value)}
              />
              <p className="relative -top-2 font-Light text-white-text text-sm">
                Pin must be only in digits
              </p>

              <FlexedButton
                firstBtnText="Cancel"
                secondBtnType="submit"
                secondBtnText={loading ? <img src={loader} /> : "Done"}
                firstBtnType="button"
                onClickCancel={handleCancel}
              />
            </div>
          </div>
        </form>
      </ModalContainer>
    </>
  );
}

export default ModifyWithdrawalModal;
