export const sections = [
  {
    title: "Introduction",
    link: "intro",
  },
  {
    title: "Scope",
    link: "scope",
  },
  {
    title: "Thrindle's Right and Obligation",
    link: "thrindleRight",
  },
  {
    title: "Merchant's Right and Obligation",
    link: "merchantRight",
  },
  {
    title: "Customer Payment",
    link: "payment",
  },
  {
    title: "Invoicing and Merchant Payment",
    link: "invoicing",
  },
  {
    title: "Commission",
    link: "commission",
  },
  {
    title: "Indemnification and Liability",
    link: "indemnify",
  },
  {
    title: "Privacy",
    link: "privacy",
  },
  {
    title: "License",
    link: "license",
  },
  {
    title: "Termination",
    link: "termination",
  },
  {
    title: "General",
    link: "general",
  },
];

export const introData = [
  "Confirm that you have obtained the necessary authority to agree to the Ts & Cs;",
  "Bind both yourself and the person, company or other legal entity that operates the business or organizational project, to these Ts & Cs; and",
  "Agree that “you” in these Ts & Cs shall reference both the individual user and the relevant person, company or legal entity unless the context requires otherwise.",
];

export const scopeData = [
  "The merchant will have the opportunity to sell their products on the marketplace. THRINDLE is entitled to accept purchases on behalf of the merchant. The service provided by THRINDLE is limited to referring customers to the merchant and accepting orders and payments on their behalf.",
  "The merchant authorises THRINDLE to accept binding orders from buyers on their behalf.",
  "THRINDLE may carry out changes to the website or service, or suspend the service, without notice.",
];

export const thrindleRightData = [
  "The merchant acknowledges that the relationship between buyers and THRINDLE is governed by the privacy policy and terms and conditions, both available on the website.",

  "THRINDLE will present on the website the products listed by the merchant. The merchant will be responsible for listing their own products.",

  "THRINDLE is authorized to accept binding sales on behalf of the merchant and will pass on order data to the merchant as well as technology allows.",

  "We do not guarantee any commercial results concerning the use of the marketplace.",

  "In order to maintain its reputation for quality and high standard of service, THRINDLE reserves the right to terminate the relationship with the merchant if the merchant repeatedly receives bad reviews or complaints or fails to comply with our recommendations.",
];

export const merchantRightData = [
  "The merchant is responsible for the provision of all the necessary information when listing a product on the marketplace. This should include but is not limited to, a detailed title and subtitle, price, quantity, picture and description . The merchant must never knowingly deceive a potential buyer by misrepresenting their product(s). The merchant must notify THRINDLE of any changes to the details of their listings while uploaded on the site.",

  "The merchant guarantees that information listed on the marketplace relating to his products satisfies all legal requirements, and in particular satisfies information requirements for consumer protection.",

  "The merchant will be responsible for keeping an up-to-date inventory of all their products listed on the marketplace.",

  "The merchant will process orders and arrange for delivery with all reasonable care the moment confirmation of sale is received through the marketplace. The delivery options and time indicated on their listing is binding, orders should be fulfilled within the indicated timeline. The merchant is required to keep his advertised products and services available to the best of his ability. Repeated stock-outs will result in the removal of the merchant and all their products from the marketplace.",

  "If the merchant cannot fulfill an order submitted to him, he must notify THRINDLE as soon as possible, and within 1 day of receiving the order at the latest.",

  "The merchant guarantees to take great care to keep up-to-date his range of products, stock count, prices and associated terms and conditions.",

  "The merchant representative is to provide THRINDLE with a copy of his/her valid Identity Card.",
];

export const customerPayment = [
  "In the case of payment before delivery, THRINDLE collects the payment for the relevant order in THRINDLE's name on behalf of the merchant and pays it out to the merchant according to the agreement between the parties",

  "In the case of payment on delivery, payment is collected by the third-party logistics company and remitted to THRINDLE on behalf of the merchant. THRINDLE will then pay out to the merchant according to the agreement between the parties.",

  "The merchant will keep receipts of deliveries to customers for at least 13 months and make those available on request. In case of problems that may cause the order to be rescinded, or in case of a delivery failure, the merchant must immediately notify THRINDLE by phone so that the credit card payment may be canceled.",

  "The merchant bears the risk of abuse of the payment medium (e.g. of credit card or debit card fraud). If a fraudulent payment has been credited to the merchant, THRINDLE reserves the right to correct the amount the merchant is invoiced to offset this payment.",
];

export const invoicing = [
  "THRINDLE's invoices may be delivered by email, online, fax, post or in person. They include THRINDLE's claims on the merchant and, if applicable, other fees.",
  "The merchant will be able to withdraw earnings from transactions on a weekly basis; every Monday.",
  "Withdrawals made by the merchant will be paid directly into the merchant’s bank account that was provided during the merchant’s onboarding/registration.",
  "In case the merchant receives the payment of sold products/services on the marketplace directly, the merchant is bound to pay the agreed commission to THRINDLE within 24 hours of receipt of such payment. In the event that the merchant fails to make such payment, THRINDLE shall deduct the sum from the merchant’s earnings.",
  "Payments would be made by bank transfer or cross cheque payable to THRINDLE.",
];

export const commissions = [
  "THRINDLE shall be entitled to a fixed percentage commission on the gross revenue from the sales made through the marketplace.",
  "The commission structure is based on 4 price bands which are set and automatically implemented by THRINDLE after the merchant has set the prices for goods listed on the marketplace.",
  "Depending on the category, it may be appropriate to agree different commission percentages for certain items or product categories.",
  "THRINDLE may start charging additional fees for the sale of goods through the marketplace at any point. These may include but are not limited to, listing fees, multiple photos fees and enhanced marketing fees. In the event of the introduction of further fees, the merchant will be notified prior to their commencement in writing and they will have the option to opt out.",
  "THRINDLE reserves the right to adjust the percentage commission, provided suitable notice is served in advance to the merchant. THRINDLE will give the merchant adequate notice of any commission changes, in writing. This does not cover adjustments that constitute a material change of the contract terms, which would require an additional agreement on the change.",
];

export const indemnifications = [
  "The merchant hereby indemnifies THRINDLE, and undertakes to keep THRINDLE indemnified, against all claims arising in relation to matters outside THRINDLE's control, including but not limited to the quality of goods and services provided by the merchant. The merchant further indemnifies THRINDLE from third parties' claims resulting from any violation of laws and regulations by the merchant.",
  "THRINDLE cannot guarantee that its service will be free from all malfunctions, but will exercise all reasonable care and skill to resolve any such case.",
  "VAT liability rests with the merchant and THRINDLE will not be responsible for any VAT issues.",
];

export const licenses = [
  "THRINDLE has the right to freely maintain the merchant's listing and its ranking on the website. THRINDLE offers customers the opportunity to give ratings and reviews of the merchant's goods and services on the website and has the right but not the obligation to publish these online and make them visible to all customers. THRINDLE reserves the right to delete ratings and reviews.",

  "THRINDLE may scan, transcribe, and publish online the merchant's listings, logos and other materials required. The merchant grants to THRINDLE a royalty- free, perpetual, unrestricted license to use and distribute any materials provided by him, for the purpose of advertising THRINDLE's service. In particular, this includes use in Google AdWords campaigns, domain name registrations and other online marketing and search engine optimization measures.",
];

export const terminations = [
  "This agreement is valid as soon as the merchant uses the marketplace or fulfills an order referred by THRINDLE, and remains valid indefinitely, until termination by either party.",

  "With respect to the relationship between the merchant and THRINDLE, termination can occur at any time, with a period of notice of one month, in writing, by email, post or fax. The revenues generated during this notice period are still subject to the agreement. The right to immediate termination by either party for an important cause remains unaffected.",

  "The right to immediate termination in particular covers the case where the merchant repeatedly receives negative ratings and reviews on the website, and when these are not obviously unjustified. Repeatedly providing misleading information or withholding information required to present the merchant's items is also grounds for immediate termination. Typos, mistakes and transmission errors are excluded from this, as long as they are not caused with intent or by gross negligence.",
];

export const generals = [
  "If a single clause in this agreement is invalid, both parties will endeavor to replace the invalid clause by a valid one that reproduces as closely as possible the intended economic meaning of the invalid clause. The validity of the rest of the agreement remains unaffected. This applies in particular if the agreement is found to be incomplete.",

  "THRINDLE reserves the right to modify its terms and conditions without giving any justification. In that case, THRINDLE may give the merchant adequate notice. The notice may contain advice on the right and period of objection to the changes, and on the consequences of leaving unexercised the right to object.",

  "The changed terms and conditions are considered agreed by the merchant if he does not object to them in writing within 2 weeks of receiving notice of the changes.",

  "Any terms and conditions of the merchant are not part of this agreement unless THRINDLE expressly agrees to adhere to them in writing.",
];
