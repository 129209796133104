import React, {useEffect} from "react";
import styled from "styled-components";
import {Link, useNavigate, useLocation} from "react-router-dom";
import {useFormik} from "formik";
import * as Yup from "yup";
import AuthContainer from "../../../components/common/authContainer/authContainer";
import Input from "../../../components/common/inputs/input";
import Button from "../../../components/common/buttons/button";
import UseAlternative from "../../../components/useAlternative/useAlternative";
import {Header, Text, Error, Span} from "../../../styles/globalStyles";
import {useSelector, useDispatch} from "react-redux";
import {login} from "../../../redux/actions/authActions";
import {showNotification} from "../../../redux/actions/statusNotifications/index";
import {CLEAR_ERROR} from "../../../redux/constants";
// import {mainApi} from "../../../utils/axiosInstance";
import {LoginIllustration} from "../../../assets/svg/login";

const Container = styled.div`
  &.login-main-container {
    width: 100%;
    background: #f5f5f5;
  }

  &.button-container {
    margin: 1.5em 0;
  }
`;

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {loading, error, message} = useSelector(state => state.userLogin);
  const history = useLocation();
  const previousRoute = history?.state?.from?.pathname | "/";

  const formik = useFormik({
    initialValues: {
      phone: "",
      password: "",
    },

    validationSchema: Yup.object({
      phone: Yup.string()
        .matches(/^\d{11}$|^[+234]\d{13}$/, "Enter a valid phone number")
        .required("Required"),
      password: Yup.string().required("Enter your password"),
    }),

    onSubmit: values => {
      dispatch(login(values.phone, values.password, navigate));
    },
  });

  // const getStoreDetails = useCallback(async () => {
    // const config = {
    //   headers: {},

    //   timeout: 30000,
    //   timeoutErrorMessage:
    //     "Couldn't connect to the server. Poor network connection",
    // };
  //   try {
  //     let res = await mainApi.get(`/stores`, config);
  //     localStorage.setItem("storeDetails", JSON.stringify(res.data.data));
  //     console.log(res.data.data);
  //   } catch (error) {
  //     throw new Error(error);
  //   }
  // }, []);

  // Status Use effect
  useEffect(() => {
    if (error && error !== "") {
      dispatch({type: CLEAR_ERROR});
      dispatch(
        showNotification({
          notify: {
            status: 2,
            message: error,
          },
        }),
      );
    }

    if (message && message !== "") {
      // getStoreDetails();
      dispatch({type: CLEAR_ERROR});
      formik.values.password = "";
      formik.values.phone = "";
      dispatch(
        showNotification({
          notify: {
            status: 1,
            message: `User ${message}`,
          },
        }),
      );
      setTimeout(() => {
        if (previousRoute && previousRoute !== "" && previousRoute !== "/otp") {
          navigate(`${previousRoute}`);
        } else {
          navigate("/products");
        }
      }, 3000);
    }
    return () => clearTimeout(3000);
  }, [
    dispatch,
    error,
    formik.values,
    message,
    navigate,
    // getStoreDetails,
    previousRoute,
  ]);

  useEffect(() => {
    if (formik.values.password === "") {
      document.getElementsByClassName("eye")[0].style.display = "none";
    } else {
      document.getElementsByClassName("eye")[0].style.display = "inline-block";
    }
  }, [formik.values.password]);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <AuthContainer image={LoginIllustration}>
      <Container className="login-form">
        <Header>Welcome Back</Header>
        <Text>Login using your email address or username</Text>

        <form onSubmit={formik.handleSubmit} className="mt-10">
          <Input
            type="tel"
            labelText="Phone"
            id="phone"
            name="phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            autoComplete="off"
          />

          <Error>
            {formik.errors.phone && formik.touched.phone ? (
              <>{formik.errors.phone}</>
            ) : null}
          </Error>

          <Input
            type="password"
            labelText="Password"
            id="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            autoComplete="current-password"
          />
          <Error>
            {formik.errors.password && formik.touched.password ? (
              <>{formik.errors.password}</>
            ) : null}
          </Error>

          <Container className="button-container">
            <Button type="submit" text="Sign In" processing={loading} />
          </Container>
        </form>

        <Container className="forgot-password">
          <Link to="/forgot-password">
            <Text light>
              Forgot Password? <Span>Click Here</Span>
            </Text>
          </Link>
        </Container>

        <UseAlternative
          text="Don't have an account?"
          link="Create Account"
          login={true}
        />
      </Container>
    </AuthContainer>
  );
}

export default Login;
