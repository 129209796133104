import React from "react";
import styled from "styled-components";
import Footer from "../footer/footer";
import NavBar from "../navBar/navBar";
import StoreNavDetails from "../navBar/StoreNavContainer";

const Main = styled.main``;

const MainContainerSlim = ({children, storeNav, fixed}) => {
  return (
    <Main>
      <NavBar fixed={fixed}/>
      {storeNav && <StoreNavDetails />}
      {children}
      <Footer />
    </Main>
  );
};

export default MainContainerSlim;
