import React from "react";
import styled from "styled-components";

const SingleProductColor = ({name}) => {
  return <MainCont name={name} className="mr-2"></MainCont>;
};

export default SingleProductColor;
const MainCont = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background-color: ${({name}) => (name ? `${name}` : "#C2C2C2")};
`;
