import React from "react";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";

const Container = styled.div`
  &.no-account {
    margin-top: 1em;
  }
`;

const Text = styled.p`
  font-family: var(--font-light);
  font-size: 14px;

  @media (min-width: 768px) {
    text-align: center;
  }

  @media (min-width: 1440px) {
    text-align: start;
  }
`;

const Span = styled.span`
  color: rgba(22, 108, 180, 0.9);
  cursor: pointer;
  font-family: var(--font-bold);

  &:hover {
    color: rgba(22, 108, 180, 1);
  }
`;

const UseAlternative = ({text, link, login, signUp}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (signUp === true) {
      navigate("/login");
    }

    if (login === true) {
      navigate("/signUp");
    }
  };

  return (
    <Container className="no-account">
      <Text>
        {text} <Span onClick={handleClick}>{link}</Span>
      </Text>
    </Container>
  );
};

export default UseAlternative;
