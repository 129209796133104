import React, {useState, useEffect, useCallback} from "react";
import MainContainer from "../../../components/common/mainContainer/mainContainer";
import styled from "styled-components";
import FieldSetInput from "../../../components/common/inputs/FieldSetInput";
import { useNavigate } from "react-router-dom";
import TextArea from "../../../components/common/inputs/textarea";
import {MdOutlineError, MdPhotoCamera} from "react-icons/md";
import {useDispatch} from "react-redux";
import {showNotification} from "../../../redux/actions/statusNotifications";
import {mainApi} from "../../../utils/axiosInstance";
import Loader from "../../../assets/images/Loader.gif";
import Button from "../../../components/common/buttons/button";
import {mainApiShop} from "../../../utils/axiosInstance";
import {toast} from "react-toastify";
import ShippinFeeModal from "../../../components/common/modal/ShippinFeeModal";
import loader from "../../../components/Loader/loader.gif";
import info from '../../../assets/images/info.png'
const EditStore = () => {
  const storeDetails = JSON.parse(localStorage.getItem("storeDetails"));
  const {
    store_name,
    description,
    store_logo,
    store_address,
    store_link,
    store_id,
  } = storeDetails[0];
  const dispatch = useDispatch();
  const [storeTitle, setStoreTitle] = useState("");
  const [storeDescription, setStoreDescription] = useState("");
  const [storeAddress, setStoreAddress] = useState("");
  const [logo, setLogo] = useState(null);
  const [changeLogoLoading, setChangeLogoLoading] = useState(false);
  const [updateStoreLoading, setUpdateStoreLoading] = useState(false);
  const [storeLink, setStoreLink] = useState("");
  const [showStoreNameMsg, setShowStoreNameMsg] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [data, setData] = useState([]);
  const [editModal, setEditModal] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [activeId, setActiveId] = useState();
  const [loading, setLoading] = useState(false);


  const navigate = useNavigate()

  // storeDetails

   const getStoreDetails = useCallback(async () => {
    const config = {
      headers: {},

      timeout: 30000,
      timeoutErrorMessage:
        "Couldn't connect to the server. Poor network connection",
    };
    try {
      let res = await mainApiShop.get(`/stores`, config);
      localStorage.setItem("storeDetails", JSON.stringify(res.data.data));
      console.log(res.data.data);
    } catch (error) {
      throw new Error(error);
    }
  }, []);
  

  

  // delivery price and region

  const priceList = useCallback(async () => {
    setLoading(true);
    try {
      const response = await mainApiShop.get(`deliveryprices/${store_name}`);
      setData(response.data.data);
      console.log(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [store_name, data]);

  const [region, setRegion] = useState("");
  const [price, setPrice] = useState("");

  const editPrice = async () => {
    setIsEditing(true);
    try {
      const response = await mainApiShop.put(
        `deliveryprices/edit/${activeId}`,
        {region, price},
      );
      getStoreDetails()
      console.log(response);
      setIsEditing(true);

      toast.success("Delivery Price Updated successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      priceList();
      setEditModal(false);
      setIsEditing(false);
    } catch (error) {
      console.log(error);
      setEditModal(false);
      setIsEditing(false);

      toast.error("An Error occurred, please try again", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
    let subscribed = true;
    if (subscribed) {
      priceList();
    }
    return () => (subscribed = false);
  }, []);

  // delete price

  const deletePrice = async id => {
    setDeleting(true);
    try {
      const response = await mainApiShop.delete(`deliveryprices/delete/${id}`);
      console.log(response);
      setDeleting(false);
      toast.success("Delivery Price deleted successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      getStoreDetails()
      priceList();
    } catch (error) {
      console.log(error);
      setDeleting(false);
      toast.error("An Error occurred, please try again", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // Decide whether to show name message or not
  const toggleNameMsg = () => {
    setShowStoreNameMsg(true);
    setTimeout(() => {
      setShowStoreNameMsg(false);
    }, 1500);
  };

  // CHOOSE IMAGE FUNCTIONS
  const chooseImage = e => {
    let newImage = e.target.files[0];
    if (newImage.type.startsWith("image")) {
      setLogo(newImage);
    } else {
      dispatch(
        showNotification({
          notify: {
            status: 2,
            message: "Please select a file of image type",
          },
        }),
      );
    }
  };
  // SET DEFAULT VALUES ON PAGE LOAD
  useEffect(() => {
    setStoreTitle(store_name);
    setStoreDescription(description);
    setStoreAddress(store_address);
    setLogo(store_logo);
    setStoreLink(store_link);
  }, [store_name, description, store_address, store_logo, store_link]);

  // CHANGE PROFILE PIC FUNCTIONS
  const changeProfilePic = async () => {
    let formData = new FormData();
    formData.append("logo", logo);
    const config = {
      url: `stores/updatelogo/${storeDetails[0].store_id}`,
      method: "put",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    try {
      setChangeLogoLoading(true);

      const {data} = await mainApi(config);
      if (data) {
        setChangeLogoLoading(false);
        dispatch(
          showNotification({
            notify: {
              status: 1,
              message: `${data.message}`,
            },
          }),
        );
        let res = await mainApi.get("stores");
        if (res) {
          setLogo(res.data.data[0].store_logo);
          console.log(res, "RES");
        }
      }
    } catch (error) {
      setChangeLogoLoading(false);
      if (!error.response) {
        dispatch(
          showNotification({
            notify: {
              status: 2,
              message: `${error}`,
            },
          }),
        );
      } else if (error.response) {
        dispatch(
          showNotification({
            notify: {
              status: 2,
              message: `${error.response.data.message}`,
            },
          }),
        );
      }
    }
  };
  // console.log(storeDescription, storeAddress, storeLink);

  // UPDATE STORE PROFILE
  const changeStoreDetails = async e => {
    e.preventDefault();
    if (storeDescription === "" || storeAddress === "" || storeLink === "") {
      dispatch(
        showNotification({
          notify: {
            status: 2,
            message: `Store description or store address or store link cannot be empty`,
          },
        }),
      );
    } else if (
      description !== storeDescription ||
      store_address !== storeAddress ||
      storeLink !== store_link
    ) {
      try {
        setUpdateStoreLoading(true);
        const {data} = await mainApi.put(`stores/updateStore`, {
          description: storeDescription,
          store_address: storeAddress,
          store_link: storeLink,
        });
        if (data) {
          setUpdateStoreLoading(false);
          dispatch(
            showNotification({
              notify: {
                status: 1,
                message: `${data.message}`,
              },
            }),
          );
          let res = await mainApi.get("stores");
          if (res) {
            console.log(res, "Hello");
            localStorage.setItem("storeDetails", JSON.stringify(res.data.data));
            setStoreDescription(res?.data?.data[0].description);
            setStoreAddress(res?.data?.data[0].store_address);
            setStoreLink(res?.data?.data[0]?.store_link);
          }
        }
      } catch (error) {
        setUpdateStoreLoading(false);
        if (!error.response) {
          dispatch(
            showNotification({
              notify: {
                status: 2,
                message: "Please check that you're connected",
              },
            }),
          );
        } else if (error.response) {
          dispatch(
            showNotification({
              notify: {
                status: 2,
                message: `${error.response.data.message}`,
              },
            }),
          );
        }
      }
    } else {
      dispatch(
        showNotification({
          notify: {
            status: 0,
            message: `Nothing to update here`,
          },
        }),
      );
    }
  };

  return (
    <MainContainer storeNav>
      <MainCont className="pt-20 pb-20  xl:w-1/3 md:w-3/5 lg:w-1/2 mx-auto">
        <div className="w-full mx-auto relative">
          {/* Empty STore logo */}
          {logo === null || logo === undefined ? (
            <div className="emptyLogo bg-primary-light flex flex-row items-center justify-center mx-auto">
              <p className=" text-7xl font-Bold text-white-main uppercase">
                {storeDetails[0]?.store_name[0]}
              </p>
            </div>
          ) : (
            <img
              src={
                typeof logo === "object"
                  ? URL.createObjectURL(logo)
                  : `${process.env.REACT_APP_API_URL_IMAGES}${logo}`
              }
              alt="Store Logo"
              className="block emptyLogo mx-auto border border-gray-400 border-solid"
            />
          )}
          <div className="absolute right-[30%] md:right-[35%] xl:right-[37%] top-[80%]">
            {typeof logo === "object" ? (
              <div className="w-full mx-auto py-4  mt-5 mr-2">
                {changeLogoLoading ? (
                  <img
                    className="block mr-10 md:mr-14 w-10 h-10"
                    src={Loader}
                    alt="Loader"
                  />
                ) : (
                  <p
                    onClick={changeProfilePic}
                    className="text-primary-light font-Bold text-base  cursor-pointer">
                    Tap to save
                  </p>
                )}
              </div>
            ) : (
              <div className="absolute cursor-pointer right-[46%] top-[40%] bg-white-dim rounded-full w-8 h-8 flex flex-row items-center justify-center">
                <div className="cursor-pointer py-4 w-full relative  mx-auto ">
                  <MdPhotoCamera className="text-2xl text-black ml-1 cursor-pointer" />

                  <input
                    type="file"
                    className="file-cont absolute top-3 w-full opacity-0 cursor-pointer"
                    onChange={chooseImage}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <form onSubmit={changeStoreDetails} className="w-full mt-10">
          {showStoreNameMsg && (
            <div className="flex flex-row items-center justify-end">
              <MdOutlineError className="text-xs text-secondary-error mr-2" />
              <p className="text-xs font-Bold text-white-text">
                Store name is not editable
              </p>
            </div>
          )}

          <div onClick={toggleNameMsg}>
            <FieldSetInput
              type="text"
              id="storeName"
              name="storeName"
              fieldset="Store Name"
              placeholder=""
              inputMode="text"
              value={storeTitle}
              onChange={val => setStoreTitle(val)}
              disabled={true}
            />
          </div>
          <div className="w-full mt-5">
            <TextArea
              type="text"
              id="storeDescription"
              name="storeDescription"
              fieldset="Store Description"
              placeholder=""
              inputMode="text"
              value={storeDescription}
              onChange={val => setStoreDescription(val)}
              noFormik
            />
          </div>
          <div className="w-full mt-5">
            <FieldSetInput
              type="text"
              id="storeAddress"
              name="storeAddress"
              fieldset="Store Address"
              placeholder=""
              inputMode="text"
              value={storeAddress}
              onChange={val => setStoreAddress(val)}
            />
          </div>
          <div className="w-full mt-5">
            <FieldSetInput
              type="text"
              id="storeLink"
              name="storeLink"
              fieldset="Store Link"
              placeholder="Unique store name link"
              inputMode="text"
              value={storeLink}
              onChange={val => setStoreLink(val)}
            />
            {store_id.startsWith("TM") ? (
              <p className="text-xs text-primary-light pt-2">
                {storeLink ? <span>{storeLink}</span> : <span>Storename</span>}
                .thrindle.shop (No spaces allowed)
              </p>
            ) : (
              <p className="text-xs text-primary-light pt-2">
                {storeLink ? <span>{storeLink}</span> : <span>Storename</span>}
                .thrindle.store (No spaces allowed)
              </p>
            )}
          </div>

          {editModal && (
            <ShippinFeeModal
              onClick={() => setEditModal(false)}
              event={editPrice}
              priceChange={e => setPrice(e)}
              regionChange={e => setRegion(e)}
              price={price}
              region={region}
              // loading={loading}
              header="Edit Delivery Fee"
              text="Update"
            />
          )}
          {isEditing && (
            <div
              className="fixed top-0 right-0 left-0 bottom-0"
              style={{
                backgroundColor: "rgba(0,0,0, 0.5)",
                zIndex: "1000",
              }}></div>
          )}

          {deleting && (
            <div
              className="fixed top-0 right-0 left-0 bottom-0"
              style={{
                backgroundColor: "rgba(0,0,0, 0.5)",
                zIndex: "1000",
              }}></div>
          )}
          {/* Delivey Price */}
          {store_id.startsWith("TM")  &&  <div className="mt-10">
            <p className="font-bold text-2xl mb-10">Delivery Details </p>
            {!loading && data.length === 0 && (
              <div className="md:flex justify-between items-center md:bg-[#F0F1F5] py-4 rounded px-6">
                <div className="flex gap-2 items-center">
                  <img src={info} alt='' className="h-8 w-8" />
                  <p className="text-sm">You do not have a delivery preference setup.</p>
                </div>
                <button  onClick={() =>navigate(-1)} className="px-3 py-2 rounded-[3px] w-full md:w-20 mt-4 md:mt-0  flex justify-center items-center text-sm bg-primary-light text-white-main">
                  Set Up
                </button>
              </div>
            )}
            {loading && (
              <div className="flex justify-center items-center">
                <img src={loader} alt="" />
              </div>
            )}
            {!loading &&
              data.length !== 0 && <div className="flex justify-between">
                <p className="font-bold text-lg">Region</p>
                <p className="font-bold text-lg">Price</p>
                <p></p>
                <p></p>
              </div>}
            {!loading &&
              data.length !== 0 &&
              data.map((item, index) => (
                <div className=" flex justify-between mt-5 " key={index}>
                  <p>{item.region}</p>

                  <p>N {item.price.toLocaleString()}.00</p>
                  <div className="flex gap-4">
                    <p
                      className="text-primary-light"
                      onClick={() => {
                        setEditModal(true);
                        setActiveId(item?.id);
                        setPrice(String(item.price));
                        setRegion(item.region);
                      }}>
                      Edit
                    </p>
                    <p
                      className="text-red-400"
                      onClick={() => {
                        deletePrice(item?.id);
                      }}>
                      delete
                    </p>
                  </div>
                </div>
              ))}
          </div>}
         

          <div className="w-full mx-auto mt-14">
            <Button
              text="Save"
              type="submit"
              processing={updateStoreLoading}
              longButton
            />
          </div>
        </form>
      </MainCont>
    </MainContainer>
  );
};

export default EditStore;

const MainCont = styled.div`
  .emptyLogo {
    width: 150px;
    height: 150px;
    border-radius: 150px;
  }
`;

// {
//   typeof logo === "object" ? (
//     <div className="w-full mx-auto py-4 absolute right-[46%] top-[40%]">
//       {changeLogoLoading ? (
//         <img className="block mx-auto w-10 h-10" src={Loader} alt="Loader" />
//       ) : (
//         <p
//           onClick={changeProfilePic}
//           className="text-primary-light font-Bold text-base text-center cursor-pointer">
//           Tap to save
//         </p>
//       )}
//     </div>
//   ) : (
//     <div className="absolute cursor-pointer right-[46%] top-[40%] bg-white-dim rounded-full w-8 h-8 flex flex-row items-center justify-center">
//       <div className="cursor-pointer py-4 w-full relative  mx-auto ">
//         <MdPhotoCamera className="text-2xl text-black ml-1 cursor-pointer" />

//         <input
//           type="file"
//           className="file-cont absolute top-3 w-full opacity-0 cursor-pointer"
//           onChange={chooseImage}
//         />
//       </div>
//     </div>
//   );
// }
