import React, {useState, useEffect, useCallback} from "react";
import styled from "styled-components";
import Button from "../../../components/common/buttons/button";
// import CustomInput from "../../../components/common/inputs/input";
import MainContainerWithBg from "../../../components/common/mainContainer/mainContainerWithBg";
import WalletModal from "../../../components/common/modal/walletModal/WalletModal";
import StoreBalanceContainer from "../../../components/storeScreen/StoreBalanceContainer";
import AccountDetailContainer from "../../../components/wallet/AccountDetailContainer";
import VerifyAccountModal from "../VerifyAccountModal";
import {useSelector, useDispatch} from "react-redux";
import {showNotification} from "../../../redux/actions/statusNotifications";
import InputWithFieldSet from "../../../components/common/inputs/FieldSetInput";
import {mainApi} from "../../../utils/axiosInstance";
import {STORE_USER_WALLET} from "../../../redux/constants";
import MainLoader from "../../../components/emptyStates/MainNetworkError";
import ErrorView from "../../../components/emptyStates/ErrorView";

const WithdrawalScreen = () => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState("");
  const [pin, setPin] = useState("");
  const [verifyModal, setVerifyModal] = useState(false);
  const {wallet} = useSelector(state => state.storeWallet);
  const [loading, setLoading] = useState(false);
  const [walletError, setWalletError] = useState("");
  const [walletLoading, setWalletLoading] = useState(false);
  // Withdraw Money
  const withdrawMoney = async () => {
    if (wallet?.account_verify === false) {
      setVerifyModal(true);
    } else if (amount === "") {
      dispatch(
        showNotification({
          notify: {
            status: 2,
            message: `Please enter the amount you want to withdraw`,
          },
        }),
      );
    } else if (pin === "") {
      dispatch(
        showNotification({
          notify: {
            status: 2,
            message: `Please enter your withdrawal pin`,
          },
        }),
      );
    } else if (wallet?.balance === 0) {
      dispatch(
        showNotification({
          notify: {
            status: 2,
            message: `Insufficient funds`,
          },
        }),
      );
    } else {
      try {
        setLoading(true);
        const {data} = await mainApi.post("withdrawal", {
          tx_pin: pin,
          amount,
          account: wallet.account,
        });
        if (data) {
          setLoading(false);
          setPin("");
          setAmount("");
          dispatch(
            showNotification({
              notify: {
                status: 1,
                message: `${data.message}`,
              },
            }),
          );
          let res = await mainApi.get(`/wallets/getone`);
          if (res) {
            dispatch({type: STORE_USER_WALLET, payload: res.data.data});
          }
        }
      } catch (error) {
        setLoading(false);
        if (!error.response) {
          dispatch(
            showNotification({
              notify: {
                status: 2,
                message: "Please check that you're connected",
              },
            }),
          );
        } else if (error.response) {
          dispatch(
            showNotification({
              notify: {
                status: 2,
                message: `${error.response.data.message}`,
              },
            }),
          );
        }
      }
    }
  };

  // GET WALLETS
  const getWallets = useCallback(async () => {
    const config = {
      headers: {},

      timeout: 30000,
      timeoutErrorMessage:
        "Couldn't connect to the server. Poor network connection",
    };
    try {
      setWalletLoading(true);
      let res = await mainApi.get(`wallets/getone`, config);
      if (res) {
        setWalletLoading(false);
        // localStorage.setItem("storeDetails", JSON.stringify(res.data.data));
        dispatch({type: STORE_USER_WALLET, payload: res.data.data});
      }
    } catch (error) {
      setWalletLoading(false);
      if (!error.response) {
        setWalletError("Please check that you're connected");
      } else {
        setWalletError(error.response.data.message);
      }
    }
  }, [dispatch]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getWallets();
    }

    return () => {
      mounted = false;
    };
  }, [getWallets]);

  if (walletLoading) {
    return <MainLoader onClick={getWallets} />;
  } else if (walletError) {
    return <ErrorView onClick={() => getWallets()} error={walletError} />;
  } else if (!walletLoading && !walletError && wallet) {
    return (
      <MainContainerWithBg>
        <div className="pt-20"></div>
        <WalletModal
          visible={verifyModal}
          closeModal={() => setVerifyModal(false)}>
          <VerifyAccountModal closeModal={() => setVerifyModal(false)} />
        </WalletModal>
        <MainCont className="xl:w-2/6 mx-auto  pb-20">
          <StoreBalanceContainer noWithdrawBtn={true} />
          <p className="text-primary-light font-ExtraBold text-lg pt-3">
            How much do you want to Withdraw from your (Store Wallet) ?
          </p>
          <div className="w-full pt-5">
            <div className="mb-2">
              <InputWithFieldSet
                type="number"
                id="amount"
                name="Amount"
                fieldset="Amount"
                placeholder="Enter amount "
                inputMode="numeric"
                value={amount}
                onChange={val => setAmount(val)}
              />
            </div>
            <div className="mb-2">
              <InputWithFieldSet
                type="number"
                id="pin"
                name="Pin"
                fieldset="Pin"
                placeholder="withdrawal pin"
                inputMode="numeric"
                value={pin}
                onChange={val => setPin(val)}
              />
            </div>
          </div>
          <p className="text-white-text text-base pt-2 font-Bold mb-2">
            Withdraw To
          </p>
          <AccountDetailContainer
            loading={walletLoading}
            onClick={() => setVerifyModal(true)}
            wallet={wallet}
          />
          <div className="mt-14 mx-auto w-full justify-center flex flex-row">
            <Button
              processing={loading}
              text="Request Payout"
              type="submit"
              onClick={withdrawMoney}
            />
          </div>
        </MainCont>
      </MainContainerWithBg>
    );
  }
};

export default WithdrawalScreen;

const MainCont = styled.div``;
