import React from "react";
import styled from "styled-components";
import SingleDropdownSelect from "../../../components/common/dropdownSelects/SingleDropdownSelect";

const DropdownContent = ({
  data,
  value,
  chooseValue,
  closeModal,
  loading,
  error,
  getData,
}) => {
  const chooseMarket = (val, val1) => {
    chooseValue(val, val1);
    closeModal();
  };
  return (
    <MainCont className="w-full  pt-2">
      {loading && (
        <div className="w-full h-full py-5 px-5 flex flex-row items-center justify-center">
          <p className="text-white-lightGray text-base text-center font-Bold">
            loading markets...
          </p>
        </div>
      )}
      {error && (
        <div className="w-full h-full py-5 px-5 flex flex-col items-center justify-center">
          <p className="text-secondary-error text-base text-center font-Bold">
            {error}
          </p>
          <p
            onClick={getData}
            className="underline text-primary-light text-base cursor-pointer">
            Try Again
          </p>
        </div>
      )}
      {data &&
        data.map((item, index) => (
          <SingleDropdownSelect
            name={item.name}
            key={index}
            value={value}
            onClick={() => chooseMarket(item.name, item.id)}
          />
        ))}
    </MainCont>
  );
};

export default DropdownContent;

const MainCont = styled.div``;
