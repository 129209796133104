import React from "react";
import {Link} from "react-router-dom";

const IconWithText = ({label, path, icon}) => {
  return (
    <div className="py-2 font-Light">
      <Link to={path}>
        <span className="mr-1">{label}</span>
        <span className="inline-block ml-1 align-sub">{icon}</span>
      </Link>
    </div>
  );
};

export default IconWithText;
