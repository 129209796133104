import React, {useState, useEffect} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import InputWithFieldSet from "../../../components/common/inputs/inputWithFieldSet";
import MainContainer from "../../../components/common/mainContainer/mainContainer";
import {Text} from "../../../styles/globalStyles";
import Button from "../../../components/common/buttons/button";
import wallet from "../../../assets/images/wallet.png";
// import Dropdown from "../../../components/common/inputs/dropdown";
import DropdownInputs from "../../../components/common/inputs/DropdownInputs";
import CustomInput from "../../../components/customInput/customInput";
import WalletDropdownContent from "./WalletDropdownContent";
import {mainBanks} from "../../../data/productionDatas";
import {useSelector, useDispatch} from "react-redux";
import {showNotification} from "../../../redux/actions/statusNotifications";
import {create_Store} from "../../../redux/actions/storeActions";
import {CLEAR_DETAILS, CLEAR_ERROR} from "../../../redux/constants";
import {useNavigate} from "react-router-dom";
import MobileBackBtn from "../../../components/common/buttons/MobileBackBtn";

function StoreWallet() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      accountNo: "",
      accountName: "",
    },

    validationSchema: Yup.object({
      accountNo: Yup.number().required("Required"),
      accountName: Yup.string().required("Required"),
    }),

    onSubmit: values => {
      if (bank === "") {
        dispatch(
          showNotification({
            notify: {
              status: 2,
              message: "Please select you bank",
            },
          }),
        );
      } else if (pin === "") {
        dispatch(
          showNotification({
            notify: {
              status: 2,
              message: "Please set your transaction pin",
            },
          }),
        );
      } else if (pin.length !== 5) {
        dispatch(
          showNotification({
            notify: {
              status: 2,
              message: "Transaction pin should have 5 digits",
            },
          }),
        );
      } else {
        dispatch(
          create_Store(
            shopTitle,
            shopDescription,
            shopAddress,
            marketId,
            shopLogo,
            values.accountName,
            values.accountNo,
            bank,
            pin,
            shopNIN,
            storeLink,
            storeState,
          ),
        );
      }
    },
  });

  const [show, setShow] = useState(false);
  const [bank, setBank] = useState("");
  const [pin, setPin] = useState("");

  const storeMarketType = useSelector(state => state.storeMarketType);
  const storeSellerDetails = useSelector(state => state.storeSellerDetails);
  const createStore = useSelector(state => state.createStore);
  const {message, error, loading} = createStore;
  const {
    shopTitle,
    shopAddress,
    shopDescription,
    shopNIN,
    shopLogo,
    storeLink,
    storeState,
  } = storeSellerDetails;
  const {marketId} = storeMarketType;

  useEffect(() => {
    if (error && error !== "") {
      dispatch(
        showNotification({
          notify: {
            status: 2,
            message: error,
          },
        }),
      );
      dispatch({type: CLEAR_ERROR});
    }
    if (message && message !== "") {
      dispatch(
        showNotification({
          notify: {
            status: 1,
            message: message,
          },
        }),
        dispatch({type: CLEAR_DETAILS}),
      );

      navigate("/store-created");
    }
  }, [error, dispatch, message, navigate]);
  return (
    <MainContainer>
      <Container className="main">
        <Container className="main-form">
          <div className="mt-10 w-full">
            <MobileBackBtn to="/store-details" />
          </div>
          <Text className="header">Store Wallet</Text>

          <form onSubmit={formik.handleSubmit}>
            <Container className="input-container">
              <InputWithFieldSet
                type="number"
                id="accountNo"
                name="accountNo"
                fieldset="Account No"
                placeholder="0123456789"
                inputMode="numeric"
                value={formik.values.accountNo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.accountNo && formik.touched.accountNo && (
                <p className="text-sm mt-1 text-secondary-error">Required</p>
              )}
            </Container>

            <Container className="input-container">
              <InputWithFieldSet
                type="text"
                id="accountName"
                name="accountName"
                fieldset="Account Name"
                placeholder="Yinka Olalere"
                value={formik.values.accountName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.accountName && formik.touched.accountName && (
                <p className="text-sm mt-1 text-secondary-error">Required</p>
              )}
            </Container>

            <Container className="input-container">
              <DropdownInputs
                visible={show}
                toggleModal={() => setShow(!show)}
                closeModal={() => setShow(false)}
                label="Bank Name"
                value={bank}
                placeholder="Select your bank">
                <WalletDropdownContent
                  data={mainBanks}
                  value={bank}
                  chooseValue={val => {
                    setBank(val);
                  }}
                  closeModal={() => setShow(false)}
                />
              </DropdownInputs>
            </Container>

            <Text className="header">Transaction PIN</Text>
            <p className="text-white-main2 my-2 md:text-center lg:text-left">
              Use your transaction pin to perform all withdrawal operations and
              request
            </p>

            <CustomInput
              size={5}
              smallerPadding={true}
              value={pin}
              isFive={true}
              onChange={val => setPin(val)}
              onBlur={formik.handleBlur}
            />

            <Container className="button-container">
              <Button
                type="submit"
                text="Activate Store"
                arrow={true}
                processing={loading}
                longButton={true}
              />
            </Container>
          </form>
        </Container>

        <Image src={wallet} alt="storeDetails" />
      </Container>
    </MainContainer>
  );
}

export default StoreWallet;

const Container = styled.div`
  &.input-container {
    margin: 1em 0;
  }

  &.button-container {
    margin: 2em 0;
  }

  &.main {
    @media (min-width: 1024px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 1em 0;
    }
  }

  &.main-form {
    @media (min-width: 1024px) {
      width: 40%;
    }
  }
`;

const Image = styled.img`
  margin: 2em 0;

  @media (min-width: 1024px) {
    width: 50%;
  }
`;
