const SupportData = [
  {
    title: "Guideline Changes",
    message:
      "We may modify the following guidelines subsequently. Any changes that concern the merchant will be effectively communicated via email, SMS, or an annoucement on the Thrindle Seller App. Merchants are required to ensure that the contact details on their profiles are up-to-date",
    plusIcon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16 16.0006H5.33333M16 26.6673V16.0006V26.6673ZM16 16.0006V5.33398V16.0006ZM16 16.0006H26.6667H16Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    ),
    minusIcon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M26.6667 16H5.33333"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    ),
    id: "1",
  },

  {
    title: "Commissions",
    message:
      "Thrindle has a comprehensible commission structure based on four (4) price bands. These commissions are set and implemented automatically by Thrindle after the merchants have set their prices.",
    plusIcon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16 16.0006H5.33333M16 26.6673V16.0006V26.6673ZM16 16.0006V5.33398V16.0006ZM16 16.0006H26.6667H16Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    ),
    minusIcon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M26.6667 16H5.33333"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    ),
    id: "2",
  },
  {
    title: "Product creation guidelines",
    message:
      "Merchants are responsible for creating product images and uploading them on the Thrindle Seller app. Unless these product images meet Thrindle’s set requirements, they will be flagged and will not go live. Image guidelines",
    plusIcon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16 16.0006H5.33333M16 26.6673V16.0006V26.6673ZM16 16.0006V5.33398V16.0006ZM16 16.0006H26.6667H16Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    ),
    minusIcon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M26.6667 16H5.33333"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    ),
    id: "3",
    list: [
      "Merchants can upload up to 6 images for each product in Jpeg and PNG formats.",
      "The first image, which is the main image, will be the front view of the product.",
      "Watermarked images are not allowed.",
    ],  
  },
  {
    title: "  Logistics & Fulfillment",
    message:
      "Thrindle will pick up orders from each Merchant, pack and ship items directly to customers via a third party logistics partner.",
    plusIcon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16 16.0006H5.33333M16 26.6673V16.0006V26.6673ZM16 16.0006V5.33398V16.0006ZM16 16.0006H26.6667H16Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    ),
    minusIcon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M26.6667 16H5.33333"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    ),
    id: "4",
  },
  {
    title: "Return Guidelines",
    message:
      "Wrong, damaged, defective or counterfeit items will be sent to our designated return hubs by the customer. Customers will initiate return within 24 hours of delivery and the merchant will in turn be contacted by Thrindle for pickup. Once the merchant has been notified of the returned item, the merchant has 7 days to pick up from the hub or these items will be considered forfeited by the merchant.",
    plusIcon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16 16.0006H5.33333M16 26.6673V16.0006V26.6673ZM16 16.0006V5.33398V16.0006ZM16 16.0006H26.6667H16Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    ),
    minusIcon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M26.6667 16H5.33333"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    ),
    id: "5",
  },
  {
    title: "Payment Terms & Conditions and Schedule",
    message:
      "Merchants will receive payment every Monday. This payment will be for only completed orders in the past week. For example, if an order placed on Sunday is yet to be shipped to the customer, the merchant will not receive payment for this order.",
    plusIcon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16 16.0006H5.33333M16 26.6673V16.0006V26.6673ZM16 16.0006V5.33398V16.0006ZM16 16.0006H26.6667H16Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    ),
    minusIcon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M26.6667 16H5.33333"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    ),
    id: "6",
  },
  {
    title: "Sales and Order Regulations",
    message:
      "Thrindle has high operational standards for all merchants joining the platform. Products offered to our customers must be authentic as Thrindle has ZERO tolerance for the sale of fake products, including products that have been illegally replicated, reproduced, or manufactured. Merchants will be delisted and barred indefinitely from the app if they flout this rule.",
    plusIcon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16 16.0006H5.33333M16 26.6673V16.0006V26.6673ZM16 16.0006V5.33398V16.0006ZM16 16.0006H26.6667H16Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    ),
    minusIcon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M26.6667 16H5.33333"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    ),
    id: "7",
  },
  {
    title: "Sensitive Goods Policies",
    message:
      "Merchants must add the following product creation requirement in the description of goods within the sensitive goods’ category.",
    plusIcon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16 16.0006H5.33333M16 26.6673V16.0006V26.6673ZM16 16.0006V5.33398V16.0006ZM16 16.0006H26.6667H16Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    ),
    minusIcon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M26.6667 16H5.33333"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    ),
    id: "8",
  },
];

export default SupportData;
