// Auth Constants
export const USER_LOGIN_LOADING = "USER_LOGIN_LOADING";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const USER_REGISTER_LOADING = "USER_REGISTER_LOADING";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";
export const STORE_REGISTER_DETAILS = "STORE_REGISTER_DETAILS";
export const CLEAR_DETAILS = "CLEAR_DETAILS";
export const STORE_PHONE_NUMBER_LOGIN = "STORE_PHONE_NUMBER_LOGIN";
export const CLEAR_PHONE_NUMBER_LOGIN = "CLEAR_PHONE_NUMBER_LOGIN";
export const STORE_LOGIN_NUMBER = "STORE_LOGIN_NUMBER";

//OTP CONSTANTS
export const VERIFY_NUMBER_LOADING = "VERIFY_NUMBER_LOADING";
export const VERIFY_NUMBER_SUCCESS = "VERIFY_NUMBER_SUCCESS";
export const VERIFY_NUMBER_FAIL = "VERIFY_NUMBER_FAIL";
export const RESEND_OTP_LOADING = "RESEND_OTP_LOADING";
export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS";
export const RESEND_OTP_FAIL = "RESEND_OTP_FAIL";

// UPDATE PASSWORD
export const FORGET_PASSWORD_LOADING = "FORGET_PASSWORD_LOADING";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_FAIL = "FORGET_PASSWORD_FAIL";
export const RESET_PASSWORD_LOADING = "RESET_PASSWORD_LOADING";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const STORE_RESET_PHONE_NUMBER = "STORE_RESET_PHONE_NUMBER";
export const STORE_RESET_PASSWORD_OTP = "STORE_RESET_PASSWORD_OTP";
// delivery edit active
export const GET_DELIVERY_PRICE = "GET_DELIVERY_PRICE"
export const GET_DELIVERY_PRICE_LOADING = "GET_DELIVERY_PRICE_LOADING"
export const GET_DELIVERY_PRICE_ERROR = "GET_DELIVERY_PRICE_ERROR"



// REQUEST STATUS
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";

// STORE CONSTANTS
export const STORE_SELLER_MARKET = "STORE_SELLER_MARKET";
export const STORE_SELLER_STORE_DETAILS = "STORE_SELLER_STORE_DETAILS";
export const STORE_SELLER_ACCOUNT_DETAILS = "STORE_SELLER_ACCOUNT_DETAILS";
export const STORE_SELLERS_STORE_LOGO = "STORE_SELLERS_STORE_LOGO";
// STORE
export const CREATE_STORE_LOADING = "CREATE_STORE_LOADING";
export const CREATE_STORE_SUCCESS = "CREATE_STORE_SUCCESS";
export const CREATE_STORE_FAIL = "CREATE_STORE_FAIL";
export const UPDATE_STORE_LOADING = "UPDATE_STORE_LOADING";
export const UPDATE_STORE_SUCCESS = "UPDATE_STORE_SUCCESS";
export const UPDATE_STORE_FAIL = "UPDATE_STORE_FAIL";
export const GET_STORE_DETAIL_LOADING = "GET_STORE_DETAIL_LOADING";
export const GET_STORE_DETAIL_SUCCESS = "GET_STORE_DETAIL_SUCCESS";
export const GET_STORE_DETAIL_FAIL = "GET_STORE_DETAIL_FAIL";
export const UPDATE_STORE_LOGO_LOADING = "UPDATE_STORE_LOGO_LOADING";
export const UPDATE_STORE_LOGO_SUCCESS = "UPDATE_STORE_LOGO_SUCCESS";
export const UPDATE_STORE_LOGO_FAIL = "UPDATE_STORE_LOGO_FAIL";
export const VALIDATE_STORE_NAME_LOADING = "VALIDATE_STORE_NAME_LOADING";
export const VALIDATE_STORE_NAME_SUCCESS = "VALIDATE_STORE_NAME_SUCCESS";
export const VALIDATE_STORE_NAME_FAIL = "VALIDATE_STORE_NAME_FAIL";
export const VALIDATE_STORE_NAME_ONBOARDING_LOADING =
  "VALIDATE_STORE_NAME_ONBOARDING_LOADING";
export const VALIDATE_STORE_NAME_ONBOARDING_SUCCESS =
  "VALIDATE_STORE_NAME_ONBOARDING_SUCCESS";
export const VALIDATE_STORE_NAME_ONBOARDING_FAIL =
  "VALIDATE_STORE_NAME_ONBOARDING_FAIL";
//MARKETS CONSTANTS
export const GET_MARKETS_LOADING = "GET_MARKETS_LOADING";
export const GET_MARKETS_SUCCESS = "GET_MARKETS_SUCCESS";
export const GET_MARKETS_FAIL = "GET_MARKETS_FAIL";

// PRODUCTS
export const STORE_ACTIVE_PRODUCT = "STORE_ACTIVE_PRODUCT";
// WALLET
export const STORE_USER_WALLET = "STORE_USER_WALLET";
export const CLEAR_WALLET = "CLEAR_WALLET";
