import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {useFormik} from "formik";
import * as Yup from "yup";
import AuthContainer from "../../../components/common/authContainer/authContainer";
import Button from "../../../components/common/buttons/button";
import Input from "../../../components/common/inputs/input";
import {Header, Text, Error} from "../../../styles/globalStyles";
import {useSelector, useDispatch} from "react-redux";
// import {reset_Password} from "../../../redux/actions/authActions";
import {showNotification} from "../../../redux/actions/statusNotifications";
// import {CLEAR_ERROR} from "../../../redux/constants";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import MobileBackBtn from "../../../components/common/buttons/MobileBackBtn";
import {LoginIllustration} from "../../../assets/svg/login";

const Container = styled.div`
  &.button-container {
    margin: 1.5em 0;
  }
`;

function SetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storeForgotPasswordNumber = useSelector(
    state => state.storeForgotPasswordNumber,
  );
  const [loading, setLoading] = useState(false);
  // const [error, setError] = useState("");
  // const [message, setMessage] = useState("");
  // const resetPassword = useSelector(state => state.resetPassword);
  // const {loading, message, error} = resetPassword;

  const {phoneNumber} = storeForgotPasswordNumber;

  const setPasswordfunc = async values => {
    try {
      setLoading(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        timeout: 60000,
        timeoutErrorMessage:
          "Couldn't connect to the server. Poor network connection",
      };

      const {data} = await axios.put(
        `${process.env.REACT_APP_API_URL}users/resetpassword`,
        {
          phone: phoneNumber,
          otp: String(values.otp),
          password: values.newPassword,
          passwordConfirm: values.confirmPassword,
        },
        config,
      );
      if (data) {
        setLoading(false);
        values.confirmPassword = "";
        values.newPassword = "";
        values.otp = "";
        dispatch(
          showNotification({
            notify: {
              status: 1,
              message: `${data.message}`,
            },
          }),
        );
        navigate("/password-reset");
      }
    } catch (error) {
      setLoading(false);
      if (!error.response) {
        dispatch(
          showNotification({
            notify: {
              status: 2,
              message: `${error}`,
            },
          }),
        );
      } else {
        dispatch(
          showNotification({
            notify: {
              status: 2,
              message: `${error.response.data.message}`,
            },
          }),
        );
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
      otp: "",
    },

    validationSchema: Yup.object({
      newPassword: Yup.string()
        .matches(
          /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          "Password must contain at least 8 characters, one uppercase, one number and one special case character",
        )
        .required("Required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords don't match")
        .required("Please confirm your password"),
      otp: Yup.number().required("Please provide the OTP sent to your phone"),
    }),

    onSubmit: values => {
      // const token = store.getState().userLogin.accessToken;

      setPasswordfunc(values);
    },
  });

  useEffect(() => {
    if (formik.values.newPassword === "") {
      document.getElementsByClassName("eye")[0].style.display = "none";
    } else {
      document.getElementsByClassName("eye")[0].style.display = "inline-block";
    }
  }, [formik.values.newPassword]);

  useEffect(() => {
    if (formik.values.confirmPassword === "") {
      document.getElementsByClassName("eye")[1].style.display = "none";
    } else {
      document.getElementsByClassName("eye")[1].style.display = "inline-block";
    }
  }, [formik.values.confirmPassword]);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  // useEffect(() => {
  //   if (error && error !== "") {
  //     dispatch(
  //       showNotification({
  //         notify: {
  //           status: 2,
  //           message: error,
  //         },
  //       }),
  //     );
  //     setError("");
  //   }
  //   if (message && message !== "") {
  //     formik.values.confirmPassword = "";
  //     formik.values.newPassword = "";
  //     formik.values.otp = "";
  //     dispatch(
  //       showNotification({
  //         notify: {
  //           status: 1,
  //           message: `${message}`,
  //         },
  //       }),
  //     );
  //     navigate("/password-reset");
  //     setMessage("");
  //   }
  // }, [error, dispatch, message, formik.values, navigate]);

  return (
    <AuthContainer image={LoginIllustration}>
      <Container>
        <MobileBackBtn to="/forgot-password" />
        <Header>Forgot Password?</Header>
        <Text>
          Not to worry, things like this happens. An OTP code will be sent to
          your phone number.
        </Text>

        <form onSubmit={formik.handleSubmit} className="mt-8">
          <Input
            labelText="OTP"
            type="number"
            id="otp"
            name="otp"
            autoComplete="new-password"
            value={formik.values.otp}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Error>
            {formik.errors.otp && formik.touched.otp ? (
              <>{formik.errors.otp}</>
            ) : null}
          </Error>
          <Input
            labelText="New Password"
            type="password"
            id="newPassword"
            name="newPassword"
            autoComplete="new-password"
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Error>
            {formik.errors.newPassword && formik.touched.newPassword ? (
              <>{formik.errors.newPassword}</>
            ) : null}
          </Error>

          <Input
            labelText="Confirm Password"
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            autoComplete="new-password"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Error>
            {formik.errors.confirmPassword && formik.touched.confirmPassword ? (
              <>{formik.errors.confirmPassword}</>
            ) : null}
          </Error>
          <Container className="button-container">
            <Button type="submit" text="Change Password" processing={loading} />
          </Container>
        </form>
      </Container>
    </AuthContainer>
  );
}

export default SetPassword;
