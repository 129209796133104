import React from "react";
import styled from "styled-components";
import Payout from "../../assets/images/payout-success.svg";
import FailedPayout from "../../assets/images/errorStatus.png";
import PendingPayout from "../../assets/images/pendingStatus.png";
import {numberFormat} from "../../utils/formatPrices";
import moment from "moment";

const SinglePayout = ({onClick, amount, status, createdAt}) => {
  return (
    <MainCont
      onClick={onClick}
      className="w-full flex flex-row items-center justify-between py-3 cursor-pointer">
      <div className="flex flex-row ">
        <img
          className="block h-8"
          src={
            status === "success"
              ? Payout
              : status === "failed"
              ? FailedPayout
              : PendingPayout
          }
          alt="Payout"
        />
        <div className="md:ml-5 ml-3">
          <p className="text-white-text md:text-lg sm:text-base text-sm font-Bold ">
            Payout
          </p>
          {status === "pending" && (
            <p className="text-secondary-yellow  sm:text-sm text-xs font-Light">
              Pending
            </p>
          )}
          {status === "success" ||
            (status === "successful" && (
              <p className="text-secondary-success  sm:text-sm text-xs font-Light">
                Successful
              </p>
            ))}
          {status === "failed" && (
            <p className="text-secondary-error  sm:text-sm text-xs font-Light">
              Failed
            </p>
          )}
        </div>
      </div>
      <div>
        <p className="text-primary-light font-Bold text-xs sm:text-base">
          N{numberFormat(amount)}
        </p>
        <p className="text-white-text  sm:text-sm text-xs font-Light">
          {moment(createdAt).format("ll")}
        </p>
      </div>
    </MainCont>
  );
};

export default SinglePayout;

const MainCont = styled.div`
  border-bottom: 1px solid #e7edf2;
`;
