import React from "react";
import styled from "styled-components";
import emptyNotification from "../../assets/svg/emptyNotification.svg";

const Image = styled.img`
  width: 200px;
  /* margin-top: 5em;
    margin-left: auto;
    margin-right: auto; */
`;

function EmptyNotification() {
  return (
    <>
      <Image src={emptyNotification} loading="eager" alt="empty-product" />
    </>
  );
}

export default EmptyNotification;
