import Account from "../pages/account/Account";
import Orders from "../pages/orders/Orders";
import Products from "../pages/products/Products";
import Wallet from "../pages/wallet/Wallet";
import WithdrawalScreen from "../pages/wallet/subScreens/WithdrawalScreen";
import AddProducts from "../pages/products/subScreens/AddProducts";
import EditProducts from "../pages/products/subScreens/EditProduct";
import ProductDetail from "../pages/products/subScreens/ProductDetail";
import Store from "../pages/store/Store";
import EditStore from "../pages/store/subScreen/EditStore";
import Profile from "../pages/profile/profile";
import Notifications from "../pages/notifications/Notification";
import EditShipping from "../pages/store/subScreen/EditShipping";

export const routes = [
  {
    title: "Orders",
    path: "orders",
    component: Orders,
  },
  {
    title: "Products",
    path: "products",
    component: Products,
  },
  {
    title: "Product Details",
    path: "products/:name/:id",
    component: ProductDetail,
  },
  {
    title: "Add Products",
    path: "products/add-product",
    component: AddProducts,
  },
  {
    title: "Edit Products",
    path: "products/edit-product",
    component: EditProducts,
  },
  {
    title: "Wallet",
    path: "wallet/history",
    component: Wallet,
  },
  {
    title: "Wallet",
    path: "wallet/withdrawal",
    component: WithdrawalScreen,
  },
  {
    title: "Account",
    path: "account",
    component: Account,
  },
  {
    title: "Store",
    path: "store",
    component: Store,
  },
  {
    title: "Edit Store",
    path: "store/edit-store",
    component: EditStore,
  },
  {
    title: "Edit Store",
    path: "store/edit-shipping",
    component: EditShipping,
  },
  {
    title: "Profile",
    path: "profile",
    component: Profile,
  },
  {
    title:'notifications',
    path: "notifications",
    component:Notifications
  }
];
