import React from "react";
import styled from "styled-components";
import tree from "../../assets/images/Tree-Image.png";
import money from "../../assets/images/money.png";
import money2 from "../../assets/images/money2.png";
import shoe from "../../assets/images/shoeLanding.png";

const Container = styled.div`
  &.main {
    background: #f8f8f8;
    padding: 1.5em;
    overflow-x: hidden;
  }

  &.images-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2em 0;
  }

  @media (min-width: 768px) {
    &.main {
      padding-left: 3em;
      padding-right: 3em;
    }
  }

  @media (min-width: 1024px) {
    &.main {
      padding-top: 4em;
    }

    &.main-container {
      display: flex;
      justify-content: space-around;
      padding-top: 2em;
    }
  }
`;

const Image = styled.img`
  border-radius: 5px;

  &.tree-image {
    margin-bottom: 2em;
  }

  &.grid-gap {
    margin-bottom: 1.5em;
  }

  &.left {
    margin-right: 1em;
  }

  @media (min-width: 768px) {
    &.tree-image {
      width: 80%;
      margin: 2em auto;
    }
  }

  @media (min-width: 1024px) {
    &.tree-image {
      width: 90%;
    }
  }
`;

const Header = styled.h1`
  color: #272d4e;
  font-weight: 800;
  text-align: center;
  margin-bottom: 1em;
`;

function OurExperience() {
  return (
    <Container className="main">
      <Header className="font-Bold lg:text-2xl">Our promised experience</Header>
      <Container className="main-container">
        <Container className="tree">
          <Image
            className="tree-image"
            src={tree}
            alt="our-process"
            loading="eager"
          />
        </Container>

        <Container className="images-grid">
          <Container>
            <Image src={money} className="grid-gap left" loading="eager" />
            <Image src={money2} className="left" loading="eager" />
          </Container>
          <Container>
            <Image src={shoe} className="grid-gap" loading="eager" />
            <Image
              src="https://res.cloudinary.com/dqf6twyxt/image/upload/q_80/v1639402135/Thrindle/grid4_p354zf.png"
              loading="eager"
            />
          </Container>
        </Container>
      </Container>
    </Container>
  );
}

export default OurExperience;
