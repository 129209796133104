import React from "react";
import styled from "styled-components";
import SingleDropdownSelect from "../../../components/common/dropdownSelects/SingleDropdownSelect";

const DropdownContent = ({data, value, chooseValue, closeModal}) => {
  const chooseMarket = val => {
    chooseValue(val);
    closeModal();
  };
  return (
    <MainCont className="w-full  pt-2">
      {data &&
        data.map((item, index) => (
          <SingleDropdownSelect
            name={item}
            key={index}
            value={value}
            onClick={() => chooseMarket(item)}
          />
        ))}
    </MainCont>
  );
};

export default DropdownContent;

const MainCont = styled.div``;
