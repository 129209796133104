import React, {useState, useEffect, useRef} from "react";
import {useFormik} from "formik";
import {useNavigate} from "react-router-dom";
import * as Yup from "yup";
import AddImageContainer from "../../../components/productScreen/AddImageContainer";
import Button from "../../../components/common/buttons/button";
import MainContainer from "../../../components/common/mainContainer/mainContainer";
import TextArea from "../../../components/common/inputs/textarea";
import Dropdown from "../../../components/common/inputs/dropdown";
import {mainApi} from "../../../utils/axiosInstance";
import {unitsArr} from "../../../data/productionDatas";
import {
  productSizes,
  productSizes2,
  mainColor,
} from "../../../data/productionDatas";
import {Error} from "../../../styles/globalStyles";
import {useDispatch, useSelector} from "react-redux";
import {showNotification} from "../../../redux/actions/statusNotifications";
import InputWithFieldSet from "../../../components/common/inputs/inputWithFieldSet";
import DisplayImagesEdit from "../../../components/displayImages/displayImagesEdit";
import SingleDropdown from "../../../components/customDropdown/singleDropdown";
import DoubleDropdown from "../../../components/customDropdown/doubleDropdown";

const EditProduct = () => {
  const productDetails = useSelector(
    state => state.storeActiveProduct.activeProduct,
  );

  const {
    category,
    description,
    images,
    name,
    no_in_stock,
    original_price,
    subcategory,
    unit,
    _id,
    weight,
    details,
  } = productDetails;

  const [allImages, setAllImages] = useState([]);

  const handleEditImages = e => {
    e.preventDefault();
    dispatch(
      showNotification({
        notify: {
          status: 0,
          message: "Feature not available currently",
        },
      }),
    );
  };

  const [categories, setCategories] = useState([]);
  const [categoryValue, setCategoryValue] = useState(category.name);
  const [subCategoryValue, setSubCategoryValue] = useState(subcategory.name);
  const [weightClass, setWeightClass] = useState([]);
  const [weightClassValue, setWeightClassValue] = useState(weight);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [currentUnit, setUnit] = useState(unit);
  const [subCategory, setSubCategory] = useState([]);
  const [currentSize, setCurrentSize] = useState({
    size1: [],
    size2: [],
  });
  const [colors, setColors] = useState(details?.color || []);
  const [otherValues, setOtherValues] = useState({});
  const [uploading, setUploading] = useState(false);
  const sizeRef1 = useRef(null);
  const sizeRef2 = useRef(null);

  const productSizeArr = productSizes.map(item => item.title);
  const productSizeArr2 = productSizes2.map(item => item.title);

  const marketId = JSON.parse(localStorage.getItem("storeDetails"))[0]
    .market_id;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // image upload, edit and delete
  const chooseImage = event => {
    if (allImages?.length < 6) {
      const newImages = event.target.files[0];
      let updatedList = [...allImages, newImages];
      setAllImages(updatedList);
    }
  };

  const deleteImage = val => {
    let updatedList = allImages?.filter((item, index) => index !== val);
    setAllImages(updatedList);
  };

  // const editImages = async e => {
  //   e.preventDefault();
  //   let formData = new FormData();
  //   const newImages = allImages.map(image => {
  //     if (image.name.startsWith("https")) {
  //       let myImage = image.name;
  //       return {
  //         myImage,
  //       };
  //     } else {
  //       return {...allImages};
  //     }
  //   });
  //   console.log(newImages, "newIMAGES");
  //   allImages.forEach(image => formData.append("images", image));

  //   try {
  //     setUpdatingImages(true);
  //     const config = {
  //       method: "put",
  //       url: `/products/updateimages/${_id}`,
  //       data: formData,
  //     };
  //     let res = await mainApi(config);
  //     console.log(res);
  //     if (res.status === 200) {
  //       setTimeout(() => {
  //         setUpdatingImages(false);
  //         navigate("/products");
  //         dispatch(
  //           showNotification({
  //             notify: {
  //               status: 1,
  //               message: "Images updated",
  //             },
  //           }),
  //         );
  //       }, 3000);
  //     }
  //   } catch (error) {
  //     setUpdatingImages(false);
  //     console.log(error);
  //   }
  // };

  // helper functions to get subCategories, weightClass
  const getSubCategories = categoryValue => {
    let marketData = JSON.parse(localStorage.getItem("marketData"));
    let findMarket = marketData?.find(item => item?.name === categoryValue);
    let subCategoryData = findMarket?.subcategories;
    let subCategory = findMarket?.subcategories?.map(item => item.name);
    setSubCategory(subCategory);
    setSubCategoryData(subCategoryData);
  };

  const getWeightClass = categoryValue => {
    let marketData = JSON.parse(localStorage.getItem("marketData"));
    let findMarket = marketData?.find(item => item?.name === categoryValue);
    let weightList = findMarket?.weight?.map(item => item?.name); // maps real-time weight class
    setWeightClass(weightList); // renders real-time weight class
  };

  // update states of forms
  const selectCategory = value => {
    setCategoryValue(value);
  };

  const selectSubCategory = value => {
    setSubCategoryValue(value);
  };

  const selectWeightClass = value => {
    setWeightClassValue(value);
  };

  const chooseSize1 = value => {
    if (currentSize?.size1?.includes(value)) {
      setCurrentSize(prevState => {
        return {
          ...prevState,
          size1: currentSize?.size1.filter(item => item !== value),
        };
      });
    } else {
      setCurrentSize(prevState => {
        return {...prevState, size1: [...currentSize.size1, value]};
      });
    }
  };

  const chooseSize2 = value => {
    if (currentSize.size2.includes(value)) {
      setCurrentSize(prevState => {
        return {
          ...prevState,
          size2: currentSize?.size2.filter(item => item !== value),
        };
      });
    } else {
      setCurrentSize(prevState => {
        return {...prevState, size2: [...currentSize.size2, value]};
      });
    }
  };

  const removeSize1 = value => {
    if (currentSize?.size1.includes(value)) {
      setCurrentSize(prevState => {
        return {
          ...prevState,
          size1: currentSize?.size1.filter(item => item !== value),
        };
      });
    }
  };

  const removeSize2 = value => {
    if (currentSize?.size2.includes(value)) {
      setCurrentSize(prevState => {
        return {
          ...prevState,
          size2: currentSize?.size2.filter(item => item !== value),
        };
      });
    }
  };

  const chooseColor = value => {
    if (colors?.includes(value)) {
      setColors(colors?.filter(item => item !== value));
    } else {
      setColors([...colors, value]);
    }
  };

  const removeColor = value => {
    if (colors?.includes(value)) {
      setColors(colors?.filter(item => item !== value));
    }
  };

  const selectUnit = value => {
    setUnit(value);
  };

  // helper function to get IDs
  const getCategoryId = category => {
    let marketData = JSON.parse(localStorage.getItem("marketData"));
    let findMarket = marketData?.find(item => item?.name === category);
    return findMarket?._id;
  };

  const getSubCategoryId = category => {
    let findMarket = subCategoryData?.find(item => item?.name === category);
    return findMarket?._id;
  };

  // form submission
  const formik = useFormik({
    initialValues: {
      title: name,
      description: description,
      productStock: no_in_stock,
      price: original_price,
    },

    validationSchema: Yup.object().shape({
      title: Yup.string().min(2, "Too Short").required("Required"),
      description: Yup.string().min(10, "Too Short").required("Required"),

      productStock: Yup.number()
        .required("Required")
        .positive("Enter a positive value")
        .integer("Enter a positive value"),
      price: Yup.number().required("Required").positive().integer(),
    }),

    onSubmit: values => {
      let values2 = {
        images: images,
        category: getCategoryId(categoryValue),
        subCategory: getSubCategoryId(subCategoryValue),
        unit: currentUnit,
        colors: colors,
        weight: weightClassValue,
        size: Array.from(new Set([...currentSize.size1, ...currentSize.size2])),
      };

      setOtherValues({...otherValues, values2});
      let x = {...values, values2};
      submitForm(x);
    },
  });

  const submitForm = async productData => {
    const {
      title,
      description,
      price,
      productStock,
      values2: {category, colors, size, subCategory, unit, weight},
    } = productData;

    const details = {
      size: size,
      color: colors,
    };

    const formData = {
      name: title,
      description: description,
      category: category,
      subcategory: subCategory,
      no_in_stock: productStock,
      unit: unit,
      original_price: price,
      weight: weight,
      details,
    };

    let config = {
      method: "put",
      url: `products/update/${_id}`,
      data: formData,
    };

    try {
      setUploading(true);
      const {data} = await mainApi(config);
      if (data) {
        setTimeout(() => {
          setUploading(false);
          navigate("/products");
          dispatch(
            showNotification({
              notify: {
                status: 1,
                message: "Product details updated",
              },
            }),
          );
        }, 3000);
      }
    } catch (error) {
      setUploading(false);
      if (error.response) {
        dispatch(
          showNotification({
            notify: {
              status: 2,
              message: "Error, Cannot connect to server",
            },
          }),
        );
      } else {
        dispatch(
          showNotification({
            notify: {
              status: 0,
              message: "Please check that you're connected",
            },
          }),
        );
      }
    } finally {
      setUploading(false);
    }
  };

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      const getMarketCategories = async () => {
        try {
          let res = await mainApi.get(`categories/market/${marketId}`);
          localStorage.setItem("marketData", JSON.stringify(res.data.data));
          let categories = res.data.data.map(item => {
            return item.name;
          });

          setCategories(categories);
        } catch (error) {
          if (error.response) {
            dispatch(
              showNotification({
                notify: {
                  status: 2,
                  message: `${error.response.data.message}`,
                },
              }),
            );
          } else {
            dispatch(
              showNotification({
                notify: {
                  status: 0,
                  message: "Please check that you're connected",
                },
              }),
            );
          }
        }
      };

      getMarketCategories();
    }
  }, [marketId, dispatch]);

  // split sizes into seperate arrays
  useEffect(() => {
    details?.size.forEach(size => {
      let regexp = /\d+/;
      if (size.match(regexp)) {
        setCurrentSize(prevState => {
          return {...prevState, size2: [...prevState.size2, size]};
        });
      } else {
        setCurrentSize(prevState => {
          return {...prevState, size1: [...prevState.size1, size]};
        });
      }
    });
  }, [details?.size]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getSubCategories(categoryValue);
      getWeightClass(categoryValue);
    }

    return () => (mounted = false);
  }, [categoryValue]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleClickOutside = e => {
      if (sizeRef1.current && sizeRef2.current) {
        if (!sizeRef1.current.contains(e.target)) {
          sizeRef1.current.open = false;
        }
        if (!sizeRef2.current.contains(e.target)) {
          sizeRef2.current.open = false;
        }
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    let addURL = images.map(image => {
      return {
        name: `https://api.thrindle.com/api/thrindle/images/${image}`,
        type: "image/jpeg",
      };
    });

    setAllImages(addURL);
  }, [images]);

  return (
    <MainContainer storeNav={true}>
      <div className="w-full pt-20">
        <div className="w-full pb-20 lg:flex lg:justify-between lg:h-[75vh]">
          <div className="w-full lg:w-[55%]">
            <div className="flex items-center pb-4">
              <p className="text-white-text font-Bold md:text-base text-sm mr-6">
                Product Listing
              </p>
              {allImages?.length !== 0 && (
                <form onSubmit={handleEditImages}>
                  <Button
                    text="Edit images"
                    type="submit"
                    blueBg={true}
                    smallButton={true}
                  />
                </form>
              )}
            </div>

            {allImages?.length !== 0 ? (
              <div className="flex gap-4 justify-between overflow-x-scroll pb-4 lg:justify-start">
                <DisplayImagesEdit
                  imageList={allImages}
                  deleteImage={val => deleteImage(val)}
                  onChange={chooseImage}
                />
              </div>
            ) : (
              <div className="border-[0.98px] border-dashed border-primary-main rounded-md flex flex-col items-center justify-center md:py-24 py-10 xl:px-10 md:px-10 px-5">
                <AddImageContainer onChange={chooseImage} required={true} />

                <p className="md:text-sm text-xs text-white-lightGray font-Bold pt-5">
                  You can attach multiple images (1-6) 500px by 500px
                </p>
              </div>
            )}
          </div>

          <form
            onSubmit={formik.handleSubmit}
            className="w-full lg:w-[40%] lg:overflow-y-scroll md:pr-4">
            <div>
              <div className="my-3">
                <InputWithFieldSet
                  type="text"
                  id="title"
                  name="title"
                  fieldset="Product title"
                  placeholder="Enter product title"
                  value={formik.values.title}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <Error>
                  {formik.errors.title && formik.touched.title ? (
                    <>{formik.errors.title}</>
                  ) : null}
                </Error>
              </div>

              <div className="my-3">
                <TextArea
                  type="textarea"
                  fieldset="Description"
                  id="description"
                  name="description"
                  placeholder="Enter product description"
                  rows={3}
                  value={formik.values.description}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <Error>
                  {formik.errors.description && formik.touched.description ? (
                    <>{formik.errors.description}</>
                  ) : null}
                </Error>
              </div>

              <div className="my-3">
                <Dropdown
                  fieldset="Category"
                  list={categories}
                  id="category"
                  name="category"
                  emptyValue="Choose a Category"
                  value={categoryValue}
                  onChange={value => selectCategory(value)}
                  required={true}
                />
              </div>

              <div className="my-3">
                <Dropdown
                  fieldset="Sub Category"
                  list={subCategory}
                  id="subCategory"
                  name="subCategory"
                  emptyValue="Choose a Sub Category"
                  value={subCategoryValue}
                  onChange={value => selectSubCategory(value)}
                  required={true}
                />
              </div>

              <div className="my-3">
                <Dropdown
                  weightClass={true}
                  fieldset="Weight Class"
                  list={weightClass}
                  id="weightClass"
                  name="weightClass"
                  emptyValue="Choose a Weight Class"
                  value={weightClassValue}
                  onChange={value => selectWeightClass(value)}
                  required={true}
                />
              </div>

              <div className="my-3">
                <InputWithFieldSet
                  type="number"
                  id="productStock"
                  name="productStock"
                  fieldset="Product Stock"
                  placeholder="Number of product in stock"
                  value={formik.values.productStock}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <Error>
                  {formik.errors.productStock && formik.touched.productStock ? (
                    <>{formik.errors.productStock}</>
                  ) : null}
                </Error>
              </div>

              <div className="my-3">
                <Dropdown
                  fieldset="Unit"
                  list={unitsArr}
                  id="unit"
                  name="unit"
                  emptyValue="Choose a unit"
                  value={currentUnit}
                  onChange={val => selectUnit(val)}
                  required={true}
                />
              </div>

              <div className="my-3">
                <InputWithFieldSet
                  type="number"
                  id="price"
                  name="price"
                  fieldset="Price"
                  placeholder="Enter Product Price"
                  value={formik.values.price}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <Error>
                  {formik.errors.price && formik.touched.price ? (
                    <>{formik.errors.price}</>
                  ) : null}
                </Error>
              </div>

              <div className="flex my-3">
                <DoubleDropdown
                  fieldset="Sizes"
                  list1={productSizeArr}
                  list2={productSizeArr2}
                  emptyState1="e.g Small"
                  emptyState2="e.g 30"
                  onChange1={value => chooseSize1(value)}
                  onChange2={value => chooseSize2(value)}
                  currentSize={currentSize}
                  removeSize1={removeSize1}
                  removeSize2={removeSize2}
                />
              </div>

              <div className="flex my-3">
                <SingleDropdown
                  fieldset="Colors"
                  mainColors={mainColor}
                  colors={colors}
                  emptyState="Product Color"
                  onChange={value => chooseColor(value)}
                  removeColor={removeColor}
                />
              </div>

              <div className="my-6">
                <Button
                  blueBg
                  longButton
                  text="Update Product"
                  type="submit"
                  processing={uploading}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </MainContainer>
  );
};

export default EditProduct;
