import React from "react";
import styled, {css} from "styled-components";

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 2em 0;

  ${props =>
    props.smallerPadding &&
    css`
      padding: 1em 0;
    `}

  ${props =>
    props.isFive &&
    css`
      grid-template-columns: repeat(5, 1fr);
    `}

  & > div {
    :nth-child(1) {
      margin-left: 0;
    }

    :nth-last-child(1) {
      margin-right: 0;
    }

    margin: 0px 5px;

    @media (min-width: 768px) {
      margin: 0px 10px;
    }
  }

  @media (min-width: 1024px) {
    justify-content: start;
  }
`;

const Box = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  border: 1px solid #20639b;
  border-radius: 15px;

  @media (min-width: 768px) {
    width: 65px;
    height: 65px;
  }
`;

const Span = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: #20639b;
`;

const HiddenInput = styled.input`
  &.hidden-input {
    opacity: 0;
    position: absolute;
    width: 100%;
    padding: 3em 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
`;

function CustomInput({
  size,
  smallerPadding,
  value,
  id,
  name,
  isFive,
  onChange,
}) {
  return (
    <div className="relative flex md:justify-center xl:justify-start">
      <Container smallerPadding={smallerPadding} isFive={isFive}>
        {size === 4 && (
          <>
            <Box>
              <Span>{value[0]}</Span>
            </Box>
            <Box>
              <Span>{value[1]}</Span>
            </Box>
            <Box>
              <Span>{value[2]}</Span>
            </Box>
            <Box>
              <Span>{value[3]}</Span>
            </Box>
          </>
        )}
        {size === 5 && (
          <>
            <Box>
              <Span>{value[0]}</Span>
            </Box>
            <Box>
              <Span>{value[1]}</Span>
            </Box>
            <Box>
              <Span>{value[2]}</Span>
            </Box>
            <Box>
              <Span>{value[3]}</Span>
            </Box>
            <Box>
              <Span>{value[4]}</Span>
            </Box>
          </>
        )}
      </Container>
      <HiddenInput
        className="hidden-input"
        type="number"
        id={id}
        name={name}
        maxLength={size}
        value={value}
        onChange={e => onChange(e.target.value)}
        required
      />
    </div>
  );
}

export default CustomInput;
