function Arrow() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.88147 7.45869C3.42438 5.14417 5.23158 3.33698 7.5461 2.79406C9.21762 2.40198 10.9572 2.40198 12.6287 2.79406C14.9432 3.33698 16.7504 5.14418 17.2933 7.4587C17.6854 9.13021 17.6854 10.8698 17.2933 12.5413C16.7504 14.8558 14.9432 16.663 12.6287 17.2059C10.9572 17.598 9.21762 17.598 7.5461 17.2059C5.23158 16.663 3.42438 14.8558 2.88147 12.5413C2.48938 10.8698 2.48938 9.13021 2.88147 7.45869Z"
        stroke="#E7EDF2"
        strokeWidth="1.5"
      />
      <path
        d="M7.1709 9.99935L13.0042 9.99935M13.0042 9.99935L10.9209 7.91602M13.0042 9.99935L10.9209 12.0827"
        stroke="#E7EDF2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Arrow;
