import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {numberFormat} from "../../utils/formatPrices";

const StoreBalanceContainer = ({noWithdrawBtn}) => {
  const {wallet} = useSelector(state => state.storeWallet);
  return (
    <MainCont className="w-full rounded-md border-2 border-primary-light border-solid xl:px-10 px-5 xl:py-10 py-5">
      <div className="w-full relative main-cont rounded-md h-40 flex flex-col items-center justify-center">
        <p className="text-sm text-white-main text-center font-Bold">
          Wallet Balance
        </p>
        <p className="xl:text-3xl md:text-2xl text-xl text-white-main text-center font-Bold">
          N{numberFormat(wallet?.balance)}
        </p>
        <div className="absolute right-0 bottom-0 px-10 py-2 withdrawBtn bg-secondary-yellow cursor-pointer">
          <Link to="/wallet/history">
            <p className="text-white-main text-sm font-Bold underline cursor-pointer">
              Transaction history
            </p>
          </Link>
        </div>
      </div>
      {!noWithdrawBtn && (
        <Button
          className="border border-primary-light px-10 rounded-md py-3 block w-full mt-7"
          // onClick={closeModal}
        >
          <Link to="/wallet/withdrawal">
            <p className="text-sm text-primary-light font-Bold">
              Withdraw funds
            </p>
          </Link>
        </Button>
      )}
    </MainCont>
  );
};

export default StoreBalanceContainer;
const MainCont = styled.div`
  .main-cont {
    background: linear-gradient(144.89deg, #20639b 21.04%, #58cced 97.54%);
  }
  .withdrawBtn {
    border-bottom-right-radius: 6px;
    border-top-left-radius: 6px;
  }
`;
const Button = styled.button``;
