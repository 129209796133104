import React from "react";
import styled from "styled-components";
import HomeImage from "../../../assets/images/Home.svg";
// import LocationImage from "../../../assets/images/Location.svg";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {IoCopy, IoCheckmarkSharp} from "react-icons/io5";

const StoreNavDetails = () => {
  const storeDetails = JSON.parse(localStorage.getItem("storeDetails"));
  const [showTip, setShowTip] = React.useState(false);
  const [isCopy, setIsCopy] = React.useState(false);
  console.log(storeDetails, "Details");

  // FOR CLIPBOARD COPY
  const isCopied = () => {
    setIsCopy(true);
    setTimeout(() => {
      setIsCopy(false);
    }, 2500);
  };

  const formattedStoreName = `${storeDetails[0]?.store_name
    .split(" ")
    .join("-")}`;

  const onMouseOver = () => {
    setShowTip(true);
  };
  const onMouseLeave = () => {
    setShowTip(false);
  };

  const shareAbleLinkTM =
    !storeDetails[0]?.store_link || storeDetails[0]?.store_link === ""
      ? `${formattedStoreName}.thrindle.shop`
      : `${storeDetails[0]?.store_link}.thrindle.shop`;
  const shareAbleLinkOM =
    !storeDetails[0]?.store_link || storeDetails[0]?.store_link === ""
      ? `${formattedStoreName}.thrindle.com`
      : `${storeDetails[0]?.store_link}.thrindle.com`;

  return (
    <>
      <StoreNav className="w-full sm:h-12 h-14 bg-primary-light lg:px-20 md:pl-12 pl-6 pr-6 flex flex-row items-center justify-between">
        <div className="flex flex-row items-center">
          <img src={HomeImage} alt="Home" className="store-nav-img block" />
          {storeDetails && storeDetails[0] && (
            <p className="text-white-main sm:text-base text-xs  sm:ml-2 ml-1 sm:mr-2 store-details capitalize">
              {storeDetails[0]?.store_name}
            </p>
          )}
          {/* <div className="border-line sm:mx-2 ml-1" /> */}
          {/* <div className="flex flex-row items-center sm:ml-3 ">
            <img
              src={LocationImage}
              alt="Location"
              className="store-nav-img block"
            />
            {storeDetails && storeDetails[0] && (
              <p className="text-white-main sm:text-base text-xs sm:ml-2 ml-1 mr-2 store-details capitalize">
                {storeDetails[0]?.store_address}
              </p>
            )}
          </div> */}
        </div>
        {/* Store links */}
        <div className="flex sm:flex-row flex-col-reverse sm:items-center items-end">
          <p className="mr-2 text-white-dim text-xs sm:text-base font-semibold">
            {storeDetails[0]?.store_id.startsWith("TM")
              ? shareAbleLinkTM
              : shareAbleLinkOM}
          </p>
          <CopyToClipboard
            onCopy={isCopied}
            text={
              storeDetails[0]?.store_id.startsWith("TM")
                ? shareAbleLinkTM
                : shareAbleLinkOM
            }>
            <div
              className="sm:w-7 sm:h-7 h-6 w-6 bg-white-dim rounded-full flex flex-row items-center  justify-center cursor-pointer relative copy"
              onMouseOver={onMouseOver}
              onMouseLeave={onMouseLeave}>
              <IoCopy className="sm:text-lg text-black text-xs" />
              {showTip && (
                <div className="absolute bg-white-lightGray whitespace-nowrap px-2 h-6 -bottom-6 right-0 flex flex-row items-center justify-center rounded-md">
                  <p className="text-sm text-black font-semibold text-center">
                    Copy your shareable link for your customers
                  </p>
                </div>
              )}
            </div>
          </CopyToClipboard>
        </div>
      </StoreNav>
      {isCopy && (
        <div className="w-full h-10 flex flex-row items-center justify-center bg-green-500">
          <IoCheckmarkSharp className="text-lg mr-2 text-white-main" />
          <p className="md:text-lg text-sm text-white-main text-center font-bold ">
            Copied to clipboard successfully
          </p>
        </div>
      )}
    </>
  );
};

export default StoreNavDetails;

const StoreNav = styled.div`
  .border-line {
    width: 5px;
    border-left: 1px solid white;
    height: 20px;
  }

  @media (max-width: 350px) {
    .store-nav-img {
      width: 13px;
      height: 13px;
    }
    .store-details {
      font-size: 9px !important;
    }
    .copy {
      justify-self: flex-end;
    }
  }
  @media (min-width: 2560px) {
    padding-left: 20em;
    padding-right: 20em;
  }
`;
