import React, {useState} from "react";
import styled from "styled-components";
import SingleDropdownSelect from "../../../components/common/dropdownSelects/SingleDropdownSelect";
import {FaSearch} from "react-icons/fa";

const WalletDropdownContent = ({data, value, chooseValue, closeModal}) => {
  const [searchValue, setSearchValue] = useState("");
  const chooseBank = val => {
    chooseValue(val);
    closeModal();
  };
  const filterBankData = val => {
    // let unique = data.filter((item, i, ar) => ar.indexOf(item) === i);
    if (val !== "") {
      const filtered = data.filter(item =>
        item.name?.toLowerCase().startsWith(val?.toLowerCase()),
      );
      return filtered;
    } else {
      return data;
    }
  };

  return (
    <MainCont className="w-full  pt-2  overflow-y-scroll ">
      <div className="w-full my-2 px-5 relative mb-14 bg-white-main">
        <div className=" flex flex-row px-2 fixed bg-white-main xl:w-1/3 lg:w-1/4 md:w-4/5 w-3/4 border border-solid border-white-gray h-10 rounded-md items-center">
          <FaSearch className="text-white-gray text-base" />
          <input
            type="text"
            className="bg-transparent w-10/12 h-full outline-none focus:outline-none text-white-text text-base placeholder-white-text pl-3"
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            placeholder="Search bank"
          />
        </div>
      </div>
      {data &&
        filterBankData(searchValue).map((item, index) => (
          <SingleDropdownSelect
            name={item.name}
            key={index}
            value={value}
            onClick={() => chooseBank(item.name)}
          />
        ))}
    </MainCont>
  );
};

export default WalletDropdownContent;

const MainCont = styled.div`
  max-height: 20rem;
`;
