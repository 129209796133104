import {STORE_USER_WALLET, CLEAR_WALLET} from "../../constants/index";

export const storeWalletReducer = (state = {wallet: {}}, {type, payload}) => {
  switch (type) {
    case STORE_USER_WALLET:
      return {
        ...state,
        wallet: payload,
      };
    case CLEAR_WALLET:
      return {
        ...state,
        wallet: {},
      };

    default:
      return state;
  }
};
