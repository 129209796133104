import React from "react";
import styled from "styled-components";

const MainTab = ({tabData, activeTab, onClick}) => {
  const length = tabData.length;

  
  return (
    <MainCont className=" mb-14 sm:h-12 lg:w-1/2 xl:w-2/5 md:w-3/4 h-12 rounded-lg  ">
      <div className="main-tab w-full px-1 h-full rounded-lg flex flex-row items-center justify-between">
        {tabData.map((item, index) => (
          <SingleTab
            onClick={() => onClick(item)}
            length={length}
            activeTab={activeTab}
            item={item}
            key={index}
            className="flex flex-row items-center justify-center rounded-lg cursor-pointer">
            <p className="tab-text capitalize  text-sm">{item}</p>
          </SingleTab>
        ))}
      </div>
    </MainCont>
  );
};

export default MainTab;

const MainCont = styled.div`
  .main-tab {
    background-color: #cdcccc;
   
  }

  @media (min-width: 2500px) {
    width: 30%;
  }
`;
const SingleTab = styled.div`
  width: ${({length}) => (length ? `${100 / length - 0.5}%` : "33.3%")};
 
  height: 85%;
  background: ${({activeTab, item}) => (activeTab === item ? "#fff" : "")};

  .tab-text {
    color: ${({activeTab, item}) =>
      activeTab === item ? "#20639b" : "#464F54"};
    font-family: ${({activeTab, item}) =>
      activeTab === item ? "Avenir-Black" : "Avenir-Regular"};
  }

  @media (max-width: 400px) {
    .tab-text {
      font-size: 10px;
    }
  }
`;
