import React, {useState} from "react";
import MainContainer from "../../../components/common/mainContainer/mainContainer";
import styled from "styled-components";
import ShippinFeeModal from "../../../components/common/modal/ShippinFeeModal";

const EditShipping = () => {
  const [active, setActive] = useState();
  const [priceModal, setPriceModal] = useState(false);
  const [lagos, setLagos] = useState(false);

  const deilveryOptions = [
    "Let Thrindle handle it",
    "I would handle the delivery myself",
  ];

  const LagosModal = ({onClick}) => {
    return (
      <div
        className="top-0 left-0 right-0 bottom-0 fixed flex justify-center items-center text-sm"
        style={{backgroundColor: "rgba(0,0,0, 0.5)", zIndex: "1000"}}>
        <div className="bg-white-main  w-[80%] md:w-[24rem] rounded-lg shadow-lg p-6">
          <div className="flex justify-between mb-4 items-center">
            <div className="flex gap-2 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                viewBox="0 0 20 20"
                fill="#166CB4">
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clipRule="evenodd"
                />
              </svg>
              <p className="font-bold text-lg">For Lagos State Merchants</p>
            </div>
            <svg
              onClick={onClick}
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#464F54"
              strokeWidth={1}>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>

          <p>This option is only available to Lagos Merchants currently. </p>
        </div>
      </div>
    );
  };
  return (
    <MainContainer storeNav>
      <MainCont className="pt-20 pb-20  xl:w-1/3 md:w-3/5 lg:w-1/2 mx-auto">
        {/* {marketType === "Thrindle Mall" && storeState === "Lagos" && ( */}
        <div className="text-sm">
          <Text className="header  pb-6">Shipping Preference</Text>

          <div>
            {deilveryOptions.map((item, index) => (
              <div
                className="flex gap-4 items-center mt-4 cursor-pointer"
                key={index}
                onClick={() => {
                  setActive(index);
                  setPriceModal(true);
                  {
                    active === 0 && setLagos(true);
                  }
                }}>
                <div className="h-4 w-4 border-2 border-primary-light rounded-full flex justify-center items-center">
                  {active === index && (
                    <div className="h-2 w-2 bg-primary-light rounded-full" />
                  )}
                </div>

                <p>{item}</p>
              </div>
            ))}
          </div>
        </div>
        <Text className="header  pt-6">Shipping Price List</Text>
        {/* )} */}
        {active === 1 && priceModal && (
          <ShippinFeeModal onClick={() => setPriceModal(false)} />
        )}
        {active === 0 && lagos && (
          <LagosModal onClick={() => setLagos(false)} />
        )}
      </MainCont>
    </MainContainer>
  );
};

export default EditShipping;
const MainCont = styled.div`
  .emptyLogo {
    width: 150px;
    height: 150px;
    border-radius: 150px;
  }
`;

export const Text = styled.p`
  font-size: 16px;

  @media (min-width: 768px) {
    text-align: center;
  }

  @media (min-width: 1440px) {
    text-align: revert;
  }

  ${props =>
    props.light &&
    css`
      font-family: var(--font-light);
      font-size: 14px;
    `}

  &.header {
    font-size: 24px;
    color: #20639b;
    font-family: var(--font-bold);
    margin: 1em 0 0.5em;
    text-align: start;
  }
`;
