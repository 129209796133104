import React, {useEffect, useState} from "react";
import styled from "styled-components";
import StoreBalanceContainer from "../../components/storeScreen/StoreBalanceContainer";
import {Link} from "react-router-dom";
import SingleStoreCategories from "../../components/storeScreen/SingleStoreCategories";
import MainContainerWithBg from "../../components/common/mainContainer/mainContainerWithBg";
import StoreButton from "../../components/common/buttons/StoreButton";
import DeliverPlanModal from "../../components/common/modal/DeliverPlanModal";

const Store = () => {
  const [storeDetails, setStoreDetails] = useState([]);
  const [delivery, setDelivery] = useState(false)
  const detailsOfStore = JSON.parse(localStorage.getItem('storeDetails'))

  const marketType = detailsOfStore[0].store_id


  useEffect(() => {
    const detail = localStorage.getItem("storeDetails");
    if (detail) {
      setStoreDetails(JSON.parse(detail));
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // console.log(storeDetails);
  return (
    <MainContainerWithBg storeNav>
      <MainCont className="pt-20 pb-20  xl:w-1/3 md:w-3/5 lg:w-1/2 mx-auto">
        <StoreBalanceContainer />
        <div className="">
          <div className="flex justify-center items-center gap-4 mt-8">
            {marketType.startsWith("TM") && <StoreButton text="Delivery Plan" onClick={() =>{setDelivery(true)}} /> }
                
            <Link to="/store/edit-store">
              <StoreButton text="Edit Store" blue />
            </Link>
          </div>
          {delivery && <DeliverPlanModal onClick={() =>{setDelivery(false)}} />}
          <div>
            <p className="text-white-text text-center font-Bold mt-6 text-lg capitalize">
              {storeDetails[0]?.store_name}  <span className="text-sm font-Bold text-white-lightGray text-center md:my-0 my-1">
              (#{storeDetails[0]?.store_id})
            </span>
            </p>
            
          </div>
        </div>
        <div className=" w-full flex flex-row flex-wrap">
          <p className="md:text-lg lg:mx-10 text-sm text-white-text font-Medium text-center pt-5 ">
            {storeDetails[0]?.description}
          </p>
        </div>
        <div className="w-full pt-5 flex flex-row items-center justify-around border-b border-b-primary-light pb-5">
          {/* Single stats */}
          <div>
            <span className="text-xs md:text-base text-secondary-success font-Bold">
              {storeDetails[1]?.TotalCompletedOrders}
            </span>
            <span className="text-xs md:text-base text-secondary-success font-Medium">
              &nbsp;Completed Orders
            </span>
          </div>
          {/* Single stats */}
          <div>
            <span className="text-xs md:text-base text-secondary-yellow font-Bold">
              {storeDetails[1]?.TotalPendingOrders}
            </span>
            <span className="text-xs md:text-base text-secondary-yellow font-Medium">
              &nbsp;Pending Orders
            </span>
          </div>
          {/* Single stats */}
          <div>
            <span className="text-xs md:text-base text-primary-light font-Bold">
              {storeDetails[1]?.TotalUploadedProducts}
            </span>
            <span className="text-xs md:text-base text-primary-light font-Medium">
              &nbsp;Products
            </span>
          </div>
        </div>
        {/* Categoreis Section */}
        <div className="w-full mt-10">
          <h6 className="text-white-text md:text-base text-sm text-center font-Bold">
            Categories
          </h6>
          {storeDetails[0]?.store_categories?.length > 0 ? (
            <div className="w-full mt-10 overflow-x-scroll scroll-cont flex flex-row items-center pb-5">
              {storeDetails[0]?.store_categories?.map((item, index) => (
                <SingleStoreCategories {...item} key={index} />
              ))}
            </div>
          ) : (
            <div className="w-full mt-14 mx-auto ">
              <p className="text-white-text md:text-base text-xs text-center text-bold">
                You have no categories.
              </p>
            </div>
          )}
        </div>
      </MainCont>
    </MainContainerWithBg>
  );
};

export default Store;

const MainCont = styled.div`
  .scroll-cont {
    overflow-x: scroll !important;
  }
`;
