import React, {useEffect, useState} from "react";
import styled from "styled-components";
import MainContainer from "../../components/common/mainContainer/mainContainer";
import support from "../../assets/images/support.png";
import {Search} from "../../assets/svg/search";
import {RemoveGeneralPadding} from "../../styles/globalStyles";
import SupportData from "../../data/support";

const Box = styled.div`
  &.support-container {
    margin: 3em auto;
    width: 90%;

    & > div {
      margin: 1em 0;
    }

    @media (min-width: 768px) {
      width: 70%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const Input = styled.input`
  outline: 0;
  border: none;
  background: inherit;
  padding: 0.5em;

  &::placeholder {
    color: #7c7c7c;
  }
`;

const Heading = styled.h4`
  color: #166cb4;
`;

const Button = styled.button`
  background: rgb(243, 158, 40, 0.95);
  outline: none;
  border: none;
  color: white;
  padding: 0.7em 2em;
  border-radius: 100px;
`;

function Support() {
  const [value, setValue] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const showText = (e, id) => {
    if (e.currentTarget.dataset.showicon === id) {
      e.currentTarget.style.display = "none";
      document.getElementById(`hideIcon${id}`).style.display = "inline-block";
      document.getElementById(`hideText${id}`).style.display = "inline-block";
    }
  };

  const hideText = (e, id) => {
    if (e.currentTarget.dataset.hideicon === id) {
      e.currentTarget.style.display = "none";
      document.getElementById(`showIcon${id}`).style.display = "inline-block";
      document.getElementById(`hideText${id}`).style.display = "none";
    }
  };

  return (
    <MainContainer>
      <RemoveGeneralPadding>
        <div className="bg-primary-blue pb-12 md:flex md:items-center md:justify-between md:p-12 lg:justify-around">
          <div className="w-[90%] mx-auto text-center py-12 md:w-1/2 md:m-0">
            <h1 className="text-white-main text-2xl font-Bold lg:text-3xl">
              How can we help?
            </h1>

            <div className="flex bg-white-main items-center justify-between rounded-[100px] pl-4 mt-4 lg:w-[80%] lg:mx-auto xl:w-[70%]">
              <div className="flex items-center">
                <span className="inline-block">{Search}</span>

                <Input
                  type="text"
                  placeholder="Search Something"
                  className="w-full"
                  value={value}
                  onChange={e => setValue(e.target.value)}
                />
              </div>

              <Button>Search</Button>
            </div>
          </div>

          <img
            src={support}
            alt="support"
            className="w-[85%] mx-auto max-h-[330px] md:w-[30%] md:m-0"
          />
        </div>

        <div>
          <h1 className="text-2xl text-center font-Bold mt-8 mb-6">
            Thrindle Support Guidelines
          </h1>
        </div>

        <Box className="support-container">
          {SupportData.map((datum, index) => (
            <Box key={index}>
              <Box className="header">
                <Heading>
                  <span className="inline-block w-[18px] h-[18px] rounded-full bg-primary-main align-sub mr-2"></span>
                  {datum.title}
                </Heading>

                <div
                  className="cursor-pointer"
                  data-showicon={datum.id}
                  id={`showIcon${datum.id}`}
                  onClick={e => showText(e, datum.id)}>
                  {datum.plusIcon}
                </div>

                <div
                  className="hidden cursor-pointer"
                  data-hideicon={datum.id}
                  id={`hideIcon${datum.id}`}
                  onClick={e => hideText(e, datum.id)}>
                  {datum.minusIcon}
                </div>
              </Box>

              <>
                <p
                  id={`hideText${datum.id}`}
                  className="hidden text-white-text py-2 px-8">
                  {" "}
                  {datum.message}
                  {datum.list && (
                    <ul className="list-disc mt-4 pl-8 text-white-text">
                      {datum.list.map((item, index) => (
                        <li key={index} className="my-2">
                          {item}
                        </li>
                      ))}
                    </ul>
                  )}
                </p>
              </>
            </Box>
          ))}
        </Box>

        <table className="table-fixed border-1 w-[95%] mt-8 mb-16 mx-auto md:w-[85%] lg:w-[75%]">
          <thead>
            <tr className="border border-white-black text-center">
              <td className="border border-white-black p-2 font-Bold md:p-4">
                Product
              </td>
              <td className="border border-white-black p-2 font-Bold md:p-4">
                Sub Category
              </td>
              <td className="border border-white-black p-2 font-Bold md:p-4">
                Description Requirements
              </td>
            </tr>
          </thead>
          <tbody>
            <tr className="border border-white-black text-center">
              <td className="border border-white-black p-2 md:p-4">Food</td>
              <td className="border border-white-black p-2 md:p-4">
                Non Alcoholic Beverages Sodas Other Beverages Food Other Food
              </td>
              <td className="border border-white-black p-2 md:p-4">
                Indicate storage condition + Origin of the food
              </td>
            </tr>

            <tr className="border border-white-black text-center">
              <td className="border border-white-black p-2 md:p-4">
                Alcohol & Tobacco
              </td>
              <td className="border border-white-black p-2 md:p-4">
                Alcoholic Beverages Cigar Universe Shisha & Accessories Other
                Tobacco E-Cigarettes & Accessories
              </td>
              <td className="border border-white-black p-2 md:p-4">
                Indicate storage condition + Origin of the product
              </td>
            </tr>

            <tr className="border border-white-black text-center">
              <td className="border border-white-black p-2 md:p-4">
                Baby Equipment
              </td>
              <td className="border border-white-black p-2 md:p-4">
                Baby Care & Safety Baby Feeding Baby & Toddler Toys Sleeping &
                Nursery Gear & Stroller Others Baby
              </td>
              <td className="border border-white-black p-2 md:p-4">
                Indicate usage condition + Minimum age + Main ingredients in the
                product
              </td>
            </tr>

            <tr className="border border-white-black text-center">
              <td className="border border-white-black p-2 md:p-4">
                Health Products
              </td>
              <td className="border border-white-black p-2 md:p-4">
                Health & Fitness, Protein Powders, Food supplements
              </td>
              <td className="border border-white-black p-2 md:p-4">
                Indicate usage condition + Storage condition + Main ingredients
              </td>
            </tr>

            <tr className="border border-white-black text-center">
              <td className="border border-white-black p-2 md:p-4">
                Beauty Products
              </td>
              <td className="border border-white-black p-2 md:p-4">
                Body shop, Make up, Facial Skincare, Perfumes, Hygiene, Hair
                care.
              </td>
              <td className="border border-white-black p-2 md:p-4">
                Indicate usage condition + Ingredients + Storage
              </td>
            </tr>

            <tr className="border border-white-black text-center">
              <td className="border border-white-black p-2 md:p-4">
                Intimate products
              </td>
              <td className="border border-white-black p-2 md:p-4">
                Condoms & lubricants
              </td>
              <td className="border border-white-black p-2 md:p-4">
                Indicate storage condition + Usage condition + Material
              </td>
            </tr>

            <tr className="border border-white-black text-center">
              <td className="border border-white-black p-2 md:p-4">
                Potential Weapons
              </td>
              <td className="border border-white-black p-2 md:p-4">
                Kitchen knife, baseball bat, weapons toys (Nerfs, Airsoft)
              </td>
              <td className="border border-white-black p-2 md:p-4">
                Indicate normal usage condition + Minimum age
              </td>
            </tr>

            <tr className="border border-white-black text-center">
              <td className="border border-white-black p-2 md:p-4">Toy</td>
              <td className="border border-white-black p-2 md:p-4">
                Baby cars & remote control toys, Dolls & Plush Educative Toys,
                Arts & Crafts Puzzles, Scooters & Ride on Toys, Sports Games
                Outdoor Toys, Kids Tablets & Laptops, Bricks, Blocks & Building
                Action & Toy figures.
              </td>
              <td className="border border-white-black p-2 md:p-4">
                Indicate minimum age + Main material
              </td>
            </tr>

            <tr className="border border-white-black text-center">
              <td className="border border-white-black p-2 md:p-4">Sex toys</td>
              <td className="border border-white-black p-2 md:p-4"></td>
              <td className="border border-white-black p-2 md:p-4">
                Indicate minimum age + Main material
              </td>
            </tr>
          </tbody>
        </table>
      </RemoveGeneralPadding>
    </MainContainer>
  );
}

export default Support;
