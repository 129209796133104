import React, {useState} from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";
import OrderModal from "../../components/common/modal/orderModal/OrderModal";
import OrderDetails from "../../pages/orders/OrderDetails";
import {numberFormat} from "../../utils/formatPrices";
// import Image from "../../../assets/images/image.jpg";

const PRODUCT = "product";

const SingleOrder = ({
  onClick,
  type,
  to,
  product,
  status,
  onClose,
  singleOrder,
  quantity,
  _id,
}) => {
  const [orderModal, setOrderModal] = useState(false);
  const toggleModal = () => {
    setOrderModal(prev => !prev);
  };

  return (
    <Product
      onClick={toggleModal}
      className="w-full bg-white-main rounded-md md:px-5 lg:px-3 px-4 py-4 overflow-hidden relative">
      {type === PRODUCT && quantity === 0 && (
        <div className="absolute w-full h-full top-0 left-0 right-0 bottom-0 sold-out flex flex-row items-center justify-center">
          <div className="sold-out-cont py-3 rounded-lg bg-white-dim">
            <p className="text-sm text-secondary-error font-ExtraBold text-center">
              Sold Out
            </p>
          </div>
        </div>
      )}
      <OrderModal visible={orderModal} closeModal={() => setOrderModal(false)}>
        <OrderDetails
          toggleModal={toggleModal}
          product={product}
          status={status}
          quantity={quantity}
          category={product?.category}
          id={_id}
          onClose={onClose}
          singleOrder={singleOrder}
        />
      </OrderModal>

      {/* product id */}
      <div className="mb-2">
        {/* <span className="text-white-ash text-xs productid">Product ID:</span> */}
        <span className="text-xs text-primary-light productid">
          {/* &nbsp;{_id} */}
        </span>
      </div>
      {/* Other content */}
      <div>
        <img
          src={`https://thrindleservices.herokuapp.com/api/thrindle/images/${product?.images[0]}`}
          alt="Product"
          className="w-full productimage block rounded-md cursor-pointer"
        />
        <div className="w-full mt-3 flex justify-between items-center">
          <div>
            <p className="text-base font-SemiBold text-white-text">
              {product?.name}
            </p>
            <p className="text-lg font-Bold pt-1  productprice">
              N{numberFormat(product?.original_price)}
            </p>
          </div>
          <p
            className={
              status === "completed"
                ? "text-secondary-success "
                : status === "cancelled"
                ? "text-secondary-error  "
                : "text-secondary-yellow "
            }>
            {status}
          </p>
        </div>
        {to ? (
          <Link to={to}>
            <Button onClick={toggleModal}>
              {type === PRODUCT ? "View product" : "View order"}
            </Button>
          </Link>
        ) : (
          <Button onClick={onClick}>
            {type === PRODUCT ? "View product" : "View order"}
            {/* View Order */}
          </Button>
        )}
      </div>
    </Product>
  );
};

export default SingleOrder;

const Product = styled.div`
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 5px 2px 1px rgba(0, 0, 0, 0.1);
  .productid {
    font-size: 10px !important;
  }
  .productimage {
    height: 250px;
    transition: all 0.3s ease-in-out;
  }
  .productprice {
    color: #2f3133;
  }
  &:hover {
    .productimage {
      transform: scale(0.95);
    }
  }
  @media (max-width: 500px) {
    .productimage {
      height: 200px;
    }
  }
  .sold-out {
    background-color: rgba(255, 255, 255, 0.7);
  }
  .sold-out-cont {
    width: 75%;
  }
`;
const Button = styled.button`
  width: 100%;
  background: rgba(22, 88, 143, 0.9);
  color: white;
  padding: 0.5em 0;
  margin-top: 1em;
  border-radius: 8px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);

  &:hover {
    background: rgba(22, 88, 143, 1);
  }
`;
