import React, {useState} from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";
import IconWithText from "../iconWithText/iconWithText";
import {StoreIcon} from "../../../assets/svg/store";
import {UploadNav} from "../../../assets/svg/uploadNav";
import {Orders} from "../../../assets/svg/orders";
import {Wallet} from "../../../assets/svg/wallet";
import {Notification} from "../../../assets/svg/notification";
import {Account} from "../../../assets/svg/account";
import {Products} from "../../../assets/svg/products.js";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {CLEAR_WALLET, USER_LOGOUT} from "../../../redux/constants";
import {useSelector} from "react-redux";

const Header = styled.header`
  position: -webkit-sticky;
  position: ${props => (props.fixed ? "static" : "sticky")};
  top: 0px;
  z-index: 5;
  background: white;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

  padding-left: 1.5em;
  padding-right: 1.5em;

  @media (min-width: 768px) {
    padding-left: 3em;
    padding-right: 3em;
  }

  @media (min-width: 1024px) {
    padding-left: 5em;
    padding-right: 5em;
  }
  @media (min-width: 2560px) {
    padding-left: 20em;
    padding-right: 20em;
  }
`;

const ThrindleLogo = styled(Link)`
  width: 114.31px;
  height: 28.99px;
`;

const MenuSmall = styled.div`
  & > span {
    height: 2px;
    display: block;
    width: 100%;
    margin-top: 5px;

    &:nth-child(1) {
      width: 50%;
    }

    &:nth-child(3) {
      width: 70%;
    }
  }
`;

const MenuLarge = styled.div``;

const List = styled.ul`
  position: relative;
  display: flex;
  align-items: center;

  & > li {
    margin: 0 8px;
    color: #464f54;
    cursor: pointer;

    &:nth-child(1) {
      margin-left: 0;
    }

    &:nth-last-child(1) {
      margin-right: 0;
    }

    @media (min-width: 1024px) {
      margin: 0 1em;
    }
  }
`;

const ListItems = styled.li``;

const BuyButton = styled.li`
  background-color: #20639b;
  color: white !important;
  padding: 0.3em 1em;
  opacity: 0.9;
  border-radius: 4px;

  &:hover {
    opacity: 1;
  }
`;

const Button = styled.button`
  background-color: #20639b;
  color: white !important;
  padding: 0.3em 1em;
  opacity: 0.9;
  outline: none;
  border: none;
  border-radius: 4px;
  margin: 0.5em 0;

  &:hover {
    opacity: 1;
    outline: none;
    border: none;
  }
`;

const MenuSmallDetails = styled.div`
  background: white;
  height: 70%;

  animation-name: slidein;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;

  @keyframes slidein {
    0% {
      transform: translateY(-100px);
    }

    100% {
      transform: translateY(0);
    }
  }
`;

const ExtendedMenu = styled.div`
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
`;

const Modal = styled.div`
  background: gray;
  opacity: 0.5;
  margin-right: -1.5em;
  margin-left: -1.5em;
  height: 40%;
  z-index: 6;
`;

function NavBar({fixed}) {
  const [menuSmallModal, setMenuSmallModal] = useState(false);
  const [extendedMenu, setExtendedMenu] = useState(false);
  const userLogin = useSelector(state => state.userLogin);
  const {user} = userLogin;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const showMenu = () => {
    setMenuSmallModal(true);
    document.getElementById("navbar").style.display = "none";
  };

  const closeMenu = () => {
    document.getElementById("navbar").style.display = "flex";
    setMenuSmallModal(false);
  };

  let userFirstName = user?.name?.split(" ")[0] || null;

  const handleMouseEvent = () => {
    setExtendedMenu(prevState => !prevState);
  };

  const handleSignOut = () => {
    localStorage.clear();
    dispatch({type: USER_LOGOUT});
    dispatch({type: CLEAR_WALLET});
    navigate("/");
  };

  return (
    <Header fixed={fixed}>
      <div
        className="flex justify-between items-center w-full py-4 lg:py-6"
        id="navbar">
        <ThrindleLogo to="/">
          <img
            src="https://res.cloudinary.com/codeinstd/image/upload/v1627459268/Logo_Identity_iskwsm.png"
            alt="thrindle logo"
            className="w-full h-full object-cover"
            loading="eager"
          />
        </ThrindleLogo>

        <MenuSmall className="block md:hidden p-1 w-8" onClick={showMenu}>
          <span className="bg-gray-500"></span>
          <span className="bg-gray-500"></span>
          <span className="bg-gray-500"></span>
        </MenuSmall>

        <MenuLarge className="hidden md:block">
          <List className="text-xs xl:text-base">
            <BuyButton>
              <a href="https://thrindle.com" target="_blank" rel="noreferrer">
                Buy
              </a>
            </BuyButton>
            <ListItems>
              <Link to="/products/add-product" replace>
                Upload
                <span className="inline-block align-sub pl-1">{UploadNav}</span>
              </Link>{" "}
            </ListItems>{" "}
            <ListItems>
              <Link to="/products" replace>
                Products
                <span className="inline-block align-sub pl-2">{Products}</span>
              </Link>
            </ListItems>{" "}
            <ListItems>
              <Link to="/orders" replace>
                Orders
                <span className="inline-block align-sub pl-1">{Orders}</span>
              </Link>{" "}
            </ListItems>{" "}
            <ListItems>
              <Link to="/wallet/withdrawal" replace>
                Wallet
                <span className="inline-block align-sub pl-2">
                  {Wallet}
                </span>{" "}
              </Link>
            </ListItems>
            <ListItems>
              <Link to="/notifications">
                Notifications{" "}
                <span className="inline-block align-sub">{Notification}</span>
              </Link>
            </ListItems>
            <ListItems onMouseEnter={handleMouseEvent} className="relative">
              <Link to="/profile" replace>
                {userFirstName ? userFirstName : "Login"}
                <span className="inline-block align-sub pl-1">
                  {Account}
                </span>{" "}
              </Link>
            </ListItems>
          </List>
          {extendedMenu && userFirstName && (
            <ExtendedMenu
              className="absolute right-10 top-16 bg-white-main text-white-text cursor-pointer rounded-md text-sm z-10 "
              onMouseLeave={handleMouseEvent}>
              <ul>
                <Link to="/profile" replace>
                  <li className="py-2.5 pl-4 pr-12 hover:text-primary-main">
                    My Account
                  </li>
                </Link>
                <Link to="/store" replace>
                  <li className="py-2.5 pl-4 pr-12 hover:text-primary-main">
                    My Store
                  </li>
                </Link>
                <li
                  className="py-2.5 pb-4 pl-4 pr-12 hover:text-primary-main"
                  onClick={handleSignOut}>
                  Sign Out
                </li>
              </ul>
            </ExtendedMenu>
          )}
        </MenuLarge>
      </div>

      {menuSmallModal && (
        <div className="h-screen">
          <MenuSmallDetails className="w-full h-full items-start py-4">
            <div className="w-full rounded-full p-1 flex flex-row justify-end">
              <div className="w-8 h-8 rounded-full bg-black p-1 flex flex-row justify-end">
                <svg
                  onClick={closeMenu}
                  className="w-6 h-6"
                  fill="none"
                  stroke="white"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            </div>

            <Button menuSmall>Buy</Button>

            <IconWithText label="Products" path="/products" icon={StoreIcon} />

            <IconWithText
              label="Upload"
              path="/products/add-product"
              icon={UploadNav}
            />

            <IconWithText label="Orders" path="/orders" icon={Orders} />

            <IconWithText
              label="Wallet"
              path="/wallet/withdrawal"
              icon={Wallet}
            />

            <IconWithText
              label="Notification"
              path="/notifications"
              icon={Notification}
            />

            <IconWithText label="My Store" path="/store" icon={StoreIcon} />

            {userFirstName ? (
              <IconWithText
                label={userFirstName}
                path="/profile"
                icon={Account}
              />
            ) : (
              <IconWithText label="Login" path="/login" icon={Account} />
            )}
            {userFirstName && (
              <ul className="list-none">
                <li
                  className="py-3 pb-4  pr-10 hover:text-primary-main"
                  onClick={handleSignOut}>
                  Sign Out
                </li>
              </ul>
            )}
          </MenuSmallDetails>
          <Modal></Modal>
        </div>
      )}
    </Header>
  );
}

export default NavBar;
