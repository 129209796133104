import React from "react";
import styled from "styled-components";
import splitRoutes from "../functions/SplitRoutes";
import {useNavigate} from "react-router-dom";

const Container = styled.div`
  margin: 1em 0 2em;

  @media (min-width: 768px) {
    display: none;
  }
`;

const Text = styled.span`
  color: #464f54;
  cursor: pointer;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 800;
  text-transform: capitalize;
`;

function NavMobile({routes}) {
  let routesArray = splitRoutes(routes);
  let prevRoute = routesArray[routesArray.length - 2];
  let history = useNavigate();

  const handleClick = () => {
    history(-1);
  };

  return (
    <Container>
      <svg
        className="w-4 h-4"
        fill="none"
        style={{display: "inline", verticalAlign: "middle"}}
        stroke="#464F54"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M15 19l-7-7 7-7"></path>
      </svg>
      <Text onClick={handleClick}>{prevRoute}</Text>
    </Container>
  );
}

export default NavMobile;
