import React from "react";
import {CgArrowLongLeft} from "react-icons/cg";
import {ArrowBack} from "../../../styles/globalStyles";
import {useNavigate} from "react-router-dom";

const MobileBackBtn = ({to}) => {
  let navigate = useNavigate();
  return (
    <div>
      <ArrowBack onClick={() => navigate(`${to}`)}>
        <CgArrowLongLeft className=" text-3xl text-primary-light mr-3" />
        <p className="text-white-text font-Bold text-base pt-1">Go Back</p>
      </ArrowBack>
    </div>
  );
};

export default MobileBackBtn;
