import React, {useState, useEffect} from "react";
import styled from "styled-components";
import Slider from "react-slick";
import {Link} from "react-router-dom";
import MainContainerWithBg from "../../../components/common/mainContainer/mainContainerWithBg";
import {useSelector} from "react-redux";
import DeleteModalView from "./DeleteModalView";
import DeleteProductModal from "../../../components/common/modal/deleteProductModal/DeleteProductModal";
import {numberFormat} from "../../../utils/formatPrices";
import {useLocation} from "react-router-dom";
import NavAndSearch from "../../../components/navAndSearch/NavAndSearch";
import NavMobile from "../../../components/navAndSearch/NavMobile";

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 2000,
};

const ProductDetail = () => {
  const [showModal, setShowModal] = useState(false);
  const storeActiveProduct = useSelector(state => state.storeActiveProduct);

  const {activeProduct} = storeActiveProduct;
  const sizeSet = new Set(activeProduct?.details?.size);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();
  const url = location.pathname;
  return (
    <MainContainerWithBg storeNav>
      <DeleteProductModal
        visible={showModal}
        closeModal={() => setShowModal(false)}>
        <DeleteModalView
          activeProduct={activeProduct}
          closeModal={() => setShowModal(false)}
        />
      </DeleteProductModal>
      <NavAndSearch routes={url} />
      <NavMobile routes={url} />

      <MainCont className="pt-10 xl:w-2/6 mx-auto  pb-20 ">
        <Slider
          {...settings}
          className="xl:w-3/4 md:w-1/2 w-11/12 mx-auto slider">
          {activeProduct?.images.map((item, index) => (
            <ImageCont key={index} className=" rounded-md">
              <img
                src={`${process.env.REACT_APP_API_URL_IMAGES}${item}`}
                alt="Product"
                className="block w-full h-full rounded-md"
              />
            </ImageCont>
          ))}
        </Slider>
        <div className="w-full flex flex-row items-center justify-center mt-5">
          {/* No in stock */}
          <div className="mr-10">
            <p className="text-2xl font-ExtraBold text-white-text text-center">
              {activeProduct?.no_in_stock}
            </p>
            <p className="text-white-lightGray text-base font-Medium">
              In Stock
            </p>
          </div>
          {/* No sold */}
          <div>
            <p className="text-2xl font-ExtraBold text-white-text text-center">
              {activeProduct?.total_units_sold}
            </p>
            <p className="text-white-lightGray text-base font-Medium">Sold</p>
          </div>
        </div>
        {/* Details */}
        <div className="w-full mt-10">
          {/* Single Details */}
          <div>
            <span className="text-white-lightGray font-Bold md:text-base text-sm">
              Product Name:
            </span>
            <span className="text-white-text font-SemiBold md:text-lg text-base">
              &nbsp;&nbsp;{activeProduct?.name}
            </span>
          </div>
          {/* Single Details */}
          <div className="mt-5">
            <span className="text-white-lightGray font-Bold md:text-base text-sm">
              Price:
            </span>
            <span className="text-primary-light font-Bold md:text-lg text-base">
              &nbsp;&nbsp; N{numberFormat(activeProduct?.original_price)}
            </span>
          </div>
          {/* Single Details */}
          <div className="mt-5">
            <span className="text-white-lightGray font-Bold md:text-base text-sm">
              Description:
            </span>
            <span className="text-white-text font-SemiBold md:text-lg text-base">
              &nbsp;&nbsp;{activeProduct?.description}
            </span>
          </div>
          {/* Single Details */}
          <div className="mt-5">
            <span className="text-white-lightGray font-Bold md:text-base text-sm">
              Category:
            </span>
            <span className="text-white-text font-SemiBold md:text-lg text-base">
              &nbsp;&nbsp;{activeProduct?.category?.name}
            </span>
          </div>
          {/* Single Details */}
          {activeProduct?.details?.size?.length > 0 && (
            <>
              <span className="text-white-lightGray font-Bold md:text-base text-sm block mt-5">
                Size:
              </span>
              <SizeContainer className="sizes wrap">
                {[...sizeSet].map((item, index) => (
                  <Span key={index} className="block">
                    {item}
                  </Span>
                ))}
              </SizeContainer>
            </>
          )}

          {/* Single Details */}
          {activeProduct?.details?.color?.length > 0 && (
            <>
              <div className="text-white-lightGray font-Bold md:text-base text-sm mt-5">
                Color:
              </div>
              <Color className="color wrap">
                {activeProduct?.details?.color?.map((color, index) => (
                  <ColorCont color={color} key={index} />
                ))}
              </Color>
            </>
          )}

          {/* Buttons */}
          <div className="w-full flex flex-row items-center mt-10 justify-center">
            <Button
              className="border border-secondary-error px-10 rounded-md py-3  mr-10"
              onClick={() => setShowModal(true)}>
              <p className="sm:text-sm text-xs text-secondary-error font-Bold">
                Delete
              </p>
            </Button>

            <Link to="/products/edit-product">
              <Button className="border bg-primary-light border-primary-light px-10 rounded-md py-3 ">
                <p className="sm:text-sm text-xs text-white-main font-Bold">
                  Edit
                </p>
              </Button>
            </Link>
          </div>
        </div>
      </MainCont>
    </MainContainerWithBg>
  );
};

export default ProductDetail;

const MainCont = styled.div`
  .slider {
    @media (min-width: 2560px) {
      width: 50%;
    }
  }
`;
const ImageCont = styled.div`
  /* width: 250px; */
  height: 300px;
  @media (max-width: 500px) {
    height: 250px;
  }
`;
const Button = styled.button``;

const SizeContainer = styled.div`
  margin: 0.5em 0;
  flex-wrap: wrap;
  display: flex;
`;

const Span = styled.span`
  padding: 0.25em 0.5em;
  background: #ffffff;
  border: 1px solid #e7edf2;
  box-sizing: border-box;
  border-radius: 8px;
  margin-right: 1em;
`;

const Color = styled.div`
  margin: 0.5em 0;
  flex-wrap: wrap;
  display: flex;
`;
const ColorCont = styled.div`
  background-color: ${({color}) => (color ? `${color}` : "")};
  width: 30px;
  height: 30px;
  border-radius: 30px;
  border: 1px solid #888;
  margin-right: 10px;
`;
