import React, {useState} from "react";
import styled from "styled-components";
import MainTab from "../../components/common/tab/MainTab";
import BalanceContainer from "../../components/wallet/BalanceContainer";
import EarningTabScreen from "./tabScreens/EarningTabScreen";
import WithdrawalsTabScreen from "./tabScreens/WithdrawalsTabScreen";
import MainContainerWithBg from "../../components/common/mainContainer/mainContainerWithBg";
import {useSelector} from "react-redux";

const tabData = ["Withdrawals", "Earnings"];

export const EARNING = "Earnings";
export const WITHDRAWALS = "Withdrawals";

function Wallet() {
  const [activeTab, setActiveTab] = useState(WITHDRAWALS);
  const {wallet} = useSelector(state => state.storeWallet);

  return (
    <MainContainerWithBg storeNav={true}>
      <MainCont className="w-full pt-20 pb-20">
        <MainTab
          tabData={tabData}
          activeTab={activeTab}
          onClick={val => setActiveTab(val)}
        />
        <div className="w-1/2 flex flex-col items-center justify-center mx-auto main-container">
          <BalanceContainer
            title={activeTab === EARNING ? EARNING : WITHDRAWALS}
            num={activeTab === EARNING ? `${wallet.total_earnings}` : "50,000"}
          />
          <div className="w-full mt-5">
            {activeTab === EARNING ? (
              <EarningTabScreen />
            ) : (
              <WithdrawalsTabScreen />
            )}
          </div>
        </div>
      </MainCont>
    </MainContainerWithBg>
  );
}

export default Wallet;

const MainCont = styled.div`
  @media (max-width: 1008px) {
    .main-container {
      width: 75%;
    }
  }
  @media (max-width: 550px) {
    .main-container {
      width: 100%;
    }
  }
  @media (min-width: 2500px) {
    .main-container {
      width: 30%;
    }
  }
`;
