import React from "react";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import splitRoutes from "../functions/SplitRoutes";

const Container = styled.div`
  &.main-header {
    display: none;
  }

  @media (min-width: 768px) {
    &.main-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2em 0;
    }
  }
`;

const Text = styled.p`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 800;
  }
`;

const Span = styled.span`
  vertical-align: middle;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

function NavAndSearch({routes}) {
  let routesArray = splitRoutes(routes);

  let lastTwoRoutes = routesArray;

  if (routesArray.length > 2) {
    // Uses the last two routes when routesArray is more than 2 entries
    lastTwoRoutes = routesArray.slice(
      routesArray.length - 2,
      routesArray.length,
    );
  }

  let history = useNavigate();

  const handleClick = route => {
    if (lastTwoRoutes[lastTwoRoutes.length - 1] !== route) {
      // History doesn't go back for most recent route
      history(-1);
    }
  };

  return (
    <Container className="main-header">
      <Text>
        {lastTwoRoutes.map((route, index) => (
          <span
            key={index}
            style={{
              verticalAlign: "middle",
              color: "#464F54",
              textTransform: "capitalize",
            }}>
            <Span onClick={() => handleClick(route)}>{route}</Span>

            <svg
              style={{
                verticalAlign: "middle",
                margin: "0 5px",
                display: "inline",
              }}
              width="6"
              height="11"
              viewBox="0 0 6 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M4.93656 5.25569L5.15145 5.47059L4.93656 5.68549L0.369972 10.2523L0.369966 10.2523C0.292258 10.33 0.283002 10.4509 0.342745 10.5384L0.376322 10.5773C0.454142 10.6494 0.570851 10.6566 0.656077 10.5984L0.696735 10.5633L5.63003 5.63003C5.70774 5.55233 5.717 5.4315 5.65726 5.34392L5.62216 5.30327L0.688857 0.369966C0.600798 0.281907 0.458026 0.281907 0.369966 0.369966C0.292258 0.447674 0.283002 0.568499 0.342744 0.656078L0.377842 0.696734L4.93656 5.25569Z"
                fill="#464F54"
                stroke="#464F54"
                strokeWidth="0.607843"
              />
            </svg>
          </span>
        ))}
      </Text>

     
    </Container>
  );
}

export default NavAndSearch;
