import React from "react";
import {NavLink} from "react-router-dom";
import styled from "styled-components";
import EmptyImage from "../../../assets/images/empty-product.svg";
import Button from "../../../components/common/buttons/button";

const EmptyStoreProducts = () => {
  return (
    <MainCont className="w-full flex flex-col items-center justify-content pb-20 pt-20">
      <img src={EmptyImage} alt="Empty Store Products" className="block" />
      <p className="text-white-text md:text-lg text-sm text-center py-5 xl:w-2/5 lg:w-1/2 md:w-3/4">
        It seems you have no available product for sale. Click on the button
        below to upload a product
      </p>
      <NavLink to="/products/add-product">
        <Button text="Upload product" blueBg />
      </NavLink>
    </MainCont>
  );
};

export default EmptyStoreProducts;

const MainCont = styled.div``;
