import {RiCloseCircleFill} from "react-icons/ri";
import {FaCheckCircle} from "react-icons/fa";
import {MdError} from "react-icons/md";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {hideNotification} from "../../../redux/actions/statusNotifications/index";
import {ThrindleNotification} from "../../../styles/globalStyles";

const Notifications = () => {
  let {notify} = useSelector(state => state.notification);

  let dispatch = useDispatch();

  return (
    <ThrindleNotification notify={notify}>
      <div className="flex items-center">
        <span className="ml-2 block">
          {notify && notify.status === 1 ? (
            <FaCheckCircle className="text-green-400 text-lg" />
          ) : notify && notify.status === 0 ? (
            <MdError className="text-secondary-yellow text-lg" />
          ) : notify && notify.status === 2 ? (
            <MdError className="text-secondary-error text-lg" />
          ) : null}
        </span>
        <p className="md:text-base text-sm text-white-text ml-2">
          {notify && notify.message}
        </p>
      </div>

      <button
        className="mr-2 block"
        onClick={() => {
          dispatch(hideNotification());
        }}>
        <RiCloseCircleFill className="text-white-text text-lg" />
      </button>
    </ThrindleNotification>
  );
};

export default Notifications;
