import React, {useEffect, useState} from "react";
import styled from "styled-components";
import OtpImage from "../../../assets/images/otp.png";
import MainContainer from "../../../components/common/mainContainer/mainContainer";
import CustomInput from "../../../components/customInput/customInput";
import {Header} from "../../../styles/globalStyles";
import Button from "../../../components/common/buttons/button";
import Input from "../../../components/common/inputs/input";
import {useDispatch, useSelector} from "react-redux";
import {showNotification} from "../../../redux/actions/statusNotifications";
import {resend_Otp, verify_Number} from "../../../redux/actions/authActions";
import {CLEAR_ERROR} from "../../../redux/constants";
import {useNavigate} from "react-router-dom";
import MobileBackBtn from "../../../components/common/buttons/MobileBackBtn";

function OTP() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  let navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const storeLoginNumber = useSelector(state => state.storeLoginNumber);
  const storeRegisterDetails = useSelector(state => state.storeRegisterDetails);
  const verifyNumber = useSelector(state => state.verifyNumber);
  const resendOtp = useSelector(state => state.resendOtp);
  const {loading: otpLoading, error: otpError, message: otpMessage} = resendOtp;
  const {loading, error, message} = verifyNumber;
  const {phone} = storeRegisterDetails;
  const {phone_number} = storeLoginNumber;

  const dispatch = useDispatch();

  const verify = () => {
    if (otp === "") {
      dispatch(
        showNotification({
          notify: {
            status: 2,
            message: `Please input the 4-digit code sent to your phone'`,
          },
        }),
      );
    } else {
      if (phone && phone !== "") {
        dispatch(verify_Number(phone, otp, navigate));
      } else {
        dispatch(verify_Number(phone_number, otp, navigate));
      }
    }
  };
  // useEffect(() => {
  //   dispatch({type: CLEAR_ERROR});
  // }, [dispatch]);

  const resendUserOtp = () => {
    if (phone && phone !== "") {
      dispatch(resend_Otp(phone));
    } else {
      dispatch(resend_Otp(phone_number));
    }
  };

  useEffect(() => {
    if (error && error !== "") {
      dispatch({type: CLEAR_ERROR});
      dispatch(
        showNotification({
          notify: {
            status: 2,
            message: error,
          },
        }),
      );
    }
    if (message && message !== "") {
      dispatch({type: CLEAR_ERROR});
      setOtp("");
      dispatch(
        showNotification({
          notify: {
            status: 1,
            message: "Account Verified successfully",
          },
        }),
      );
      navigate("/account-created");
    }
  }, [dispatch, message, error, navigate]);

  useEffect(() => {
    if (otpError && otpError !== "") {
      dispatch({type: CLEAR_ERROR});
      dispatch(
        showNotification({
          notify: {
            status: 2,
            message: otpError,
          },
        }),
      );
    }
    if (otpMessage && otpMessage !== "") {
      // dispatch({type: CLEAR_DETAILS});
      dispatch({type: CLEAR_ERROR});
      dispatch(
        showNotification({
          notify: {
            status: 1,
            message: otpMessage,
          },
        }),
      );
    }
  }, [dispatch, otpMessage, otpError]);

  return (
    <MainContainer removeFooter={true}>
      <Container className="main-container">
        <Image
          src="https://res.cloudinary.com/dqf6twyxt/image/upload/q_20/v1640030224/Thrindle/signUp_afbmaz.png"
          alt="signUp-banner"
          loading="eager"
        />

        <Container className="forms">
          <Header>Welcome</Header>
          <Text className="dark">
            Create a personal account by filling the form below
          </Text>

          <form className="mt-10">
            <Input
              type="text"
              name="fullName"
              id="fullName"
              labelText="Full name"
            />

            <Input type="email" name="email" id="email" labelText="Email" />

            <Input type="tel" name="phone" id="phone" labelText="Phone" />

            <Input
              type="password"
              name="password"
              id="password"
              labelText="Password"
              autoComplete="new-password"
            />

            <Input
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              labelText="Confirm Password"
              autoComplete="new-password"
            />

            <Button type="submit" text="Sign Up" auth={true} />
          </form>
        </Container>
      </Container>

      <Container className="otp-container"></Container>
      <Container className="otp-innerContainer">
        <Container className="otp-innerContainer-left">
          <MobileBackBtn to="/login" />
          <Text className="text-otp">Enter Verification Code</Text>
          {phone && phone !== "" ? (
            <p className="text-white-lightGray text-sm">
              {`Enter the 4 digit code sent to ${phone}`}
            </p>
          ) : (
            <p className="text-white-lightGray text-sm">
              {`Enter the 4 digit code sent to ${phone_number}`}
            </p>
          )}

          <CustomInput size={4} value={otp} onChange={val => setOtp(val)} />

          <Text className="resend">
            Didn&apos;t the receive code?{" "}
            {otpLoading ? (
              <span>loading...</span>
            ) : (
              <Span onClick={resendUserOtp}>Resend</Span>
            )}
          </Text>
          <Container className="button-container">
            <Button
              type="submit"
              text="Verify"
              longButton={true}
              onClick={verify}
              processing={loading}
            />
          </Container>
        </Container>

        <Image src={OtpImage} alt="otp-image" />
      </Container>
    </MainContainer>
  );
}

export default OTP;

const Container = styled.div`
  &.main-container {
    display: none;

    @media (min-width: 1024px) {
      display: flex;
      justify-content: space-between;
      margin-left: -5em;
      margin-right: -5em;
      max-height: 100vh;
    }
  }

  &.otp-container {
    display: none;

    @media (min-width: 1024px) {
      background: gray;
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 5;
      opacity: 0.6;
    }
  }

  &.otp-innerContainer {
    background: white;
    display: block;
    padding: 2em 0;
    border-radius: 12px;

    @media (min-width: 1024px) {
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 3em 0 3em 3em;
      width: 80%;
      transform: translate(-50%, -50%);
      z-index: 6;
    }

    @media (min-width: 1440px) {
      width: 60%;
    }
  }

  &.signUp-background {
    display: none;

    @media (min-width: 1024px) {
      display: block;
    }
  }

  &.forms {
    @media (min-width: 1024px) {
      &.forms {
        padding: 3em;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    @media (min-width: 1440px) {
      &.forms {
        width: 50%;
        padding: 4em 7em;
      }
    }
  }

  &.otp-innerContainer-left {
    @media (min-width: 425px) {
      text-align: center;
    }

    @media (min-width: 768px) {
      width: 70%;
      margin-left: auto;
      margin-right: auto;
    }

    @media (min-width: 1024px) {
      width: 40%;
      text-align: revert;
    }
  }

  &.button-container {
    margin: 1em 0;
  }
`;

const Image = styled.img`
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 1024px) {
    width: 60%;
  }
`;

const Text = styled.p`
  &.dark {
    font-size: 16px;
  }

  &.text-otp {
    color: #20639b;
    font-family: var(--font-bold);
    font-size: 28px;
  }

  &.resend {
    font-family: var(--font-light);
    font-size: 16px;
    margin: 1em 0;
  }
`;

const Span = styled.span`
  color: rgba(22, 108, 180, 0.9);
  cursor: pointer;
  font-family: var(--font-bold);

  &:hover {
    color: rgba(22, 108, 180, 1);
  }
`;
