import React, {useEffect} from "react";
import styled from "styled-components";
import {useFormik} from "formik";
import * as Yup from "yup";
import AuthContainer from "../../../components/common/authContainer/authContainer";
import Input from "../../../components/common/inputs/input";
import {Header, Text, Error} from "../../../styles/globalStyles";
import Button from "../../../components/common/buttons/button";
import UseAlternative from "../../../components/useAlternative/useAlternative";
import {useDispatch, useSelector} from "react-redux";
import {register} from "../../../redux/actions/authActions";
import {showNotification} from "../../../redux/actions/statusNotifications/index";
import {CLEAR_ERROR} from "../../../redux/constants";
import {useNavigate} from "react-router-dom";
import {SignUpIllustration} from "../../../assets/svg/signUp";

const Container = styled.div`
  &.button-container {
    margin: 1.5em 0;
  }
`;

function SignUp() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const userRegister = useSelector(state => state.userRegister);

  const {loading, error, message} = userRegister;

  const formik = useFormik({
    initialValues: {
      fullName: "",
      phone: "",
      password: "",
      confirmPassword: "",
    },

    validationSchema: Yup.object({
      fullName: Yup.string().required("Required"),
      phone: Yup.string()
        .matches(/^\d{11}$|^[+234]\d{13}$/, "Enter a valid number")
        .required("Required"),
      password: Yup.string()
        .matches(
          /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          "Password must contain at least 8 characters, one uppercase, one number and one special case character",
        )
        .required("Required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords don't match")
        .required("Please confirm your password"),
    }),

    onSubmit: values => {
      dispatch(
        register(
          values.fullName,
          values.phone,
          // values.email,
          values.password,
          values.confirmPassword,
        ),
      );
    },
  });

  // STATUSES
  useEffect(() => {
    if (error && error !== "") {
      dispatch({type: CLEAR_ERROR});
      dispatch(
        showNotification({
          notify: {
            status: 2,
            message: error,
          },
        }),
      );
    }
    if (message && message !== "") {
      dispatch({type: CLEAR_ERROR});
      formik.values.password = "";
      formik.values.phone = "";
      formik.values.confirmPassword = "";
      formik.values.fullName = "";
      // formik.values.email = "";
      dispatch(
        showNotification({
          notify: {
            status: 1,
            message: `User ${message}`,
          },
        }),
      );
      navigate("/otp");
    }
  }, [message, error, dispatch, formik.values, navigate]);

  useEffect(() => {
    if (formik.values.password === "") {
      document.getElementsByClassName("eye")[0].style.display = "none";
    } else {
      document.getElementsByClassName("eye")[0].style.display = "inline-block";
    }
  }, [formik.values.password]);

  useEffect(() => {
    if (formik.values.confirmPassword === "") {
      document.getElementsByClassName("eye")[1].style.display = "none";
    } else {
      document.getElementsByClassName("eye")[1].style.display = "inline-block";
    }
  }, [formik.values.confirmPassword]);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <AuthContainer image={SignUpIllustration}>
        <Container>
          <Header>Welcome</Header>
          <Text>Create a personal account by filling the form below</Text>

          <form onSubmit={formik.handleSubmit} className="mt-10">
            <Input
              type="text"
              name="fullName"
              id="fullName"
              labelText="Full name"
              value={formik.values.fullName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Error>
              {formik.errors.fullName && formik.touched.fullName ? (
                <>{formik.errors.fullName}</>
              ) : null}
            </Error>

            {/* <Input
              type="email"
              name="email"
              id="email"
              labelText="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Error>
              {formik.errors.email && formik.touched.email ? (
                <>{formik.errors.email}</>
              ) : null}
            </Error> */}

            <Input
              type="tel"
              name="phone"
              id="phone"
              labelText="Phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Error>
              {formik.errors.phone && formik.touched.phone ? (
                <>{formik.errors.phone}</>
              ) : null}
            </Error>

            <Input
              type="password"
              name="password"
              id="password"
              labelText="Password"
              autoComplete="new-password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Error>
              {formik.errors.password && formik.touched.password ? (
                <>{formik.errors.password}</>
              ) : null}
            </Error>

            <Input
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              labelText="Confirm Password"
              autoComplete="new-password"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Error>
              {formik.errors.confirmPassword &&
              formik.touched.confirmPassword ? (
                <>{formik.errors.confirmPassword}</>
              ) : null}
            </Error>

            <Container className="button-container">
              <Button type="submit" text="Sign Up" processing={loading} />
            </Container>
          </form>
          <UseAlternative
            text="Already have an account?"
            link="Sign in"
            signUp={true}
          />
        </Container>
      </AuthContainer>
    </>
  );
}

export default SignUp;
