import React from "react";
import InputWithFieldSet from "../inputs/inputWithFieldSet";
import {motion} from "framer-motion";
// import { GetWithHeaderShop } from "../../../utils/requestFunc";

const ShippinFeeModal = ({
  onClick,
  event,
  loading,
  price,
  region,
  header,
  priceChange,
  text,
  regionChange,
}) => {
  const handleSubmit = e => {
    if (region !== "" && price !== "") {
      e.preventDefault();

      event();
      e.target.blur();

      console.log(region, price);
    } else {
      e.preventDefault();
    }
  };
  return (
    <motion.div
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      exit={{opacity: 0}}
      transition={{
        duration: 0.8,
        ease: [0.6, 0.05, -0.01, 0.9],
      }}
      className="top-0 left-0 right-0 bottom-0 fixed flex justify-center items-center text-sm"
      style={{backgroundColor: "rgba(0,0,0, 0.5)", zIndex: "1000"}}>
      <div className="bg-white-main  w-[80%] md:w-[24rem] rounded-lg shadow-lg p-6">
        <div className="flex justify-between mb-8 items-center">
          <p className="font-bold">{header}</p>
          <svg
            onClick={onClick}
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#464F54"
            strokeWidth={1}>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <div>
          <InputWithFieldSet
            type="text"
            id="region"
            name="region"
            fieldset="Region"
            placeholder=""
            value={region}
            onChange={e => regionChange(e.target.value)}
          />
          <div className="my-4" />
          <InputWithFieldSet
            type="number"
            id="price"
            name="price"
            fieldset="Price"
            placeholder=""
            value={price}
            onChange={e => priceChange(e.target.value)}
          />
        </div>
        <div className="flex ml-auto justify-end gap-4 mt-8 w-28 items-center">
          <button
            onClick={onClick}
            className="border border-white-text2 px-4 rounded-md py-3">
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="bg-primary-light text-white-main w-20 whitespace-nowrap flex justify-center items-center  px-8 rounded-md py-3">
            {loading ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20px"
                height="20px"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid">
                <circle
                  cx="50"
                  cy="50"
                  fill="none"
                  stroke="#fff"
                  strokeWidth="10"
                  r="35"
                  strokeDasharray="164.93361431346415 56.97787143782138">
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    repeatCount="indefinite"
                    dur="1s"
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"></animateTransform>
                </circle>
              </svg>
            ) : (
              <p>{text}</p>
            )}
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default ShippinFeeModal;
