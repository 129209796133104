import React, {useState, useEffect} from "react";
import styled from "styled-components";
import Button from "../../../components/common/buttons/button";
import MainDropDown from "../../../components/common/inputs/DropdownInputs";
import MainContainer from "../../../components/common/mainContainer/mainContainer";
import market from "../../../assets/images/market.png";
import DropdownContent from "./DropdownContent";
import {useDispatch, useSelector} from "react-redux";
import {get_Markets} from "../../../redux/actions/storeActions";
import {STORE_SELLER_MARKET} from "../../../redux/constants";
import {useNavigate} from "react-router-dom";
import {showNotification} from "../../../redux/actions/statusNotifications";

function ChooseMarket() {
  const [value, setValue] = useState("");
  const [marketId, setMarketId] = useState("");
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getMarkets = useSelector(state => state.getMarkets);
  const {loading, error, markets} = getMarkets;


  useEffect(() => {
    dispatch(get_Markets());
  }, [dispatch]);

  const changeScreen = () => {
    if (value === "" && !marketId) {
      dispatch(
        showNotification({
          notify: {
            status: 2,
            message: `Please select a market`,
          },
        }),
      );
    } else {
      navigate("/store-details");
    }
  };

  return (
    <MainContainer>
      <Container className="main ">
        <Container className="main-form ">
          <Text className="header">Choose Market</Text>
          <Text>Choose your preferred market</Text>

          <Container className="dropdown">
            {/* <Dropdown list={list} fieldset="Market" /> */}
            <MainDropDown
              visible={show}
              toggleModal={() => setShow(!show)}
              closeModal={() => setShow(false)}
              label="Markets"
              value={value}
              placeholder="Select preferred market">
              <DropdownContent
                data={markets}
                value={value}
                loading={loading}
                error={error}
                chooseValue={(val, val1) => {
                  setValue(val);
                  setMarketId(val1);
                  const data = {name: val, id: val1};
                  dispatch({type: STORE_SELLER_MARKET, payload: data});
                }}
                closeModal={() => setShow(false)}
                getData={() => dispatch(get_Markets())}
              />
            </MainDropDown>
          </Container>

          <Container className="button-container">
            <Button text="Continue" arrow={true} onClick={changeScreen} />
          </Container>
        </Container>

        <Image src={market} alt="choose-market" />
      </Container>
    </MainContainer>
  );
}

export default ChooseMarket;

const Container = styled.div`
  &.dropdown {
    margin-top: 2em;
  }

  &.button-container {
    margin: 2em 0;
  }

  &.main {
    @media (min-width: 1024px) {
      display: flex;
      justify-content: space-between;
      margin: 2em 0;
    }
  }

  &.main-form {
    padding-top: 40px;
    @media (min-width: 1024px) {
      width: 30%;
    }
    @media (max-width: 800px) {
      width: 70%;
    }
    @media (max-width: 500px) {
      width: 100%;
    }
    /* @media (min-width: 2000px) {
      width: 55%;
    } */
  }
`;

const Text = styled.p`
  &.header {
    font-size: 24px;
    color: #20639b;
    font-family: var(--font-bold);
    margin: 1em 0 0.5em;
  }
`;

const Image = styled.img`
  margin: 2em 0;

  @media (min-width: 768px) {
    margin: 3em 0;
  }
  @media (max-width: 768px) {
    margin: 6em 0;
  }

  @media (min-width: 1024px) {
    width: 50%;
  }
`;
