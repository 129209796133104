import React from "react";
import styled from "styled-components";
import MainContainer from "../../components/common/mainContainer/mainContainer";
import Typewriter from "typewriter-effect";
import seller from "../../assets/images/seller.png";
import Button from "../../components/common/buttons/button";
import coins from "../../assets/images/coins.png";
import Subscribe from "../../components/subscribe/subscribe";
import OurExperience from "../../components/ourExperience/ourExperience";
import {GeneralPadding} from "../../styles/globalStyles";
import {Link} from "react-router-dom";

const Banner = styled.div`
  width: auto;
  background: linear-gradient(
    180deg,
    #e5efff 0%,
    rgba(229, 239, 255, 0.262661) 83.7%,
    rgba(229, 239, 255, 0) 100%
  );
  overflow-x: hidden;
  margin-left: -1.5em;
  margin-right: -1.5em;

  @media (min-width: 768px) {
    margin-left: -3em;
    margin-right: -3em;
    padding: 0em 3em;
  }

  @media (min-width: 1024px) {
    margin-left: -5em;
    margin-right: -5em;
    padding: 0em 5em;
  }

  @media (min-width: 2560px) {
    padding-left: 10em;
    padding-right: 10em;
    padding: 0em 10em;
  }
`;

const Container = styled.div`
  &.why {
    background: rgba(32, 99, 155, 0.1);
  }

  &.offset {
    margin-left: -1.5em;
    margin-right: -1.5em;

    @media (min-width: 768px) {
      margin-left: -3em;
      margin-right: -3em;
    }

    @media (min-width: 1024px) {
      margin-left: -5em;
      margin-right: -5em;
    }

    @media (min-width: 2560px) {
      margin-left: -10em;
      margin-right: -10em;
    }
  }

  .temp {
    @media (min-width: 768px) {
      flex: 1 1 auto;
    }

    &:nth-child(2) {
      margin-left: 0.1em;
    }
  }
`;

const Image = styled.img`
  padding: 2em;

  @media (min-width: 768px) {
    padding: 2em 0 2em 2em;
  }
`;

const Text = styled.p`
  @media (min-width: 1024px) {
    &.intro-text {
      padding-right: 4em;
    }
  }

  @media (min-width: 1440px) {
    &.intro-text {
      padding-right: 6em;
    }
  }
`;

function Home() {
  return (
    <MainContainer>
      <Banner>
        <div className="block md:flex md:items-center">
          <div className="w-full px-6 pb-6 pt-8 md:w-1/2 md:p-0">
            <Container className="">
              <h1
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="500"
                className="text-white-text whitespace-nowrap text-3xl md:text-4xl lg:text-5xl xl:text-7xl font-bold">
                Selling
                <span className="inline-block ml-2 text-primary-main text-3xl md:text-4xl lg:text-5xl xl:text-7xl lg:ml-4 font-bold mt-3">
                  <Typewriter
                    options={{
                      autoStart: true,
                      loop: true,
                      strings: [
                        "made easy",
                        '<span style="color:#F69F13">made faster</span>',
                      ],
                    }}
                  />
                </span>
                <span className="block">on Thrindle</span>
              </h1>

              <Text className="intro-text text-white-text2 text-base my-6 lg:w-full">
                Sell your products to consumers within and outside Lagos at no
                cost.
              </Text>

              <Container className="button-container">
                <Link to="/products">
                  <Button
                    type="button"
                    text="Start Selling"
                    shortButton={true}
                    scale={true}
                  />
                </Link>
              </Container>
            </Container>
          </div>

          <div
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-delay="50"
            className="w-full md:w-1/2">
            <Image src={seller} alt="seller" loading="eager" />
          </div>
        </div>

        <Container className="why bg-primary-dark md:px-4 md:pb-4">
          <p className="py-4 text-center text-white-darkGray font-Bold lg:text-2xl">
            Why sell on Thrindle?
          </p>
          <div className="md:flex md:items-center">
            <div className="temp grid grid-cols-3 gap-0.5">
              <div className="inline-block text-center p-2 bg-primary-main text-white-main rounded cursor-pointer">
                Convenience
              </div>
              <div className="inline-block text-center p-2 bg-primary-main text-white-main rounded cursor-pointer">
                24/7 Market
              </div>
              <div className="inline-block text-center p-2 bg-primary-main text-white-main rounded cursor-pointer">
                Quick & Easy
              </div>
            </div>
            <div className="temp grid grid-cols-2 gap-0.5 mt-0.5 md:mt-0">
              <div className="inline-block text-center p-2 bg-primary-main text-white-main rounded cursor-pointer">
                Wider reach
              </div>
              <div className="inline-block text-center p-2 bg-primary-main text-white-main rounded cursor-pointer">
                Increased sales
              </div>
            </div>
          </div>
        </Container>
      </Banner>
      <Container className="offset bg-primary-gray md:mt-12 ">
        <GeneralPadding className="md:flex md:items-center">
          <div className="text-center md:w-1/2 md:text-left">
            <p className="py-6 text-xl font-Bold text-white-darkGray lg:text-2xl xl:text-4xl">
              Earn Money without the stress
            </p>
            <div className="inline-block mx-auto">
              <Link to="/products">
                <Button text="Start Selling" shortButton={true} scale={true} />
              </Link>
            </div>
          </div>
          <img
            src={coins}
            alt="coins"
            className="p-6 md:w-1/2 md:pr-0 lg:h-[300px]"
          />
        </GeneralPadding>
      </Container>

      <Container className="offset">
        <OurExperience />
        <Subscribe />
      </Container>
    </MainContainer>
  );
}

export default Home;
