import React from "react";
import styled from "styled-components";
import {FaTimesCircle} from "react-icons/fa";
// import Image from "../../assets/images/image.jpg";
import SingleProductSize from "../../components/common/SingleProductSize";
import SingleProductColor from "../../components/common/SingleProductColor";
import {formatDate, getColorCode, numberFormat} from "../../utils/formatPrices";
import {useLocation} from "react-router-dom";
import NavAndSearch from "../../components/navAndSearch/NavAndSearch";
import NavMobile from "../../components/navAndSearch/NavMobile";

const OrderDetails = ({
  product,
  //  toggleModal,
  category,
  quantity,
  status,
  singleOrder,
}) => {
  const {name, original_price, images} = product;
  const {
    payment: {priceDetails},
  } = singleOrder;
  const location = useLocation();
  const url = location.pathname;
  // GETTING THE MAIN PRODUCT FROM PAYMENT/PRICE DETAILS WHICH IS AN ARRAY
  const mainProductPayment = priceDetails?.filter(
    item => item.productId === product?._id,
  );
  return (
    <MainCont className="w-full lg:px-10 xl:px-20 lg:py-5 xl:py-7 md:px-14 px-10 py-5">
      <NavAndSearch routes={url} />
      <NavMobile routes={url} />

      {/* <button>close </button> */}
      <div className="w-full flex flex-row justify-end">
        <FaTimesCircle className="cursor-pointer text-white-text text-xl" />
      </div>
      {/* Order Ids */}
      <div className="w-full flex flex-row items-center justify-between mt-5">
        <div>
          <div>
            <span className="text-xs text-primary-light font-SemiBold">
              Order ID:
            </span>
            <span className="text-xs text-primary-light font-SemiBold">
              &nbsp;{singleOrder?.order_no}
            </span>
          </div>
          <div className="">
            <span className="text-white-text text-xs">
              {formatDate(singleOrder?.updatedAt)}
            </span>
            {/* <span className="text-white-text text-xs">
              &nbsp;&nbsp;2 min ago
            </span> */}
          </div>
        </div>
        {/* Order Status */}
        <div
          className={
            status === "completed"
              ? "px-2 py-2 bg-secondary-success rounded-md"
              : status === "cancelled"
              ? "px-2 py-2 bg-secondary-error text-white-main rounded-md"
              : "px-2 py-2 bg-secondary-yellow rounded-md"
          }>
          <p className=" text-xs">{status}</p>
        </div>
      </div>
      {/* ORDER DETAILS */}
      <div className="w-full pt-5 ">
        <div className="title-cont rounded-md xl:w-10/12 mx-auto px-5 py-2">
          <p className="text-white-text font-Bold">Order Details</p>
        </div>
        {/* MainContent */}
        <div className="xl:w-10/12 mt-5 mx-auto">
          {/* Image container */}
          <div className="h-52 xl:w-1/2 md:w-3/5  mx-auto">
            <img
              src={`https://thrindleservices.herokuapp.com/api/thrindle/images/${images[0]}`}
              alt="Product"
              className="w-full h-full rounded-md block"
            />
          </div>
          {/* Product Details */}
          <div className="w-full mt-5">
            {/* Product Name */}
            <div className="flex flex-row items-center justify-between">
              <p className="text-white-text text-base font-Bold">{name}</p>
              <p className="text-primary-light font-Bold text-base">
                N{numberFormat(original_price)}
              </p>
            </div>
            {/* Product Quantity */}
            <div className="mt-5">
              <span className="text-base text-white-text font-Light">
                Quantity:
              </span>
              <span className="text-base text-white-text font-Bold">
                &nbsp;&nbsp;{quantity}
                {product?.unit}s
              </span>
            </div>
            {/* Product Category */}
            <div className="mt-5">
              <span className="text-base text-white-text font-Light">
                Category:
              </span>
              <span className="text-base text-white-text font-Bold">
                &nbsp;&nbsp;{category.name}
              </span>
            </div>
            {/* Product Category */}
            {mainProductPayment[0]?.size && (
              <div className="mt-5 flex flex-row items-center">
                <span className="text-base text-white-text font-Light">
                  Size:&nbsp;&nbsp;
                </span>
                <div className="flex flex-row items-center">
                  <SingleProductSize name={mainProductPayment[0]?.size} />
                  {/* {mainProductPayment?.size?.map((item, index) => (
                    <SingleProductSize name={item} key={index} />
                  ))} */}
                </div>
              </div>
            )}

            {/* Product Category */}
            {mainProductPayment[0]?.color && (
              <div className="mt-3 flex flex-row items-center">
                <span className="text-base text-white-text font-Light">
                  Color:&nbsp;&nbsp;
                </span>
                <div className="flex flex-row items-center">
                  <SingleProductColor
                    name={getColorCode(mainProductPayment[0]?.color)}
                  />
                  <SingleProductSize name={mainProductPayment[0]?.color} />
                  {/* {details?.color?.map((item, index) => (
                    <SingleProductColor name={item} key={index} />
                  ))} */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </MainCont>
  );
};

export default OrderDetails;

const MainCont = styled.div`
  .status-cont {
    background-color: rgba(3, 193, 92, 0.3);
  }
  .title-cont {
    background-color: #f7f7f7;
  }
  @media (max-width: 400px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
