import React from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {EARNING, WITHDRAWALS} from "../../pages/wallet/Wallet";
import {numberFormat} from "../../utils/formatPrices";

const BalanceContainer = ({title, num}) => {
  return (
    <MainCont className="w-full bg-white-main rounded-md h-36 flex flex-col items-center justify-center relative">
      <p className="text-primary-light font-Bold md:text-xl text-lg">{title}</p>
      {title === EARNING && (
        <p className="text-white-text font-Bold sm:text-lg text-base">
          N{numberFormat(num)}
        </p>
      )}

      {title === WITHDRAWALS && (
        <div className="absolute bottom-0 right-0 md:h-12 h-10  flex flex-row items-center justify-center withdraw cursor-pointer">
          <Link to="/wallet/withdrawal">
            <p className="text-white-main md:text-sm text-xs font-Bold withdraw-text">
              Make new withdrawal
            </p>
          </Link>
        </div>
      )}
    </MainCont>
  );
};

export default BalanceContainer;

const MainCont = styled.div`
  .withdraw {
    background: linear-gradient(144.89deg, #20639b 21.04%, #58cced 97.54%);
    width: 40%;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: rgba(32, 99, 155, 1);
    }
  }

  @media (max-width: 400px) {
    .withdraw {
      height: 35px;
      width: 45%;
    }
    .withdraw-text {
      font-size: 10px;
    }
  }
  @media (max-width: 350px) {
    height: 130px;
    .withdraw {
      height: 35px;
      width: 45%;
    }
    .withdraw-text {
      font-size: 8px;
    }
  }
`;
