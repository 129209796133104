import React from "react";
import styled from "styled-components";
import {MdCheckBox, MdCheckBoxOutlineBlank} from "react-icons/md";

const SingleDropdownSelect = ({ name, value, onClick }) => {

  

  return (
    <MainCont
      onClick={onClick}
      className="w-full h-14 flex flex-row items-center justify-between border-b border-solid border-white-ash cursor-pointer px-5">
      <p className="text-white-text sm:text-base text-sm font-Bold ">{name}</p>
      {name === value ? (
        <MdCheckBox className="text-primary-light text-2xl cursor-pointer" />
      ) : (
        <MdCheckBoxOutlineBlank className="text-primary-light text-2xl cursor-pointer" />
      )}
    </MainCont>
  );
};

export default SingleDropdownSelect;

const MainCont = styled.div`


`;
