import {
  USER_LOGIN_FAIL,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_LOADING,
  USER_REGISTER_FAIL,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_LOADING,
  CLEAR_ERROR,
  VERIFY_NUMBER_FAIL,
  VERIFY_NUMBER_SUCCESS,
  VERIFY_NUMBER_LOADING,
  RESEND_OTP_FAIL,
  RESEND_OTP_SUCCESS,
  RESEND_OTP_LOADING,
  STORE_LOGIN_NUMBER,
  FORGET_PASSWORD_FAIL,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_LOADING,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_LOADING,
  STORE_REGISTER_DETAILS,
  CLEAR_DETAILS,
  USER_LOGOUT,
  STORE_RESET_PHONE_NUMBER,
} from "../../constants/index";

export const userLoginReducer = (
  state = {
    loading: false,
    error: "",
    accessToken: "",
    refreshToken: "",
    user: {},
    message: "",
  },
  {type, payload},
) => {
  switch (type) {
    case USER_LOGIN_LOADING:
      return {...state, loading: true};
    case USER_LOGIN_SUCCESS:
      return {...state, loading: false, error: "", ...payload};
    case USER_LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        accessToken: "",
        refreshToken: "",
        error: payload,
      };
    case USER_LOGOUT:
      return {
        accessToken: "",
        refreshToken: "",
        user: {},
      };
    case CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
      };

    default:
      return state;
  }
};

export const userRegisterReducer = (
  state = {loading: false, error: "", message: ""},
  {type, payload},
) => {
  switch (type) {
    case USER_REGISTER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case USER_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: payload,
      };
    case USER_REGISTER_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
        message: "",
      };
    case CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
      };

    default:
      return state;
  }
};

export const verifyNumberReducer = (
  state = {loading: false, error: "", token: "", message: ""},
  {type, payload},
) => {
  switch (type) {
    case VERIFY_NUMBER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case VERIFY_NUMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        ...payload,
      };
    case VERIFY_NUMBER_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
        // token: "",
      };
    case CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
      };
    case CLEAR_DETAILS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        token: "",
      };

    default:
      return state;
  }
};

export const resendOtpReducer = (
  state = {loading: false, error: "", message: ""},
  {type, payload},
) => {
  switch (type) {
    case RESEND_OTP_LOADING:
      return {...state, loading: true};
    case RESEND_OTP_SUCCESS:
      return {...state, loading: false, message: payload, error: ""};
    case RESEND_OTP_FAIL:
      return {...state, loading: false, message: "", error: payload};
    case CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
      };
    default:
      return state;
  }
};

export const forgotPasswordReducer = (
  state = {loading: false, error: "", message: ""},
  {type, payload},
) => {
  switch (type) {
    case FORGET_PASSWORD_LOADING:
      return {
        ...state,
        loading: true,
      };
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: payload,
      };
    case FORGET_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
        message: "",
      };
    case CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
      };

    default:
      return state;
  }
};

export const resetPasswordReducer = (
  state = {loading: false, error: "", message: ""},
  {type, payload},
) => {
  switch (type) {
    case RESET_PASSWORD_LOADING:
      return {
        ...state,
        loading: true,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: payload,
      };
    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
        message: "",
      };
    case CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
      };

    default:
      return state;
  }
};

export const storeLoginNumberReducer = (
  state = {phone_number: ""},
  {type, payload},
) => {
  switch (type) {
    case STORE_LOGIN_NUMBER:
      return {...state, phone_number: payload};

    default:
      return state;
  }
};

export const storeRegisterDetailsReducer = (
  state = {phone: "", password: ""},
  {type, payload},
) => {
  switch (type) {
    case STORE_REGISTER_DETAILS:
      return {
        ...state,
        ...payload,
      };
    case CLEAR_DETAILS:
      return {
        ...state,
        phone: "",
        password: "",
      };

    default:
      return state;
  }
};
export const storeForgotPasswordNumberReducer = (
  state = {phoneNumber: ""},
  {type, payload},
) => {
  switch (type) {
    case STORE_RESET_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: payload,
      };

    default:
      return state;
  }
};
