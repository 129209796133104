import React, {useEffect, useState, useCallback} from "react";
import styled from "styled-components";
import SingleOrderProduct from "../../../components/productScreen/SingleOrderProduct";
import {mainApi} from "../../../utils/axiosInstance";
import Loader from "../../../assets/images/Loader.gif";
import EmptyOrder from "../../../assets/images/no-orders.png";

const EarningTabScreen = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const getAllOrder = useCallback(async () => {
    try {
      setLoading(true);
      const {data} = await mainApi.get(
        `${process.env.REACT_APP_API_URL}orders/getorders`,
      );
      const res = data.data;
      if (res) {
        setLoading(false);
        setOrders(res);
      }
    } catch (error) {
      setLoading(false);
      if (!error.response) {
        setError("Please check that you're connected");
      } else if (error.response) {
        setError(error.response.data.message);
      }
    }
  }, []);

  useEffect(() => {
    getAllOrder();
  }, [getAllOrder]);

  if (loading) {
    return (
      <div className="w-full mt-10 mx-auto">
        <img src={Loader} alt="loader" className="w-20 h-20 block mx-auto" />
      </div>
    );
  } else if (error) {
    return (
      <div className="w-full mt-10 mx-auto">
        <p className="md:text-lg text-secondary-error text-center text-base pb-5">
          {error}
        </p>
        <p
          onClick={() => getAllOrder()}
          className="underline text-center text-primary-main font-Bold cursor-pointer text-sm">
          Try Again.
        </p>
      </div>
    );
  } else if (!loading && !error && orders.length === 0) {
    return (
      <div className="w-full mt-10 mx-auto">
        <EmptyOrders src={EmptyOrder} alt="empty" className=" block mx-auto" />
      </div>
    );
  }

  return (
    <MainCont className="w-full">
      <p className="text-white-text py-2 font-Bold md:text-base xl:text-lg text-sm">
        Recent Orders
      </p>
      {orders.slice(0, 10).map((item, index) => (
        <SingleOrderProduct {...item} key={index} />
      ))}
    </MainCont>
  );
};

export default EarningTabScreen;

const MainCont = styled.div``;

const EmptyOrders = styled.img``;
