const headers = [
  {
    heading: "Thrindle Privacy Policy",
    link: "thrindePrivacy",
  },
  {
    heading: "Information We Collect",
    link: "information",
  },
  {
    heading: "How We Collect Your Information",
    link: "howWeCollect",
  },
  {
    heading: "Direct Interactions",
    link: "directInteractions",
  },
  {
    heading: "Automated Technologies",
    link: "Automated",
  },
  {
    heading: "How We Use Your Data",
    link: "howWeUse",
  },
  {
    heading: "Marketing Purpose",
    link: "marketing",
  },
  {
    heading: "Data We Share and Receive",
    link: "dataWeShare",
  },
  {
    heading: "Where Your Data Maybe Sent",
    link: "whereYourData",
  },
  {
    heading: "How We Keep Your Information Secure",
    link: "howWeKeep",
  },
  {
    heading: "How Long We May Keep Your Data",
    link: "howLong",
  },
  {
    heading: "Your Rights and Choices",
    link: "YourRight",
  },
  {
    heading: "Third Party Links",
    link: "thirdParty",
  },
  {
    heading: "Changes to Our Privacy Policy",
    link: "changes",
  },
  {
    heading: "How to Reach Us",
    link: "howToReach",
  },
];

export default headers;
